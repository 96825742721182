import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { ApiService, ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { IApiAction, IApiResponse } from '@app/libs/bitforce/api';

@Component({
  selector: 'ibm-smiles-editor-textual',
  templateUrl: './smiles-editor-textual.component.html',
  styleUrls: ['./smiles-editor-textual.component.scss'],
})
export class SmilesEditorTextualComponent implements OnInit {
  @Input()
  set smiles(smiles) {
    if (!this.smilesForm) {
      this.initForm();
    }
    this.smilesForm.patchValue({ smiles });
  }
  get smiles() {
    return this.smilesForm.value.smiles;
  }

  @Output()
  loadSmilesInVisualEditor: EventEmitter<string> = new EventEmitter<string>();

  smilesForm: UntypedFormGroup;
  isValidating = false;

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit() {}

  initForm() {
    this.smilesForm = this.fb.group({
      smiles: [null, Validators.required],
    });
  }

  loadInKetcher() {
    this.isValidating = true;
    this.apiService.actions
      .validateSmiles(this.smilesForm.value.smiles)
      .pipe(finalize(() => (this.isValidating = false)))
      .subscribe(
        (response: IApiResponse<IApiAction>) => {
          if (response.content.success) {
            this.loadSmilesInVisualEditor.emit(this.smilesForm.value.smiles);
          }
        },
        () => {
          this.toastMessagesService.show({
            title: 'ERROR',
            message: 'Error on smiles validation service',
            type: EToastMessageType.ERROR,
          });
        }
      );
  }

  validateSmiles() {
    this.isValidating = true;
    this.apiService.actions
      .validateSmiles(this.smilesForm.value.smiles)
      .pipe(finalize(() => (this.isValidating = false)))
      .subscribe(
        (response: IApiResponse<IApiAction>) => {
          if (response.content.success) {
            this.toastMessagesService.show({
              title: '',
              message: 'Smiles is valid',
              type: EToastMessageType.SUCCESS,
            });
          }
        },
        () => {
          this.toastMessagesService.show({
            title: 'ERROR',
            message: 'Error on smiles validation service',
            type: EToastMessageType.ERROR,
          });
        }
      );
  }
}
