import { Pipe, PipeTransform } from '@angular/core';
import * as errorMessages from './validation-message/validation-messages.json';

@Pipe({
  name: 'formValidationMessage',
})
export class FormValidationMessagePipe implements PipeTransform {
  transform(errors): any {
    if (!errors) {
      return '';
    }
    //  NOTE: Used for default Angular errors
    return Object.keys(errors)
      .map(key => errors[key]?.message || errorMessages[key] || 'Incorrect value')
      .join(', ');
  }
}
