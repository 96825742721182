import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ListItem, OverflowMenuDirective } from 'carbon-components-angular';

import { MinMaxValidator } from '@app/shared/form-utilities';
import { getRange, getRegexQueryFromKeyword } from '@bitf/utils';
import { Observable, Subscription } from 'rxjs';
import { EFilterType } from '@app/shared/enums/filters.enum';
import { ERetrosynthesisTypology } from '@app/core/models';
import { ESynthesisSourceType, ESynthesisTypologyFilter } from '@app/core/models/synthesis-super.model';

@Component({
  selector: 'ibm-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, OnDestroy {
  @ViewChild(OverflowMenuDirective) overflowMenu: OverflowMenuDirective;

  @Input()
  filters: EFilterType[] = [];
  @Input()
  filtersValues: IFiltersFormValue;
  @Input()
  header = 'Filter reactions collected by';
  @Input()
  loadFiltersData: Observable<{ [key: string]: any }> = new Observable();

  @Output()
  filtersChange: EventEmitter<IActiveFilters> = new EventEmitter<IActiveFilters>();
  @Output()
  showFiltersChange: EventEmitter<boolean> = new EventEmitter();
  @Output()
  cancel: EventEmitter<any> = new EventEmitter();
  @Output()
  search: EventEmitter<{
    searchValue: string;
    filterType: EFilterType;
  }> = new EventEmitter();

  filtersData: IFiltersData = {};
  isFiltersDataLoaded = false;
  showFilters = false;
  filtersForm: UntypedFormGroup;
  numberMin = 'Min confidence';
  numberMax = 'Max confidence';
  subscription = new Subscription();
  eFilterType = EFilterType;

  dateFormat = 'd-m-Y';
  moleculeSelectionOptions: ListItem[] = [
    { content: '-', selected: true },
    { value: 'with', content: 'With this', selected: false },
    { value: 'without', content: 'Without this', selected: false },
  ];
  favoriteSelectionOptions: ListItem[] = [
    { content: 'All', selected: true },
    { value: 'favorite', content: 'Favorite', selected: false },
    { value: 'not_favorite', content: 'Not favorite', selected: false },
  ];

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.filtersForm = this.fb.group({
      dateRange: [],
      favorite: [null],
      minConfidence: [
        null,
        [MinMaxValidator.MinMaxValidator('maxConfidence'), Validators.min(0), Validators.max(10)],
      ],
      maxConfidence: [null, [Validators.min(0), Validators.max(10)]],
      molecules: [],
      moleculeSelectionType: [this.moleculeSelectionOptions[0]],
      solvent: [false],
      catalyst: [false],
      generic: [false],
      status: [null],
      stepsFrom: [null, [MinMaxValidator.MinMaxValidator('stepsTo'), Validators.min(0), Validators.max(10)]],
      stepsTo: [null, [Validators.min(0), Validators.max(10)]],
      createdBy: [null],
      creatorFullName: [null],
      aiModel: [[]],
      modelType: [[]],
      retrosynthesisTypology: [null],
      synthesisSourceTypology: [null],
      retrosynthesisSource: [
        {
          value: null,
          disabled:
            this.filters.includes(EFilterType.SYNTHESIS_SOURCE_TYPOLOGY) &&
            this.filtersValues?.synthesisSourceTypology !== ESynthesisTypologyFilter.FROM_RETROSYNTHESIS,
        },
      ],
      synthesisProcedure: [null],
    });

    if (this.filtersValues) {
      this.filtersForm.patchValue(this.filtersValues);
    }
  }

  onFiltersFormSubmit() {
    if (!this.filtersForm.valid) {
      return;
    }

    this.filtersChange.emit(this.getActiveFilters());
    this.closeFilters();
  }

  onSearch(searchValue, filterType) {
    if (searchValue) {
      this.search.emit({ searchValue, filterType });
    }
  }

  getSmilesQuery() {
    const smilesString = this.filtersForm.value.molecules;
    if (!smilesString) {
      return null;
    }
    const type = this.filtersForm.value.moleculeSelectionType;

    if (!smilesString || !type) {
      return null;
    }
    let smilesQueryList = null;
    if (type === 'with') {
      smilesQueryList = smilesString
        .split(',')
        .map(smiles => getRegexQueryFromKeyword(smiles, ['smiles'])[0]);
    } else {
      smilesQueryList = smilesString
        .split(',')
        .map(smiles => getRegexQueryFromKeyword(smiles, ['smiles'], true)[0]);
    }

    return smilesQueryList ? { $and: smilesQueryList } : null;
  }

  onSynthesisSourceTypologyChange(item: ListItem) {
    if (item.id !== ESynthesisTypologyFilter.FROM_RETROSYNTHESIS) {
      this.filtersForm.get('retrosynthesisSource').disable();
      this.filtersForm.get('retrosynthesisSource').reset();
    } else {
      this.filtersForm.get('retrosynthesisSource').enable();
    }
  }

  resetFilters() {
    this.filtersForm.reset({
      aiModel: [],
    });
    this.filtersForm.updateValueAndValidity();
    this.onFiltersFormSubmit();
  }

  closeFilters(): void {
    this.showFilters = false;
    this.overflowMenu.close();
  }

  openFilters(): void {
    this.showFilters = true;
    this.overflowMenu.open();
  }

  shouldCloseFilters() {
    return !this.showFilters;
  }

  toggleFilters() {
    if (!this.isFiltersDataLoaded) {
      this.isFiltersDataLoaded = true;
      this.subscription.add(
        this.loadFiltersData.subscribe((filtersData: IFiltersData) => {
          this.filtersData = { ...this.filtersData, ...filtersData };
          setTimeout(() => {
            this.filtersForm.patchValue(this.filtersValues);
          });
        })
      );
    }

    this.showFiltersChange.emit(!this.overflowMenu.isOpen);
    if (!this.overflowMenu.isOpen) {
      this.openFilters();
    } else {
      this.closeFilters();
    }
  }

  private getActiveFilters(): IActiveFilters {
    const filters = {
      $and: [],
    };

    const dateRange: [Date, Date] = this.filtersForm.get('dateRange').value;
    if (dateRange) {
      dateRange[1] = new Date(dateRange[1].getTime() + 60 * 60 * 24 * 1000);
      filters.$and.push({
        $or: [
          getRange(dateRange[0], dateRange[1], 'createdOn'),
          getRange(dateRange[0], dateRange[1], 'modifiedOn'),
        ],
      });
    }

    // check for confidence range
    if (this.filters.includes(EFilterType.CONFIDENCE)) {
      const confidenceRange = getRange(
        this.filtersForm.get('minConfidence').value,
        this.filtersForm.get('maxConfidence').value,
        'confidence'
      );
      if (confidenceRange) {
        filters.$and.push(confidenceRange);
      }
    }

    // check for confidence range
    if (this.filters.includes(EFilterType.STEPS)) {
      const confidenceRange = getRange(
        this.filtersForm.get('stepsFrom').value,
        this.filtersForm.get('stepsTo').value,
        'steps'
      );
      if (confidenceRange) {
        filters.$and.push(confidenceRange);
      }
    }

    // check for molecules
    if (this.filters.includes(EFilterType.MOLECULES)) {
      const smilesQuery = this.getSmilesQuery();
      if (smilesQuery) {
        filters.$and.push(smilesQuery);
      }
    }

    // check for status
    if (this.filters.includes(EFilterType.STATUS) && this.filtersForm.get('status').value) {
      filters.$and.push({
        status: {
          $regex: this.filtersForm.get('status').value,
          $options: 'i',
        },
      });
    }

    // check ai training model
    if (
      this.filters.includes(EFilterType.TRAINING_MODEL_TYPE) &&
      this.filtersForm.get('modelType').value &&
      this.filtersForm.get('modelType').value.length
    ) {
      filters.$and.push({
        modelType: {
          $regex: this.filtersForm.get('modelType').value,
          $options: 'i',
        },
      });
    }

    // check for createdBy
    if (this.filters.includes(EFilterType.CREATED_BY) && this.filtersForm.get('createdBy').value) {
      filters.$and.push({
        createdBy: this.filtersForm.get('createdBy').value,
      });
    }

    // check for creator full name
    if (
      this.filters.includes(EFilterType.CREATOR_FULL_NAME) &&
      this.filtersForm.get('creatorFullName').value
    ) {
      filters.$and.push({
        creatorFullName: {
          $regex: this.filtersForm.get('creatorFullName').value,
          $options: 'i',
        },
      });
    }

    // check for aiModel
    if (
      this.filters.includes(EFilterType.AI_MODEL) &&
      this.filtersForm.get('aiModel').value &&
      this.filtersForm.get('aiModel').value.length
    ) {
      filters.$and.push({
        aiModel: { $in: this.filtersForm.get('aiModel').value },
      });
    }

    if (
      this.filters.includes(EFilterType.RETROSYNTHESIS_TYPOLOGY) &&
      this.filtersForm.get('retrosynthesisTypology').value
    ) {
      const retroTypology: ERetrosynthesisTypology = this.filtersForm.get('retrosynthesisTypology').value;
      switch (retroTypology) {
        case ERetrosynthesisTypology.INTERACTIVE:
          filters.$and.push({
            interactive: true,
          });
          break;
        case ERetrosynthesisTypology.AUTOMATIC:
          filters.$and.push({
            interactive: false,
            fromFile: false,
          });
          break;
        case ERetrosynthesisTypology.FROM_FILE:
          filters.$and.push({
            fromFile: true,
            interactive: false,
          });
          break;
      }
    }

    if (
      this.filters.includes(EFilterType.SYNTHESIS_SOURCE_TYPOLOGY) &&
      this.filtersForm.get('synthesisSourceTypology').value
    ) {
      const synthesisSourceTypology: ESynthesisTypologyFilter = this.filtersForm.get(
        'synthesisSourceTypology'
      ).value;
      switch (synthesisSourceTypology) {
        case ESynthesisTypologyFilter.FROM_TEXT:
          filters.$and.push({
            sourceType: ESynthesisSourceType.TEXT,
          });
          break;
        case ESynthesisTypologyFilter.FROM_RETROSYNTHESIS:
          filters.$and.push({
            sourceType: ESynthesisSourceType.RETROSYNTHESIS,
          });
          break;
      }
    }

    if (
      this.filters.includes(EFilterType.RETROSYNTHESIS_SOURCE) &&
      this.filtersForm.get('retrosynthesisSource').value &&
      !Array.isArray(this.filtersForm.get('retrosynthesisSource').value)
    ) {
      filters.$and.push({
        retrosynthesisId: this.filtersForm.get('retrosynthesisSource').value.id,
      });
    }

    if (
      this.filters.includes(EFilterType.SYNTHESIS_PROCEDURE) &&
      this.filtersForm.get('synthesisProcedure').value &&
      !Array.isArray(this.filtersForm.get('synthesisProcedure').value)
    ) {
      filters.$and.push({
        synthesisId: this.filtersForm.get('synthesisProcedure').value.id,
      });
    }

    // check for favorite
    if (this.filters.includes(EFilterType.FAVORITE) && this.filtersForm.get('favorite').value) {
      filters.$and.push({
        favorite: this.filtersForm.get('favorite').value === 'favorite',
      });
    }

    return {
      filtersQuery: filters.$and.length ? filters : null,
      filtersValues: filters.$and.length ? this.filtersForm.value : null,
    };
  }

  ngOnDestroy() {
    this.closeFilters();
    this.subscription.unsubscribe();
  }
}

export interface IFiltersFormValue {
  dateRange?: any;
  minConfidence?: number;
  maxConfidence?: number;
  molecules?: string;
  solvent?: boolean;
  catalyst?: boolean;
  generic?: boolean;
  favorite?: boolean;
  moleculeSelectionType?: ListItem;
  status?: ListItem;
  createdBy?: string;
  aiModel?: string[];
  isInteractive?: boolean;
  retrosynthesisSource?: ListItem;
  synthesisProcedure?: ListItem;
  retrosynthesisTypology?: string;
  synthesisSourceTypology?: string;
}

export interface IActiveFilters {
  filtersQuery?: any;
  filtersValues?: IFiltersFormValue;
}

export interface IFiltersData {
  [key: string]: {
    options: ListItem[];
    isLoading: boolean;
  };
}
