import { Molecule } from '.';
import { SuperModel } from './super-model.model';

export class TmapReaction extends SuperModel {
  product: string;
  reactants: string[];
  rxnClass: string;
  source: string;
  url: string;
  productMolecule: Molecule;
  reactantsMolecules: Molecule[];

  constructor(data: any = {}) {
    super(data);

    if (data.productMolecule) {
      this.productMolecule = new Molecule(data.productMolecule);
    }

    if (data.reactantsMolecules) {
      this.reactantsMolecules = data.reactantsMolecules.map(
        reactantsMolecule => new Molecule(reactantsMolecule)
      );
    }
  }

  get smiles() {
    return [this.reactantsMolecules.map(r => r.smiles).join('.')]
      .concat(this.productMolecule.smiles)
      .join('>>');
  }

  get serialised() {
    return {};
  }
}
