import { Retrosynthesis } from '.';
import { SynthesisSuperModel } from './synthesis-super.model';

export class SynthesisProcedure extends SynthesisSuperModel {
  retrosynthesis: Retrosynthesis;
  hasSynthesisExecutions: boolean;

  constructor(data: any) {
    super(data);

    if (data.retrosynthesis) {
      this.retrosynthesis = new Retrosynthesis(data.retrosynthesis);
    }
    this.isProcedure = true;
  }

  get serialised() {
    return {
      reactors: this.id,
    };
  }
}
