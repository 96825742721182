<ibm-modal [open]="open">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>Site upgrades:</h3>
  </ibm-modal-header>
  <div ibmModalContent
    class="mx-5">
    <ul ibmList>
      <li ibmListItem><strong>UI enhancements</strong> for a more intuitive user experience</li>
      <li ibmListItem><strong>Enhanced parallelization</strong> for faster results</li>
      <li ibmListItem><strong>Unlimited API access</strong> with subscription</li>
      <li ibmListItem><strong>Multiple payment options</strong> to suit your needs:
        <ul ibmList>
          <li ibmListItem><strong>Pay as you go</strong> to buy only what you need, or get a</li>
          <li ibmListItem><strong>Teams subscription</strong> for higher usage</li>
        </ul>
      </li>
    </ul>
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--primary"
      data-e2e="closeReleaseNotesBtn"
      (click)="onClose()">Ok</button>
  </ibm-modal-footer>
</ibm-modal>