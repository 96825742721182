import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { isCallingApi } from '@bitf/utils';
import { environment } from '@env/environment';
import { AuthService } from '@bitf/services';
@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isCallingLoginEndpoints = req.url.includes('/auth/security');
    const isPublicApi = req.url.includes('/retrosynthesis-snapshots');

    if (
      !isPublicApi &&
      isCallingApi(environment, req) &&
      !isCallingLoginEndpoints &&
      this.authService.authToken
    ) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.authService.authToken}`),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
