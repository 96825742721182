import { SuperModel } from './super-model.model';
import { Reaction } from './reaction.model';

export class PredictionTask extends SuperModel {
  attempts: Reaction[];
  status: string;
  request: any;
  type: string;
  canGenerateOutcomes: boolean;
  projectId: string;
  aiModel: string;

  constructor(object: any = {}) {
    super(object);
    if (object.attempts) {
      this.attempts = this.attempts.map(attempt => new Reaction(attempt));
    }
  }

  get serialised() {
    return { taskId: this.id };
  }
}
