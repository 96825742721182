import { AfterContentChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SimilarReaction } from '@app/core/models';
import { ApiService, ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { copyToClipboard } from '@app/shared/common-utilities/clipboard';
import { EAiModelType } from '@app/shared/enums/ai-model.enum';
import { PaginationModel } from 'carbon-components-angular';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ibm-similar-reactions-documents-list',
  templateUrl: './similar-reactions-documents-list.component.html',
  styleUrls: ['./similar-reactions-documents-list.component.scss'],
})
export class SimilarReactionsDocumentsListComponent implements OnInit, AfterContentChecked {
  @ViewChild('viewDocs') viewDocs: ElementRef;
  @Input() smiles: string;
  @Input() aiModel: string;
  @Input() aiModelType: EAiModelType;
  @Input() showPanel = false;
  @Input() panelWidth = 800;

  similarReactions: SimilarReaction[] = [];
  panelPosition = { top: '0', left: '0' };
  isLoading = false;
  paginationModel: PaginationModel[] = [];

  constructor(private apiService: ApiService, private toastMessagesService: ToastMessagesService) {}

  ngOnInit(): void {
    this.panelWidth = Math.min(this.panelWidth, 800);
  }

  ngAfterContentChecked() {
    const data = this.viewDocs?.nativeElement?.getBoundingClientRect();
    this.panelPosition.top = `${data?.top + data?.height}px`;
    this.panelPosition.left = `${data?.right - this.panelWidth}px`;
  }

  onClickOutside() {
    this.showPanel = false;
  }

  onTogglePanel() {
    this.showPanel = !this.showPanel;
    if (this.showPanel) {
      this.isLoading = true;
      this.apiService.actions
        .getSimilarReactionDocuments({
          smiles: this.smiles,
          aiModel: this.aiModel,
          aiModelType: this.aiModelType,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(response => {
          this.similarReactions = response.content;
          this.paginationModel = this.similarReactions.map(similarReaction => ({
            currentPage: 1,
            pageLength: 1,
            totalDataLength: similarReaction.references?.length,
          }));
        });
    } else {
      this.similarReactions = [];
    }
  }

  onSelectPage(currentPage: number, paginatorIndex: number) {
    this.paginationModel[paginatorIndex].currentPage = currentPage;
  }

  onViewDocument($event: Event, url: string) {
    $event.stopPropagation();
    window.open(url);
  }

  async onCopySmiles($event: Event, smiles: string) {
    $event.stopPropagation();
    const copied = await copyToClipboard(smiles);
    if (copied) {
      this.toastMessagesService.show({
        title: 'Info',
        message: 'Smiles copied to clipboard',
        type: EToastMessageType.SUCCESS,
      });
    } else {
      // eslint-disable-next-line  @typescript-eslint/quotes
      this.toastMessagesService.show({
        title: 'Error',
        message: `Couldn't copy smiles to clipboard`,
        type: EToastMessageType.ERROR,
      });
    }
  }
}
