import { Injectable, Injector } from '@angular/core';
import { ApiSuperService } from '@app/libs/bitforce/api';

@Injectable({
  providedIn: 'root',
})
export class AiTrainingFilesService extends ApiSuperService {
  // mockApiUrl = 'https://340cac68-939c-45bf-aca0-2d22ede80dc9.mock.pstmn.io/api/v1/';

  constructor(injector: Injector) {
    super(injector);
    this.name = 'ai-training-files';
  }
}
