import { Injectable, Injector } from '@angular/core';
import { ApiSuperService } from '@app/libs/bitforce/api';

@Injectable({
  providedIn: 'root',
})
export class RetrosynthesisSequencesService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'sequences';
  }
}
