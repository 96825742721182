<ibm-modal [open]="open"
  [hasScrollingContent]="false">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>Predict retrosynthesis</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <form (ngSubmit)="createRetro()"
      [formGroup]="form">
      <ibm-select-or-create-project toAddLabel="retrosynthesis"
        [form]="form"
        [projects]="projects"
        [selectedProject]="dialogData?.project"></ibm-select-or-create-project>

      <fieldset class="bx--fieldset mt-6"
        *ngIf="form.value.projectGroup?.selectedProject || form.value.projectGroup?.projectName">
        <legend class="bx--label">Start From</legend>
        <ibm-radio-group formControlName="startFrom"
          class="mt-2">
          <ibm-radio [value]="TARGET_MOLECULE">
            Target molecule
          </ibm-radio>
          <ibm-radio [value]="FROM_FILE">
            From file
          </ibm-radio>
        </ibm-radio-group>

        <div class="mt-8"
          *ngIf="form.value.startFrom === FROM_FILE">
          <h6 class="mb-2">Upload retrosynthetic data</h6>
          <p class="font-caption-02 mb-4">
            You can upload text files containing one reaction SMILES per line ({{maxFileSizeString }} max
            size).<br />
            <a (click)="onDownloadExampleFile()"
              title="Download file">Download an example file</a>
          </p>
          <ibm-mlrp-file-uploader [dropText]="'Drag and Drop files here or upload'"
            [maxFileSize]="uploadFileMaxSize"
            [multiple]="false"
            [acceptedExtensions]="['.txt']"
            (selectedFiles)="onSelectedFiles($event)"></ibm-mlrp-file-uploader>
        </div>
      </fieldset>
    </form>
  </div>
  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="closeModal()">Close</button>
    <button ibmButton="primary"
      (click)="createRetro()"
      [disabled]="!form.valid">
      Predict retrosynthesis
    </button>
  </ibm-modal-footer>
</ibm-modal>