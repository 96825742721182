<ibm-mlrp-header [hasSidenav]="false"
  [isModal]="inModal">
  <ibm-header-global>
    <div class="smiles-editor-header__buttons-bar">
      <button class="bx--btn border-radius-left"
        [ngClass]="{'active': currentEditor === eSmilesEditorType.VISUAL}"
        (click)="changeEditorType.emit(eSmilesEditorType.VISUAL)">
        Visual editor
      </button>
      <button class="bx--btn border-radius-right"
        [ngClass]="{'active': currentEditor === eSmilesEditorType.TEXTUAL}"
        (click)="changeEditorType.emit(eSmilesEditorType.TEXTUAL)">
        Smiles string editor
      </button>
    </div>
    <button ibmButton
      type="primary"
      [disabled]="disableActions"
      (click)="action.emit()">
      {{actionLabel}}
    </button>
    <ibm-header-action title="close"
      (click)="close.emit()">
      <svg icon
        ibmIconCloseFilled
        size="16"></svg>
    </ibm-header-action>
  </ibm-header-global>
</ibm-mlrp-header>