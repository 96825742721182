import { SuperModel } from '@models/super-model.model';

export class ActionSchema extends SuperModel {
  name: string;
  label: string;
  description: string;
  supported: boolean;
  parameters: ActionSchemaParameter[];

  constructor(data: any) {
    super(data);
    this.parameters = this.parameters || [];
    this.parameters = this.parameters.map(p => new ActionSchemaParameter(p));
  }

  get serialised() {
    return {};
  }
}

export class ActionSchemaParameter extends SuperModel {
  name: string;
  label: string;
  type: string;
  multiple: boolean;
  optional: boolean;
  values: {
    name: string;
    label: string;
    value: string;
  }[];
  units: string[];
  validations: { type: 'remote' | 'required' }[];

  constructor(data: any) {
    super(data);
    this.validations = this.validations || [];
  }

  get serialised() {
    return {};
  }
}
