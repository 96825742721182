export enum ETableList {
  PROJECTS_LIST = 'PROJECTS_LIST',
  REACTIONS_LIST = 'REACTIONS_LIST',
  REACTIONS_COMPLETION_LIST = 'REACTIONS_COMPLETION_LIST',
  RETROSYNTHESIS_LIST = 'RETROSYNTHESIS_LIST',
  SYNTHESIS_PROCEDURE_LIST = 'SYNTHESIS_PROCEDURE_LIST',
  SYNTHESIS_EXECUTION_LIST = 'SYNTHESIS_EXECUTION_LIST',
  TRAINING_MODELS_LIST = 'TRAINING_MODELS_LIST',
  TRAINING_MODELS_ADMIN_LIST = 'TRAINING_MODELS_ADMIN_LIST',
}
