import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Project } from '@models';
import { DialogsService } from '@services';
import { IDialogCloseEvent } from '@services/dialog/dialog.interface';

import { CreateProjectDialogComponent } from '@app/shared/components/create-project-dialog/create-project-dialog.component';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';

@Component({
  selector: 'ibm-new-project-button',
  templateUrl: './new-project-button.component.html',
  styleUrls: ['./new-project-button.component.scss'],
})
export class NewProjectButtonComponent implements OnInit {
  constructor(private dialogsService: DialogsService, private router: Router) {}

  ngOnInit(): void {}

  onNewProject() {
    this.dialogsService.dialog
      .open(CreateProjectDialogComponent, {})
      .afterClosed()
      .subscribe((closeEvent: IDialogCloseEvent<Project>) => {
        if (closeEvent.status === EDialogCloseEventStatus.OK) {
          this.router.navigate(['projects', closeEvent.data.id, closeEvent.data.name, 'dashboard']);
        }
      });
  }
}
