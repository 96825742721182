<h5>{{title}}</h5>
<div>
  <h6 class="mt-6">Owner</h6>
  <div class="mt-4"
    *ngIf="owner">{{owner}}</div>
</div>

<div>
  <h6 class="mt-6">Date of creation</h6>
  <div class="mt-4">{{createdOn | date: 'dd/MM/yyyy'}}</div>
</div>

<div *ngIf="aiModel">
  <h6 class="mt-6">AI Model</h6>
  <div class="mt-4">{{aiModel}}</div>
</div>