import { Injectable, Injector } from '@angular/core';

import { ApiSuperService } from '@bitf/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class SynthesisExecutionsService extends ApiSuperService {
  // mockApiUrl = 'https://83b3046e-8bbe-4b29-a893-6a9d7059324b.mock.pstmn.io/api/v1/';

  constructor(injector: Injector) {
    super(injector);
    this.name = 'synthesis-executions';
  }
}
