import { Component, Injector } from '@angular/core';
import { BaseModal } from 'carbon-components-angular';

@Component({
  selector: 'ibm-training-hours-help-dialog',
  templateUrl: './training-hours-help-dialog.component.html',
  styleUrls: ['./training-hours-help-dialog.component.scss'],
})
export class TrainingHoursHelpDialogComponent extends BaseModal {
  constructor() {
    super();
  }
}
