import {
  FadeModule,
  UserAvatarFilledAltModule,
  AddModule,
  HomeModule,
  FoldersModule,
  StarModule,
  ChemistryModule,
  MigrateModule,
  SankeyDiagramModule,
  CollaborateModule,
  EducationModule,
  LaunchModule,
  ArrowRightModule,
  StarFilledModule,
  VideoModule,
  SettingsModule,
  DeleteModule,
  EmailModule,
  SendModule,
  EditModule,
  CloseFilledModule,
  ViewModule,
  CheckmarkFilledModule,
  ErrorFilledModule,
  PauseOutlineFilledModule,
  StopFilledModule,
  DraggableModule,
  DownloadModule,
  FilterModule,
  LogoTwitterModule,
  FaceWinkModule,
  CloseModule,
  FaceSatisfiedModule,
  FaceDissatisfiedModule,
  ZoomOutModule,
  ZoomInModule,
  CopyModule,
  InformationModule,
  InformationFilledModule,
  CopyFileModule,
  ChartLineSmoothModule,
  GeneratePdfModule,
  ChartLineModule,
  FlowModule,
  PauseFilledModule,
  PlayFilledModule,
  RenewModule,
  WarningModule,
  WarningFilledModule,
  WarningAltFilledModule,
  DataVis_1Module,
  ChevronDownModule,
  InProgressModule,
  SkipForwardOutlineFilledModule,
  TimeModule,
  DashboardModule,
  SchematicsModule,
  UserMultipleModule,
  EventsAltModule,
  UserAdminModule,
  UserModule,
  ReportModule,
  SaveModule,
  MachineLearningModelModule,
  ImageModule,
  NotebookModule,
  FingerprintRecognitionModule,
  ShareModule,
  IdeaModule,
  TemperatureHotModule,
  ChevronUpModule,
  CameraModule,
  OpenPanelRightModule,
  OpenPanelFilledRightModule,
  HelpModule,
  CheckboxCheckedModule,
  CheckboxModule as CheckboxIconModule,
  InProgressErrorModule,
  RocketModule,
  FitToScreenModule,
  DocumentViewModule,
  InProgressWarningModule,
  DataBaseModule,
  DragVerticalModule,
  AddCommentModule,
  ArrowLeftModule,
  VisualRecognitionModule,
  TaskViewModule,
  TextCreationModule,
  ArrowsHorizontalModule,
  ErrorModule,
  SubtractModule,
  Network_1Module,
  Network_2Module,
  Network_3Module,
  ObjectStorageModule,
  WalletModule,
} from '@carbon/icons-angular';
// REF https://carbon-elements.netlify.app/icons/examples/preview/
import {
  SideNavModule,
  HeaderModule,
  GridModule,
  DialogModule,
  PlaceholderModule,
  ModalModule,
  NotificationModule,
  LoadingModule,
  TableModule,
  PaginationModule,
  TagModule,
  StructuredListModule,
  SearchModule,
  SkeletonModule,
  ButtonModule,
  DropdownModule,
  InputModule,
  DatePickerModule,
  NumberModule,
  CheckboxModule,
  RadioModule,
  ComboBoxModule,
  SliderModule,
  TilesModule,
  SelectModule,
  ListModule,
  TabsModule,
  ProgressIndicatorModule,
  ProgressBarModule,
  FileUploaderModule,
  InlineLoadingModule,
  AccordionModule,
  ToggleModule,
  IconModule,
  BreadcrumbModule,
} from 'carbon-components-angular';

export const CARBON_ICONS = [
  FadeModule,
  UserAvatarFilledAltModule,
  AddModule,
  HomeModule,
  FoldersModule,
  StarModule,
  ChemistryModule,
  MigrateModule,
  SankeyDiagramModule,
  CollaborateModule,
  EducationModule,
  LaunchModule,
  StarFilledModule,
  VideoModule,
  SettingsModule,
  DeleteModule,
  EmailModule,
  SendModule,
  EditModule,
  CloseFilledModule,
  ArrowRightModule,
  ViewModule,
  CheckmarkFilledModule,
  ErrorFilledModule,
  ErrorModule,
  PauseOutlineFilledModule,
  StopFilledModule,
  DraggableModule,
  DownloadModule,
  FilterModule,
  LogoTwitterModule,
  FaceWinkModule,
  CloseModule,
  LogoTwitterModule,
  FaceSatisfiedModule,
  FaceDissatisfiedModule,
  ZoomOutModule,
  ZoomInModule,
  EditModule,
  CopyModule,
  InformationModule,
  InformationFilledModule,
  CopyFileModule,
  ChartLineSmoothModule,
  GeneratePdfModule,
  ChartLineModule,
  FlowModule,
  PauseFilledModule,
  PlayFilledModule,
  RenewModule,
  WarningModule,
  WarningFilledModule,
  WarningAltFilledModule,
  DataVis_1Module,
  ChevronDownModule,
  InProgressModule,
  SkipForwardOutlineFilledModule,
  TimeModule,
  DashboardModule,
  SchematicsModule,
  UserMultipleModule,
  EventsAltModule,
  UserAdminModule,
  UserModule,
  ReportModule,
  SaveModule,
  MachineLearningModelModule,
  ImageModule,
  NotebookModule,
  FingerprintRecognitionModule,
  ShareModule,
  IdeaModule,
  TemperatureHotModule,
  ChevronUpModule,
  CameraModule,
  OpenPanelRightModule,
  OpenPanelFilledRightModule,
  HelpModule,
  ChevronDownModule,
  ChevronUpModule,
  CheckboxIconModule,
  CheckboxCheckedModule,
  InProgressErrorModule,
  RocketModule,
  FitToScreenModule,
  DocumentViewModule,
  InProgressWarningModule,
  DataBaseModule,
  DragVerticalModule,
  AddCommentModule,
  ArrowLeftModule,
  VisualRecognitionModule,
  TaskViewModule,
  TextCreationModule,
  ArrowsHorizontalModule,
  SubtractModule,
  Network_1Module,
  Network_2Module,
  Network_3Module,
  ObjectStorageModule,
  WalletModule,
];

export const CARBON_COMPONENTS = [
  SideNavModule,
  HeaderModule,
  GridModule,
  DialogModule,
  PlaceholderModule,
  ModalModule,
  NotificationModule,
  LoadingModule,
  TableModule,
  PaginationModule,
  TagModule,
  StructuredListModule,
  SearchModule,
  SkeletonModule,
  ButtonModule,
  DropdownModule,
  InputModule,
  DatePickerModule,
  NumberModule,
  CheckboxModule,
  RadioModule,
  ComboBoxModule,
  SliderModule,
  TilesModule,
  SelectModule,
  ListModule,
  TabsModule,
  ProgressIndicatorModule,
  ProgressBarModule,
  FileUploaderModule,
  InlineLoadingModule,
  AccordionModule,
  ToggleModule,
  IconModule,
  BreadcrumbModule,
];

export const CARBON_MODULES = [...CARBON_ICONS, ...CARBON_COMPONENTS];
