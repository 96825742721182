import { Directive, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ibmNativeElementInjector]',
})
export class NativeElementInjectorDirective {
  constructor(private el: ElementRef, private control: NgControl) {
    setTimeout(() => {
      (control.control as any).nativeElement = el.nativeElement;
    });
  }
}
