import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  EConfidenceTagColor,
  EConfidenceTagName,
  EConfidenceTagTextColor,
  Reaction,
  ReactionCompletion,
  RetrosynthesisSequence,
} from '@models';
import { ListItem } from 'carbon-components-angular';

@Component({
  selector: 'ibm-sequence-bar',
  templateUrl: './sequence-bar.component.html',
  styleUrls: ['./sequence-bar.component.scss'],
})
export class SequenceBarComponent implements OnInit {
  @Input() sequence: RetrosynthesisSequence | Reaction | ReactionCompletion;
  @Input() sequences: ListItem;

  @Output() selected: EventEmitter<ListItem> = new EventEmitter();

  eConfidenceTagName = EConfidenceTagName;
  eConfidenceTagTextColor = EConfidenceTagTextColor;
  eConfidenceTagColor = EConfidenceTagColor;

  constructor() {}

  ngOnInit(): void {}
}
