import { SuperModel } from '@models/super-model.model';
import { RetrosynthesisMolecule } from './retrosynthesis-molecule.model';

export class RetrosynthesisSibling extends SuperModel {
  createdBy: string;
  id: string;
  modifiedBy: string;
  smiles: string;
  isThermal: boolean;
  isPhotochemical: boolean;
  reactions: IRetrosynthesisSiblingChildren[];

  constructor(data: any = {}) {
    super(data);

    if (data.reactions) {
      this.reactions = data.reactions.map(r => ({
        ...r,
        children: r.children.map(s => new RetrosynthesisMolecule(s)),
      }));
    }
  }

  get serialised() {
    return {};
  }
}

export interface IRetrosynthesisSiblingChildren {
  children: RetrosynthesisMolecule[];
  rclass: string;
  confidence: number;
  confidenceTag: string;
  isConfidenceComputed?: boolean;
  id: string;
  smiles: string;
  reactionSmiles: string;
  isThermal: boolean;
  isPhotochemical: boolean;
  custom: boolean;
}
