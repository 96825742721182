import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { AppSessionService } from '@services';

@Component({
  selector: 'ibm-mlrp-header[hasSidenav]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  @HostBinding('class.bx--header') headerClass = true;
  @Input() hasSidenav;
  @Input() isModal;
  @Input() isUserMenuActive;

  constructor(public appSessionService: AppSessionService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasSidenav && !this.isModal) {
      setTimeout(() => (this.appSessionService.isLeftSidenavActive = changes.hasSidenav.currentValue));
    }
  }
}
