import { SuperModel } from '@models/super-model.model';

import { Reactor } from '@models';

export class RoboRxnSummary extends SuperModel {
  reactors: Reactor[];

  constructor(data: any) {
    super(data);

    if (data.reactors) {
      this.reactors = data.reactors.map(item => new Reactor(item));
    }
  }

  get serialised() {
    return {
      reactors: this.reactors,
    };
  }
}
