export async function copyToClipboard(value: string, element = document.body) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari && navigator.clipboard) {
    try {
      await navigator.clipboard.write([
        // @ts-ignore
        new ClipboardItem({
          'text/plain': new Promise(resolve => resolve(new Blob([value], { type: 'text/plain' }))),
        }),
      ]);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  } else {
    let input: HTMLInputElement = null;
    let result = true;

    try {
      input = document.createElement('input');
      input.setAttribute('value', value);
      input.style.position = 'absolute';
      input.style.top = '-10000px';
      element.appendChild(input);
      input.select();
      input.focus();
      document.execCommand('copy');
    } catch (error) {
      result = false;
    } finally {
      element.removeChild(input);
    }

    return result;
  }
}
