import { SuperModel } from '@models/super-model.model';

export class RoboRxnConfig extends SuperModel {
  webcamUrl: string;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {
      webcamUrl: this.webcamUrl,
    };
  }
}
