export enum EFilterType {
  DATE = 'DATE',
  MOLECULES = 'MOLECULES',
  CONFIDENCE = 'CONFIDENCE',
  STATUS = 'STATUS',
  FAVORITE = 'FAVORITE',
  STEPS = 'STEPS',
  CREATED_BY = 'CREATED_BY',
  CREATOR_FULL_NAME = 'CREATOR_FULL_NAME',
  AI_MODEL = 'AI_MODEL',
  RETROSYNTHESIS_TYPOLOGY = 'RETROSYNTHESIS_TYPOLOGY',
  SYNTHESIS_SOURCE_TYPOLOGY = 'SYNTHESIS_SOURCE_TYPOLOGY',
  SYNTHESIS_PROCEDURE = 'SYNTHESIS_PROCEDURE',
  RETROSYNTHESIS_SOURCE = 'RETROSYNTHESIS_SOURCE',
  TRAINING_MODEL_TYPE = 'TRAINING_MODEL_TYPE',
}
