import { Component, Input } from '@angular/core';
import { AuthService } from '@app/libs/bitforce/services';

@Component({
  selector: 'ibm-upgrade-plan-button',
  templateUrl: './upgrade-plan-button.component.html',
  styleUrls: ['./upgrade-plan-button.component.scss'],
})
export class UpgradePlanButtonComponent {
  constructor(public authService: AuthService) {}
}
