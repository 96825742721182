import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KetcherDataService {
  private _smiles: string[] = [];

  get smiles(): string[] {
    return this._smiles;
  }

  set smiles(smiles: string[]) {
    this._smiles = smiles;
  }

  constructor() {}

  clear() {
    this._smiles = [];
  }
}
