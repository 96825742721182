import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IApiResponse, IApiRequest } from '@bitf/api';
import { ApiService, ToastMessagesService } from '@services';
import { User, Collaborator } from '@models';
import { getMongoQuery } from '@bitf/utils';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';

@Component({
  selector: 'ibm-search-collaborators',
  templateUrl: './search-collaborators.component.html',
  styleUrls: ['./search-collaborators.component.scss'],
})
export class SearchCollaboratorsComponent implements OnInit {
  @Output()
  collaboratorsSelectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  searchCollaboratorsForm: UntypedFormGroup;

  collaborators: Collaborator[] = [];
  selectedCollaborators: (any | Collaborator)[] = [];
  showUsersList: boolean;
  subscriptions: Subscription = new Subscription();
  searchText = '';
  delay = 500;
  minLength = 3;

  constructor(
    private apiService: ApiService,
    private fb: UntypedFormBuilder,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.searchCollaboratorsForm = this.fb.group({
      searchTextControl: [null, [Validators.email, Validators.required]],
    });

    // Temporarily disabled, waiting for BE fix
    // const searchTextControl = this.searchCollaboratorsForm.get('searchTextControl');
    // const sub = searchTextControl.valueChanges
    //   .pipe(debounceTime(this.delay), distinctUntilChanged())
    //   .subscribe(value => {
    //     if (!value.length) {
    //       this.showUsersList = false;
    //       this.collaborators = [];
    //       return;
    //     }
    //     const request = {
    //       query: getMongoQuery(null, value, ['fullName', 'email']),
    //       id: this.apiService.auth.user.id,
    //       relation: 'collaborators',
    //     } as IApiRequest;

    //     this.apiService.users
    //       .getRels<Collaborator>(request)
    //       .subscribe((response: IApiResponse<Collaborator[]>) => {
    //         this.collaborators = response.content.length ? response.content : [];
    //         this.removeSelectedCollaboratorsFromList();
    //         this.showUsersList = true;
    //       });
    //   });
    // this.subscriptions.add(sub);
  }

  addCollaborator(user: User) {
    if (user) {
      this.selectedCollaborators.push(user.email);
      this.removeSelectedCollaboratorsFromList();
    }
    this.selectionChanged();
    this.resetInput();
  }

  addCollaboratorByEmail() {
    const searchText = this.searchCollaboratorsForm.value.searchTextControl;
    const emailPattern = /^[a-zA-Z0-9._\-\+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const regexEmail = new RegExp(emailPattern);
    if (searchText && regexEmail.test(searchText)) {
      this.selectedCollaborators.push(searchText);
      this.showUsersList = false;
      this.selectionChanged();
      this.resetInput();
    } else {
      this.toastMessagesService.show({
        title: 'Error',
        message: 'Incorrect email',
        type: EToastMessageType.ERROR,
      });
    }
  }

  deleteCollaborator(index: number) {
    this.selectedCollaborators.splice(index, 1);
    this.selectionChanged();
  }

  onClickedOutside() {
    this.showUsersList = false;
  }

  selectionChanged() {
    const collaboratorsEmails = this.selectedCollaborators.map((collaborator: Collaborator | any) => {
      if (collaborator instanceof Collaborator) {
        return collaborator.user.email;
      }
      return collaborator;
    });

    this.collaboratorsSelectionChange.emit(collaboratorsEmails);
  }

  clearSelectedCollaboratorsList() {
    this.resetInput();
    this.selectedCollaborators = [];
  }

  resetInput() {
    this.searchCollaboratorsForm.setValue({ searchTextControl: '' });
  }

  private removeSelectedCollaboratorsFromList(): void {
    this.collaborators = this.collaborators.filter(
      collaborator =>
        !this.selectedCollaborators.find(collaboratorEmail => collaboratorEmail === collaborator.user.email)
    );
  }
}
