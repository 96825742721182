import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { throwIfAlreadyLoaded } from '@core/module-config';
import { FooterComponent } from '@core/components/footer/footer.component';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { SharedModule } from '@shared/shared.module';

// Modules included in main bundle (not lazy loaded)
import { LoginModule } from '@modules/login/login.module';
import { OAuthInterceptor, RetryInterceptor, ApiUiMessagesInterceptor } from '@bitf/interceptors';
import { RedirectComponent, GetAuthTokenComponent } from '@bitf/components/functional';

import { ErrorHandlerService } from '@bitf/services';
import { MessageAfterLoginComponent } from './components/message-after-login/message-after-login.component';
import { DplFailComponent } from './components/dpl-fail/dpl-fail.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { FullscreenLoadingComponent } from './components/fullscreen-loading/fullscreen-loading.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';

const DECLARE_EXPORTS_COMPONENTS = [
  FooterComponent,
  NotFoundComponent,
  RedirectComponent,
  GetAuthTokenComponent,
  MessageAfterLoginComponent,
  MainLayoutComponent,
  GetAuthTokenComponent,
  DplFailComponent,
  FullscreenLoadingComponent,
  TermsOfUseComponent,
];

@NgModule({
  imports: [BrowserModule, CommonModule, HttpClientModule, SharedModule.forRoot(), RouterModule, LoginModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUiMessagesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  declarations: [...DECLARE_EXPORTS_COMPONENTS],
  exports: [...DECLARE_EXPORTS_COMPONENTS],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
