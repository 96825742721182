import { SuperModel } from './super-model.model';

export class RetrosynthesisWizardConfig extends SuperModel {
  //@ts-ignore
  [key: string]: IRetrosynthesisWizardConfigParam;

  constructor(data: any = {}) {
    super(data);

    if (!this.speed.step) {
      this.speed.step = this.speed.values[1] - this.speed.values[0];
    }
  }

  //@ts-ignore
  get serialised() {
    return {};
  }
}

export interface IRetrosynthesisWizardConfigParam {
  label: string;
  description: string;
  min: number;
  max: number;
  step?: number;
  values?: Array<number>;
  metaData?: Array<IRetrosynthesisWizardConfigMetaDataItem>;
  type: 'checkbox' | 'slider' | 'radio';
  options?: Array<{ label: string; value: number }>;
}

export interface IRetrosynthesisWizardConfigMetaDataItem {
  value: number;
  parameters: {
    beam_dimension: number;
    max_steps: number;
    nbeams: number;
    ntop: number;
  };
}
