import { Injectable, Injector } from '@angular/core';
import { ApiSuperService, IApiResponse } from '@bitf/api';
import { Group } from '@models';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GroupsService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'user-groups';
  }

  mockGroups(params: any): Observable<IApiResponse<Group[]>> {
    const groups: Group[] = [
      new Group({
        id: '1',
        name: 'Group 1',
        description: 'description 1',
        totalUsers: 2,
        isDeletable: true,
      }),
      new Group({ id: '2', name: 'Group 2', description: 'description 2', totalUsers: 1 }),
      new Group({ id: '3', name: 'Group 3', description: 'description 3', totalUsers: 5 }),
    ];

    return of({
      content: groups,
      pagination: {
        first: true,
        last: true,
        page: 0,
        size: 10,
        itemsInPage: groups.length,
        totalItems: groups.length,
        totalPages: 1,
      },
    });
  }

  mockGroup(groupId: string): Observable<IApiResponse<Group>> {
    return of({
      content: new Group({ id: groupId, name: 'Group 1', description: 'description 1' }),
    }).pipe(delay(10));
  }
}
