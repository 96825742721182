import { IAnalysisReport, SynthesisProcedure } from '.';
import { SynthesisSuperModel } from './synthesis-super.model';

export class SynthesisExecution extends SynthesisSuperModel {
  status: ESynthesisExecutionStatus;
  completedOn: string | Date;
  rootSynthesisExecutionNodeId: string;
  synthesisId: string;
  isRelaunchable: boolean;
  isPausable: boolean;
  isStoppable: boolean;
  analysisReports?: IAnalysisReport[];
  synthesis: SynthesisProcedure; // source synthesis procedure
  numberOfAnnotations: number;
  canAddAnnotations: boolean;

  constructor(data: any) {
    super(data);
    if (data.synthesis) {
      this.synthesis = new SynthesisProcedure(data.synthesis);
    }
    this.isProcedure = false;
  }

  get serialised() {
    return {
      reactors: this.id,
    };
  }
}

export enum ESynthesisExecutionStatus {
  NEW = 'NEW',
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  PAUSED = 'PAUSED',
  DONE = 'DONE',
  ERROR = 'ERROR',
  STOPPED = 'STOPPED',
}
