import { Component, Input, OnInit } from '@angular/core';
import { ApiSuperService } from '@app/libs/bitforce/api';

@Component({
  selector: 'ibm-file-upload-loader',
  templateUrl: './file-upload-loader.component.html',
  styleUrls: ['./file-upload-loader.component.scss'],
})
export class FileUploadLoaderComponent implements OnInit {
  @Input() uploaderService: ApiSuperService;
  constructor() {}

  ngOnInit(): void {}
}
