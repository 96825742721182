import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'ibm-mlrp-admin-sidenav',
  templateUrl: './admin-sidenav.component.html',
  styleUrls: ['./admin-sidenav.component.scss'],
})
export class AdminSidenavComponent implements OnInit {
  @Input() sidenavActive: boolean;

  environment = environment;

  constructor(public router: Router) {}

  ngOnInit(): void {}
}
