import { ComponentRef, Injectable } from '@angular/core';

import { Notification, NotificationService } from 'carbon-components-angular';
import { NotificationContent } from 'carbon-components-angular/notification/notification-content.interface';
import { EToastHorizontalPosition, EToastMessageType, EToastVerticalPosition } from './toast-message.enum';
import { IToastData } from './toast-message.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService {
  constructor(protected notificationService: NotificationService) {}

  show(data: IToastData): ComponentRef<Notification> {
    const options = {
      duration: data.duration || 8000,
      horizontalPosition: data.horizontalPosition || EToastHorizontalPosition.CENTER,
      verticalPosition: data.verticalPosition || EToastVerticalPosition.BOTTOM,
    };

    const notification = this.notificationService.showToast({
      type: data?.type?.toLowerCase() || EToastMessageType.SUCCESS.toLocaleLowerCase(),
      title: data.title,
      duration: options.duration,
      closeLabel: 'Close',
      subtitle: data.message,
      target: '.notification-container',
      showClose: true,
      lowContrast: false,
    } as NotificationContent);

    return notification.componentRef;
  }

  close(notificationRef: ComponentRef<Notification>) {
    this.notificationService.close(notificationRef);
  }
}
