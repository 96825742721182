export enum EPredictionType {
  FLOAT = 'float',
  ATOM_MAPPING = 'atom_mapping',
}

export enum EPredictionLibrary {
  CDK = 'CDK',
  RDKIT = 'RDKIT',
  INDIGO = 'INDIGO',
}

export enum EReactionPropertyPredictionWizardStep {
  ADD_REACTIONS = 0,
  PREDICTION_RESULTS = 1,
}
