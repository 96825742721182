import { HttpHeaders } from '@angular/common/http';

import { IApiRequest, IApiSorting } from '@bitf/api';
import { IApiDefaultRequestParsed } from './default-api.interfaces';

export const requestParser = ({
  headers,
  queryParams,
  query,
  embed,
  computedFields,
  page,
  size,
  sorting,
  files,
  responseType,
}: IApiRequest): any => {
  // TODO: add embed
  const options: IApiDefaultRequestParsed = {
    params: {},
    responseType: responseType || 'json',
    headers: new HttpHeaders(),
  };
  if (queryParams) {
    Object.assign(options.params, queryParams);
  }
  if (query) {
    options.params.raw = JSON.stringify(query);
  }
  if (embed && Array.isArray(embed)) {
    options.params.embed = embed.join(',');
  }
  if (computedFields && Array.isArray(computedFields)) {
    options.params.computedFields = computedFields.join(',');
  }
  if (!isNaN(page)) {
    options.params.page = page;
  }
  if (!isNaN(size)) {
    options.params.size = size;
  }

  if (sorting) {
    const sortingString = stringifySort(sorting);
    if (sortingString) {
      options.params.sort = sortingString;
    }
  }
  if (headers) {
    headers.forEach(header => {
      const { headerName, value } = header;
      options.headers = options.headers.append(headerName, value);
    });
  }
  if (files) {
    options.headers.append('Content-Type', 'multipart/form-data');
    options.reportProgress = true;
  }

  return options;
};

function stringifySort(sorting: IApiSorting[]): string[] {
  if (!sorting) {
    return [''];
  }
  return sorting.map((sortingItem: IApiSorting) =>
    sortingItem ? `${sortingItem.property},${sortingItem.direction}` : ''
  );
}
