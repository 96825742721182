import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CARBON_MODULES } from './carbon-modules';

import { FiltersComponent } from '@app/shared/components/filters/filters.component';
import { SearchCollaboratorsComponent } from '@app/shared/components/search-collaborators/search-collaborators.component';
import { GetRoborxnKeyDialogComponent } from '@app/shared/components/get-roborxn-key/get-roborxn-key-dialog.component';

import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { ClipboardDirective } from '@shared/directives/clipboard.directive';
import { AvatarDirective } from '@shared/directives/avatar.directive';
import { BackgroundAvatarDirective } from '@shared/directives/avatar.directive';

import {
  ValidationMessageComponent,
  ValidationMessageDirective,
  NativeElementInjectorDirective,
} from '@shared/form-utilities';

import { BitfSafeHtml } from '@bitf/pipes/bitf-safe-html.pipe';
import { LoaderComponent } from '@bitf/components';
import { TreeGraphComponent } from './components/tree-graph/tree-graph.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { CloseButtonComponent } from './components/close-button/close-button.component';

import { RoboSynthesisStatus } from '@app/shared/pipes/robo-synthesis-status.pipe';
import { ZoomComponent } from '@app/shared/components/zoom/zoom.component';
import { ModalCloseButtonComponent } from './components/modal-close-button/modal-close-button.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { CreateProjectDialogComponent } from './components/create-project-dialog/create-project-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { NewProjectButtonComponent } from './components/new-project-button/new-project-button.component';
import { UserOverflowMenuComponent } from './components/user-overflow-menu/user-overflow-menu.component';
import { OkCancelDialogComponent } from './components/ok-cancel-dialog/ok-cancel-dialog.component';
import { SynthesisStatusIconComponent } from './components/synthesis-status-icon/synthesis-status-icon.component';
import { TableListExpansionComponent } from './components/table-list-expansion/table-list-expansion.component';
import { KetcherComponent } from './components/ketcher/ketcher.component';
import { FeedbackShareBarComponent } from './components/feedback-share-bar/feedback-share-bar.component';
import { SequenceBarComponent } from './components/sequence-bar/sequence-bar.component';
import { InformationDetailsMenuComponent } from './components/information-details-menu/information-details-menu.component';
import { RetrosynthesisQueueStateComponent } from './components/retrosynthesis-queue-state/retrosynthesis-queue-state.component';
import { SynthesisExecutionStateComponent } from './components/synthesis-execution-state/synthesis-execution-state.component';
import { AdminSidenavComponent } from './components/admin-sidenav/admin-sidenav.component';
import { NoItemsFoundComponent } from './components/no-items-found/no-items-found.component';
import { FileUploadLoaderComponent } from './components/file-upload-loader/file-upload-loader.component';
import { SimilarReactionsDocumentsListComponent } from './components/similar-reactions-documents-list/similar-reactions-documents-list.component';
import { FingerprintButtonComponent } from './components/fingerprint-button/fingerprint-button.component';
import { TmapButtonComponent } from './components/tmap-button/tmap-button.component';
import { SelectOrCreateProjectComponent } from './components/select-or-create-project/select-or-create-project.component';
import { ReactionImageComponent } from './components/reaction-image/reaction-image.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { CreateRetrosynthesisWizardDialogComponent } from './components/create-retrosynthesis-wizard-dialog/create-retrosynthesis-wizard-dialog.component';
import { CreateSynthesisWizardDialogComponent } from './components/create-synthesis-wizard-dialog/create-synthesis-wizard-dialog.component';
import { SynthesisRetrosynthesisInformationComponent } from './components/synthesis-retrosynthesis-information/synthesis-retrosynthesis-information.component';
import { DownloadFileEntryButtonComponent } from './components/download-file-entry-button/download-file-entry-button.component';
import { ANGULAR_CDK_MODULES } from './angular-cdk-modules';
import { TruncDecimalPipe } from './pipes/trunc-decimal.pipe';
import { FormValidationMessagePipe } from './form-utilities/form-validation-message.pipe';
import { CopySmilesDialogComponent } from './components/copy-smiles-dialog/copy-smiles-dialog.component';
import { FileUploaderDialogComponent } from './components/file-uploader-dialog/file-uploader-dialog.component';
import { FullHeightPanelComponent } from './components/full-height-panel/full-height-panel.component';
import { SelectProjectDialogComponent } from './components/select-project-dialog/select-project-dialog.component';
import { AIModelSelectionDialogComponent } from './components/ai-model-selection-dialog/ai-model-selection-dialog.component';
import { RenameDialogComponent } from './components/rename-dialog/rename-dialog.component';
import { TreeLegendComponent } from './components/tree-legend/tree-legend.component';
import { DraggableResizableDirective } from './directives/draggable-resizable.directive';
import { UserAccountMenuComponent } from './components/user-account-menu/user-account-menu.component';
import { UpgradePlanDialogComponent } from './components/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { SelectCountryDialogComponent } from './components/select-country-dialog/select-country-dialog.component';
import { PlanComponent } from './components/plan/plan.component';
import { TrainingCostDetailDialogComponent } from './components/training-cost-detail-dialog/training-cost-detail-dialog.component';
import { TrainingHoursHelpDialogComponent } from './components/training-hours-help-dialog/training-hours-help-dialog.component';
import { SearchBillingAddressComponent } from './components/search-billing-address/search-billing-address.component';
import { PaymentMethodDialogComponent } from './components/payment-method-dialog/payment-method-dialog.component';
import { PlanForecastNotificationComponent } from './components/plan-forecast-notification/plan-forecast-notification.component';
import { BytesToSizePipe } from './pipes/bytes-to-size.pipe';
import { UnsuccessfulPaymentNotificationComponent } from './components/unsuccessful-payment-notification/unsuccessful-payment-notification.component';
import { UpgradePlanButtonComponent } from './components/upgrade-plan-button/upgrade-plan-button.component';
import { UnsubscribeButtonComponent } from './components/unsubscribe-button/unsubscribe-button.component';
import { HeapDirective } from './directives/heap.directive';
import { ReleaseNotesDialogComponent } from './components/release-notes-dialog/release-notes-dialog.component';

const SHARED_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  RouterModule,
  CARBON_MODULES,
  ANGULAR_CDK_MODULES,
];

const SHARED_COMPONENTS = [
  NativeElementInjectorDirective,
  ValidationMessageDirective,
  ValidationMessageComponent,
  FiltersComponent,
  SearchCollaboratorsComponent,
  TreeGraphComponent,
  SearchBarComponent,
  CloseButtonComponent,
  ZoomComponent,
  ModalCloseButtonComponent,
  DropdownMenuComponent,
  CreateProjectDialogComponent,
  HeaderComponent,
  SidenavComponent,
  NewProjectButtonComponent,
  UserOverflowMenuComponent,
  TableListExpansionComponent,
  SequenceBarComponent,
  KetcherComponent,
  FeedbackShareBarComponent,
  InformationDetailsMenuComponent,
  OkCancelDialogComponent,
  SynthesisStatusIconComponent,
  RetrosynthesisQueueStateComponent,
  SynthesisExecutionStateComponent,
  GetRoborxnKeyDialogComponent,
  AdminSidenavComponent,
  NoItemsFoundComponent,
  FileUploadLoaderComponent,
  SimilarReactionsDocumentsListComponent,
  FingerprintButtonComponent,
  TmapButtonComponent,
  SelectOrCreateProjectComponent,
  ReactionImageComponent,
  FileUploaderComponent,
  CreateRetrosynthesisWizardDialogComponent,
  CreateSynthesisWizardDialogComponent,
  SynthesisRetrosynthesisInformationComponent,
  DownloadFileEntryButtonComponent,
  CopySmilesDialogComponent,
  FileUploaderDialogComponent,
  FullHeightPanelComponent,
  AIModelSelectionDialogComponent,
  RenameDialogComponent,
  SelectProjectDialogComponent,
  TreeLegendComponent,
  UserAccountMenuComponent,
  UpgradePlanDialogComponent,
  SelectCountryDialogComponent,
  ReleaseNotesDialogComponent,
  PlanComponent,
  TrainingCostDetailDialogComponent,
  TrainingHoursHelpDialogComponent,
  SearchBillingAddressComponent,
  PaymentMethodDialogComponent,
  PlanForecastNotificationComponent,
  UnsuccessfulPaymentNotificationComponent,
  UpgradePlanButtonComponent,
  UnsubscribeButtonComponent,
];

const SHARED_DIRECTIVES = [
  ValidationMessageDirective,
  ClickOutsideDirective,
  ClipboardDirective,
  AvatarDirective,
  BackgroundAvatarDirective,
  DraggableResizableDirective,
  HeapDirective,
];

const SHARED_BITF_COMPONENTS = [LoaderComponent, BitfSafeHtml];
const SHARED_PIPES = [RoboSynthesisStatus, TruncDecimalPipe, FormValidationMessagePipe, BytesToSizePipe];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_BITF_COMPONENTS,
    ...SHARED_PIPES,
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_BITF_COMPONENTS, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...SHARED_PIPES],
})
export class SharedModule {
  // This should only be called inside the CoreModule!
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
