import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { ESocketNotificationLevel, ESocketNotificationTarget, SocketNotification } from '../models';
import { EToastMessageType } from './toast-messages/toast-message.enum';
import { ToastMessagesService } from './toast-messages/toast-messages.service';
import { DialogsService } from './dialog/dialog.service';
import { OkCancelDialogComponent } from '@app/shared/components/ok-cancel-dialog/ok-cancel-dialog.component';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SocketNotificationService {
  notifications$ = new Subject<SocketNotification>();
  constructor(
    private socketService: SocketService,
    private toastMessagesService: ToastMessagesService,
    private dialogsService: DialogsService
  ) {}

  init() {
    this.socketService
      .watch<SocketNotification>('/user/topic/notification', 'socket-notifications')
      .subscribe(response => {
        this.parseNotification(response.content);
        this.notifications$.next(response.content);
      });
  }

  private parseNotification(notification: SocketNotification) {
    switch (notification.target) {
      case ESocketNotificationTarget.TOAST:
        this.showToastMessages(notification);
        break;
      case ESocketNotificationTarget.DIALOG:
        this.showDialog(notification);
        break;
    }
  }

  private showToastMessages(notification: SocketNotification) {
    let toastMessageType: EToastMessageType;
    switch (notification.level) {
      case ESocketNotificationLevel.INFO:
        toastMessageType = EToastMessageType.INFO;
        break;
      case ESocketNotificationLevel.WARNING:
        toastMessageType = EToastMessageType.WARNING;
        break;
      case ESocketNotificationLevel.ERROR:
        toastMessageType = EToastMessageType.ERROR;
        break;
      case ESocketNotificationLevel.SUCCESS:
        toastMessageType = EToastMessageType.SUCCESS;
        break;
      default:
        toastMessageType = EToastMessageType.WARNING;
        break;
    }

    this.toastMessagesService.show({
      title: notification.title || '',
      message: notification.message || '',
      duration: notification.timeout || 10000,
      type: toastMessageType,
    });
  }

  private showDialog(notification: SocketNotification) {
    this.dialogsService.dialog.closeAll();
    this.dialogsService.dialog.open(OkCancelDialogComponent, {
      title: notification.title,
      message: notification.message,
      okText: 'Ok',
    });
  }
}
