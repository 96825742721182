import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiSuperService, IApiRequest, IApiResponse } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class ParagraphToActionsService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'paragraph-actions';
  }

  paragraphToActions(request: IApiRequest): Observable<IApiResponse<{ actionSequence: string }>> {
    return this.post<{ actionSequence: string }>(request);
  }
}
