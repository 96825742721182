import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { IApiResponse } from '@bitf/api';
import { configs } from '@configs';
import { environment } from '@env/environment';
import { Login, User } from '@models';
import { AppSessionService } from '@services/app-session.service';

declare global {
  interface Window {
    heap: any;
  }
}

export class SuperAuthService {
  _user: User;
  _authToken: string;
  _refreshToken: string;
  user$: Subject<User> = new Subject();
  _authTokenMetaData: any;

  constructor(public http: HttpClient, public router: Router, public appSessionService: AppSessionService) {}

  get user() {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
    this.user$.next(user);
  }

  login(loginResponse: Login, setHeapIdentify = true) {
    this.authToken = loginResponse.token;
    this.refreshToken = loginResponse.refreshToken;
    this.user = loginResponse.user;
    this.userId = loginResponse.user.id;
    this.idToken = loginResponse.idToken;
    if (environment.heap.enabled && setHeapIdentify) {
      try {
        window.heap.identify(this.user.id);
        const emailDomain = this.user.email.split('@').pop();
        window.heap.addUserProperties({
          Provider: this.user.provider,
          MigratedUser: this.user.migratedUser,
          SubscriptionType: this.user.planType,
          EmailDomain: emailDomain,
        });
      } catch (error) {
        console.log('heap deactivated');
      }
    }
  }

  logout() {
    let logoutUrl;
    if (this.idToken) {
      logoutUrl = environment.logoutUrl + this.idToken;
    }
    this.appSessionService.cleanSession();
    this.authToken = undefined;
    this.authTokenMetaData = undefined;
    this.userId = undefined;
    this.idToken = undefined;
    this.refreshToken = undefined;
    if (logoutUrl) {
      window.location.href = logoutUrl;
    } else {
      this.router.navigate([configs.general.loginUrl]);
    }
  }

  generateToken(token: string): Observable<IApiResponse<Login>> {
    const tokenGeneratorUrl = `${environment.apiUrl}auth/security/token`;
    const tokenApiConfig = configs.authService.getGenerateTokenApiConfig(token);
    return this.http.get(tokenGeneratorUrl, { ...tokenApiConfig }).pipe(
      map((envelope: any) => ({
        content: new Login(envelope.payload),
      }))
    );
  }

  renewToken() {
    if (!this.refreshToken) {
      throw new Error('refresh token not found');
    }
    const tokenGeneratorUrl = `${environment.apiUrl}auth/security/refresh-token`;

    return this.http.post(tokenGeneratorUrl, this.refreshToken).pipe(
      map((envelope: any) => ({
        content: new Login(envelope.payload),
      })),
      map(data => this.login(data.content, false))
    );
  }

  get authToken(): string | undefined {
    if (this._authToken) {
      if (this.isTokenValid(this._authToken) || this.refreshToken) {
        return this._authToken;
      }
      return undefined;
    } else {
      // Try to load it from the local storage
      const localStorageToken = localStorage.getItem(configs.authService.localStorageTokenName);
      if (this.isTokenValid(localStorageToken) || this.refreshToken) {
        this._authToken = localStorageToken;
        return localStorageToken;
      }
      return undefined;
    }
  }

  set authToken(token: string) {
    this._authToken = token;
    if (!token) {
      localStorage.removeItem(configs.authService.localStorageTokenName);
    } else {
      localStorage.setItem(configs.authService.localStorageTokenName, token);
    }
  }

  get refreshToken(): string | undefined {
    if (this._refreshToken) {
      if (this.isTokenValid(this._refreshToken)) {
        return this._refreshToken;
      }
      return undefined;
    } else {
      // Try to load it from the local storage
      const localStorageRefreshToken = localStorage.getItem(configs.authService.localStorageRefreshTokenName);
      if (this.isTokenValid(localStorageRefreshToken)) {
        this._refreshToken = localStorageRefreshToken;
        return localStorageRefreshToken;
      }
      return undefined;
    }
  }

  set refreshToken(refreshToken: string) {
    this._refreshToken = refreshToken;
    if (!refreshToken) {
      localStorage.removeItem(configs.authService.localStorageRefreshTokenName);
    } else {
      localStorage.setItem(configs.authService.localStorageRefreshTokenName, refreshToken);
    }
  }

  get authTokenMetaData() {
    if (this._authTokenMetaData) {
      return this._authTokenMetaData;
    }
    const metaData = localStorage.getItem(`${configs.authService.localStorageTokenName}MetaData`);
    if (metaData) {
      this._authTokenMetaData = JSON.parse(metaData);
      return this._authTokenMetaData;
    }
  }

  set authTokenMetaData(authTokenMetaData: any) {
    if (!authTokenMetaData) {
      localStorage.removeItem(`${configs.authService.localStorageTokenName}MetaData`);
      this._authTokenMetaData = undefined;
    } else {
      localStorage.setItem(
        `${configs.authService.localStorageTokenName}MetaData`,
        JSON.stringify(authTokenMetaData)
      );
    }
  }

  get rawAuthToken() {
    return localStorage.getItem(configs.authService.localStorageTokenName);
  }

  get userId() {
    return localStorage.getItem('userId');
  }

  set userId(userId: string) {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }
  }

  get idToken() {
    return localStorage.getItem('idToken');
  }

  set idToken(idToken: string) {
    if (idToken) {
      localStorage.setItem('idToken', idToken);
    } else {
      localStorage.removeItem('idToken');
    }
  }

  isTokenValid(token: string) {
    if (!token) {
      return false;
    }
    return true;
  }
}
