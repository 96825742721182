import { EReactionCompletionPredictionStatus } from '@app/shared/enums/reaction-completion-prediction.enum';
import { SuperModel } from '@models/super-model.model';
import { ITreeLegendItem } from './tree-legend.interface';
import { ReactionCompletion } from './reaction-completion.model';
import { Molecule } from './molecule.model';

export class ReactionCompletionPrediction extends SuperModel {
  projectId: string;
  request: {
    reactants: string;
    product: string;
    aiModel: string;
  };
  status: EReactionCompletionPredictionStatus;
  errorMessage: string;
  canGenerateCompletions: boolean;
  aiModel: string;
  isAiModelDeleted: boolean;
  sequences: ReactionCompletion[];
  legend: ITreeLegendItem[];
  molecules: Molecule[];

  constructor(payload: Partial<ReactionCompletionPrediction> = {}) {
    super(payload);

    if (payload.sequences?.length) {
      this.sequences = payload.sequences.map(
        reactionCompletion => new ReactionCompletion(reactionCompletion)
      );
    }
  }

  get serialised() {
    return {
      id: this.id,
      projectId: this.projectId,
      request: this.request,
      status: this.status,
      errorMessage: this.errorMessage,
      canGenerateCompletions: this.canGenerateCompletions,
      aiModel: this.aiModel,
      isAiModelDeleted: this.isAiModelDeleted,
      reactionCompletions: this.sequences.map(reactionCompletion => reactionCompletion.serialised),
    };
  }
}
