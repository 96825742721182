import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { Observable } from 'rxjs';
import { AuthService } from '@app/libs/bitforce/services';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { responseParser } from '@app/libs/bitforce/api/parsers/default-parser';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  rxStomp?: RxStomp;

  constructor(private authService: AuthService) {}

  connect() {
    this.rxStomp = new RxStomp();
    this.rxStomp.configure({
      brokerURL: this.getWsUrl(),
      heartbeatIncoming: 0,
      heartbeatOutgoing: 20000,
      reconnectDelay: 200,
      beforeConnect: async client => {
        if (!this.authService.isTokenValid(this.authService.authToken)) {
          await this.authService.renewToken().toPromise();
        }

        client.stompClient.connectHeaders = {
          Authorization: this.authService.authToken,
        };
      },
    });
    this.rxStomp.activate();
  }

  private getWsUrl() {
    return `${environment.wsUrl}`;
  }

  watch<T>(destination: string, modelMapperEntityName: string = ''): Observable<ISocketResponse<T>> {
    return this.rxStomp.watch(destination).pipe(
      map(response => ({
        response: (response as any) as ISocketRawResponse,
        content: responseParser<T>({ payload: JSON.parse(response.body) }, modelMapperEntityName).content,
      }))
    );
  }

  unsubscribe() {
    if (this.rxStomp) {
      this.rxStomp.deactivate();
    }
  }
}

export interface ISocketResponse<T> {
  response: ISocketRawResponse;
  content: T;
}

export interface ISocketRawResponse {
  body: string;
  binaryBody: Uint8Array;
  command: string;
  headers: {
    'content-length': string;
    'message-id': string;
    subscription: string;
    'content-type': string;
    destination: string;
  };
  isBinaryBody: boolean;
}
