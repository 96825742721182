import { modelsMapperStrategy } from '@services/api/models-mapper/models-mapper.strategy';
import * as defaultParser from '@bitf/api/parsers/default-parser';

import { SignInComponent } from '@modules/login/sign-in/sign-in.component';
import { FullscreenLoadingComponent } from '@app/core/components/fullscreen-loading/fullscreen-loading.component';
import { environment } from '@env/environment';

const redirectUrl = new URL(environment.redirectUrl);

export const configs: any = {
  general: { homePageUrl: '/', loginUrl: '/sign-in' },
  parser: { modelsMapperStrategy, parserStrategy: { defaultParser } },
  guard: {
    checkUserRoutePermission: (state, authService) => {
      if (!state.url.includes('/admin')) {
        return true;
      }
      if (authService.user.isAdmin) {
        return true;
      }
      return false;
    },
  },
  loaderService: { customLoader: { component: FullscreenLoadingComponent, params: { fixed: true } } },
  authService: {
    tokenQueryParmName: 'code',
    localStorageTokenName: 'authToken',
    localStorageRefreshTokenName: 'refreshToken',
    getGenerateTokenApiConfig: token => ({
      method: 'GET',
      params: { code: token, redirectUri: redirectUrl.searchParams.get('redirect_uri') },
    }),
  },
  getAuthTokenComponent: { signInComponent: SignInComponent },
};
