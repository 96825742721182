import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AppSessionService } from '@services';
import { environment } from '@env/environment';

@Component({
  selector: 'ibm-user-overflow-menu',
  templateUrl: './user-overflow-menu.component.html',
  styleUrls: ['./user-overflow-menu.component.scss'],
})
export class UserOverflowMenuComponent implements OnInit {
  userMenuOpened = false;

  constructor(public apiService: ApiService, public appSessionService: AppSessionService) {}

  ngOnInit(): void {}

  onToggleUserMenu() {
    this.userMenuOpened = !this.userMenuOpened;
  }
}
