<ibm-header name="RXN"
  [brand]="brandTemplate">
  <ibm-hamburger *ngIf="hasSidenav && !isModal"
    [active]="appSessionService.isLeftSidenavVisible"
    (selected)="appSessionService.isLeftSidenavVisible = !appSessionService.isLeftSidenavVisible">
  </ibm-hamburger>
  <ibm-header-global>
    <ng-content></ng-content>
  </ibm-header-global>
</ibm-header>

<ng-template #brandTemplate>
  <a class="bx--header__name"
    [routerLink]="['/', 'home']"> <span class="bx--header__name--prefix">IBM</span>&nbsp;RXN for Chemistry</a>
</ng-template>