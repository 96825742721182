import { SuperModel } from '@models/super-model.model';
import { Action } from './action.model';

export class ReactionSettings extends SuperModel {
  product: IReactionSettingsProduct;
  actions: Action[];

  constructor(data: any = {}) {
    super(data);

    if (data.actions) {
      this.actions = data.actions.map(action => new Action(action));
    }
  }

  get serialised() {
    return {
      id: this.id,
      product: this.product,
      actions: this.actions,
    };
  }
}

export interface IReactionSettingsProduct {
  productMassAndReactionInformation: {
    quantity: number;
    unit: string;
    yield: number;
    securityFactor: number;
    stoichiometryFactor: number;
  };

  moleculeInfo: {
    molecularWeight: number;
    density: number;
    name: string;
  };
}
