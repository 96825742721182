import { Injectable, Injector } from '@angular/core';

import { ApiSuperService } from '@bitf/api';
import { IHardwareSettings } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class RobotRxnService extends ApiSuperService {
  // mockApiUrl = 'https://83b3046e-8bbe-4b29-a893-6a9d7059324b.mock.pstmn.io/api/v1/';

  constructor(injector: Injector) {
    super(injector);
    this.name = 'roborxn';
  }

  getRobotHardwareSettings() {
    return this.getRel<IHardwareSettings>({
      relation: 'hardware-settings',
    });
  }

  deleteIngredient(ingredientName: string, nodeId: string) {
    return this.request({
      method: 'DELETE',
      path: `ingredients/${ingredientName}/`,
      isBodyRaw: true,
      body: {
        nodeId,
      },
    });
  }
}
