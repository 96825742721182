<ibm-modal [open]="open">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>Create a new project</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <form id="createProjectForm"
      class="ibm-create-project"
      [formGroup]="createProjectForm">
      <section>
        <div class="mb-5">
          <ibm-label
            [invalid]="createProjectForm.get('projectName').touched && !createProjectForm.get('projectName').valid"
            [invalidText]="'Project name required'">
            Project Name
            <input ibmText
              formControlName="projectName"
              id="projectName"
              placeholder="Insert the name"
              autocomplete="off" />
          </ibm-label>
        </div>
        <div class="mb-5">
          <ibm-checkbox formControlName="isAddingCollaborator">
            Add Collaborator
          </ibm-checkbox>
        </div>
        <div *ngIf="createProjectForm.get('isAddingCollaborator').value">
          <ibm-search-collaborators (collaboratorsSelectionChange)="collaboratorsSelectionChanged($event)">
          </ibm-search-collaborators>
        </div>
      </section>
    </form>
  </div>
  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="closeModal()">Close</button>
    <button ibmButton="primary"
      (click)="createProject()"
      [disabled]="!createProjectForm.valid">
      Create a new project
    </button>
  </ibm-modal-footer>
</ibm-modal>