import { ISvgToUrl, svgToUrl } from '@app/shared/common-utilities/svg-to-url';
import { Molecule } from './molecule.model';
import { RetrosynthesisMolecule } from './retrosynthesis-molecule.model';
import { SuperModel } from './super-model.model';

export class OpticalChemicalRecognition extends SuperModel {
  fileID: string;
  fileImage: string;
  fileExtension: string;
  segments: OpticalChemicalRecognitionSegment[];
  reactions: OpticalChemicalRecognitionReaction[];

  constructor(payload: Partial<OpticalChemicalRecognition> = {}) {
    super(payload);
    if (payload.reactions) {
      this.reactions = payload.reactions.map(x => new OpticalChemicalRecognitionReaction(x));
    }

    if (payload.segments) {
      this.segments = payload.segments.map(x => new OpticalChemicalRecognitionSegment(x));
    }
  }

  get base64Image() {
    return `data:image/${this.fileExtension};base64,` + this.fileImage;
  }

  get serialised() {
    return {
      id: this.id,
      fileImage: this.fileImage,
      segments: this.segments.map(x => x.serialised),
      reactions: this.reactions.map(x => x.serialised),
    };
  }
}

export class OpticalChemicalRecognitionSegment extends SuperModel {
  id?: string;
  xTopLeft: number;
  yTopLeft: number;
  xBottomRight: number;
  yBottomRight: number;
  boundingBoxType: EBoundingBoxType;
  content?: string;
  fromUser: boolean;
  //FE properties
  xTopLeftInitial?: number;
  yTopLeftInitial?: number;
  xBottomRightInitial?: number;
  yBottomRightInitial?: number;

  constructor(payload: Partial<OpticalChemicalRecognitionSegment> = {}) {
    super(payload);

    this.xBottomRightInitial = this.xBottomRight;
    this.xTopLeftInitial = this.xTopLeft;
    this.yBottomRightInitial = this.yBottomRight;
    this.yTopLeftInitial = this.yTopLeft;
  }

  get serialised() {
    return {
      id: this.id,
      xTopLeft: this.xTopLeft,
      yTopLeft: this.yTopLeft,
      xBottomRight: this.xBottomRight,
      yBottomRight: this.yBottomRight,
      boundingBoxType: this.boundingBoxType,
      content: this.content,
      fromUser: this.fromUser,
    };
  }
}

export class OpticalChemicalRecognitionReaction extends SuperModel {
  id: string;
  reactant: { segmentId: string; content: string };
  text: { content: string };
  product: {
    segmentId: string;
    content: string;
  };
  polygon: number[][];
  tree?: IOpticalChemicalRecognitionTree;
  order: number;

  constructor(payload: Partial<OpticalChemicalRecognitionReaction> = {}) {
    super(payload);
  }

  get serialised() {
    return {
      id: this.id,
      reactant: this.reactant,
      text: this.text,
      product: this.product,
      polygon: this.polygon,
    };
  }
}

export interface IOpticalChemicalRecognitionTree {
  moleculeImage: string;
  smiles: string;
  children: {
    moleculeImage: string;
    smiles: string;
  }[];
}

export enum EBoundingBoxType {
  MOLECULE = 0,
  ARROW = 1,
  TEXT = 2,
  SYMBOL = 3,
}
