<div class="paper-container"
  [ngClass]="showBottomBar && 'bottom-bar-padding'"></div>
<div class="tree-graph-bottom-bar p-3"
  *ngIf="showBottomBar">
  <div class="tree-graph-bottom-bar__section justify-content-start">
    <ng-content select="[left]"></ng-content>
  </div>
  <div class="tree-graph-bottom-bar__section justify-content-center">
    <ibm-zoom #zoom
      (zoomSelected)="setZoom($event)"
      [zoomValues]="zoomSteps"
      [currentZoomIndex]="currentZoomIndex"
      theme="dark"></ibm-zoom>
  </div>

  <div class="tree-graph-bottom-bar__section justify-content-end">
    <ng-content select="[right]"
      class="tree-graph-bottom-bar__section"></ng-content>
  </div>
</div>