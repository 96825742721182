import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ESmilesEditorType } from '../smiles-editor/smiles-editor.enum';

@Component({
  selector: 'ibm-smiles-editor-header',
  templateUrl: './smiles-editor-header.component.html',
  styleUrls: ['./smiles-editor-header.component.scss'],
})
export class SmilesEditorHeaderComponent implements OnInit {
  @Input()
  disableActions = false;
  @Input()
  currentEditor = ESmilesEditorType.VISUAL;
  @Input()
  actionLabel: string;
  @Output()
  close = new EventEmitter<void>();
  @Output()
  action = new EventEmitter<void>();
  @Output()
  changeEditorType = new EventEmitter<ESmilesEditorType>();
  @Input()
  inModal = false;

  eSmilesEditorType = ESmilesEditorType;

  ngOnInit() {}
}
