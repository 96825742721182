<ibm-modal [open]="open"
  size="xs">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>Copy smiles</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <div class="w-100 p-3 smiles-container">
      {{ dialogData.smiles}}
    </div>
  </div>
  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="closeModal()">Close</button>
    <button ibmButton="primary"
      (click)="onCopySmiles()">
      Copy
    </button>
  </ibm-modal-footer>
</ibm-modal>