import { SuperModel } from '@models/super-model.model';
import { Molecule } from '.';

export class AiTrainingFile extends SuperModel {
  fileName: string;
  previewReactions?: {
    productMolecule: Molecule;
    outcomeMolecules: Molecule[];
  }[];

  constructor(data) {
    super(data);
    this.previewReactions = data.previewReactions?.map(previewReaction => ({
      productMolecule: new Molecule(previewReaction.productMolecule),
      outcomeMolecules: previewReaction.outcomeMolecules?.map(
        outcomeMolecule => new Molecule(outcomeMolecule)
      ),
    }));
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}
