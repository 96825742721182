<header class="header">
  <img src="assets/img/logo/ibm-logo.svg"
    class="logo" />

  <span class="title">Accelerated Discovery</span>
</header>

<div ibmRow>
  <div ibmCol
    class="left-side"
    [columnNumbers]="{lg:9, md:4, sm:6}">
    <div ibmRow
      class="d-flex flex-column">
      <h2 class="title"> RXN for Chemistry </h2>
      <p class="description mt-6">Predict reactions, find retrosynthesis pathways, and derive experimental
        procedures with RXN for Chemistry.</p>
    </div>
    <div ibmRow
      class="mt-10">
      <button ibmButton="primary"
        class="mr-7 button-main"
        (click)="goToSignUp()"
        size="md">
        Sign up
      </button>
    </div>
    <div ibmRow>
      <button ibmButton="ghost"
        class="mr-7 button-main login-button"
        (click)="goToLogin()"
        size="md">
        Log in
      </button>
    </div>
    <div ibmRow>
      <button ibmButton="ghost"
        class="mr-7 button-main google-button"
        (click)="goToLoginGoogle()"
        size="md">
        Log in with Google
        <svg xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-google"
          viewBox="0 0 16 16">
          <path
            d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"
            fill="currentColor"></path>
        </svg>
      </button>
    </div>
    <!-- <div ibmRow>
      <button ibmButton="ghost"
        class="mr-7 button-main facebook-button"
        (click)="goToLoginFacebook()"
        size="md">
        Log in with Facebook
        <svg focusable="false"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          width="18"
          height="18"
          viewBox="0 0 32 32"
          aria-hidden="true">
          <path
            d="M26.67,4H5.33A1.34,1.34,0,0,0,4,5.33V26.67A1.34,1.34,0,0,0,5.33,28H16.82V18.72H13.7V15.09h3.12V12.42c0-3.1,1.89-4.79,4.67-4.79.93,0,1.86,0,2.79.14V11H22.37c-1.51,0-1.8.72-1.8,1.77v2.31h3.6l-.47,3.63H20.57V28h6.1A1.34,1.34,0,0,0,28,26.67V5.33A1.34,1.34,0,0,0,26.67,4Z">
          </path>
          <title>Logo Facebook</title>
        </svg>
      </button>
    </div> -->
  </div>
  <div ibmCol
    class="right-side"
    [columnNumbers]="{lg:7, md:4, sm:0}">
  </div>
</div>