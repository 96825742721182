import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { FileItem, ListItem } from 'carbon-components-angular';
import { catchError, debounceTime, filter, finalize, map, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { IApiResponse } from '@app/libs/bitforce/api';
import { LoaderService } from '@app/libs/bitforce/components';

import { AiModel, Project, SynthesisProcedure } from '@models';
import { ApiService } from '@services';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { SuperWizardComponent } from '@app/shared/components/super-wizard/super-wizard.component';
import { requiredIf } from '@app/shared/form-utilities/validators/required-if.validator';
import { bitfPromptToDownloadFile, getMongoQuery } from '@app/libs/bitforce/utils';
import { AuthService } from '@app/libs/bitforce/services';

@Component({
  selector: 'ibm-create-synthesis-wizard-dialog',
  templateUrl: './create-synthesis-wizard-dialog.component.html',
  styleUrls: ['./create-synthesis-wizard-dialog.component.scss'],
})
export class CreateSynthesisWizardDialogComponent extends SuperWizardComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  fromProjects: ListItem[];
  retrosynthesis: ListItem[];
  isRetrosynthesisListLoading = false;
  sequences: ListItem[];
  isSequencesListLoading = false;
  aiModels: ListItem[];
  isAiModelsLoading = false;
  isFromProjectLoading = false;
  uploadFileMaxSize = 20971520;

  EXPERIMENTAL_PROCEDURE = 'EXPERIMENTAL_PROCEDURE';
  RETROSYNTHESIS = 'RETROSYNTHESIS';
  FROM_RETROSYNTHESIS_FILE = 'FROM_RETROSYNTHESIS_FILE';
  FROM_EXISTING_RETROSYNTHESIS = 'FROM_EXISTING_RETROSYNTHESIS';

  private subscription = new Subscription();

  constructor(
    public injector: Injector,
    private apiService: ApiService,
    private loaderService: LoaderService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    @Inject('dialogData') public dialogData: { project: Project }
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      startFrom: [null, [Validators.required]],
      retroStartFrom: [null, requiredIf(() => this.form?.get('startFrom'), this.RETROSYNTHESIS)],
      fromProject: [
        null,
        requiredIf(() => this.form?.get('retroStartFrom'), this.FROM_EXISTING_RETROSYNTHESIS),
      ],
      selectedRetrosynthesis: [
        null,
        requiredIf(() => this.form?.get('retroStartFrom'), this.FROM_EXISTING_RETROSYNTHESIS),
      ],
      selectedSequence: [
        null,
        requiredIf(() => this.form?.get('retroStartFrom'), this.FROM_EXISTING_RETROSYNTHESIS),
      ],
      selectedAiModel: [
        null,
        requiredIf(() => this.form?.get('retroStartFrom'), this.FROM_EXISTING_RETROSYNTHESIS) ||
          requiredIf(() => this.form?.get('retroStartFrom'), this.FROM_RETROSYNTHESIS_FILE),
      ],
      fileId: [null, requiredIf(() => this.form?.get('retroStartFrom'), this.FROM_RETROSYNTHESIS_FILE)],
    });

    this.subscription.add(
      this.form.get('startFrom').valueChanges.subscribe(() => {
        this.form.patchValue({
          retroStartFrom: null,
        });
        this.resetStartFromRetrosynthesisForm();
      })
    );

    this.subscription.add(
      this.form.get('retroStartFrom').valueChanges.subscribe(() => {
        this.resetStartFromRetrosynthesisForm();
      })
    );

    this.getProjectsListItems().subscribe();

    this.retroStartFromChange();
    this.fromProjectChange();
    this.retrosyntheticRouteChange();
    this.sequenceRouteChange();
  }

  onDownloadExampleFile() {
    bitfPromptToDownloadFile(
      'CCC(O)CCN.O=S(=O)(Cl)c1ccc2ncccc2c1>>CCC(O)CCNS(=O)(=O)c1ccc2ncccc2c1' +
        '\n' +
        'CCC(O)CCNS(=O)(=O)c1ccc2ncccc2c1.BrC(Br)(Br)Br.ClCCl>>CCC(Br)CCNS(=O)(=O)c1ccc2ncccc2c1',
      'txt',
      'txt',
      'synthesis-from-file-sample'
    );
  }

  get maxFileSizeString() {
    return (this.uploadFileMaxSize || 0) / 1024 / 1024 + 'MB';
  }

  onSelectedFiles(files: Set<FileItem>) {
    if (files) {
      const selectedFile = Array.from(files)[0];
      if (selectedFile) {
        selectedFile.state = 'upload';
        this.apiService.fileEntries
          .upload<any>({ files: [selectedFile.file] })
          .subscribe(
            (response: any) => {
              this.form.patchValue({
                fileId: response.content?.id,
              });

              selectedFile.state = 'edit';
              selectedFile.uploaded = true;
            },
            error => {
              selectedFile.state = 'edit';
              selectedFile.invalid = true;
              selectedFile.invalidText =
                error.error?.metadata?.uiMessages?.errors[0]?.message || error.message;
            }
          );
      }
    } else {
      this.form.patchValue({
        fileId: null,
      });
    }
  }

  save() {
    this.getOrCreateProject(this.form.value.projectGroup).subscribe((project: Project) => {
      switch (this.form.value.startFrom) {
        case this.EXPERIMENTAL_PROCEDURE: {
          this.closeModal(EDialogCloseEventStatus.OK);
          this.router.navigate(['projects', project.id, project.name, 'experimental-procedure-text']);
          break;
        }
        case this.RETROSYNTHESIS: {
          switch (this.form.value.retroStartFrom) {
            case this.FROM_EXISTING_RETROSYNTHESIS: {
              this.loaderService.show();
              this.apiService.synthesisProcedures
                .getAction<SynthesisProcedure>({
                  relation: 'create-from-sequence',
                  method: 'POST',
                  body: {
                    sequenceId: this.form.value.selectedSequence.sequence.id,
                    aiModel: this.form.value.selectedAiModel.model.name,
                    projectId: project.id,
                  },
                  isBodyRaw: true,
                })
                .pipe(finalize(() => this.loaderService.hide()))
                .subscribe((response: IApiResponse<SynthesisProcedure>) => {
                  this.eventTrackingService.trackEvent('Plan a Synthesis');

                  this.closeModal(EDialogCloseEventStatus.OK);
                  this.router.navigate([
                    `/projects`,
                    project.id,
                    project.name,
                    'synthesis',
                    'procedures',
                    response.content.id,
                  ]);
                });
              break;
            }
            case this.FROM_RETROSYNTHESIS_FILE: {
              this.loaderService.show();
              this.apiService.synthesisProcedures
                .post<SynthesisProcedure>({
                  path: 'create-from-file',
                  body: {
                    aiModel: this.form.value.selectedAiModel.model.name,
                    projectId: project.id,
                    fileId: this.form.value.fileId,
                  },
                  isBodyRaw: true,
                })
                .pipe(finalize(() => this.loaderService.hide()))
                .subscribe((response: IApiResponse<SynthesisProcedure>) => {
                  this.eventTrackingService.trackEvent('Plan a Synthesis');

                  this.closeModal(EDialogCloseEventStatus.OK);
                  this.router.navigate([
                    `/projects`,
                    project.id,
                    project.name,
                    'synthesis',
                    'procedures',
                    response.content.id,
                  ]);
                });
              break;
            }
          }
        }
      }
    });
  }

  private resetStartFromRetrosynthesisForm() {
    this.fromProjects = null;
    this.retrosynthesis = null;
    this.sequences = null;
    this.aiModels = null;
    this.form.patchValue(
      {
        fromProject: null,
        selectedRetrosynthesis: null,
        selectedSequence: null,
        selectedAiModel: null,
        fileId: null,
      },
      { emitEvent: false }
    );
    this.form.updateValueAndValidity();
  }

  private retroStartFromChange() {
    this.subscription.add(
      this.form
        .get('retroStartFrom')
        .valueChanges.pipe(
          filter(value => [this.FROM_EXISTING_RETROSYNTHESIS, this.FROM_RETROSYNTHESIS_FILE].includes(value)),
          switchMap(value => {
            if (value === this.FROM_EXISTING_RETROSYNTHESIS) {
              return of(null).pipe(
                switchMap(() => {
                  this.isFromProjectLoading = true;
                  return this.projectsService.getProjectsWithRetrosynthesis().pipe(
                    finalize(() => (this.isFromProjectLoading = false)),
                    catchError(err => {
                      console.log(err);
                      return of(null);
                    })
                  );
                }),
                map((response: IApiResponse<Project[]>) =>
                  response.content?.map((project: Project) => ({
                    content: project.name,
                    project,
                    selected: false,
                  }))
                ),
                tap((projects: ListItem[]) => (this.fromProjects = projects))
              );
            } else {
              return this.loadAiModels().pipe(map(response => this.mapAiModels(response?.content?.models)));
            }
          })
        )
        .subscribe()
    );
  }

  // List of retrosynthesis
  private fromProjectChange() {
    this.subscription.add(
      this.form
        .get('fromProject')
        .valueChanges.pipe(
          tap((item: ListItem) => {
            this.retrosynthesis = null;
            this.sequences = null;
            this.aiModels = null;

            if (Array.isArray(item) && item.length === 0) {
              // NOTE: a value of [] is sent when the user press on the clear (x) button on the combobox
              this.form.get('fromProject').setValue(null, { emitEvent: false });
            }
            if (!item?.project) {
              this.form.patchValue(
                { selectedRetrosynthesis: null, selectedSequence: null, selectedAIModel: null },
                { emitEvent: false }
              );
            }
          }),
          filter((item: ListItem) => item?.project),
          switchMap((item: ListItem) => {
            this.isRetrosynthesisListLoading = true;
            return this.projectsService
              .getRels({
                id: item.project.id,
                relation: 'retrosynthesis',
                query: getMongoQuery(
                  {
                    filtersQuery: { $and: [{ createdBy: this.authService.user.id }] },
                  },
                  null,
                  null
                ),
              })
              .pipe(
                finalize(() => (this.isRetrosynthesisListLoading = false)),
                catchError(err => {
                  console.log(err);
                  return of(null);
                })
              );
          }),
          filter(response => response !== null),
          tap((response: IApiResponse<SynthesisProcedure[]>) => {
            this.retrosynthesis = response.content.map(retro => {
              const isSelected = response?.content?.length === 1;
              const item = {
                content: retro.name,
                retrosynthesis: retro,
                selected: isSelected,
              };
              if (isSelected) {
                this.form.patchValue({ selectedRetrosynthesis: item });
              }
              return item;
            });
          })
        )
        .subscribe()
    );
  }

  // List of sequences or select default sequence
  private retrosyntheticRouteChange() {
    this.subscription.add(
      this.form
        .get('selectedRetrosynthesis')
        .valueChanges.pipe(
          tap((item: ListItem) => {
            this.sequences = null;
            this.aiModels = null;

            if (Array.isArray(item) && item.length === 0) {
              // NOTE: a value of [] is sent when the user press on the clear (x) button on the combobox
              this.form.get('selectedRetrosynthesis').setValue(null, { emitEvent: false });
            }
            this.form.get('selectedSequence').setValue(null, { emitEvent: false });
          }),
          filter((item: ListItem) => item?.retrosynthesis),
          switchMap((item: ListItem) => {
            this.isSequencesListLoading = true;
            return this.apiService.retrosynthesis.getById({ id: item.retrosynthesis.id }).pipe(
              finalize(() => (this.isSequencesListLoading = false)),
              catchError(err => {
                console.log(err);
                return of(null);
              })
            );
          })
        )
        .subscribe((response: IApiResponse<SynthesisProcedure>) => {
          this.sequences = null;
          if (response?.content?.sequences) {
            this.sequences = response?.content?.sequences
              .filter(sequence => sequence.canCreateSynthesis)
              .map(sequence => {
                const isSelected = response?.content?.sequences.length === 1;
                const item = {
                  content: sequence.label,
                  sequence,
                  selected: isSelected,
                };
                if (isSelected) {
                  this.form.patchValue({ selectedSequence: item });
                }
                return item;
              });
          }
        })
    );
  }

  // list of ai models
  private sequenceRouteChange() {
    this.subscription.add(
      this.form
        .get('selectedSequence')
        .valueChanges.pipe(
          tap((item: ListItem) => {
            this.aiModels = null;
            if (Array.isArray(item) && item.length === 0) {
              // NOTE: a value of [] is sent when the user press on the clear (x) button on the combobox
              this.form.get('selectedSequence').setValue(null, { emitEvent: false });
            }
            this.form.get('selectedAiModel').setValue(null, { emitEvent: false });
          }),
          filter((item: ListItem) => item?.sequence),
          debounceTime(500),
          switchMap(() => this.loadAiModels()),
          map(response => this.mapAiModels(response?.content?.models))
        )
        .subscribe()
    );
  }

  private loadAiModels(): Observable<IApiResponse<{ models: AiModel[] }>> {
    this.isAiModelsLoading = true;
    return this.apiService.aiModels.getAiModelsForSynthesisFromSequence().pipe(
      finalize(() => (this.isAiModelsLoading = false)),
      catchError(err => {
        console.log(err);
        return of(null);
      })
    );
  }

  private mapAiModels(models: AiModel[]) {
    this.aiModels = null;
    if (models) {
      this.aiModels = models.map((model: AiModel) => {
        const item = {
          content: model.name,
          model,
          selected: model.isDefault,
        };

        if (model.isDefault) {
          this.form.patchValue({ selectedAiModel: item });
        }
        return item;
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
