import { SuperModel } from '@models/super-model.model';
import { Molecule } from './molecule.model';
import { RetrosynthesisMolecule } from './retrosynthesis-molecule.model';
import { IFeedback } from './retrosynthesis-sequence.model';

export class ReactionCompletion extends SuperModel {
  reactionCompletionPredictionId: string;
  name: string;
  smiles: string;
  confidence: number;
  isConfidenceComputed: boolean;
  isFavorite: boolean;
  isRenameable: boolean;
  isDeletable: boolean;
  feedback: IFeedback;
  productSmiles: string;
  productMolecule: Molecule;
  precursorSmiles: string;
  precursorMolecules: Molecule[];
  tree: RetrosynthesisMolecule;

  constructor(payload: Partial<ReactionCompletion> = {}) {
    super(payload);

    if (payload.productMolecule) {
      this.productMolecule = new Molecule(payload.productMolecule);
    }

    if (payload.tree) {
      this.tree = new RetrosynthesisMolecule(payload.tree);
    }

    if (payload.precursorMolecules) {
      this.precursorMolecules = payload.precursorMolecules.map(
        precursorMolecule => new Molecule(precursorMolecule)
      );
    }
  }

  get creatorName() {
    if (typeof this.createdBy === 'object') {
      return this.createdBy.fullName;
    }
    return this.createdBy;
  }

  get serialised() {
    return {
      id: this.id,
      reactionCompletionPredictionId: this.reactionCompletionPredictionId,
      name: this.name,
      smiles: this.smiles,
      confidence: this.confidence,
      feedback: this.feedback,
      productSmiles: this.productSmiles,
      productMolecule: this.productMolecule.serialised,
      precursorSmiles: this.precursorSmiles,
      precursorMolecules: this.precursorMolecules.map(precursorMolecule => precursorMolecule.serialised),
      tree: this.tree.serialised,
    };
  }
}
