import { User } from '@models/user.model';

export abstract class SuperModel {
  id?: string;
  createdOn?: string | Date;
  modifiedOn?: string | Date;
  createdBy?: string | User;
  modifiedBy?: string | User;

  constructor(object: any = {}) {
    Object.assign(this, object);
    if (!object) {
      return;
    }
    if (typeof object.createdBy === 'object') {
      this.createdBy = new User(object.createdBy);
    }
    if (typeof object.modifiedBy === 'object') {
      this.modifiedBy = new User(object.modifiedBy);
    }
  }

  abstract readonly serialised;

  toString() {
    return '';
  }
}
