import { RetrosynthesisSequence, RetrosynthesisSibling, RetrosynthesisMolecule } from '@models';

export function sequencesFromSiblingsForMolecule({
  siblings,
  sequence,
  treeNode,
}: {
  siblings: RetrosynthesisSibling[];
  sequence: RetrosynthesisSequence;
  treeNode: RetrosynthesisMolecule;
}): RetrosynthesisSequence[] {
  return siblings
    .filter(sibling => sibling.smiles === treeNode.smiles)
    .map(sibling => siblingToSequence({ sibling, sequence, treeNode }))
    .reduce((acc, currentValue) => acc.concat(currentValue), []);
}

function siblingToSequence({
  sibling,
  sequence,
  treeNode,
}: {
  sibling: RetrosynthesisSibling;
  sequence: RetrosynthesisSequence;
  treeNode: RetrosynthesisMolecule;
}): RetrosynthesisSequence[] {
  const result: RetrosynthesisSequence[] = [];
  sibling.reactions.forEach((reaction, i) => {
    const newSiblingSequence = new RetrosynthesisSequence({
      ...sequence,
      id: i + 1,
      label: `Reaction ${i + 1}`,
      confidence: reaction.confidence,
      confidenceTag: reaction.confidenceTag,
      reactionSmiles: reaction.reactionSmiles,
      custom: reaction.custom,
      tree: {
        ...treeNode,
        confidence: reaction.confidence,
        rclass: reaction.rclass,
        siblingId: reaction.id,
        children: reaction.children,
        isThermal: reaction.isThermal,
        isPhotochemical: reaction.isPhotochemical,
      },
    });
    result.push(newSiblingSequence);
  });

  return result;
}
