import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from './core/services';
import { MessagesAfterLoginService } from './core/services/messages-after-login.service';
import { SocketService } from './core/services/socket.service';
import { filter, take } from 'rxjs/operators';
import { SocketNotificationService } from './core/services/socket-notification.service';
import { environment } from '@env/environment';

@Component({
  selector: 'ibm-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private apiService: ApiService,
    private messagesAfterLoginService: MessagesAfterLoginService,
    private socketService: SocketService,
    private socketNotificationService: SocketNotificationService
  ) {}

  ngOnInit() {
    this.apiService.auth.user$
      .pipe(
        filter(user => !!user),
        take(1)
      )
      .subscribe(() => {
        this.messagesAfterLoginService.showMessages();
        this.socketService.connect();
        this.socketNotificationService.init();
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // This utility manages mobile viewport (when height 100% goes over the page)
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  ngOnDestroy() {
    this.socketService.unsubscribe();
  }
}
