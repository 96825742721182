<ibm-file-uploader [title]="title"
  [description]="description"
  [accept]="acceptedExtensions"
  [multiple]="multiple"
  [skeleton]="skeleton"
  [(files)]="files"
  [size]="size"
  [drop]="drop"
  [dropText]="dropText"
  [buttonText]="buttonText"
  [buttonType]="buttonType"
  (filesChange)="onSelectFiles($event)">
</ibm-file-uploader>