import { SuperModel } from '@models/super-model.model';
import { Challenge } from './challenge.model';
import { Reaction } from './reaction.model';

export class ChallengeResult extends SuperModel {
  challenge?: Challenge;
  humanPrediction?: Reaction;
  machinePredictions?: Reaction[];
  humanTotalTime?: number;
  machineTotalTime?: number;
  humanCorrect?: boolean;
  machineCorrect?: boolean;

  constructor(challengeResult = {}) {
    super(challengeResult);
    if (this.challenge) {
      this.challenge = new Challenge(this.challenge);
    }
    if (this.humanPrediction) {
      this.humanPrediction = new Reaction(this.humanPrediction);
    }
    if (this.machinePredictions) {
      this.machinePredictions = this.machinePredictions.map(reaction => new Reaction(reaction));
    }
  }

  get serialised() {
    return {
      id: this.id,
      challenge: this.challenge && this.challenge.serialised,
      humanPrediction: this.humanPrediction && this.humanPrediction.serialised,
      humanTotalTime: this.humanTotalTime,
      machineTotalTime: this.machineTotalTime,
      humanCorrect: this.humanCorrect,
      machineCorrect: this.machineCorrect,
    };
  }
}
