import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IIbmSearchData } from './search-bar.interface';
import { DialogsService } from '@app/core/services';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { ESearchType } from './search-bar.enum';
import { SmilesEditorDialogComponent } from '@app/modules/smiles-editor/smiles-editor-dialog/smiles-editor-dialog.component';

@Component({
  selector: 'ibm-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input()
  set searchData(value: IIbmSearchData) {
    this.searchString = value.searchString;
    this.searchType = value.isSmiles ? ESearchType.SMILES : ESearchType.ALPHABETICAL;
  }

  @Input()
  showSmilesOption = false;
  @Input()
  showKetcherButton = false;
  @Input()
  placeholder = '';
  @Output()
  searchChange: EventEmitter<IIbmSearchData> = new EventEmitter<IIbmSearchData>();

  private searchSub$ = new Subject<void>();
  searchString = '';
  searchType: ESearchType = ESearchType.ALPHABETICAL;
  eSearchType = ESearchType;
  subscription: Subscription = new Subscription();
  searchTypes = [
    {
      content: 'Alphabetical',
      id: ESearchType.ALPHABETICAL,
    },
    {
      content: 'Smiles',
      id: ESearchType.SMILES,
    },
  ];

  constructor(private dialogsService: DialogsService) {}

  ngOnInit() {
    this.subscription.add(
      this.searchSub$.pipe(debounceTime(500)).subscribe(_ => {
        this.searchChange.emit({
          searchString: this.searchString,
          isSmiles: this.searchType === ESearchType.SMILES,
        });
      })
    );
  }

  onOpenKetcherFilter() {
    this.dialogsService.dialog
      .open(SmilesEditorDialogComponent, {
        smiles: this.searchType === ESearchType.SMILES || !this.showSmilesOption ? this.searchString : null,
        actionLabel: 'Search',
      })
      .afterClosed()
      .subscribe((closeEvent: IDialogCloseEvent<any>) => {
        if (closeEvent.status === EDialogCloseEventStatus.OK) {
          this.searchType = ESearchType.SMILES;
          this.searchString = closeEvent.data;
          this.searchChange.emit({
            searchString: closeEvent.data,
            isSmiles: true,
          });
        }
      });
  }

  onSearch(searchString) {
    this.searchString = searchString || '';
    if (!searchString) {
      this.searchType = ESearchType.ALPHABETICAL;
    }
    this.searchSub$.next();
  }

  onSearchTypeSelected() {
    if (this.searchString !== '') {
      this.searchSub$.next();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
