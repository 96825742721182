import { Component } from '@angular/core';

@Component({
  selector: 'ibm-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent {
  constructor() {}
}
