import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

declare const FB: any;

@Injectable({
  providedIn: 'root',
})
export class SocialFbService {
  initialised = false;

  constructor() {
    this.init();
  }

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.initialised) {
        resolve({});
        return;
      }
      if (!FB) {
        reject({});
        return;
      }

      const params = {
        appId: environment.fbAppId,
        hideFlashCallback: () => {},
        cookie: null,
        status: null,
        version: 'v2.8',
      };

      try {
        resolve(FB.init(params));
        this.initialised = true;
      } catch (e) {
        reject(e);
      }
    });
  }

  ui(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.init().then(
        result => {
          try {
            FB.ui(params, (response: any) => {
              if (!response) {
                reject();
              } else if (response.error) {
                reject(response.error);
              } else {
                resolve(response);
              }
            });
          } catch (e) {
            reject(e);
          }
        },
        error => reject(error)
      );
    });
  }
}
