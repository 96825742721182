import { EPredictionLibrary } from '@app/shared/enums/reaction-property-prediction';
import { SuperModel } from '@models/super-model.model';
import { svgToUrl, ISvgToUrl } from '@shared/common-utilities/svg-to-url';

export class Molecule extends SuperModel {
  name: string;
  smiles: string;
  moleculeName: string;
  reactionsCount?: number;
  moleculeImage?: string;
  metaData?: any;
  library?: EPredictionLibrary;

  constructor(molecule = {}) {
    super(molecule);
  }

  get moleculeImageSvg(): ISvgToUrl {
    return svgToUrl(this.moleculeImage, this.library);
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      smiles: this.smiles,
      reactionsCount: this.reactionsCount,
    };
  }
}
