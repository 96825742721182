import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { BaseModal } from 'carbon-components-angular';

import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';

@Component({
  selector: 'ibm-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss'],
})
export class RenameDialogComponent extends BaseModal implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject('dialogData')
    public dialogData: { name: string; title: string; message: string; label: string; placeholder: string }
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      name: [this.dialogData.name, Validators.required],
    });
  }

  onRename() {
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
      data: this.form.value.name,
    } as IDialogCloseEvent<string>);
  }

  closeModal() {
    this.close.emit({
      status: EDialogCloseEventStatus.CANCEL,
    } as IDialogCloseEvent<void>);
  }
}
