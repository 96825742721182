<div class="list__expansion-row">
  <div class="list__expansion-row__table">
    <div class="list__expansion-row__table-row">
      <div class="list__expansion-row__img-container">
        <ng-content select="[images]"></ng-content>
      </div>
    </div>
    <div class="list__expansion-row__table-row">
      <div class="list__expansion-row__smiles-container">
        <div class="list__expansion-row__smiles-container__title small--xs mb-5">Smiles</div>
        <div class="list__expansion-row__smiles-container__smiles">
          <ng-content select="[smiles]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>