import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { AppSessionService } from '@services';
@Component({
  selector: 'ibm-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  constructor(public appSessionService: AppSessionService, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // lg breakpoint
    if (window.innerWidth < 1056) {
      setTimeout(() => (this.appSessionService.isLeftSidenavVisible = false));
    } else {
      setTimeout(() => (this.appSessionService.isLeftSidenavVisible = true));
    }
  }

  public isFullscreen(node: ActivatedRouteSnapshot = this.activatedRoute.snapshot) {
    return node && (node.data.fullscreen || this.isFullscreen(node.firstChild));
  }
}
