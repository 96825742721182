import { IPaymentMethod } from './payment-method.interface';
import { Subscription } from './subscription.model';
import { SuperModel } from './super-model.model';
import { UserBillingInformation } from './user-billing-information.model';

export class BillingOverview extends SuperModel {
  billingInformation: UserBillingInformation;
  billingCycle: string;
  usageInCurrentCycleFormatted: string;
  amountInCurrentCycleFormatted: string;
  paymentMethod: IPaymentMethod;
  subscription: Subscription;
  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
