<div
  data-dropdown
  data-dropdown-type="inline"
  data-value
  class="bx--dropdown bx--dropdown--inline w-100"
  #dropDown
>
  <ul class="bx--dropdown-list" role="menu" tabindex="0">
    <li
      *ngFor="let item of items"
      data-option
      data-value="{item}"
      (click)="select.emit(item)"
      class="bx--dropdown-item"
    >
      <a class="bx--dropdown-link">{{ item }}</a>
    </li>
  </ul>
</div>
