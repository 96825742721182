import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FileItem } from 'carbon-components-angular';

import { requiredIf } from '@app/shared/form-utilities/validators/required-if.validator';
import { Project, Retrosynthesis } from '@models';
import { ApiService } from '@services';
import { EDialogCloseEventStatus } from '@services/dialog/dialog.enum';
import { SuperWizardComponent } from '@app/shared/components/super-wizard/super-wizard.component';
import { IApiResponse } from '@app/libs/bitforce/api';
import { LoaderService } from '@app/libs/bitforce/components';
import { bitfPromptToDownloadFile } from '@app/libs/bitforce/utils';

@Component({
  selector: 'ibm-create-retrosynthesis-wizard-dialog',
  templateUrl: './create-retrosynthesis-wizard-dialog.component.html',
  styleUrls: ['./create-retrosynthesis-wizard-dialog.component.scss'],
})
export class CreateRetrosynthesisWizardDialogComponent extends SuperWizardComponent
  implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  TARGET_MOLECULE = 'TARGET_MOLECULE';
  FROM_FILE = 'FROM_FILE';
  uploadFileMaxSize = 20971520;

  private subscription = new Subscription();

  constructor(
    public injector: Injector,
    private apiService: ApiService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    @Inject('dialogData') public dialogData: { project: Project }
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      startFrom: [null, [Validators.required]],
      fileId: [null, requiredIf(() => this.form?.get('startFrom'), this.FROM_FILE)],
    });
    this.getProjectsListItems().subscribe();

    this.subscription.add(
      this.form.get('startFrom').valueChanges.subscribe(() => {
        this.form.get('fileId').updateValueAndValidity();
      })
    );
  }

  get maxFileSizeString() {
    return (this.uploadFileMaxSize || 0) / 1024 / 1024 + 'MB';
  }

  onSelectedFiles(files: Set<FileItem>) {
    if (files) {
      const selectedFile = Array.from(files)[0];
      if (selectedFile) {
        selectedFile.state = 'upload';
        this.apiService.fileEntries
          .upload<any>({ files: [selectedFile.file] })
          .subscribe(
            (response: any) => {
              this.form.patchValue({
                fileId: response.content?.id,
              });

              selectedFile.state = 'edit';
              selectedFile.uploaded = true;
            },
            error => {
              selectedFile.state = 'edit';
              selectedFile.invalid = true;
              selectedFile.invalidText =
                error.error?.metadata?.uiMessages?.errors[0]?.message || error.message;
            }
          );
      }
    } else {
      this.form.patchValue({
        fileId: null,
      });
    }
  }

  createRetro() {
    this.getOrCreateProject({ ...this.form.value.projectGroup }).subscribe((project: Project) => {
      if (this.form.value?.startFrom === this.FROM_FILE) {
        this.loaderService.show();
        this.apiService.retrosynthesis
          .post({
            path: 'rs/from-file',
            isBodyRaw: true,
            body: {
              projectId: project.id,
              fileId: this.form.value.fileId,
            },
          })
          .pipe(finalize(() => this.loaderService.hide()))
          .subscribe((response: IApiResponse<Retrosynthesis>) => {
            this.eventTrackingService.trackEvent('Predict Retrosynthesis');

            this.closeModal(EDialogCloseEventStatus.OK);
            this.router.navigate([
              '/projects',
              project.id,
              project.name,
              'retrosynthesis',
              response.content.id,
            ]);
          });
      } else {
        this.closeModal(EDialogCloseEventStatus.OK);
        this.router.navigate(['projects', project.id, project.name, 'workspace', 'retrosynthesis']);
      }
    });
  }

  onDownloadExampleFile() {
    bitfPromptToDownloadFile(
      'CCC(O)CCN.O=S(=O)(Cl)c1ccc2ncccc2c1>>CCC(O)CCNS(=O)(=O)c1ccc2ncccc2c1' +
        '\n' +
        'CCC(O)CCNS(=O)(=O)c1ccc2ncccc2c1.BrC(Br)(Br)Br.ClCCl>>CCC(Br)CCNS(=O)(=O)c1ccc2ncccc2c1',
      'txt',
      'txt',
      'retrosynthesis-from-file-sample'
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
