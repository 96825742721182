import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

/**
 * NOTE: to make this validator work, it is needed to revalidate correlated form controls
 *
 * @param controlGetter getter function to get the control to check for validation
 * @param value the value that makes the control to be validated requred
 * @returns validator function
 */
export function requiredIf(
  controlGetter: () => AbstractControl,
  value: any
): (c: AbstractControl) => ValidationErrors | null {
  return c => (controlGetter()?.value === value ? Validators.required(c) : null);
}
