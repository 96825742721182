<ibm-modal [open]="open">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>{{dialogData.title}}</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <div>{{dialogData.description}}</div>

    <div class="mt-5">
      <ibm-dropdown label="AI model version"
        placeholder="Choose AI model"
        [formControl]="aiModelFromControl"
        itemValueKey="content">
        <ibm-dropdown-list [items]="aiModels"></ibm-dropdown-list>
      </ibm-dropdown>
    </div>
  </div>
  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="closeModal()">Close</button>

    <button ibmButton="primary"
      [disabled]="!aiModelFromControl.value"
      (click)="onSelectAIModel()">
      {{dialogData.actionLabel}}
    </button>
  </ibm-modal-footer>
</ibm-modal>