import { SuperModel } from './super-model.model';

export class SubscriptionTeam extends SuperModel {
  description: string;
  name: string;
  documentNumber: string;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {
      description: this.description,
      name: this.name,
      documentNumber: this.documentNumber,
      id: this.id,
    };
  }
}
