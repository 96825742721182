import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncDecimal',
})
export class TruncDecimalPipe implements PipeTransform {
  transform(value: number, decimals = 3): string {
    if (value < Math.pow(10, -decimals)) {
      return value.toExponential(decimals);
    } else if (!Number.isInteger(value)) {
      return (Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals)).toString();
    } else {
      return value.toString();
    }
  }
}
