export const dimensionUnits = ['Bytes', 'KB', 'MB', 'GB', 'TB'] as const;

export function getBestDimensionUnit(bytes: number) {
  const i = Math.min(Math.max(Math.floor(Math.log(bytes) / Math.log(1024)), 0), dimensionUnits.length - 1);
  return dimensionUnits[i];
}
export function convertToBytes(value: number, unit: 'Bytes' | 'KB' | 'MB' | 'GB' | 'TB'): number {
  switch (unit) {
    case 'Bytes':
      return value;
    case 'KB':
      return value * 1024;
    case 'MB':
      return value * 1024 * 1024;
    case 'GB':
      return value * 1024 * 1024 * 1024;
    case 'TB':
      return value * 1024 * 1024 * 1024 * 1024;
    default:
      return 0;
  }
}

export function convertFromBytes(value: number, unit: 'Bytes' | 'KB' | 'MB' | 'GB' | 'TB'): number {
  switch (unit) {
    case 'Bytes':
      return value;
    case 'KB':
      return value / 1024;
    case 'MB':
      return value / (1024 * 1024);
    case 'GB':
      return value / (1024 * 1024 * 1024);
    case 'TB':
      return value / (1024 * 1024 * 1024 * 1024);
    default:
      return 0;
  }
}
