import { SuperModel } from './super-model.model';

export class QueueState extends SuperModel {
  itemsInQueue?: number;
  willBeCompletedOn?: Date;

  constructor(data: any) {
    super(data);
    if (data.willBeCompletedOn) {
      this.willBeCompletedOn = new Date(data.willBeCompletedOn);
    }
  }

  get serialised() {
    return {};
  }
}
