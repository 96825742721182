<ibm-smiles-editor-header (action)="onAction()"
  [currentEditor]="currentEditor"
  [disableActions]="currentEditor === eSmilesEditorType.TEXTUAL"
  [actionLabel]="actionLabel"
  [inModal]="inModal"
  (changeEditorType)="onChangeEditorType($event)"
  (close)="close.emit()"></ibm-smiles-editor-header>

<div class="smiles-editor"
  [ngClass]="{ 'modal': inModal}">
  <ibm-ketcher [hidden]="currentEditor !== eSmilesEditorType.VISUAL"
    #ketcher
    class="flex-grow-1 h-100 w-100"
    height="100%"
    width="100%"
    [enableAutoSave]="false"></ibm-ketcher>

  <ibm-smiles-editor-textual [hidden]="currentEditor !== eSmilesEditorType.TEXTUAL"
    class="flex-grow-1 w-100 h-100"
    [smiles]="smiles"
    (loadSmilesInVisualEditor)="onLoadSmilesInVisualEditor($event)">
  </ibm-smiles-editor-textual>
</div>