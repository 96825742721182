import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSessionService } from '@app/core/services';
import { ListItem } from 'carbon-components-angular';

@Component({
  selector: 'ibm-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss'],
})
export class ZoomComponent implements OnInit {
  @Input()
  theme = 'light';
  @Input()
  defaultZoomValue = 100;
  @Input()
  showZoomSelector = true;
  @Input()
  showFitToScreen = false;
  @Input()
  zoomValues = [50, 75, 100, 200, 300, 400];
  @Input()
  currentZoomIndex;

  @Output()
  zoomSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  fitToScreen: EventEmitter<void> = new EventEmitter<void>();

  public zoomOptions: ListItem[] = [];

  constructor(public appSessionService: AppSessionService) {}

  ngOnInit() {
    this.zoomOptions = this.zoomValues.map((val, index) => ({
      content: val + '%',
      value: val,
      selected: index === this.defaultZoomIndex,
    }));

    if (!this.currentZoomIndex) {
      this.currentZoomIndex = this.defaultZoomIndex;
    }
  }

  get defaultZoomIndex() {
    return Math.max(this.zoomValues.indexOf(this.defaultZoomValue), 0);
  }

  get currentZoom() {
    return this.zoomOptions[this.currentZoomIndex];
  }

  resetToDefault() {
    this.currentZoomIndex = this.defaultZoomIndex;
  }

  onZoomIn() {
    this.currentZoomIndex = Math.min(this.currentZoomIndex + 1, this.zoomOptions.length - 1);
    this.emitZoomValue();
  }

  onZoomOut() {
    this.currentZoomIndex = Math.max(this.currentZoomIndex - 1, 0);
    this.emitZoomValue();
  }

  onZoomSelected({ item }: { item: ListItem }) {
    this.currentZoomIndex = this.zoomOptions.findIndex(option => option.value === item.value);
    this.emitZoomValue();
  }

  onFitToScreen() {
    this.resetToDefault();
    this.fitToScreen.emit();
  }

  private emitZoomValue() {
    this.zoomSelected.emit(this.currentZoom.value);
  }
}
