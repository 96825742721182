import {
  IRetrosynthesisWizardParameters,
  Project,
  Retrosynthesis,
  RetrosynthesisMolecule,
  RetrosynthesisSequence,
  User,
} from '.';
import { SuperModel } from './super-model.model';

export abstract class SynthesisSuperModel extends SuperModel {
  molecules: RetrosynthesisMolecule[];
  sequences: RetrosynthesisSequence[];
  wizardParameters?: IRetrosynthesisWizardParameters;
  project: Project;
  user: User;
  productMolecule: RetrosynthesisMolecule;
  outcomeMolecules: RetrosynthesisMolecule[];
  retrosynthesis: Retrosynthesis;
  projectId: string;
  retrosynthesisId: string;
  retrosynthesisCollectionId: string;
  errorMessage: string;
  name: string;
  canCreateSynthesis: boolean;
  fromText: boolean;
  isFavorite: boolean;
  isRunnable: boolean;
  isEditable: boolean;
  isDeletable: boolean;
  isRenameable: boolean;
  isInteractive: boolean;
  isResumable: boolean;
  isFromFile: boolean;
  isProcedure?: boolean;
  stats: {
    total: number;
    totalByStatus: {
      WAITING: number;
      NEW: number;
      DONE: number;
      RUNNING: number;
      ERROR: number;
    };
  };
  steps: string;
  aiModel: string;
  reactors?: number[];
  smiles: string;
  reactionSmiles: string;
  datasetName: string;
  paragraphsText: string[];
  sourceType: ESynthesisSourceType;

  constructor(data) {
    super(data);

    if (data.retrosynthesis) {
      this.retrosynthesis = new Retrosynthesis(data.retrosynthesis);
    }
    if (data.molecules) {
      this.molecules = data.molecules.map(m => new RetrosynthesisMolecule(m));
    }
    if (data.sequences) {
      this.sequences = data.sequences.map(s => new RetrosynthesisSequence(s));
    }
    if (data.project) {
      this.project = new Project(data.project);
    }
    if (data.user) {
      this.user = new User(data.user);
    }
    if (data.productMolecule) {
      this.productMolecule = new RetrosynthesisMolecule(data.productMolecule);
    }
    if (data.outcomeMolecules && data.outcomeMolecules.length) {
      this.outcomeMolecules = data.outcomeMolecules.map(molecule => new RetrosynthesisMolecule(molecule));
    }
  }

  get creatorName() {
    if (this.createdBy && typeof this.createdBy === 'object') {
      return this.createdBy.fullName;
    } else if (this.user && typeof this.user === 'object') {
      return this.user.fullName;
    } else {
      return this.createdBy;
    }
  }

  get sourceTypeLabel() {
    switch (this.sourceType) {
      case ESynthesisSourceType.TEXT:
        return 'From text';
      case ESynthesisSourceType.RETROSYNTHESIS:
        return 'From retrosynthesis';
    }
  }
}

export enum ESynthesisTypologyFilter {
  FROM_RETROSYNTHESIS = 'from_retrosynthesis',
  FROM_TEXT = 'from_text',
}

export enum ESynthesisSourceType {
  TEXT = 'TEXT',
  RETROSYNTHESIS = 'RETROSYNTHESIS',
}
