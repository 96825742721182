<ibm-modal [open]="open"
  size="xs"
  [hasScrollingContent]="false">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h4>Pay-as-you-go training rates</h4>
  </ibm-modal-header>
  <section class="bx--modal-content">
    <ibm-structured-list>
      <ibm-list-row *ngFor="let item of dialogData.data">
        <ibm-list-column>
          <div class="d-flex align-items-center">
            <span>{{item.hours}}</span>
            <span class="ml-auto"><strong>{{item.cost}}</strong></span>
          </div>
        </ibm-list-column>
      </ibm-list-row>
    </ibm-structured-list>
  </section>
</ibm-modal>