import {
  Directive,
  Input,
  ElementRef,
  ViewContainerRef,
  ComponentRef,
  TemplateRef,
  ComponentFactoryResolver,
  OnDestroy,
} from '@angular/core';

import {
  FormGroup,
  UntypedFormControl,
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { Subscription } from 'rxjs';

// eslint-disable-next-line max-len
import { ValidationMessageComponent } from '@shared/form-utilities/validation-message/validation-message.component';

// NOTE Demo / test directive
@Directive({
  selector: '[ibmValidationMessage]',
})
export class ValidationMessageDirective implements OnDestroy {
  el: ElementRef;

  private formControl: AbstractControl;
  private errorComponent: ComponentRef<ValidationMessageComponent>;
  private validateOnSubmitOnly = false;
  private statusChangeSubscription: Subscription;
  private submitChangeSubscription: Subscription;
  private view: any;
  private inputElement: HTMLElement;

  @Input('ibmValidationMessage')
  set formField(formControl: UntypedFormControl) {
    this.unsubscribeAll();

    this.formControl = formControl;

    this.view = null;
    if (this.viewContainer.length > 0) {
      this.view = this.viewContainer.get(0);
    } else {
      this.view = this.viewContainer.createEmbeddedView(this.templateRef);
    }

    this.inputElement = this.view.rootNodes[0];

    if (formControl) {
      const cmpFactory = this.cfr.resolveComponentFactory(ValidationMessageComponent);
      this.errorComponent = this.viewContainer.createComponent(cmpFactory);
      this.errorComponent.instance.attachedTo = formControl;
      this.errorComponent.instance.validateOnSubmitOnly = this.validateOnSubmitOnly;
      this.errorComponent.instance.inputElement = this.inputElement;

      const formDirective = this.controlContainer.formDirective as FormGroupDirective;
      this.statusChangeSubscription = formControl.statusChanges.subscribe(e => this.manipulateCSSClasses());
      this.submitChangeSubscription = formDirective.ngSubmit.subscribe(e => this.onSubmit());
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private controlContainer: ControlContainer
  ) {}

  private onSubmit() {
    this.formControl.markAsTouched();
    this.manipulateCSSClasses();
  }

  private unsubscribeAll() {
    if (this.inputElement) {
      this.inputElement.removeAttribute('data-invalid');
    }
    if (this.errorComponent) {
      this.errorComponent.destroy();
    }
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
      delete this.statusChangeSubscription;
    }

    if (this.submitChangeSubscription) {
      this.submitChangeSubscription.unsubscribe();
      delete this.submitChangeSubscription;
    }
  }

  private manipulateCSSClasses() {
    this.inputElement.removeAttribute('data-invalid');
    const formSubmitted = (this.controlContainer.formDirective as FormGroupDirective).submitted;

    if (this.validateOnSubmitOnly && !formSubmitted) {
      return;
    }

    const reset = (!this.formControl.touched && this.formControl.pristine) || this.formControl.disabled;

    let classes: string[] = this.inputElement.className.split(' ');
    const hasInvalid = classes.includes('is-invalid');

    if (reset) {
      classes = classes.filter(c => c !== 'is-invalid');
    } else if (this.formControl.status === 'INVALID' && !hasInvalid) {
      classes.push('is-invalid');
    } else if (this.formControl.status !== 'INVALID' && hasInvalid) {
      classes = classes.filter(c => c !== 'is-invalid');
    }

    this.inputElement.className = classes.join(' ');
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }
}
