import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { LoggerService, ILoggerParams } from '@bitf/services';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    this.handle(error);
    super.handleError(error);
  }

  handle(errorObj: Error | HttpErrorResponse, loggerParams: ILoggerParams = {}) {
    const loggerService = this.injector.get(LoggerService);
    const logObj: ILoggerParams = {};
    if (errorObj instanceof Error) {
      if (
        environment.loggerConfig.logExceptions ||
        (loggerParams.fromDecorator && environment.loggerConfig.logDecoratedExceptions)
      ) {
        const stackParts = errorObj.stack.split(/\u000A/);
        Object.assign(logObj, {
          level: 'ERROR',
          title: 'Exception',
          description: stackParts[0],
          location: stackParts[1],
        });
      }
    } else if (errorObj instanceof HttpErrorResponse) {
      if (environment.loggerConfig.logHttpErrorsWithStatusesCodes.includes(errorObj.status)) {
        Object.assign(logObj, {
          level: 'ERROR',
          title: 'HttpErrorResponse',
          httpErrorResponse: {
            error: errorObj.error,
            url: errorObj.url,
            message: errorObj.message,
            name: errorObj.name,
          },
        });
      }
    } else {
      logObj.level = (loggerParams && loggerParams.level) || 'INFO';
      logObj.title = loggerParams.title || 'Generic log';
      logObj.description = loggerParams.description || 'Generic log';
    }
    if (Object.keys(logObj).length) {
      Object.assign(logObj, loggerParams);
      if (environment.loggerConfig.enabled) {
        loggerService.log(logObj);
      }
    }
  }
}
