<ibm-mlrp-header [hasSidenav]="false">
</ibm-mlrp-header>


<p><strong>AI Results Terms of Use</strong></p>
<p>The results of any AI model can be downloaded and used by each user with no
  limitations under a <a href="https://creativecommons.org/licenses/by/4.0/"
    rel="no-follow">CC-BY license</a></p>
<p class="text-center mt-5">
  <strong>International Business Machines Corporation</strong>
</p>
<p class="text-center">
  <strong>
    Thomas J. Watson Research Center, 1101 Kitchawan Road, Yorktown Heights,
    New York 10598, USA
  </strong>
</p>
<p class="mt-5 mb-5">
  <strong>Complete Agreement:</strong> This IBM Technology Early Access
  Agreement (“Agreement”) is the complete agreement governing the Customer’s
  access to and use of the IBM Technology made available in a cloud services
  environment.
</p>
<table cellspacing="0"
  cellpadding="0"
  class="terms-table">
  <tbody>
    <tr>
      <td colspan="2"
        valign="top">
      </td>
    </tr>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>1. Definitions</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>a. Customer</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            <strong>“Customer”</strong> means the company and/or
            authorized users of the IBM Technology.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>b. Content</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            <strong>“Content”</strong> means any public or
            proprietary data and information that Customer or its
            authorized users provides, authorizes access to, uploads
            or inputs to the IBM Technology under this Agreement,
            including but not limited to inputs to a model,
            including by inferencing or prompting the model, and any
            content provided by Customer for further training of a
            model, including fine-tuning or prompt-tuning.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>c. IBM Technology</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            <strong>“IBM Technology”</strong> means RXN for
            Chemistry, which is a digital chemistry service that
            provides access to technology that predicts chemical
            properties and reactions. IBM Technology offers a set of
            capabilities that enable chemists and materials
            scientists to experiment with AI models for, including
            but not limited to, forward reaction prediction,
            retrosynthetic prediction, and experimental procedure
            generation.
          </li>
        </ul>
        <p>
          <strong></strong> <br />
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
      </td>
      <td valign="top">
      </td>
    </tr>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>2. Scope</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>
            a. IBM Technology Access, Right to Use, Customer
            Responsibilities
          </strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            IBM will provide Customer with secure access to the IBM
            Technology.
          </li>
          <li>
            Customer may use the IBM Technology to access and use a
            set of cloud-hosted tools that use AI to perform
            predictions and analysis of chemical reactions. Customer
            may use the IBM Technology as long as Customer has
            access to it.
          </li>
          <li>
            Customer is responsible for the use of the IBM
            Technology by any user who accesses the IBM Technology
            with Customer's account credentials.
          </li>
          <li>
            Customer agrees to provide IBM with usage statistics and
            feedback on its use of the IBM Technology. IBM may use
            such usage statistics and feedback for any purpose,
            including for improvements or enhancements of the IBM
            Technology.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>b. Prohibited Use</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            IBM Technology may not be used to undertake any activity
            or host Content that: (1) is unlawful, fraudulent,
            harmful, malicious, obscene, or offensive; (2) breaches,
            threatens or violates the rights of others; (3) disrupts
            or gains (or intends to disrupt or gain) unauthorized
            access to data, services, networks, or computing
            environments within or external to IBM; (4) sends
            unsolicited, abusive, or deceptive messages of any type;
            (5) distributes any form of malware, or (6) is not in
            accordance with this Agreement.
          </li>
          <li>
            If there is a complaint or notice of violation, access
            to the IBM Technology may be immediately suspended until
            resolved, and terminated if not resolved promptly.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>c. IBM’s Right to Change IBM Cloud Services</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            At any time and at IBM’s discretion, IBM may change the
            terms applicable to the IBM Technology, modify the
            computing environment, or withdraw its features, in
            whole or in part by providing notice to the Customer.
          </li>
          <li>
            Continued use of the IBM Technology is assumed as
            Customer’s acceptance of any such change. If Customer
            does not accept a change, Customer is responsible to
            discontinue the use of the IBM Technology upon such
            notice by IBM.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>d. Tier plans</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            <strong>Free plan</strong>. Access to the IBM Technology
            through the web-based UI.
          </li>
          <li>
            <strong>Pay-as-you-go plan</strong>. Access to the IBM
            Technology, including the UI/UX and the API, with an
            expanded catalog of public models and collaboration
            features. Access to the IBM Technology will be provided
            on a time delineated basis, where the metric of use will
            be a computational second.
          </li>
          <li>
            <strong>Subscription plan</strong>. All features and
            access to the IBM Technology included in the
            pay-as-you-go plan for up to ten (10) user accounts
            during the term of this Agreement.
          </li>
        </ul>
        <p>
          <strong></strong> <br />
        </p>
      </td>
    </tr>
    <tr>
      <td colspan="2"
        valign="top">
      </td>
    </tr>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>3. Consideration</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          a. Consideration amount
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Pricing information can be found under
            <a href="https://rxn.app.accelerate.science/rxn/user-subscription">
              https://rxn.app.accelerate.science/rxn/user-subscription
            </a>
            .
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          b. Payment method
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Payments are non-refundable and will be made in U.S.
            Dollars via credit card provided by Customer.
          </li>
          <li>
            <strong>Pay-as-you-go plan</strong>. Payments will be
            automatically billed on an actual usage, in arrears, at
            the end of the monthly billing period.
          </li>
          <li>
            <strong>Subscription plan.</strong>Both, monthly and
            annual payments will be automatically billed in advance
            prior to obtaining access to the IBM Technology.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          c. Taxes
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            In the event Customer is required to pay any withholding
            or deduction of tax required under an applicable
            governmental entity regulation based on IBM’s charge for
            cross border transaction, Customer will increase the sum
            payable by the amount necessary to ensure IBM receives
            an amount equal to the sum it would have received had no
            withholdings or deductions been made. Customer is
            responsible to pay any withholding tax directly to the
            appropriate government entity where required by law.
          </li>
          <li>
            If Customer imports, exports, transfers, accesses, or
            uses an IBM Technology across a border, Customer agrees
            to be responsible for and pay authorities any custom,
            duty, tax, or similar levy assessed by the authorities.
            This excludes those taxes based on IBM’s net income.
          </li>
          <li>
            Where taxes are based upon the location(s) receiving the
            benefit of the IBM Technology, Customer has an ongoing
            obligation to notify IBM of such location(s) if
            different than Customer’s business address listed above.
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
  <tbody>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>4. Term and Termination</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>a. Term</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            This Agreement becomes effective on the date Customer
            accepts this Agreement and remains in effect as long as
            Customer has access to the IBM Technology under this
            Agreement.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>b. Termination</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            <strong>Termination for Convenience</strong>. Customer
            may terminate its subscription to the IBM Technology at
            any time by giving IBM a written notice of termination.
            Such termination shall be effective as of the time of
            notification.
          </li>
          <li>
            <strong>Termination for Material Breach</strong>. If
            either party to this Agreement breaches any material
            obligation of this Agreement, then, upon thirty (30)
            days written notice to the breaching party specifying
            such breach, the non-breaching Party may terminate this
            Agreement without liability, unless the breach specified
            in the notice has been cured within the thirty (30) day
            notice period to the satisfaction of the non-breaching
            party.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>c. Effect of Termination</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Upon termination of the Agreement, IBM will revoke
            Customer’s access to the IBM Technology and remove
            Content from it after a retention period of one (1)
            month.
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>
<table cellspacing="0"
  cellpadding="0"
  class="terms-table">
  <tbody>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>5. Content and Data Protection</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>a. Ownership</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer will retain all right, title and interest in
            the Content and the output generated by the IBM
            Technology, subject only to the limited right of access
            provided to IBM under this Agreement.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>b. IBM’s Right of Access</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer grants the rights and permissions to IBM, its
            affiliates, and contractors of either, to use, provide,
            store, and otherwise process Content solely for the
            purpose of providing access to and facilitating the use
            of and improving the IBM Technology.
          </li>
          <li>
            IBM, its affiliates, and contractors of either, may
            access and use the Content solely for such purposes,
            unless otherwise agreed in writing.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>c. Confidentiality</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Content shall be considered Customer confidential
            information.
          </li>
          <li>
            Except for Content, any information provided by either
            party under this Agreement will be considered
            non-confidential, and each party will be free to use and
            disclose such information without limitation.
          </li>
          <li>
            IBM, its affiliates, and contractors of either will
            treat Content as confidential by only disclosing it to
            those employees and representatives to the extent
            necessary to provide access to and facilitate the use of
            the IBM Technology.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>d. Customer Representations and Warranties</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer represents and warrants that (i) the Content
            does not contain information that may include personally
            identifiable information, except Business Contact
            Information or Account Usage Information, as defined in
            Article 8(b), (ii) the Content does not contain any
            other information that may be subject to personal
            privacy laws of any jurisdiction, including the European
            General Data Protection Regulation (EU/2016/679) (“Data
            Protection Laws”), (iii) it has all necessary rights and
            consents to upload or input Content to the IBM
            Technology and to permit IBM and its contractors to
            access and use the Content if required for the purpose
            of providing access to and facilitating the use of the
            IBM Technology, (iv) the access and use of Content by
            IBM and its contractors for the purpose of (iii) will
            not violate any laws, including Data Protection Laws.
            Customer is solely responsible for outputs generated by
            any model.
          </li>
          <li>
            Customer agrees not to upload or input any Content to
            the IBM Technology that may contain personally
            identifiable information or any other data that may be
            subject to Data Protection Laws, unless and until the
            parties have reached an agreement in writing on the
            requirements for receipt and processing of any such
            other data. Such additional agreement can be found at
            <a href="http://ibm.com/dpa">
              http://ibm.com/dpa
            </a>
            and will be subject to the terms of this Agreement.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>e. Removal of Content</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer may remove Content from IBM Technology at any
            time.
          </li>
          <li>
            IBM will remove Content from IBM computing resources
            upon the termination of the access to the IBM
            Technology, or earlier upon Customer's request.
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>
<table cellspacing="0"
  cellpadding="0"
  class="terms-table">
  <tbody>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>
            6. IBM Technology Ownership, Access and Use Standards
          </strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>a. Ownership</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            IBM will retain all right, title and interest in the IBM
            Technology, subject only to the limited right of access
            provided to Customer under this Agreement.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>b. Customer’s Right of Access</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer may access and use the IBM Technology, and any
            information contained therein only as provided in this
            Agreement. Customer may access the IBM Technology, as
            licensed, through (i) a user interface, or (ii) a public
            API. Customer accepts that availability of the IBM
            Technology is subject to maintenance and unanticipated
            conditions.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>
            c. Customer’s Responsibility of Access Control
          </strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer is solely responsible for: (i) access control
            to the IBM Technology, (ii) ensuring that all usernames
            and passwords provided by IBM to Customer remain
            confidential and are not used by unauthorized
            individuals, and (iii) all activities performed by
            Customer employees/representatives while logged in under
            usernames and passwords that IBM has provided to
            Customer. Customer must immediately contact IBM if it
            suspects usernames and passwords have been compromised
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>
            d. Customer’s Responsibilities and Use Standards
          </strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer ensures that all of its equipment which can
            access the IBM Technology will be physically and
            technically protected, including without limitation,
            screen and keyboard locks, and will prevent IP
            forwarding or other services such as DNS or DHCP, and
            maintain the security and integrity of Customer’s
            facilities and its computer network.
          </li>
          <li>
            Customer ensures to comply with state-of-the-art
            security standards and to not expose IBM Technology to
            any cybersecurity threats. Customer will promptly notify
            IBM if Customer discovers or suspects any cybersecurity
            threat, and will fully cooperate with IBM to remove such
            threat and protect the IBM Technology, which may
            include, without limitation, termination of Customer’s
            access rights to the IBM Technology.
          </li>
          <li>
            Customer will not use the IBM name, any IBM trademark,
            or refer to Customer’s access to the IBM Technology
            without IBM’s prior written approval.
          </li>
          <li>
            Customer will not, and will not allow any third party
            to: (i) copy, modify, link to, distribute or access the
            IBM Technology, or any component thereof, or (ii)
            reverse engineer or otherwise attempt to discover or
            decode the IBM Technology, or any component thereof,
            except as specifically permitted by law without the
            possibility of contractual waiver.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>e. IBM’s Right to Monitor</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            IBM may monitor Customer’s use of the IBM Technology for
            (i) control of Customer’s compliance with the Use
            Standards set out in Article 6(d) above, (ii) quality
            control, and (iii) other purposes.
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>
<table cellspacing="0"
  cellpadding="0"
  class="terms-table">
  <tbody>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>7. Disclaimers and Limitation of Liability</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>a. No Warranty</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            The IBM Technology is provided as-is, with no warranties
            of any kind, including (i) no warranty for uninterrupted
            or error-free operation of the IBM Technology; or (ii)
            no other warranties such as implied warranties or
            conditions of satisfactory quality, merchantability,
            fitness for a particular purpose, and any warranty of
            non-infringement of patents, copyrights, or any other
            intellectual property right.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>b. Customers risks</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Customer assumes all risks associated with its access to
            and use of the IBM Technology, and all component(s)
            thereof. The risks Customer assumes include, but are not
            limited to, the risks of (i) IBM Technology errors, (ii)
            intellectual property rights related to uploading or
            inputting Content to the IBM Technology, or those
            related to any output of the IBM Technology (iii) damage
            to or loss of Customer programs or equipment, and (iv)
            unavailability or interruption of operations. Neither
            IBM, nor its affiliates or contractors are responsible
            for the accuracy, completeness, timeliness, reliability,
            content or availability of the IBM Technology.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>c. Limitation of Liability</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            IBM’s and Customer’s entire liability for all claims in
            the aggregate arising under this Agreement will not
            exceed the amount of any actual direct damages incurred
            up to the lesser of ten thousand US Dollars (USD
            $10,000) or the actual amounts paid for access to the
            IBM Technology. This limit applies regardless of why a
            party claims damages from the other party, including
            default, fundamental breach, negligence,
            misrepresentation, or other contract or tort claim.
          </li>
          <li>
            The following amounts, if a party is legally liable for
            them, are not subject to the above liability cap: (i)
            damages that cannot be capped by applicable law, (ii)
            damages for misuse or misappropriation of intellectual
            property rights in breach of this Agreement, (iii)
            damages for misuse, misappropriation or disclosure of
            confidential information in breach of a party’s
            confidentiality obligations under this Agreement, (iv)
            payment obligations pursuant to Article 3.0
            (Consideration), and (v) damage for breach of Customer’s
            obligations pursuant to Article 5.0 (Content and Data
            Privacy).
          </li>
          <li>
            Except for damages for misuse or misappropriation of
            intellectual property in breach of the Agreement,
            damages for misuse, misappropriation or disclosure of
            confidential information in breach of a party’s
            confidentiality obligations under this Agreement, damage
            for breach of Customer’s obligations pursuant to Article
            5.0 (Content and Data Privacy), under no circumstances,
            even if informed of the possibility, is either Party
            liable for special, incidental, exemplary, indirect or
            economic consequential damages (including damages based
            on the value of lost data or the cost of recreating it),
            lost profits, business, revenue, or anticipated savings.
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>
<table cellspacing="0"
  cellpadding="0"
  class="terms-table">
  <tbody>
    <tr>
      <td colspan="2"
        valign="top">
        <p>
          <strong>8. General Provisions</strong>
        </p>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>
            a. Business Contact Information and Account Usage
            Information
          </strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            IBM, its affiliates, and contractors of either require
            use of business contact information and certain account
            usage information. This information is not Content.
            Business Contact Information is used to communicate and
            manage business dealings with the Customer. Examples of
            Business Contact Information include name, business
            telephone, address, email, user ID, and tax registration
            information. Account Usage Information is required to
            enable, provide, manage, support, administer, and
            improve the IBM Technology. Examples of Account Usage
            Information include digital information gathered using
            tracking technologies, such as cookies and web beacons
            during use of the IBM Technology.
          </li>
          <li>
            The IBM Privacy Statement at
            <a href="https://www.ibm.com/privacy/">
              https://www.ibm.com/privacy/
            </a>
            provides additional details with respect to IBM's
            collection, use, and handling of business contact and
            account usage information.
          </li>
          <li>
            When Customer provides information to IBM and notice to,
            or consent by, the individuals is required for such
            processing, Customer will notify individuals and obtain
            consent.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>b. Publicity</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            This Agreement does not confer any rights to use in
            advertising, publicity other marketing activities any
            name, trade name, trademark, or other designation of
            either party hereto, including any contraction,
            abbreviation, or simulation of any of the foregoing,
            without prior written approval, and neither party will
            use or refer to this Agreement or its terms in any such
            activities without the express written approval of the
            other party.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>c. Assignment</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Neither party may assign, or otherwise transfer, its
            rights or delegate its obligations under this Agreement,
            in whole or in part, without the prior written consent
            of the other. Any attempt to do so will be void.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>d. Notices and Administration</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            All notices under this Agreement must be in writing and
            sent to: IBM Corporation, 1101 Kitchawan Drive, Office
            36-238C, Yorktown Heights, 10598 New York, USA, Attn:
            <a href="mailto:research.contracts@ibm.com">
              research.contracts@ibm.com
            </a>
            .
          </li>
          <li>
            The parties’ consent to the use of electronic means for
            communications as a signed writing.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>e. Export Control</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Each Party shall comply with all applicable export and
            import laws and associated embargo and economic sanction
            regulations, including those of the United States, that
            prohibit or restrict the export, re-export, or transfer
            of products, technology, services or data, directly or
            indirectly, to certain countries, or for certain end
            uses or end users. Technology, software, and items
            furnished under this Agreement are subject to United
            States export/re-export control laws and regulations,
            including the U.S. Export Administration Regulations
            (EAR). The Parties will not provide technical data,
            defense articles, or services controlled under the U.S.
            International Traffic in Arms Regulations (ITAR).
            Further, unless authorized by applicable government
            license or otherwise permitted by regulation, including
            but not limited to any U.S. authorization, each Party
            may not directly or indirectly export or re-export, at
            any time, any technology, software, and/or commodities
            furnished or developed under this Agreement between the
            Parties, or its direct product, to any prohibited
            country (including release of technology, software,
            and/or commodities to nationals, wherever they may be
            located, of any prohibited country) as specified in
            applicable export, embargo, and sanctions regulations.
            Prohibited countries include Sudan, Ukraine (including
            the Crimea, Donetsk, Luhansk, Kherson, and Zaporizhia
            (Zaporizhzhia) regions of Ukraine), and countries in
            Country Group D:1, E:1 or E:2, of the U.S. Export
            Administration Regulations Supplement No. 1 to Part 740
            of the EAR
            https://www.bis.doc.gov/index.php/regulations/export-administration-regulations-ear.
            The Parties acknowledge that these obligations survive
            the termination of this Agreement.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>f. Cause of Action</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Neither party will bring a legal action arising out of
            or related to this Agreement, regardless of form, more
            than one (1) year after the cause of action has accrued.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>g. Governing Law and Dispute Resolution</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            The Parties agree to the application of the laws of the
            State of New York, United States, without regard to the
            conflict of laws provisions thereof, to any disputes
            under this Agreement.
          </li>
          <li>
            Any proceedings to resolve disputes relating to this
            Agreement shall be brought only in the State of New
            York, and in a U.S. federal court in New York State if
            there is jurisdiction. The Parties waive the right to
            trial by jury in any matter which arises under this
            Agreement.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>h. Global Resources</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            IBM may use personnel and resources in locations
            worldwide, including contractors, to support the
            delivery of the IBM Technology.
          </li>
          <li>
            Customer’s use of the IBM Technology may result in the
            transfer of Content across country borders.
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td valign="top">
        <p>
          <strong>i. Survival</strong>
        </p>
      </td>
      <td valign="top">
        <ul>
          <li>
            Any rights and obligations which by their nature survive
            and continue after the termination of this Agreement
            will survive and continue to bind the Parties and their
            successors and assigns until such obligations are
            fulfilled.
          </li>
          <li>
            If any provision of the Agreement is invalid or
            unenforceable, the remaining provisions remain in full
            force and effect.
          </li>
        </ul>
      </td>
    </tr>
  </tbody>
</table>