import { Component, Inject } from '@angular/core';

import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { ToastMessagesService, ApiService } from '@services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { SuperModalComponent } from '../super-modal/super-modal.component';

@Component({
  selector: 'ibm-message-after-login',
  templateUrl: './message-after-login.component.html',
  styleUrls: ['./message-after-login.component.scss'],
})
export class MessageAfterLoginComponent extends SuperModalComponent {
  agreeTerms = false;
  enrollUpdates = false;
  disabledHandleKeyboardEvent = true;

  constructor(
    @Inject('dialogData') public dialogData: any,
    private apiService: ApiService,
    private toastMessagesService: ToastMessagesService
  ) {
    super();
  }

  closeModal(): void {
    this.close.emit({
      status: EDialogCloseEventStatus.CLOSE,
    } as IDialogCloseEvent<void>);
  }

  onOK() {
    this.apiService.users.addTerms().subscribe(x => {
      if (x.metadata.uiMessages.errors.length === 0) {
        this.toastMessagesService.show({
          title: 'Terms and Conditions',
          message: 'Terms and Conditions have been accepted',
          type: EToastMessageType.SUCCESS,
        });
      }
    });
    if (this.enrollUpdates) {
      this.apiService.hubspot.subscribeHubspot().subscribe(x => {
        if (x.metadata.uiMessages.errors.length === 0) {
          this.toastMessagesService.show({
            title: 'Hubspot Success',
            message: 'Hubspot Success',
            type: EToastMessageType.SUCCESS,
          });
        }
      });
    }
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
    } as IDialogCloseEvent<void>);
  }
}
