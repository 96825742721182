<div (ibmClickOutside)="onClickOutside()"
  class="d-inline"
  *ngIf="smiles">
  <button #viewDocs
    ibmButton="ghost"
    class="view-docs-btn"
    (click)="onTogglePanel()"
    [ngClass]="{'btn-active': showPanel}"
    [iconOnly]="true"
    [attr.aria-expanded]="showPanel"
    [disabled]="isLoading"
    data-e2e="similarReactionsBtn">
    <svg icon
      ibmIconNotebook
      size="16"
      class="bx--btn__icon"></svg>
  </button>
  <div class="document-list-container p-5"
    [ngStyle]="{
    'left': panelPosition.left, 
    'top': panelPosition.top, 
    'width': panelWidth + 'px', 
    'height': 'calc(100vh - ' + panelPosition.top +'',
    'max-height': 'calc(100vh - ' + panelPosition.top +''
  }"
    [ngClass]="{ 'document-list-container__invisible': !showPanel }">
    <h4 class="mb-5">Similar reactions list</h4>
    <ibm-accordion *ngIf="!isLoading"
      data-e2e="similarReactionsList">
      <ibm-accordion-item [title]="title"
        *ngFor="let reaction of similarReactions;  let i = index;"
        [context]="{ 'reaction': reaction, 'index': i }"
        [expanded]="i === 0">
        <div class="document-list-container__document-description"
          *ngFor="let reference of reaction.references; let refIndex = index;">
          <div *ngIf="paginationModel[i].currentPage === refIndex + 1"
            class="p-5">
            <div class="d-flex align-items-center justify-content-between mb-5">
              <h5>{{ reference.source }}</h5>
              <div class="d-flex align-items-center cursor-pointer text-interactive-01"
                (click)="onViewDocument($event, reference.url)"
                *ngIf="reference.url">
                Open Document
                <svg icon
                  ibmIconLaunch
                  class="fill-interactive ml-3"
                  size="16"></svg>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-3"
                *ngIf="reference.url">
                <h6 class="mr-2">URL:</h6>
                <span>{{reference.url}}</span>
              </div>
              <div class="d-flex align-items-center mb-3">
                <h6 class="mr-2">Dataset:</h6>
                <span>{{reference.dataset}}</span>
              </div>
              <div class="break-all">
                {{ reference.text }}
              </div>
            </div>
          </div>
        </div>
        <ibm-pagination [model]="paginationModel[i]"
          [pageInputDisabled]="true"
          [showPageInput]="false"
          (selectPage)="onSelectPage($event, i)"
          [translations]="{ OF_LAST_PAGES: 'of {{last}} documents', OF_LAST_PAGE: 'of {{last}} document'}"
          class="w-100">
        </ibm-pagination>
      </ibm-accordion-item>
    </ibm-accordion>

    <ibm-accordion *ngIf="isLoading"
      skeleton="true">
      <ibm-accordion-item></ibm-accordion-item>
      <ibm-accordion-item></ibm-accordion-item>
      <ibm-accordion-item></ibm-accordion-item>
    </ibm-accordion>
  </div>

  <ng-template #title
    let-reaction="reaction"
    let-index="index">
    <div class="d-flex flex-column bx--accordion__title reaction-container">
      <div class="d-flex align-items-center justify-content-between w-100 mb-5">
        <div class="d-flex align-items-center">
          <h5 class="mr-3">
            Reaction {{index + 1}}
          </h5>
          <button ibmButton="ghost"
            *ngIf="reaction.smiles"
            [iconOnly]="true"
            size="sm"
            title="Copy Smiles"
            (click)="onCopySmiles($event, reaction.smiles)">
            <svg icon
              ibmIconCopy
              size="16"></svg>
          </button>
        </div>
        <div class="d-flex align-items-center">
          <strong class="mr-2">
            Score
          </strong>
          <span class="bx--tag bx--tag--green mr-5">
            {{ reaction.score }}
          </span>
          <strong class="mr-2">
            Reaction class
          </strong>
          <span class="bx--tag bx--tag--gray mr-3">
            {{ reaction.rclass || 'N/A' }}
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center my-7">
        <div class="reaction-container__image d-flex align-items-start mb-1">
          <ng-container *ngTemplateOutlet="reactionImage; context: { data: reaction }"></ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #reactionImage
    let-data="data">
    <div class="d-flex align-items-center"
      *ngIf="data"
      [ngStyle]="{'max-width': (panelWidth - 100) + 'px'}">
      <ibm-reaction-image [productMolecule]="data.productMolecule"
        [precursorMolecules]="data.outcomeMolecules"></ibm-reaction-image>
    </div>
  </ng-template>