export enum EIngredientType {
  HANDMADE_SOLUTION = 'handmade_solution',
  PURE_COMPOUND = 'pure_compound',
  STANDARD_SOLUTION = 'standard_solution',
}

export enum EIngredientPhase {
  SOLID = 'solid',
  LIQUID = 'liquid',
  GAS = 'gas',
}

export enum EAddIngredientWizardStep {
  INFO = 0,
  COMPOSITION = 1,
}
