import { Pipe, PipeTransform } from '@angular/core';
import { convertFromBytes, getBestDimensionUnit } from '../common-utilities/bytes-converter';

@Pipe({
  name: 'bytesToSize',
})
export class BytesToSizePipe implements PipeTransform {
  transform(value: string | number): string {
    const bytes = +value;
    if (isNaN(bytes)) {
      return null;
    }
    if (bytes <= 0) {
      return '0 Bytes';
    }
    const dimension = getBestDimensionUnit(bytes);
    let size = convertFromBytes(bytes, dimension).toFixed(2);
    if (size.endsWith('.00')) {
      size = parseInt(size, 10).toString();
    }
    return size + ' ' + dimension;
  }
}
