import { Component, Input, OnInit } from '@angular/core';
import { ApiService, ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { LoaderService } from '@app/libs/bitforce/components';
import { copyToClipboard } from '@app/shared/common-utilities/clipboard';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ibm-fingerprint-button',
  templateUrl: './fingerprint-button.component.html',
  styleUrls: ['./fingerprint-button.component.scss'],
})
export class FingerprintButtonComponent implements OnInit {
  @Input() smiles: string;

  constructor(
    private toastMessagesService: ToastMessagesService,
    private apiService: ApiService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {}

  onCopyFingerprint() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.loaderService.show();
    if (isSafari && navigator.clipboard) {
      // @ts-ignore
      navigator.clipboard.write([
        // @ts-ignore
        new ClipboardItem({
          'text/plain': this.getFingerprint()
            .toPromise()
            .then(response => {
              const fingerprint = response.content?.data?.fingerprint;
              if (fingerprint) {
                this.showFingerprintConfirmMessage();
                return new Blob([`[${fingerprint}]`], { type: 'text/plain' });
              } else {
                this.showFingerprintErrorMessage();
              }
            })
            .catch(() => {
              this.showFingerprintErrorMessage();
              return '';
            }),
        }),
      ]);
    } else {
      this.getFingerprint().subscribe(async response => {
        const fingerprint = response.content?.data?.fingerprint;
        if (fingerprint) {
          await copyToClipboard(`[${fingerprint}]`);
          if (document.getElementById('fingerprintValue')) {
            document.getElementById('fingerprintValue').innerText = `[${fingerprint}]`;
          }
          this.showFingerprintConfirmMessage();
        } else {
          this.showFingerprintErrorMessage();
        }
      });
    }
  }

  private getFingerprint() {
    return this.apiService.actions.generateFingerprint(this.smiles).pipe(
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }

  private showFingerprintConfirmMessage() {
    this.toastMessagesService.show({
      title: 'Info',
      message: 'Fingerprint copied to clipboard',
      type: EToastMessageType.SUCCESS,
    });
  }

  private showFingerprintErrorMessage() {
    this.toastMessagesService.show({
      title: 'Error',
      message: 'Unable to generate fingerprint',
      type: EToastMessageType.ERROR,
    });
  }
}
