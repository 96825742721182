import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'ibm-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  loginForm: UntypedFormGroup;
  hostUrl = environment.hostUrl;

  constructor() {}

  ngOnInit() {}

  goToSignUp() {
    window.open(
      environment.redirectUrl.replace('openid-connect/auth', 'openid-connect/registrations'),
      '_self'
    );
  }

  goToLogin() {
    window.open(environment.redirectUrl, '_self');
  }

  goToLoginGoogle() {
    window.open(environment.redirectUrl + '&kc_idp_hint=google', '_self');
  }

  goToLoginFacebook() {
    window.open(environment.redirectUrl + '&kc_idp_hint=facebook', '_self');
  }
}
