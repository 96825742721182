import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Project } from '@app/core/models';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { SuperWizardComponent } from '../super-wizard/super-wizard.component';

@Component({
  selector: 'ibm-select-project-dialog',
  templateUrl: './select-project-dialog.component.html',
  styleUrls: ['./select-project-dialog.component.scss'],
})
export class SelectProjectDialogComponent extends SuperWizardComponent implements OnInit {
  form: FormGroup;

  constructor(
    public injector: Injector,
    private formBuilder: FormBuilder,
    @Inject('dialogData')
    public dialogData: { title: string; description: string; buttonCallToAction: string; entityName: string }
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
    this.getProjectsListItems().subscribe();
  }

  onSelectProject() {
    this.getOrCreateProject(this.form.value.projectGroup).subscribe((project: Project) => {
      this.close.emit({
        status: EDialogCloseEventStatus.OK,
        data: project,
      } as IDialogCloseEvent<Project>);
    });
  }
}
