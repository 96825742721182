import { Component, OnInit } from '@angular/core';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { Router } from '@angular/router';

import { BaseModal } from 'carbon-components-angular';
import { ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';

@Component({
  selector: 'ibm-get-roborxn-key-dialog',
  templateUrl: './get-roborxn-key-dialog.component.html',
  styleUrls: ['./get-roborxn-key-dialog.component.scss'],
})
export class GetRoborxnKeyDialogComponent extends BaseModal implements OnInit {
  simulatorKey = 'z7WcYzqPGEcFW.iY2VUA7hvzTs4';

  constructor(private router: Router, private toastMessagesService: ToastMessagesService) {
    super();
  }

  ngOnInit() {}

  onActivateNow() {
    this.router.navigate(['/', 'user', 'profile']);
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
    } as IDialogCloseEvent<void>);
  }

  onCancel() {
    this.close.emit({
      status: EDialogCloseEventStatus.CANCEL,
    } as IDialogCloseEvent<void>);
  }

  simulatorKeyClick() {
    this.toastMessagesService.show({
      title: 'Info',
      message: 'Key copied to clipboard',
      type: EToastMessageType.SUCCESS,
    });
  }
}
