<ibm-modal [open]="open"
  [size]="'sm'">
  <ibm-modal-header (closeSelect)="onCancel()">
    <h4>IBM RoboRXN</h4>
  </ibm-modal-header>
  <section class="bx--modal-content">
    It looks like you do not have a valid RoboRXN activation key.
    You can activate the simulator key right away and unleash the power of Cloud and AI:

    <p class="d-flex align-items-center justify-content-center mt-3">
      <span class="font-weight-bold mr-5">{{ simulatorKey }}</span>
      <button ibmButton="ghost"
        [iconOnly]="true"
        (click)="simulatorKeyClick()"
        [ibmClipboard]="simulatorKey">
        <ibm-icon-copy-file size="20"
          class="fill-interactive"></ibm-icon-copy-file>
      </button>
    </p>
  </section>

  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="onCancel()">
      Cancel
    </button>
    <button ibmButton="primary"
      (click)="onActivateNow()">
      Activate now
    </button>
  </ibm-modal-footer>
</ibm-modal>