import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Dropdown } from 'carbon-components';

@Component({
  selector: 'ibm-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent<T> implements AfterViewInit {
  @Input()
  items: T[];
  @Input()
  set opened(value: boolean) {
    if (this.dropDown) {
      if (value) {
        this.openDropdown();
      } else {
        this.closeDropdown();
      }
    } else {
      this.openedAfterViewInit = value;
    }
  }

  @Output()
  select = new EventEmitter<T>();

  @ViewChild('dropDown', { static: true }) dropDownEl: ElementRef;
  private dropDown: Dropdown;

  private openedAfterViewInit: boolean = undefined;

  constructor() {}

  ngAfterViewInit() {
    this.dropDown = new Dropdown(this.dropDownEl.nativeElement);
    if (this.openedAfterViewInit !== undefined) {
      if (this.openedAfterViewInit) {
        this.openDropdown();
      } else {
        this.closeDropdown();
      }
      this.openedAfterViewInit = undefined;
    }
  }

  private openDropdown() {
    const opened = this.dropdownOpened();
    if (!opened) {
      this.toggleDropdown();
    }

    return opened;
  }

  private closeDropdown() {
    const closed = !this.dropdownOpened();
    if (!closed) {
      this.toggleDropdown();
    }

    return closed;
  }

  private toggleDropdown() {
    this.dropDown._toggle({
      type: 'click',
      target: this.dropDownEl.nativeElement,
    });
  }

  private dropdownOpened() {
    return this.dropDown && this.dropDown.element.classList.contains('bx--dropdown--open');
  }
}
