import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { SignInComponent } from '@modules/login/sign-in/sign-in.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [SignInComponent, LogoutComponent],
})
export class LoginModule {}
