import { Component, Input, OnInit } from '@angular/core';
import { ERetrosynthesisStatus } from '@app/core/models';

@Component({
  selector: 'ibm-synthesis-status-icon',
  templateUrl: './synthesis-status-icon.component.html',
  styleUrls: ['./synthesis-status-icon.component.scss'],
})
export class SynthesisStatusIconComponent implements OnInit {
  @Input() status: ERetrosynthesisStatus;
  @Input() size = 16;
  constructor() {}

  ngOnInit(): void {}
}
