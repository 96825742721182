import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@bitf/components';
import { IApiResponse, IApiAction } from '@bitf/api';
import { GenericService } from '@bitf/services/generic/generic.service';
// TODO we should provide a default toast similar to loader
import { ToastMessagesService } from '@services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bitf-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  apiCall: string;
  redirectTo: string;

  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private genericService: GenericService,
    private router: Router,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit() {
    this.loaderService.show();
    this.apiCall = this.route.snapshot.queryParams['apiCall'];
    this.redirectTo = this.route.snapshot.queryParams['redirectTo'];

    if (this.apiCall) {
      this.genericService.callAction({ path: this.apiCall, method: 'GET' }).subscribe(
        (response: IApiResponse<IApiAction>) => {
          if (response.content.success) {
            /*
              When an user accepts an invitation to collaborate to a project, sometimes after redirect the
              projects list is not already updated, the timeout tries to mitigate this problem.
            */
            setTimeout(() => {
              this.loaderService.hide();
              this.router.navigateByUrl(this.redirectTo);
            }, 1000);
          } else {
            this.errorRedirect();
          }
        },
        error => {
          this.errorRedirect();
        }
      );
    } else if (this.redirectTo) {
      this.router.navigateByUrl(this.redirectTo);
    } else {
      this.router.navigate(['']);
    }
  }

  errorRedirect() {
    this.loaderService.hide();
    this.toastMessagesService.show({
      title: 'Error',
      message: 'Incorrect redirect params',
      type: EToastMessageType.ERROR,
    });
    setTimeout(() => this.router.navigate(['']));
  }
}
