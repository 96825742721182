<ng-container *ngIf="isReady">
  <div class="loader-overlay"
    *ngIf="loaderType === 'default'">
    <div class="loader-overlay-content">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <div [hidden]="loaderType !== 'custom'"
    class="loader-overlay">
    <div class="loader-overlay-content">
      <template #content></template>
    </div>
  </div>
</ng-container>