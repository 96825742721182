import { SuperModel } from '@models/super-model.model';
import { RetrosynthesisSequence } from './retrosynthesis-sequence.model';
import { RetrosynthesisMolecule } from './retrosynthesis-molecule.model';
import { RetrosynthesisSibling } from './retrosynthesis-sibling.model';
import { Project } from './project.model';
import { User } from './user.model';
import { FileEntry, ITreeLegendItem } from '.';

export class Retrosynthesis extends SuperModel {
  name: string;
  projectId: string;
  status: ERetrosynthesisStatus;
  errorMessage: string;
  task: {
    task_id: string;
  };
  molecules: RetrosynthesisMolecule[];
  sequences: RetrosynthesisSequence[];
  sequencesIds: string[];
  legend: ITreeLegendItem[];
  wizardParameters: IRetrosynthesisWizardParameters;
  siblings: RetrosynthesisSibling[];
  productMolecule: RetrosynthesisMolecule;
  metaData: {
    queuePosition: number;
    willBeCompletedOn: Date;
    progressMessage: string;
  };
  aiModel: string;
  predictionStats: {
    operations_backward_forward: string;
    optimization_time: string;
  };
  isInteractive: boolean;
  isFromFile: boolean;
  isRenameable: boolean;
  isDeletable?: boolean;
  isFinalized: boolean;
  isFavorite: boolean;
  outcomeMolecules: RetrosynthesisMolecule[];
  project: Project;
  user: User;
  totalSynthesisPlans: number;
  canCreateSynthesis: boolean;
  uploadedFile: FileEntry;

  constructor(data: any = {}) {
    super(data);

    if (data.molecules) {
      this.molecules = data.molecules.map(m => new RetrosynthesisMolecule(m));
    }
    if (data.sequences) {
      this.sequences = data.sequences.map(s => new RetrosynthesisSequence(s));
    }
    if (data.siblings) {
      this.siblings = data.siblings.map(s => new RetrosynthesisSibling(s));
    }
    if (data.metaData && data.metaData.willBeCompletedOn) {
      this.metaData.willBeCompletedOn = new Date(data.metaData.willBeCompletedOn * 1000);
    }
    if (data.productMolecule) {
      this.productMolecule = new RetrosynthesisMolecule(data.productMolecule);
    }
    if (data.outcomeMolecules && data.outcomeMolecules.length) {
      this.outcomeMolecules = data.outcomeMolecules.map(molecule => new RetrosynthesisMolecule(molecule));
    }
    if (data.project) {
      this.project = new Project(data.project);
    }
    if (data.uploadedFile) {
      this.uploadedFile = new FileEntry(data.uploadedFile);
    }
  }

  get creatorName() {
    if (this.createdBy && typeof this.createdBy === 'object') {
      return this.createdBy.fullName;
    } else if (this.user && typeof this.user === 'object') {
      return this.user.fullName;
    } else {
      return this.createdBy;
    }
  }

  get typology(): ERetrosynthesisTypology {
    if (this.isFromFile && !this.isInteractive) {
      return ERetrosynthesisTypology.FROM_FILE;
    }
    if (this.isInteractive) {
      return ERetrosynthesisTypology.INTERACTIVE;
    } else {
      return ERetrosynthesisTypology.AUTOMATIC;
    }
  }

  get typologyLabel() {
    switch (this.typology) {
      case ERetrosynthesisTypology.FROM_FILE:
        return 'From file';
      case ERetrosynthesisTypology.INTERACTIVE:
        return 'Interactive';
      case ERetrosynthesisTypology.AUTOMATIC:
        return 'Automatic';
    }
  }

  get serialised() {
    return {};
  }
}

export interface IRetrosynthesisWizardParameters {
  available_smiles: Array<string>;
  exclude_smiles: Array<string>;
  exclude_substructures: Array<string>;
  parameters: {
    max_steps: IRetrosynthesisWizardParam;
    nbeams: IRetrosynthesisWizardParam;
    ntop: IRetrosynthesisWizardParam;
  };
}

export interface IRetrosynthesisWizardParam {
  label: string;
  description: string;
  value: number;
}

export enum ERetrosynthesisStatus {
  DONE = 'DONE', // Only for interactive: done but not finalized (SUCCESS)
  SUCCESS = 'SUCCESS',
  RETROSYNTHESIS_READY = 'RETROSYNTHESIS_READY',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  NEW = 'NEW',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  PAUSED = 'PAUSED',
}

export enum ESynthesisStatusClassColor {
  DONE = 'bx--tag--done',
  SUCCESS = 'bx--tag--success',
  RETROSYNTHESIS_READY = 'bx--tag--retrosynthesis_ready',
  ERROR = 'bx--tag--error',
  PENDING = 'bx--tag--pending',
  WAITING = 'bx--tag--pending',
  PROCESSING = 'bx--tag--processing',
  NEW = 'bx--tag--new',
  RUNNING = 'bx--tag--running',
  STOPPED = 'bx--tag--stopped',
  PAUSED = 'bx--tag--paused',
}

export enum ERetrosynthesisSteps {
  STEP1 = '1-steps',
  STEP2 = '2-3-steps',
  STEP3 = '4-6-steps',
  STEP4 = '7-15-steps',
  STEP5 = '16-steps',
}

export enum ERetrosynthesisTypology {
  INTERACTIVE = 'interactive',
  AUTOMATIC = 'automatic',
  FROM_FILE = 'from_file',
}
