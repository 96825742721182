<a ibmButton="ghost"
  class="text-01 small--xs"
  data-e2e="tmapBtn"
  [iconOnly]="true"
  title="Open Tmap"
  [routerLink]="['/tmap']"
  [queryParams]="{ 'aiModel': aiModel, 'aiModelType': aiModelType, 'reactionName': reactionName, 'reactionSmiles': reactionSmiles, 'retrosynthesisSequenceId': retrosynthesisSequenceId }">
  <svg icon
    ibmIconDataVis_1
    size="16"
    class="bx--btn__icon"></svg>
</a>