import { Injectable, Injector } from '@angular/core';
import { IPlanForecast } from '@app/core/models';
import { ApiSuperService, IApiResponse } from '@app/libs/bitforce/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlansService extends ApiSuperService {
  // mockApiUrl = 'https://b8d18e18-66eb-481b-a04d-259bcd048385.mock.pstmn.io/api/v1/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'plans';
  }

  getForecast(): Observable<IApiResponse<IPlanForecast[]>> {
    return this.request({
      method: 'GET',
      path: 'forecast',
      modelMapper: 'plan-forecast',
    });
  }
}
