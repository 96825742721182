import { DomSanitizer } from '@angular/platform-browser';
import { EPredictionLibrary } from '../enums/reaction-property-prediction';

const widthRegex = {
  [EPredictionLibrary.INDIGO]: /width="([\d]+)"/gm,
  [EPredictionLibrary.CDK]: /width='([\d]+)[.]*[\d]+mm'/gm,
  [EPredictionLibrary.RDKIT]: /width='([\d]+)[.]*[\d]+mm'/gm,
};
const heightRegex = {
  [EPredictionLibrary.INDIGO]: /height="([\d]+)"/gm,
  [EPredictionLibrary.CDK]: /height='([\d]+)[.]*[\d]+mm'/gm,
  [EPredictionLibrary.RDKIT]: /height='([\d]+)[.]*[\d]+mm'/gm,
};

function pxToMM(px: number): number {
  return px * 0.264583;
}

export function svgToUrl(svg: string, library: EPredictionLibrary = EPredictionLibrary.CDK): ISvgToUrl {
  const sanitizer = window['rootInjector'].get(DomSanitizer);
  if (!svg) {
    return {
      base64Url: sanitizer.bypassSecurityTrustUrl(
        // eslint-disable-next-line max-len
        'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNjAiIGhlaWdodD0iNjAiPgo8cGF0aCBmaWxsPSJub25lIiBkPSJNMSwxVjE5OUgxOTlWMXoiLz4KPC9zdmc+'
      ),
      width: 60,
      height: 60,
      originalWidth: 60,
      originalHeight: 60,
      unit: 'px',
    };
  }
  const maxWidth = 300;
  const minWidth = 60;
  const maxHeight = 150;
  const minHeight = 50;
  const multiplier = 4;
  let width = 100;
  let height = 100;
  try {
    svg = svg.replace(new RegExp('svg:svg', 'g'), 'svg');
    let regex = widthRegex[library] || widthRegex[EPredictionLibrary.CDK];
    const regexWidthResult = regex.exec(svg);
    regex = heightRegex[library] || heightRegex[EPredictionLibrary.CDK];
    const regexHeightResult = regex.exec(svg);

    if (library === EPredictionLibrary.INDIGO) {
      regexWidthResult[0] = `width='${pxToMM(+regexWidthResult[1]).toFixed(2)}mm'`;
      regexHeightResult[0] = `height='${pxToMM(+regexHeightResult[1]).toFixed(2)}mm'`;
      regexWidthResult[1] = Math.floor(pxToMM(+regexWidthResult[1])).toString();
      regexHeightResult[1] = Math.floor(pxToMM(+regexHeightResult[1])).toString();
    }

    if (regexWidthResult[1] && regexHeightResult[1]) {
      width = +regexWidthResult[1] * multiplier;
      width = Math.max(width, minWidth);
      width = Math.min(width, maxWidth);
      height = (width * +regexHeightResult[1]) / +regexWidthResult[1];

      // Cap the max height
      if (height > maxHeight) {
        height = maxHeight;
        width = (+regexWidthResult[1] * height) / +regexHeightResult[1];
      } else if (height < minHeight) {
        height = minHeight;
        width = (+regexWidthResult[1] * height) / +regexHeightResult[1];
      }
    }

    const svgData = {
      base64Url: sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${btoa(svg)}`),
      width,
      height,
      originalWidth: +regexWidthResult[1],
      originalHeight: +regexHeightResult[1],
      unit: 'px',
    };
    return svgData;
  } catch (error) {
    const svgData = {
      base64Url: sanitizer.bypassSecurityTrustUrl(`data:image/svg+xml;base64,${btoa(svg)}`),
      width,
      height,
      originalWidth: undefined,
      originalHeight: undefined,
      unit: 'px',
    };
    return svgData;
  }
}

export interface ISvgToUrl {
  base64Url: string;
  width: number;
  height: number;
  unit: string;
  originalWidth: number;
  originalHeight: number;
}
