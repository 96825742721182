import { Component, Input } from '@angular/core';
import { Molecule } from '@app/core/models';

@Component({
  selector: 'ibm-reaction-image',
  templateUrl: './reaction-image.component.html',
  styleUrls: ['./reaction-image.component.scss'],
})
export class ReactionImageComponent {
  @Input() productMolecule: Molecule; // this represent the result or the source of a reaction
  @Input() outcomeMolecules?: Molecule[]; // use this input when you are showing a retrosynthesis image
  @Input() precursorMolecules?: Molecule[]; // use this input when you are showing a reaction image
  @Input() size = 1; // svg image size multiplier

  constructor() {}
}
