<button [ibmButton]="filtersValues ? 'primary' : 'secondary'"
  [iconOnly]="true"
  [hasAssistiveText]="true"
  [ibmOverflowMenu]="templateRef"
  [shouldClose]="shouldCloseFilters.bind(this)"
  (click)="toggleFilters()"
  [customPane]="true"
  [flip]="true"
  [offset]="{ x: 4, y: 0 }"
  placement="bottom"
  wrapperClass="ibm-filters-wrapper"
  #overflowMenu>
  <svg ibmIconFilter
    size="16"></svg>
</button>
<ng-template #templateRef>
  <form class="ibm-filters"
    [formGroup]="filtersForm"
    (submit)="onFiltersFormSubmit()">
    <div class="ibm-filters__container">
      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.FAVORITE)">
        <div class="w-100">
          <ibm-dropdown label="Show"
            [theme]="'light'"
            placeholder="All"
            formControlName="favorite"
            [dropUp]="false"
            itemValueKey="value">
            <ibm-dropdown-list [items]="favoriteSelectionOptions"></ibm-dropdown-list>
          </ibm-dropdown>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.CREATED_BY)">
        <div class="w-100">
          <ibm-dropdown label="Creator"
            [theme]="'light'"
            placeholder="Choose creator"
            formControlName="createdBy"
            [dropUp]="false"
            itemValueKey="id"
            [skeleton]="filtersData?.createdBy?.isLoading">
            <ibm-dropdown-list [items]="filtersData?.createdBy?.options || []"></ibm-dropdown-list>
          </ibm-dropdown>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.CREATOR_FULL_NAME)">
        <div class="w-100">
          <ibm-label>
            Creator
            <input ibmText
              [theme]="'light'"
              autocomplete="off"
              formControlName="creatorFullName">
          </ibm-label>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.AI_MODEL)">
        <div class="w-100">
          <ibm-combo-box label="AI model"
            [theme]="'light'"
            placeholder="Choose AI models"
            formControlName="aiModel"
            [dropUp]="false"
            itemValueKey="id"
            type="multi"
            *ngIf="!filtersData?.aIModel?.isLoading"
            [items]="filtersData?.aIModel?.options || []">
            <ibm-dropdown-list></ibm-dropdown-list>
          </ibm-combo-box>
          <ibm-dropdown label="AI Model"
            placeholder="Choose AI Models"
            *ngIf="filtersData?.aIModel?.isLoading"
            [skeleton]="true">
            <ibm-dropdown-list></ibm-dropdown-list>
          </ibm-dropdown>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.SYNTHESIS_SOURCE_TYPOLOGY)">
        <div class="w-100">
          <ibm-dropdown label="Source"
            [theme]="'light'"
            placeholder="Source"
            formControlName="synthesisSourceTypology"
            [dropUp]="false"
            itemValueKey="id"
            (selected)="onSynthesisSourceTypologyChange($event.item)">
            <ibm-dropdown-list [items]="filtersData?.synthesisSourceTypology?.options || []">
            </ibm-dropdown-list>
          </ibm-dropdown>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.RETROSYNTHESIS_SOURCE)">
        <div class="w-100">
          <ibm-combo-box [theme]="'light'"
            label="Retrosynthesis"
            placeholder="Search retrosynthesis"
            formControlName="retrosynthesisSource"
            [dropUp]="false"
            (search)="onSearch($event, eFilterType.RETROSYNTHESIS_SOURCE)"
            [items]="filtersData?.retrosynthesisSource?.options || []">
            <ibm-dropdown-list></ibm-dropdown-list>
          </ibm-combo-box>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.SYNTHESIS_PROCEDURE)">
        <div class="w-100">
          <ibm-combo-box [theme]="'light'"
            label="Source"
            placeholder="Search source"
            formControlName="synthesisProcedure"
            [dropUp]="false"
            (search)="onSearch($event, eFilterType.SYNTHESIS_PROCEDURE)"
            [items]="filtersData?.synthesisProcedure?.options || []">
            <ibm-dropdown-list></ibm-dropdown-list>
          </ibm-combo-box>
        </div>
      </div>


      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.DATE)">
        <ibm-date-picker range="true"
          id="initial-value-datepicker"
          formControlName="dateRange"
          dateFormat="Y-m-d"
          label="Start date"
          rangeLabel="End date"
          [theme]="'light'">
        </ibm-date-picker>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.RETROSYNTHESIS_TYPOLOGY)">
        <div class="w-100">
          <ibm-dropdown label="Type"
            [theme]="'light'"
            placeholder="Type"
            formControlName="retrosynthesisTypology"
            [dropUp]="false"
            itemValueKey="id">
            <ibm-dropdown-list [items]="filtersData?.retrosynthesisTypology?.options || []">
            </ibm-dropdown-list>
          </ibm-dropdown>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.CONFIDENCE)">
        <div class="d-flex w-100">
          <ibm-number [label]="numberMin"
            [theme]="'light'"
            [min]="0"
            [max]="1"
            [step]="0.1"
            [precision]="1"
            formControlName="minConfidence">
          </ibm-number>
          <ibm-number [label]="numberMax"
            [theme]="'light'"
            [min]="0"
            [max]="1"
            [step]="0.1"
            [precision]="1"
            formControlName="maxConfidence">
          </ibm-number>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.STEPS)">
        <ibm-number label="Steps from"
          [theme]="'light'"
          [min]="0"
          [max]="100"
          [step]="1"
          [precision]="1"
          formControlName="stepsFrom">
        </ibm-number>
        <ibm-number label="Steps to"
          [theme]="'light'"
          [min]="0"
          [max]="100"
          [step]="1"
          [precision]="1"
          formControlName="stepsTo">
        </ibm-number>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.STATUS)">
        <div class="w-100">
          <ibm-dropdown label="Status"
            [theme]="'light'"
            placeholder="Status"
            formControlName="status"
            [dropUp]="false"
            itemValueKey="value">
            <ibm-dropdown-list [items]="filtersData?.status.options || []"></ibm-dropdown-list>
          </ibm-dropdown>
        </div>
      </div>

      <div class="ibm-filters__container__form-item"
        *ngIf="filters.includes(eFilterType.TRAINING_MODEL_TYPE)">
        <div class="w-100">
          <ibm-dropdown label="Model training type"
            [theme]="'light'"
            placeholder="Model training type"
            formControlName="modelType"
            [dropUp]="false"
            itemValueKey="value">
            <ibm-dropdown-list [items]="filtersData?.modelType.options || []"></ibm-dropdown-list>
          </ibm-dropdown>
        </div>
      </div>

      <div class="ibm-filters__container__form-item d-flex flex-column"
        *ngIf="filters.includes(eFilterType.MOLECULES)">
        <div class="ibm-filters-molecule-description">
          Select with or without and type the molecule SMILES, use “,” to separate and add more that one
        </div>
        <div class="ibm-filters-molecule">
          <div class="ibm-filters-molecule_item-wrapper">
            <ibm-dropdown [theme]="'light'"
              formControlName="moleculeSelectionType"
              [dropUp]="false"
              itemValueKey="value">
              <ibm-dropdown-list [items]="moleculeSelectionOptions"></ibm-dropdown-list>
            </ibm-dropdown>
          </div>
          <div class="ibm-filters-molecule_item-wrapper">
            <input ibmText
              [theme]="'light'"
              [autocomplete]="false"
              formControlName="molecules">
          </div>
        </div>
      </div>
      <div class="mt-5">
        <a (click)="resetFilters()">Reset all filters</a>
      </div>
    </div>
    <div class="ibm-filters__footer">
      <button [ibmButton]="'primary'"
        [disabled]="filtersForm.invalid"
        type="submit">
        Filter
      </button>
      <button [ibmButton]="'secondary'"
        (click)="closeFilters()">
        Cancel
      </button>
    </div>
  </form>
</ng-template>