<ibm-search [theme]="theme"
  [ngClass]="{'loader-active': isLoading}"
  [label]="placeholder"
  [placeholder]="placeholder"
  [value]="searchString"
  [autocomplete]="'off'"
  (valueChange)="onSearch($event)"
  (clear)="onClear()">
</ibm-search>
<ibm-dropdown theme="dark"
  [dropUp]="true"
  #addressesDropDown>
  <ibm-dropdown-list [items]="options"
    (select)="onSelect($event)"></ibm-dropdown-list>
</ibm-dropdown>

<ibm-loading *ngIf="isLoading"
  [isActive]="true"
  size="sm"></ibm-loading>