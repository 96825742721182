<ibm-modal [open]="open"
  size="xs"
  [hasScrollingContent]="false">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h4>How many training hours do I need?</h4>
  </ibm-modal-header>
  <section class="bx--modal-content">
    <p>The number of training hours you consume will directly correlate to the number of prediction reactions
      used as input.
    </p>
    <ul ibmList
      class="pl-5 mt-4">
      <li ibmListItem>30k reactions - ~30 minutes</li>
      <li ibmListItem>30k-60k reactions - ~1 hour</li>
      <li ibmListItem>60k-180k reactions - ~3.25 hours</li>
      <li ibmListItem>180k-450k reactions - ~8 hours</li>
      <li ibmListItem>450k-750k reactions - ~13 hours</li>
      <li ibmListItem>750k-1m reactions - ~18 hours</li>
    </ul>
  </section>
</ibm-modal>