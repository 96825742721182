import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { requiredIf } from '@app/shared/form-utilities/validators/required-if.validator';
import { Project } from '@app/core/models';
import { ListItem } from 'carbon-components-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ibm-select-or-create-project',
  templateUrl: './select-or-create-project.component.html',
  styleUrls: ['./select-or-create-project.component.scss'],
})
export class SelectOrCreateProjectComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  toAddLabel: string;
  @Input()
  projects: ListItem[];
  @Input()
  selectedProject: Project;
  @Input()
  form: UntypedFormGroup;

  projectForm: UntypedFormGroup;
  EProjectSelectionMode = EProjectSelectionMode;

  private subscription = new Subscription();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.projectForm = this.fb.group({
      projectRadio: ['existing', Validators.required],
      projectName: [
        null,
        requiredIf(() => this.projectForm?.get('projectRadio'), EProjectSelectionMode.NEW_PROJECT),
      ],
      selectedProject: [
        null,
        requiredIf(() => this.projectForm?.get('projectRadio'), EProjectSelectionMode.EXISTING_PROJECT),
      ],
    });

    this.subscription.add(
      this.projectForm.get('projectRadio').valueChanges.subscribe(() => {
        this.projectForm.get('projectName').updateValueAndValidity();
        this.projectForm.get('selectedProject').updateValueAndValidity();
      })
    );

    this.form.addControl('projectGroup', this.projectForm);
  }

  ngOnChanges() {
    if (this.selectedProject && this.projects) {
      const selectedItem = this.projects.find(item => item.project.id === this.selectedProject.id);
      this.projects = [
        ...this.projects.map(item => {
          item.selected = item.project.id === this.selectedProject.id;
          return item;
        }),
      ];
      this.projectForm.patchValue({ selectedProject: selectedItem });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export enum EProjectSelectionMode {
  EXISTING_PROJECT = 'existing',
  NEW_PROJECT = 'new',
}
