import { SuperModel } from '@models/super-model.model';

export class TuneRxnConfig extends SuperModel {
  reactionDataSampleFileContent: string;
  reactionDataFileMaxSize: number;
  reactionDataPreviewLimit: number;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {
      reactionDataSampleFileContent: this.reactionDataSampleFileContent,
      reactionDataFileMaxSize: this.reactionDataFileMaxSize,
      reactionDataPreviewLimit: this.reactionDataPreviewLimit,
    };
  }
}
