import { Injectable } from '@angular/core';
import {
  ERoboRxnApiKeyType,
  IHardwareSettings,
  RetrosynthesisMolecule,
  SynthesisProcedure,
} from '@app/core/models';
import { Subject } from 'rxjs';
import { ApiService } from './api';

@Injectable({
  providedIn: 'root',
})
export class SynthesisReactionSettingsService {
  reactionSettingsState$: Subject<any> = new Subject<any>();
  isReactionSettingsFormTouched = false;
  isReactionIngredientsFormTouched = false;

  synthesis: SynthesisProcedure;
  molecule: RetrosynthesisMolecule;
  hardwareSettings: IHardwareSettings;

  constructor(private apiService: ApiService) {}

  get isSimulator() {
    return this.apiService.auth.user.roboRxnApiKeyType === ERoboRxnApiKeyType.SIMULATOR;
  }
}
