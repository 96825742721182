export function bitfLoadAsset(fileUrl, fileType: string, id = fileUrl): Promise<any> {
  return new Promise<void | string>((resolve, reject) => {
    let assetElement;
    // Check if the asset is already loaded
    const alreadyLoadedAsset = document.getElementById(id);
    if (alreadyLoadedAsset) {
      resolve('File already loaded');
      return;
    }
    switch (fileType) {
      case 'js':
        assetElement = document.createElement('script');
        assetElement.setAttribute('type', 'text/javascript');
        assetElement.setAttribute('src', fileUrl);
        assetElement.setAttribute('id', id);
        break;

      case 'css':
        assetElement = document.createElement('link');
        assetElement.setAttribute('rel', 'stylesheet');
        assetElement.setAttribute('type', 'text/css');
        assetElement.setAttribute('href', fileUrl);
        assetElement.setAttribute('id', id);
        break;

      default:
        reject('File could not be loaded');
        return;
    }
    assetElement.onload = () => {
      resolve();
    };
    assetElement.onerror = error => {
      reject(error);
    };
    document.getElementsByTagName('head')[0].appendChild(assetElement);
  });
}
