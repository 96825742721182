import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'ibm-mlrp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
