import { Observable, from, of } from 'rxjs';
import { map, filter, switchMap, retryWhen, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import {
  HttpEvent,
  HttpEventType,
  HttpRequest,
  HttpParameterCodec,
  HttpParams,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

import { IApiRequest, IApiResponse, IApiCount, IApiAction } from './api.interfaces';
import { getParser } from '@bitf/api/parsers';
import { AuthService } from '../services';
import { Injector } from '@angular/core';

export abstract class ApiSuperService {
  mockApiUrl = '';
  name: string;
  parserType = 'defaultParser';
  fileUploadStatus = { info: '', loaded: 0, total: 0, percentage: 0 };
  protected http: HttpClient;
  protected authService: AuthService;

  constructor(protected injector: Injector) {
    this.http = injector.get(HttpClient);
    this.authService = injector.get(AuthService);
  }

  get<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T[]>> {
    return this.fetch({
      method: 'GET',
      path: `${this.apiUrl}${this.name}`,
      ...requestParams,
    }).pipe(map(envelope => this.parseEnvelope<T[]>(envelope, requestParams)));
  }

  getById<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ method: 'GET', path: `${requestParams.id}`, ...requestParams });
  }

  post<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ method: 'POST', ...requestParams });
  }

  patch<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ method: 'PATCH', path: `${requestParams.body.id}`, ...requestParams });
  }

  put<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({ method: 'PUT', path: `${requestParams.body.id}`, ...requestParams });
  }

  delete(requestParams: IApiRequest = {}): Observable<IApiResponse<IApiAction>> {
    return this.fetch({
      ...requestParams,
      method: 'DELETE',
      path: `${this.apiUrl}${this.name}/${requestParams.id}`,
    }).pipe(
      map(envelope => this.parseEnvelope<IApiAction>(envelope, { ...requestParams, modelMapper: 'action' }))
    );
  }

  // FIXME: check the return type
  bulkDelete(requestParams: IApiRequest): Observable<IApiResponse<IApiAction>> {
    return this.fetch({
      ...requestParams,
      method: 'DELETE',
      path: `${this.apiUrl}${this.name}/delete-all`,
    }).pipe(
      map(envelope =>
        this.parseEnvelope<IApiAction>(envelope, { ...requestParams, modelMapper: 'actionData' })
      )
    );
  }

  count(requestParams: IApiRequest = {}): Observable<IApiResponse<IApiCount>> {
    return this.fetch({
      method: 'GET',
      path: `${this.apiUrl}${this.name}/count`,
      ...requestParams,
    }).pipe(
      map(envelope => this.parseEnvelope<IApiCount>(envelope, { ...requestParams, modelMapper: 'count' }))
    );
  }

  // METHODS to call related entities ====================================================
  private doGetRel(requestParams: IApiRequest) {
    let path = requestParams.id
      ? `${this.apiUrl}${this.name}/${requestParams.id}/${requestParams.relation}`
      : `${this.apiUrl}${this.name}/${requestParams.relation}`;
    path += requestParams.path ? requestParams.path : '';
    return this.fetch({
      method: 'GET',
      ...requestParams,
      path,
    });
  }

  getAction<T>(requestParams: IApiRequest): Observable<IApiResponse<T>> {
    return this.doGetRel(requestParams).pipe(map(envelope => this.parseEnvelope<T>(envelope, requestParams)));
  }

  getRel<T>(requestParams: IApiRequest): Observable<IApiResponse<T>> {
    return this.doGetRel(requestParams).pipe(map(envelope => this.parseEnvelope<T>(envelope, requestParams)));
  }

  getRels<T>(requestParams: IApiRequest): Observable<IApiResponse<T[]>> {
    return this.doGetRel(requestParams).pipe(
      map(envelope => this.parseEnvelope<T[]>(envelope, requestParams))
    );
  }

  getRelById<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({
      method: 'GET',
      path: `${requestParams.id}/${requestParams.relation}/${requestParams.relationId}`,
      ...requestParams,
    });
  }

  postRel<T>(requestParams: IApiRequest) {
    return this.request<T>({
      method: 'POST',
      path: `${requestParams.id}/${requestParams.relation}`,
      ...requestParams,
    });
  }

  putRel<T>(requestParams: IApiRequest): Observable<any> {
    return this.request<T>({
      method: 'PUT',
      path: `${requestParams.id}/${requestParams.relation}/${requestParams.body.id ||
        requestParams.relationId}`,
      ...requestParams,
    });
  }

  patchRel<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    return this.request<T>({
      method: 'PATCH',
      path: `${requestParams.id}/${requestParams.relation}/${requestParams.body.id ||
        requestParams.relationId}`,
      ...requestParams,
    });
  }

  deleteRel(requestParams: IApiRequest): Observable<IApiResponse<IApiAction>> {
    return this.fetch({
      method: 'DELETE',
      path: `${this.apiUrl}${this.name}/${requestParams.id}/${requestParams.relation}/${requestParams.relationId}`,
      ...requestParams,
    }).pipe(
      map(envelope => this.parseEnvelope<IApiAction>(envelope, { ...requestParams, modelMapper: 'action' }))
    );
  }

  deleteRelBulk<T>(requestParams: IApiRequest): Observable<IApiResponse<IApiAction>> {
    let path = `${this.apiUrl}${this.name}`;
    path += requestParams.id ? `/${requestParams.id}` : '';
    path += requestParams.relation ? `/${requestParams.relation}` : '';

    return this.fetch({
      method: 'DELETE',
      path,
      ...requestParams,
    }).pipe(
      map(envelope =>
        this.parseEnvelope<IApiAction>(envelope, { ...requestParams, modelMapper: 'actionData' })
      )
    );
  }

  countRel(requestParams: IApiRequest): Observable<IApiResponse<IApiCount>> {
    return this.fetch({
      method: 'GET',
      path: `${this.apiUrl}${this.name}/${requestParams.id}/${requestParams.relation}/count`,
      ...requestParams,
    }).pipe(
      map(envelope => this.parseEnvelope<IApiCount>(envelope, { ...requestParams, modelMapper: 'count' }))
    );
  }

  // ADD and remove already existing entity as relations
  addEditRel<T>(requestParams: IApiRequest): Observable<IApiResponse<IApiAction>> {
    return this.fetch({
      method: 'PUT',
      path: `${this.apiUrl}${this.name}/${requestParams.id}/${requestParams.relation}/rel/${requestParams.relationId}`,
      ...requestParams,
    }).pipe(
      map(envelope => this.parseEnvelope<IApiAction>(envelope, { ...requestParams, modelMapper: 'action' }))
    );
  }

  removeRel<T>(requestParams: IApiRequest): Observable<IApiResponse<IApiAction>> {
    return this.fetch({
      method: 'DELETE',
      path: `${this.apiUrl}${this.name}/${requestParams.id}/${requestParams.relation}/rel/${requestParams.relationId}`,
      ...requestParams,
    }).pipe(
      map(envelope => this.parseEnvelope<IApiAction>(envelope, { ...requestParams, modelMapper: 'action' }))
    );
  }

  upload<T>(requestParams: IApiRequest): Observable<IApiResponse<T>> {
    const formData = new FormData();
    requestParams.files.forEach((file: File, index) => {
      formData.append(`file-${index}`, file, file.name);
    });

    const builtRequestParams = this.buildRequest(requestParams);
    builtRequestParams.params = new HttpParams({
      encoder: HTTP_PARAMETERS_CODEC,
      fromObject: builtRequestParams.params,
    });

    const request = new HttpRequest(
      'POST',
      `${this.apiUrl}${this.name}/upload`,
      formData,
      builtRequestParams
    );

    return this.http.request(request).pipe(
      map(event => this.getUploadStatus(event, {} as File)),
      filter(event => event.type === HttpEventType.Response),
      map((envelope: HttpResponse<any>) => this.parseEnvelope<T>(envelope.body, requestParams))
    );
  }

  /**
   * This is an Api helper that will parse the request and response, calling the this.apiUrl
   * as base endpoint
   */
  request<T>(requestParams: IApiRequest = {}): Observable<IApiResponse<T>> {
    const path = `${this.apiUrl}${this.name}` + (requestParams.path ? `/${requestParams.path}` : '');
    const request = this.fetch({
      ...requestParams,
      path,
    });
    return request.pipe(map(envelope => this.parseEnvelope<T>(envelope, requestParams)));
  }
  /**
   * This is a generic Api helper usefull to do HTTP calls to arbitrary endpoint without parsing the
   * response. Note taht this is parsing the request, so this method can call only application API's
   */
  fetch(requestParams: IApiRequest = {}): Observable<any> {
    const body = this.parseBody(requestParams);
    const request = this.buildRequest(requestParams);
    request.params = new HttpParams({
      encoder: HTTP_PARAMETERS_CODEC,
      fromObject: request.params,
    });
    let apiCall: Observable<any>;
    switch (requestParams.method) {
      case 'GET':
        {
          apiCall = this.http[requestParams.method.toLocaleLowerCase()](`${requestParams.path}`, request);
        }
        break;
      case 'DELETE':
        if (body) {
          apiCall = this.http.request('delete', `${requestParams.path}`, { body });
          break;
        }
        apiCall = this.http[requestParams.method.toLocaleLowerCase()](`${requestParams.path}`, body, request);
        break;
      case 'POST':
      case 'PUT':
      case 'PATCH':
        apiCall = this.http[requestParams.method.toLocaleLowerCase()](`${requestParams.path}`, body, request);
        break;
    }

    return of(this.authService.isTokenValid(this.authService.authToken)).pipe(
      switchMap(isTokenValid =>
        isTokenValid || !this.authService.isTokenValid(this.authService.refreshToken)
          ? of(true)
          : this.authService.renewToken().pipe(
              catchError(e => {
                // token invalidated manually from identity provider
                this.authService.logout();
                throw e;
              })
            )
      ),
      switchMap(() =>
        apiCall.pipe(
          retryWhen(retryEvent =>
            retryEvent.pipe(
              switchMap(errorEvent => {
                // NOTE: doing this in the constructor will lead errors since the authService use this class
                if (errorEvent instanceof HttpErrorResponse && errorEvent.status === 401) {
                  return from(this.authService.renewToken()).pipe(
                    catchError(() => {
                      // token invalidated manually from identity provider
                      this.authService.logout();
                      // NOTE: forward the HttpErrorResponse not the renewToken one
                      throw errorEvent;
                    })
                  );
                } else {
                  throw errorEvent;
                }
              })
            )
          ),
          catchError(error => {
            error.method = requestParams.method;
            error.requestBody = requestParams.body;
            error.queryParams = requestParams.queryParams;
            throw error;
          })
        )
      )
    );
  }

  buildRequest(requestParams?: IApiRequest) {
    // Set / override of some header params
    requestParams.headers = requestParams.headers || [];
    if (requestParams.bodyParser === 'formUrlEncoded') {
      requestParams.headers.push({
        headerName: 'Content-Type',
        value: 'application/x-www-form-urlencoded',
      });
    }
    const parser = getParser(this.parserType);
    return parser.requestParser(requestParams);
  }

  getModelMapper(requestParams: IApiRequest) {
    const { relation, modelMapper } = requestParams;
    return modelMapper || relation || this.name;
  }

  parseEnvelope<T>(apiOutput, requestParams: IApiRequest): IApiResponse<T> {
    const parser = getParser(this.parserType);
    const parsedResponse: IApiResponse<T> = parser.responseParser(
      apiOutput,
      this.getModelMapper(requestParams)
    );

    return parsedResponse;
  }

  parseBody(requestParams: IApiRequest) {
    let body = requestParams.body;
    if (!body) {
      return {};
    }

    if (!requestParams.isBodyRaw) {
      if (body.length) {
        body = body.map(item => item.serialised);
      } else {
        body = body.serialised;
      }
    }

    switch (requestParams.bodyParser) {
      case 'formData':
        const formData = new FormData();
        Object.entries(body as Record<string, any>).forEach(([key, val]) => {
          formData.set(key, val);
        });
        return formData;
      case 'formUrlEncoded':
        const bodyString: string[] = [];
        Object.entries(body).forEach(([key, val]) => {
          bodyString.push(`${key}=${val}`);
        });
        return bodyString.join('&');
      default:
        return body;
    }
  }

  get apiUrl() {
    return this.mockApiUrl || environment.apiUrl;
  }

  protected getUploadStatus(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        this.fileUploadStatus.info = `Uploading file "${file.name}" of size ${file.size}.`;
        break;
      case HttpEventType.UploadProgress:
        this.fileUploadStatus.percentage = Math.round((100 * event.loaded) / event.total);
        this.fileUploadStatus.loaded = event.loaded;
        this.fileUploadStatus.total = event.total;
        this.fileUploadStatus.info = `File "${file.name}" is ${this.fileUploadStatus.percentage}% uploaded.`;
        break;
      case HttpEventType.Response:
        this.fileUploadStatus = { info: '', loaded: 0, total: 0, percentage: 0 };
        break;
      default:
        this.fileUploadStatus.info = `File "${file.name}" surprising upload event: ${event.type}.`;
        break;
    }
    return event;
  }
}

const HTTP_PARAMETERS_CODEC: HttpParameterCodec = {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  },
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  },
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  },
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  },
};
