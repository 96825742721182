<div class="d-flex flex-column ibm-search-users">
  <div class="d-flex">
    <div class="w-100">
      <form [formGroup]="searchCollaboratorsForm">
        <input formControlName="searchTextControl"
          type="email"
          id="collaboratorSearch"
          class="bx--text-input"
          placeholder="Insert the email"
          (keyup.enter)="addCollaboratorByEmail()"
          autocomplete="off" />
      </form>
      <div (ibmClickOutside)="onClickedOutside()">
        <ul class="ibm-search-users__search"
          *ngIf="showUsersList && collaborators.length">
          <li (click)="addCollaborator(collaborator.user)"
            class="ibm-search-users__search-results"
            *ngFor="let collaborator of collaborators">
            <span>
              {{ selectedCollaborator.user.fullName }}
              {{ collaborator.user.email }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <button [ibmButton]="'primary'"
      class="bx--btn--field"
      [iconOnly]="true"
      [disabled]="!searchCollaboratorsForm.valid"
      (click)="addCollaboratorByEmail()">
      <svg ibmIconSend
        size="16"
        class="fill-white"></svg>
    </button>
  </div>
  <div class="d-flex flex-wrap">
    <div class="ibm-search-users__usersSelected"
      *ngFor="let selectedCollaborator of selectedCollaborators; let i = index">
      <span class="bx--tag bx--tag--gray">
        <ng-container *ngIf="selectedCollaborator.id">
          {{ selectedCollaborator.user.fullName }}
        </ng-container>
        <ng-container *ngIf="!selectedCollaborator.id">
          {{ selectedCollaborator }}
        </ng-container>
        <a class="ibm-search-users__close align-right"
          (click)="deleteCollaborator(i)">
          <svg width="10"
            height="10"
            viewBox="0 0 10 10"
            fill-rule="evenodd">
            <path d="M9.8 8.6L8.4 10 5 6.4 1.4 10 0 8.6 3.6 5 .1 1.4 1.5 0 5 3.6 8.6 0 10 1.4 6.4 5z">
            </path>
          </svg>
        </a>
      </span>
    </div>
  </div>
</div>