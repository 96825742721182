import { Injectable } from '@angular/core';
import { CreateRetrosynthesisWizardDialogComponent } from '@app/shared/components/create-retrosynthesis-wizard-dialog/create-retrosynthesis-wizard-dialog.component';
import { CreateSynthesisWizardDialogComponent } from '@app/shared/components/create-synthesis-wizard-dialog/create-synthesis-wizard-dialog.component';
import { GetRoborxnKeyDialogComponent } from '@app/shared/components/get-roborxn-key/get-roborxn-key-dialog.component';
import { SelectProjectDialogComponent } from '@app/shared/components/select-project-dialog/select-project-dialog.component';
import { filter } from 'rxjs/operators';
import { EDialogCloseEventStatus } from '../dialog/dialog.enum';
import { IDialogCloseEvent } from '../dialog/dialog.interface';
import { DialogsService } from '../dialog/dialog.service';
import { Router } from '@angular/router';
import { Project } from '@app/core/models';
import { AuthService } from '@app/libs/bitforce/services';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    public router: Router,
    private dialogsService: DialogsService,
    private authService: AuthService
  ) {}

  onCreateForwardReaction() {
    this.dialogsService.dialog
      .open(SelectProjectDialogComponent, {
        title: 'Predict product',
        buttonCallToAction: 'Predict product',
        entityName: 'product',
      })
      .afterClosed()
      .pipe(filter((event: IDialogCloseEvent<Project>) => event.status === EDialogCloseEventStatus.OK))
      .subscribe((response: IDialogCloseEvent<Project>) => {
        this.router.navigate([
          'projects',
          response.data.id,
          response.data.name,
          'workspace',
          'forward-reaction-prediction',
        ]);
      });
  }

  onCreatePredictRetrosyntheticRoutes() {
    this.dialogsService.dialog
      .open(CreateRetrosynthesisWizardDialogComponent, {})
      .afterClosed()
      .subscribe();
  }

  onPlanASynthesis() {
    if (!this.authService.user.roboRxnEnabled) {
      this.dialogsService.dialog
        .open(GetRoborxnKeyDialogComponent, {})
        .afterClosed()
        .subscribe();
      return;
    }
    this.dialogsService.dialog
      .open(CreateSynthesisWizardDialogComponent, {})
      .afterClosed()
      .subscribe();
  }

  onCreateReactionCompletion() {
    this.dialogsService.dialog
      .open(SelectProjectDialogComponent, {
        title: 'Predict reagents',
        buttonCallToAction: 'Predict reagents',
        entityName: 'reagent',
      })
      .afterClosed()
      .pipe(filter((event: IDialogCloseEvent<Project>) => event.status === EDialogCloseEventStatus.OK))
      .subscribe((response: IDialogCloseEvent<Project>) => {
        this.router.navigate([
          'projects',
          response.data.id,
          response.data.name,
          'reaction-completions',
          'create',
        ]);
      });
  }
}
