import { Component } from '@angular/core';
import { ApiService, DialogsService } from '@app/core/services';
import { AuthService } from '@app/libs/bitforce/services';
import { PaymentMethodDialogComponent } from '../payment-method-dialog/payment-method-dialog.component';
import { SubscriptionCost, User } from '@app/core/models';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { LoaderService } from '@app/libs/bitforce/components';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ibm-unsuccessful-payment-notification',
  templateUrl: './unsuccessful-payment-notification.component.html',
  styleUrls: ['./unsuccessful-payment-notification.component.scss'],
})
export class UnsuccessfulPaymentNotificationComponent {
  constructor(
    private dialogsService: DialogsService,
    public authService: AuthService,
    private apiService: ApiService,
    private loaderService: LoaderService
  ) {}

  onRetryPayment() {
    this.dialogsService.dialog
      .open(PaymentMethodDialogComponent, {
        isRetry: true,
      })
      .afterClosed()
      .subscribe(response => {
        if (response.status === EDialogCloseEventStatus.OK) {
          this.loaderService.show();
          this.apiService.users
            .getById<User>({
              id: this.apiService.auth.userId,
            })
            .pipe(finalize(() => this.loaderService.hide()))
            .subscribe(userResponse => {
              this.apiService.auth.user = userResponse.content;
            });
        }
      });
  }
}
