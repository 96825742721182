import { SuperModel } from '@models/super-model.model';

export type InvitationStatus = 'WAITING' | 'PENDING' | 'ACCEPTED' | 'ERROR';

export class Invitation extends SuperModel {
  email: string;
  status?: InvitationStatus;
  isDeletable?: boolean;

  constructor(invitation = {}) {
    super(invitation);
  }

  get serialised() {
    return { email: this.email, status: this.status };
  }
}
