import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RadioChange } from 'carbon-components-angular';
import { finalize } from 'rxjs/operators';

import { IFeedback, RetrosynthesisSequence } from '@models';
import { EFeedback } from '../../enums/feedback.enum';
import { DialogsService } from '@app/core/services';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { environment } from '@env/environment';
import { SmilesEditorDialogComponent } from '@app/modules/smiles-editor/smiles-editor-dialog/smiles-editor-dialog.component';

export enum EShareType {
  TWITTER = 'twitter',
}
@Component({
  selector: 'ibm-feedback-share-bar',
  templateUrl: './feedback-share-bar.component.html',
  styleUrls: ['./feedback-share-bar.component.scss'],
})
export class FeedbackShareBarComponent implements OnInit, OnChanges {
  @Input() hasShare: boolean;
  @Input() hasFeedback: boolean;
  @Input() sequence: RetrosynthesisSequence;
  @Input() feedback: IFeedback;
  @Output() sendFeedback: EventEmitter<IFeedback> = new EventEmitter<IFeedback>();
  @Output() shareOn: EventEmitter<EShareType> = new EventEmitter<EShareType>();
  @Output() copyShareUrl: EventEmitter<void> = new EventEmitter();

  eShareType = EShareType;
  eFeedbackType = EFeedback;
  showFeedbackBox = false;
  showShareBox = false;
  feedbackForm: UntypedFormGroup;
  isKetcherOpened = false;
  feedbackBackup: IFeedback;
  shareUrl = environment.hostUrl + '/public';

  constructor(private formBuilder: UntypedFormBuilder, private dialogsService: DialogsService) {}

  ngOnInit(): void {
    this.intiForm();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.feedback) {
      this.feedbackBackup = changes.feedback.currentValue;
      this.intiForm();
    }
  }

  private intiForm() {
    this.feedbackForm = this.formBuilder.group({
      feedback: [this.feedback?.opinion || null, Validators.required],
      smiles: [this.feedback?.message || null],
    });
  }

  onShareOn(shareType: EShareType) {
    this.shareOn.emit(shareType);
    this.closeAllBoxes();
  }

  onCopyShareUrl() {
    this.copyShareUrl.emit();
    this.closeAllBoxes();
  }

  submitFeedback() {
    this.sendFeedback.emit({
      message: this.feedbackForm.get('smiles').value,
      opinion: this.feedbackForm.get('feedback').value,
    });
    this.closeAllBoxes();
  }

  onChangeFeedback(data: RadioChange) {
    switch (data.value) {
      case this.eFeedbackType.LIKE:
        this.feedbackForm.get('smiles').disable();
        this.feedbackForm.get('smiles').clearValidators();
        this.feedbackForm.get('smiles').updateValueAndValidity();
        break;
      case this.eFeedbackType.DISLIKE:
        this.feedbackForm.get('smiles').enable();
        this.feedbackForm.get('smiles').setValidators([Validators.required]);
        this.feedbackForm.get('smiles').updateValueAndValidity();
        break;
    }
    this.feedbackForm.get('smiles').reset();
  }

  toggleFeedbackBox() {
    this.showShareBox = false;
    this.showFeedbackBox = !this.showFeedbackBox;
  }

  toggleShareBox() {
    this.showFeedbackBox = false;
    this.showShareBox = !this.showShareBox;
  }

  onOpenKetcher() {
    this.isKetcherOpened = true;
    this.dialogsService.dialog
      .open(SmilesEditorDialogComponent, {
        smiles: this.feedbackForm.get('smiles').value,
        actionLabel: 'Confirm',
      })
      .afterClosed()
      .pipe(
        finalize(() => {
          setTimeout(() => (this.isKetcherOpened = false));
        })
      )
      .subscribe((closeEvent: IDialogCloseEvent<any>) => {
        if (closeEvent.status === EDialogCloseEventStatus.OK) {
          this.feedbackForm.get('smiles').setValue(closeEvent.data);
        }
      });
  }

  onCancelShare() {
    this.closeAllBoxes();
  }

  onCancelFeedback() {
    this.closeAllBoxes();
    this.feedback = this.feedbackBackup;
    this.intiForm();
  }

  onClickOutside() {
    this.closeAllBoxes();
  }

  closeAllBoxes() {
    if (!this.isKetcherOpened) {
      this.showFeedbackBox = false;
      this.showShareBox = false;
    }
  }
}
