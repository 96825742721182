<button ibmButton="ghost"
  (click)="onCopyFingerprint()"
  class="text-01 small--xs"
  [iconOnly]="true"
  title="Copy fingerprint"
  *ngIf="smiles"
  data-e2e="fingerprintBtn">
  <svg icon
    ibmIconFingerprintRecognition
    size="16"
    class="bx--btn__icon"></svg>
</button>
<div id="fingerprintValue"
  class="d-none"
  data-e2e="fingerprintValue"></div>