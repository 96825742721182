<ng-template #customTrigger>
  <svg ibmIconUserAvatarFilledAlt
    size="20"></svg>
</ng-template>
<ibm-overflow-menu [placement]="'bottom'"
  [customTrigger]="customTrigger"
  [flip]="true"
  [offset]="{ x: 3, y: 0 }">
  <ibm-overflow-menu-option [routerLink]="['/', 'account']">Account</ibm-overflow-menu-option>
  <!-- <ibm-overflow-menu-option *ngIf="!apiService.auth?.user?.hasFreePlan"
    [routerLink]="['/', 'account', 'billing']">Billing
  </ibm-overflow-menu-option> -->
  <!-- <ibm-overflow-menu-option [routerLink]="['/', 'account', 'profile']">Profile
  </ibm-overflow-menu-option> -->
  <ibm-overflow-menu-option [routerLink]="['/', 'admin']"
    *ngIf="apiService.auth?.user?.isAdmin && !appSessionService.isAdminArea">Admin
  </ibm-overflow-menu-option>
  <ibm-overflow-menu-option [routerLink]="['/']"
    *ngIf="apiService.auth?.user?.isAdmin && appSessionService.isAdminArea">Back to app
  </ibm-overflow-menu-option>
  <ibm-overflow-menu-option [routerLink]="['/', 'logout']">Logout</ibm-overflow-menu-option>
</ibm-overflow-menu>