import { HttpHeaders } from '@angular/common/http';
type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD';
export interface IApiRequest {
  id?: string | number;
  method?: HttpMethods;
  path?: string;
  headers?: { headerName: string; value: string }[];
  queryParams?: any;
  query?: any;
  embed?: string[];
  computedFields?: string[];
  page?: number;
  size?: number;
  sorting?: IApiSorting[];
  body?: any;
  bodyParser?: string;
  isBodyRaw?: boolean;
  files?: File[];
  relation?: string;
  relationId?: string | number;
  action?: string;
  responseType?: 'text' | 'arraybuffer' | 'blob' | 'json';
  modelMapper?: string;
}

export interface IApiRequestParsed {
  headers?: HttpHeaders;
  withCredentials?: boolean;
  reportProgress?: boolean;
  responseType: string;
}

export interface IApiResponse<T> {
  content: T;
  pagination?: IApiPagination;
  metadata?: IApiMetadata;
  sorting?: IApiSorting[];
}

export interface IApiPagination {
  first: boolean;
  last: boolean;
  page: number;
  size: number; // max number of items per page
  itemsInPage: number; // number of items on current page, for example on
  // last page could be less than size
  totalItems: number;
  totalPages: number;
}

export interface IApiSorting {
  property: string;
  direction: IApiSortDirection;
  default?: boolean;
}

export enum IApiSortDirection {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface IApiMetadata {
  uiMessages?: {
    infos: IApiMessage[];
    errors: IApiMessage[];
    warnings: IApiMessage[];
  };
  extendedPagination?: {
    numberOfRetrosynthesis: number;
    numberOfSequences: number;
  };
}

export type uiMessageType = 'SUCCESS' | 'INFO' | 'WARNING' | 'ERROR';
export type uiMessageTarget = 'TOAST' | 'VALIDATION' | 'DIALOG' | 'CONSOLE';
export interface IApiMessage {
  code: string;
  title: string;
  message: string;
  type: uiMessageType;
  fieldId: string;
  target: uiMessageTarget;
  duration: number;
}

export interface IApiCount {
  count: number;
}

export interface IApiParser {
  requestParser(request: IApiRequest);
  responseParser<T>(apiOputput: any, relation: string): IApiResponse<T>;
}

export interface IApiAction {
  success: boolean;
}

export interface IApiActionData {
  data: any;
}
