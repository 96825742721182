import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ILoaderAction, LoaderAction, ICustomLoader } from './loader.model';
import { configs } from '@configs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  customLoader: ICustomLoader;
  onLoaderChange: Subject<ILoaderAction> = new Subject<ILoaderAction>();
  constructor() {}

  show(customLoader: ICustomLoader = null) {
    this.onLoaderChange.next({
      action: LoaderAction.SHOW,
      customLoader: customLoader || (configs.loaderService && configs.loaderService.customLoader),
    } as ILoaderAction);
  }

  hide() {
    this.onLoaderChange.next({ action: LoaderAction.HIDE } as ILoaderAction);
  }
}
