export function getRange(from: any, to: any, propertyName) {
  if (!from && !to) {
    return null;
  }

  if (from && to) {
    return {
      $and: [
        { [propertyName]: { $gte: getRangeValue(from) } },
        { [propertyName]: { $lte: getRangeValue(to) } },
      ],
    };
  }
  // if (from && to) {
  //   return {
  //     [propertyName]: { $gt: getRangeValue(from), $lt: getRangeValue(to) },
  //   };
  // }

  if (from) {
    return { [propertyName]: { $gte: getRangeValue(from) } };
  }

  if (to) {
    return { [propertyName]: { $lte: getRangeValue(to) } };
  }
}

function getRangeValue(value: any) {
  if (value instanceof Date) {
    return { $date: `${value.toISOString()}` };
  } else {
    return value;
  }
}

export function getMongoQuery(filters: any, searchText: string, searchTextFields: Array<string>) {
  if ((!filters || (filters && !filters.filtersQuery)) && !searchText) {
    return {};
  }

  let searchQuery = null;

  if (searchText) {
    searchQuery = { $or: getRegexQueryFromKeyword(searchText, searchTextFields) };
    if (!filters || !filters.filtersQuery) {
      return searchQuery;
    }
  }

  if (filters && filters.filtersQuery && !searchQuery) {
    return filters.filtersQuery;
  }

  return {
    $and: [searchQuery, filters.filtersQuery],
  };
}

export function getRegexQueryFromKeyword(keyword: string, fields: string[], excludeKeyword = false) {
  if (excludeKeyword) {
    keyword = `^((?!${keyword}).)*$`;
  }
  keyword = keyword.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  const searchValue = { $regex: keyword, $options: 'i' };
  return fields.map((fieldName: string) => {
    const obj = {};
    obj[fieldName] = searchValue;
    return obj;
  });
}
