// TODO
// grab colors from config

// svg sizes
// fix zoom and paper drag / pan
// changing sequence zoom the tree
// the graph must be centered
// order of the molecules when we open the detail change
// Smiles in the molecule description should have a mouse hover or expand his height dynamically
declare const joint: any;
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { bitfLoadAsset } from '@bitf/utils';
import {
  RetrosynthesisMolecule,
  Retrosynthesis,
  RetrosynthesisSequence,
  ERetrosynthesisMoleculeAction,
  Synthesis,
  ReactionCompletionPrediction,
} from '@models';
import { sequencesFromSiblingsForMolecule } from '@app/shared/common-utilities/siblings-to-sequence';
import { getStatusBoxProperties } from './status-box-helper';
import { copyToClipboard } from '../../common-utilities/clipboard';
import { AppSessionService, DialogsService, ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { TruncDecimalPipe } from '@app/shared/pipes/trunc-decimal.pipe';
import { CopySmilesDialogComponent } from '../copy-smiles-dialog/copy-smiles-dialog.component';

@Component({
  selector: 'ibm-tree-graph',
  templateUrl: './tree-graph.component.html',
  styleUrls: ['./tree-graph.component.scss'],
})
export class TreeGraphComponent implements OnInit, OnChanges {
  @Input()
  treeData: Retrosynthesis | Synthesis | ReactionCompletionPrediction;
  @Input()
  sequenceIndex = 0;
  @Input()
  showEdges = true;
  @Input()
  showMoleculesColor = true;
  @Input()
  uiConfig;
  @Input()
  selectedMoleculeId: string = null;
  @Input()
  roborxn = false;
  @Input()
  noLeafButtons = false;
  @Input()
  showBottomBar = false;
  @Input()
  readonly = false;
  @Input()
  annotationsMode = false;
  @Input()
  editMode = false;
  @Input()
  interactive = false;

  @Output()
  edgeClicked = new EventEmitter();
  @Output()
  sequenceIndexChange = new EventEmitter<number>();
  @Output()
  interactiveButtonClicked = new EventEmitter<{
    molecule: RetrosynthesisMolecule;
    action: ERetrosynthesisMoleculeAction;
  }>();

  // Rappid elements
  graph;
  paper;
  paperScroller;
  treeLayout;

  isTreeGraphInitialised = false;
  jointInitialised = false;
  zoomSteps: number[] = [];
  currentZoomIndex = 0;
  initialZoom = 0;
  parsedTreeData;
  defaultUiConfig = {
    fontFamily: '"ibm-plex-sans", Helvetica Neue, Arial, sans-serif',
    fontSize: 15,
    fontWeight: 500,
    molecule: {
      height: 250,
    },
    moleculeToolsIcon: {
      fill: '#0f62fe',
      size: 16,
    },
    smilesText: {
      fill: '#000',
    },
    card: {
      width: 250,
      height: 290, // molecule.height + smiles.height
      strokeWidth: 2,
      strokeColor: '#e0e0e0',
      fill: '#FFF',
    },
    labelText: {
      fill: '#FFF',
    },
    toolsBox: {
      height: 40,
      icons: {
        fill: 'white',
        size: 20,
      },
    },
    zoomStep: 100,
    minZoom: 100,
    maxZoom: 400,
    padding: 1,
    expand: {
      paddingTop: 20,
      relativeWidth: 0.9,
    },
  };

  constructor(
    private toastMessagesService: ToastMessagesService,
    private elRef: ElementRef,
    private truncDecimalPipe: TruncDecimalPipe,
    private appSessionService: AppSessionService,
    private dialogsService: DialogsService
  ) {}

  ngOnInit() {
    this.readonly = this.readonly || (this.treeData as Retrosynthesis).isFinalized;
    this.interactive = this.interactive && !this.roborxn;
    this.uiConfig = Object.assign(this.defaultUiConfig, this.uiConfig);
    for (let index = this.uiConfig.minZoom; index <= this.uiConfig.maxZoom; index += this.uiConfig.zoomStep) {
      this.zoomSteps.push(index);
    }
  }

  isTreeEditable() {
    return this.editMode && this.interactive && !this.readonly;
  }

  async ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes.treeData) {
        await this.init();
        this.parseTreeData(changes.treeData.currentValue);
        const children = (changes.treeData.currentValue.sequences[0] || { tree: {} }).tree.children || [];
        this.uiConfig.padding = children.length <= 1 ? 30 : this.uiConfig.padding;
        this.showSequenceByIndex(this.sequenceIndex);
        const needsFit = this.interactive || changes.treeData.previousValue == null;
        if (needsFit) {
          this.zoomToFit();
        }
      }

      if (changes.sequenceIndex) {
        this.parseTreeData(this.treeData);
        this.showSequenceByIndex(this.sequenceIndex);
        this.zoomToFit();
      }

      if (changes.selectedMoleculeId) {
        this.parseTreeData(this.treeData);
        this.showSequenceByIndex(this.sequenceIndex);
      }

      if (changes.editMode) {
        this.parseTreeData(this.treeData);
        this.showSequenceByIndex(this.sequenceIndex);
      }

      if (changes.annotationsMode) {
        this.parseTreeData(this.treeData);
        this.showSequenceByIndex(this.sequenceIndex);
      }
    } catch (e) {
      console.error(e);
      this.toastMessagesService.show({
        title: 'Error',
        message: 'Graph could not be initialised',
        type: EToastMessageType.ERROR,
      });
    }
  }

  private async init(): Promise<any> {
    await this.loadRappidScripts();
    this.initJoint();
    this.initTreeGraph();
  }

  private loadRappidScripts(): Promise<any> {
    return Promise.all([
      bitfLoadAsset('assets/rappid/rappid.boundle.min.js', 'js'),
      bitfLoadAsset('assets/rappid/rappid.boundle.min.css', 'css'),
    ]);
  }

  private initJoint() {
    if (this.jointInitialised) {
      return;
    }
    joint.dia.Element.define(
      'ibm.Molecule',
      {},
      {
        markup: [
          { tagName: 'rect', selector: 'card' },
          { tagName: 'image', selector: 'molecule' },
          { tagName: 'rect', selector: 'toolsBox' },
          { tagName: 'image', selector: 'copyIcon' },
          { tagName: 'image', selector: 'editIcon' },
          { tagName: 'image', selector: 'deleteIcon' },
          { tagName: 'text', selector: 'smilesText' },
          { tagName: 'rect', selector: 'belowBox' },
          { tagName: 'text', selector: 'belowBoxText' },
          { tagName: 'text', selector: 'belowBoxTextExpand' },
          { tagName: 'text', selector: 'belowBoxTextCustomExpand' },
          { tagName: 'line', selector: 'belowBoxTextSeparator' },
          { tagName: 'line', selector: 'lineSeparator' },
          { tagName: 'rect', selector: 'numberOfMoleculesBox' },
          { tagName: 'text', selector: 'numberOfMoleculesBoxText' },
        ],
      }
    );
    this.jointInitialised = true;
  }

  private initTreeGraph() {
    if (this.isTreeGraphInitialised) {
      this.treeLayout.set('parentGap', this.showEdges ? 180 : 110);
      return;
    }

    // GRAPH ---------------------------------
    this.graph = new joint.dia.Graph();

    // PAPER ---------------------------------
    this.paper = new joint.dia.Paper({
      model: this.graph,
      interactive: false,
    });

    // PAPER SCROLLER ---------------------------------
    this.paperScroller = new joint.ui.PaperScroller({
      paper: this.paper,
      padding: 0,
      baseWidth: 0,
      baseHeight: 0,
      cursor: 'grab',
      // autoResizePaper: true,
    });

    this.paperScroller.$el
      // .css({ width: '100%', height: '100%', border: 'solid 4px green' })
      .appendTo('.paper-container');

    this.paper.on('blank:pointerdown', this.paperScroller.startPanning);

    this.paper.on('element:showAlternativeReactions', (linkView, evt, x, y) => {
      evt.stopPropagation();
      if (this.treeData instanceof Retrosynthesis) {
        const targetElement = linkView.model.getTargetElement();
        const nodeClicked = targetElement.attributes.sequenceNode.parent;
        const newSequence = new RetrosynthesisSequence({
          ...this.treeData.sequences[this.sequenceIndex],
          id: 0,
          label: 'Reaction 0',
          confidence: nodeClicked.confidence,
          tree: {
            ...nodeClicked.cloneMeWithOnlyFirstChild(),
            isEditable: false,
            isExpandable: false,
          },
        });

        const newRetroResponse = {
          ...this.treeData,
          sequences: [newSequence],
        };

        // Search for siblings to create new sequences
        if (this.treeData.siblings) {
          const newSequences = sequencesFromSiblingsForMolecule({
            siblings: (this.treeData as Retrosynthesis).siblings,
            sequence: this.treeData.sequences[this.sequenceIndex],
            treeNode: nodeClicked,
          });

          newRetroResponse.sequences = newRetroResponse.sequences.concat(newSequences);
        }
        this.edgeClicked.emit(newRetroResponse);
      }
    });

    this.paper.on('element:expandBox', (linkView, evt) => {
      evt.stopPropagation();
      this.interactiveButtonClicked.emit({
        molecule: linkView.model.attributes.sequenceNode,
        action: ERetrosynthesisMoleculeAction.EXPAND,
      });
    });

    this.paper.on('element:expandBoxCustom', (linkView, evt) => {
      evt.stopPropagation();
      this.interactiveButtonClicked.emit({
        molecule: linkView.model.attributes.sequenceNode,
        action: ERetrosynthesisMoleculeAction.CUSTOM_EXPAND,
      });
    });

    this.paper.on('element:inspectBox', (linkView, evt) => {
      evt.stopPropagation();
      const toEmit = linkView.model.getTargetElement
        ? linkView.model.getTargetElement().attributes.sequenceNode.parent
        : linkView.model.attributes.sequenceNode;
      this.interactiveButtonClicked.emit(toEmit);
    });

    this.paper.on('element:copy', async (linkView, evt) => {
      evt.stopPropagation();
      const smiles = linkView.model.attributes.sequenceNode.smiles;
      if (!this.appSessionService.platformInformation.isIOS) {
        const copied = await copyToClipboard(smiles, this.elRef.nativeElement);

        if (copied) {
          this.toastMessagesService.show({
            title: 'Info',
            message: 'Smiles copied to clipboard',
            type: EToastMessageType.SUCCESS,
          });
        } else {
          this.toastMessagesService.show({
            title: 'Error',
            message: `Couldn't copy smiles to clipboard`,
            type: EToastMessageType.ERROR,
          });
        }
      } else {
        this.dialogsService.dialog
          .open(CopySmilesDialogComponent, {
            smiles,
          })
          .afterClosed()
          .subscribe();
      }
    });

    this.paper.on('element:editChild', (linkView, evt) => {
      evt.stopPropagation();
      this.interactiveButtonClicked.emit({
        molecule: linkView.model.attributes?.source?.attributes?.sequenceNode,
        action: ERetrosynthesisMoleculeAction.EDIT_CHILDREN,
      });
    });

    this.paper.on('element:deleteChild', (linkView, evt) => {
      evt.stopPropagation();
      this.interactiveButtonClicked.emit({
        molecule: linkView.model.attributes?.source?.attributes?.sequenceNode,
        action: ERetrosynthesisMoleculeAction.DELETE_CHILDREN,
      });
    });

    this.paper.on('element:addChild', (linkView, evt) => {
      evt.stopPropagation();
      this.interactiveButtonClicked.emit({
        molecule: linkView.model.attributes?.source?.attributes?.sequenceNode,
        action: ERetrosynthesisMoleculeAction.ADD_CHILDREN,
      });
    });

    this.paper.on('element:editMolecule', (linkView, evt) => {
      evt.stopPropagation();
      this.interactiveButtonClicked.emit({
        molecule: linkView.model.attributes?.sequenceNode,
        action: ERetrosynthesisMoleculeAction.EDIT_MOLECULE,
      });
    });

    this.paper.on('element:deleteMolecule', (linkView, evt) => {
      evt.stopPropagation();
      this.interactiveButtonClicked.emit({
        molecule: linkView.model.attributes?.sequenceNode,
        action: ERetrosynthesisMoleculeAction.DELETE_MOLECULE,
      });
    });

    // TREE LAYOUT ---------------------------------
    this.treeLayout = new joint.layout.TreeLayout({
      graph: this.graph,
      direction: 'B',
      parentGap: this.showEdges ? 180 : 110,
      siblingGap: 30,
    });

    this.isTreeGraphInitialised = true;
  }

  private parseTreeData(treeData: Retrosynthesis | Synthesis | ReactionCompletionPrediction) {
    this.parsedTreeData = treeData.sequences.map(sequence => {
      const membersAndConnections = { members: [], connections: [] };
      this.parseSequence(treeData, sequence.tree, membersAndConnections);
      return membersAndConnections;
    });
  }

  private parseSequence(
    treeData,
    sequenceNode,
    membersAndConnections,
    parentNode?,
    addEdge = true,
    siblingRank = 0
  ) {
    const molecule = treeData.molecules.find(
      m =>
        m.id === sequenceNode.moleculeId ||
        m.smiles
          .split('.')
          .sort()
          .join('.') ===
          sequenceNode.smiles
            .split('.')
            .sort()
            .join('.')
    );
    if (!molecule) {
      return;
    }
    // Create node
    const moleculeMember = this.createNode(molecule, sequenceNode, siblingRank);
    membersAndConnections.members.push(moleculeMember);

    // Create links
    if (parentNode) {
      membersAndConnections.connections.push(
        this.createLinkBetweenNodes(parentNode, moleculeMember, addEdge)
      );
    }
    if (sequenceNode.children && sequenceNode.children.length) {
      sequenceNode.children.forEach((sequenceNodeChild, i) =>
        this.parseSequence(
          treeData,
          sequenceNodeChild,
          membersAndConnections,
          moleculeMember,
          i === 0,
          siblingRank + 1 + i
        )
      );
    }
    return {};
  }

  private showSequenceByIndex(index = 0) {
    if (!this.parsedTreeData[index]) {
      index = 0;
    }
    this.sequenceIndex = index;
    this.sequenceIndexChange.emit(index);
    if (this.parsedTreeData[this.sequenceIndex] && this.parsedTreeData[this.sequenceIndex].connections) {
      // LOAD THE GRAPH CELLS
      this.graph.resetCells(
        this.parsedTreeData[this.sequenceIndex].members.concat(
          this.parsedTreeData[this.sequenceIndex].connections
        )
      );
    } else {
      return;
    }

    // RUN TREE LAYOUT ALGORITHM ------------
    this.treeLayout.layout();
  }

  private zoomToFit() {
    // PAPER SCROLLER PROPS
    this.paperScroller.zoomToFit({ padding: this.uiConfig.padding });
    this.currentZoomIndex = this.zoomSteps.findIndex(z => z === 100);
    this.initialZoom = this.paperScroller.zoom();
    this.paperScroller.positionContent('top');
  }

  private createNode(molecule: RetrosynthesisMolecule, sequenceNode: RetrosynthesisMolecule, siblingRank) {
    const isLeaf = sequenceNode.children && sequenceNode.children.length === 0;
    const belowBoxVisible = !this.noLeafButtons && sequenceNode.isExpandable && !this.readonly && isLeaf;

    let buttonProps = {
      title: 'Expand',
      fill: '#0f62fe',
    };
    if (this.roborxn) {
      buttonProps = getStatusBoxProperties(sequenceNode);
    }

    const belowBox = belowBoxVisible ? this.createBelowBox(buttonProps, sequenceNode) : undefined;
    const belowBoxText = belowBoxVisible ? this.createBelowBoxText(buttonProps, sequenceNode) : undefined;

    const filter = {
      name: 'highlight',
      args: {
        color: 'black',
        width: 2,
        opacity: 0.5,
        blur: 5,
      },
    };

    const isSelected = sequenceNode.id === this.selectedMoleculeId;
    const isParentSelected = sequenceNode.parent && sequenceNode.parent.id === this.selectedMoleculeId;
    const useFilter = isSelected || isParentSelected;

    const element = new joint.shapes.ibm.Molecule({
      size: {
        width: this.uiConfig.card.width,
        height: this.uiConfig.card.height,
      },
      siblingRank,
      sequenceNode,
      molecule,
      attrs: {
        root: {
          opacity: sequenceNode.status === 'PENDING' ? 0.3 : 1,
          filter: useFilter ? filter : undefined,
        },
        card: {
          fill: this.uiConfig.card.fill,
          stroke:
            (this.showMoleculesColor && sequenceNode.metaData && sequenceNode.metaData.borderColor) ||
            this.uiConfig.card.strokeColor,
          strokeWidth: this.uiConfig.card.strokeWidth,
          width: this.uiConfig.card.width,
          height: this.uiConfig.molecule.height + this.uiConfig.toolsBox.height,
          cursor: 'default',
          pointerEvents: 'visiblePainted',
        },
        molecule: {
          xlinkHref: createInlineSvgImage(molecule.moleculeImage),
          width: this.uiConfig.card.width - this.uiConfig.card.strokeWidth * 2,
          height: this.uiConfig.molecule.height - this.uiConfig.card.strokeWidth * 2,
          x: this.uiConfig.card.strokeWidth,
          y: this.uiConfig.card.strokeWidth,
          title: molecule.smiles,
          cursor: 'copy',
          event: 'element:copy',
        },
        toolsBox: {
          width: this.uiConfig.card.width - this.uiConfig.card.strokeWidth,
          height: this.uiConfig.toolsBox.height,
          fill: sequenceNode.custom ? '#e0e0e0' : '#ffffff',
          x: 1,
          y: this.uiConfig.molecule.height - 1,
          pointerEvents: 'none',
          cursor: 'default',
          event: 'element:toolsBox',
        },
        lineSeparator: {
          x1: 1,
          y1: this.uiConfig.molecule.height,
          x2: this.uiConfig.card.width - 1,
          y2: this.uiConfig.molecule.height,
          stroke: this.uiConfig.card.strokeColor,
          pointerEvents: 'none',
        },
        smilesText: {
          pointerEvents: 'none',
          fill: this.uiConfig.smilesText.fill,
          fontSize: this.uiConfig.fontSize,
          fontWeight: this.uiConfig.fontWeight,
          fontFamily: this.uiConfig.fontFamily,
          textAnchor: 'start',
          textVerticalAnchor: 'middle',
          refY: this.uiConfig.molecule.height + this.uiConfig.toolsBox.height / 2,
          refX: 10,
          textWrap: {
            text: molecule.moleculeName || molecule.smiles,
            ellipsis: true,
            width: -10,
            height: this.uiConfig.toolsBox.height / 2,
          },
          opacity: this.isTreeEditable() ? 0 : 1,
        },
        ...(sequenceNode.count > 1 && {
          numberOfMoleculesBox: {
            width: 32,
            height: 32,
            refX: this.uiConfig.card.width / 2 - 16,
            refY: -16,
            fill: '#0f62fe',
            rx: 16,
            ry: 16,
            title: 'Stoichiometry',
            cursor: 'default',
          },
        }),
        ...(sequenceNode.count > 1 && {
          numberOfMoleculesBoxText: {
            pointerEvents: 'none',
            fill: 'white',
            fontSize: this.uiConfig.fontSize,
            fontWeight: this.uiConfig.fontWeight,
            fontFamily: this.uiConfig.fontFamily,
            textAnchor: 'middle',
            textVerticalAnchor: 'middle',
            refX: this.uiConfig.card.width / 2,
            width: 32,
            height: 32,
            textWrap: {
              text: sequenceNode.count,
              ellipsis: true,
            },
          },
        }),
        ...(sequenceNode.isEditable && {
          editIcon: {
            xlinkHref: createInlineSvgImage(
              `<svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
            <rect x="2" y="26" width="28" height="2" fill="${this.uiConfig.moleculeToolsIcon.fill}"/>
            <path d="M25.4,9c0.8-0.8,0.8-2,0-2.8c0,0,0,0,0,0l-3.6-3.6c-0.8-0.8-2-0.8-2.8,0c0,0,0,0,0,0l-15,15V24h6.4L25.4,9z M20.4,4L24,7.6
              l-3,3L17.4,7L20.4,4z M6,22v-3.6l10-10l3.6,3.6l-10,10H6z"
              fill="${this.uiConfig.moleculeToolsIcon.fill}"/>
            </svg>`
            ),
            width: this.uiConfig.moleculeToolsIcon.size,
            height: this.uiConfig.moleculeToolsIcon.size,
            refY:
              this.uiConfig.molecule.height +
              this.uiConfig.toolsBox.height / 2 -
              this.uiConfig.moleculeToolsIcon.size / 2,
            refX: this.uiConfig.card.width / 2 - this.uiConfig.moleculeToolsIcon.size * 3,
            cursor: 'pointer',
            event: 'element:editMolecule',
            title: 'Edit',
            opacity: this.isTreeEditable() ? 1 : 0,
            pointerEvents: this.isTreeEditable() ? 'visiblePainted' : 'none',
          },
        }),
        copyIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="${this.uiConfig.moleculeToolsIcon.fill}" d="M1 10H0V2C0 .9.9 0 2 0h8v1H2c-.6 0-1 .5-1 1v8z"></path><path fill="${this.uiConfig.moleculeToolsIcon.fill}" d="M11 4.2V8h3.8L11 4.2zM15 9h-4c-.6 0-1-.4-1-1V4H4.5c-.3 0-.5.2-.5.5v10c0 .3.2.5.5.5h10c.3 0 .5-.2.5-.5V9zm-4-6c.1 0 .3.1.4.1l4.5 4.5c0 .1.1.3.1.4v6.5c0 .8-.7 1.5-1.5 1.5h-10c-.8 0-1.5-.7-1.5-1.5v-10C3 3.7 3.7 3 4.5 3H11z"></path></svg>`
          ),
          title: 'Copy',
          width: this.uiConfig.moleculeToolsIcon.size,
          height: this.uiConfig.moleculeToolsIcon.size,
          refY:
            this.uiConfig.molecule.height +
            this.uiConfig.toolsBox.height / 2 -
            this.uiConfig.moleculeToolsIcon.size / 2,
          refX: this.uiConfig.card.width / 2 - this.uiConfig.moleculeToolsIcon.size / 2,
          cursor: 'pointer',
          event: 'element:copy',
          opacity: this.isTreeEditable() ? 1 : 0,
          pointerEvents: this.isTreeEditable() ? 'visiblePainted' : 'none',
        },
        ...(sequenceNode.isDeletable && {
          deleteIcon: {
            xlinkHref: createInlineSvgImage(
              `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><rect fill="${this.uiConfig.moleculeToolsIcon.fill}" x="12" y="12" width="2" height="12"/><rect fill="${this.uiConfig.moleculeToolsIcon.fill}" x="18" y="12" width="2" height="12"/><path fill="${this.uiConfig.moleculeToolsIcon.fill}" d="M4,6V8H6V28a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V8h2V6ZM8,28V8H24V28Z"/><rect fill="${this.uiConfig.moleculeToolsIcon.fill}" x="12" y="2" width="8" height="2"/></svg>`
            ),
            width: this.uiConfig.moleculeToolsIcon.size,
            height: this.uiConfig.moleculeToolsIcon.size,
            refY:
              this.uiConfig.molecule.height +
              this.uiConfig.toolsBox.height / 2 -
              this.uiConfig.moleculeToolsIcon.size / 2,
            refX: this.uiConfig.card.width / 2 + this.uiConfig.moleculeToolsIcon.size * 2,
            cursor: 'pointer',
            event: 'element:deleteMolecule',
            title: 'Delete',
            opacity: this.isTreeEditable() ? 1 : 0,
            pointerEvents: this.isTreeEditable() ? 'visiblePainted' : 'none',
          },
        }),
        // EXPAND
        belowBox,
        ...belowBoxText,
      },
    });

    element.set({
      offset: this.showEdges ? -50 : -15,
    });

    return element;
  }

  private createBelowBox(buttonProps: any, sequenceNode: any) {
    const isCommercial = sequenceNode.isCommercial;
    if (isCommercial) {
      return {
        isCommercial,
        x: (this.uiConfig.card.width * (1 - this.uiConfig.expand.relativeWidth)) / 2,
        y: this.uiConfig.molecule.height + this.uiConfig.toolsBox.height + this.uiConfig.expand.paddingTop,
        cursor: 'default',
        width: this.uiConfig.card.width * this.uiConfig.expand.relativeWidth,
        height: this.uiConfig.toolsBox.height,
        rx: this.uiConfig.toolsBox.height / 2,
        ry: this.uiConfig.toolsBox.height / 2,
        ...buttonProps,
        fill: '#8D8D8D',
      };
    }
    return {
      isCommercial,
      x: (this.uiConfig.card.width * (1 - this.uiConfig.expand.relativeWidth)) / 2,
      y: this.uiConfig.molecule.height + this.uiConfig.toolsBox.height + this.uiConfig.expand.paddingTop,
      cursor: 'default',
      width: this.uiConfig.card.width * this.uiConfig.expand.relativeWidth,
      height: this.uiConfig.toolsBox.height,
      rx: this.uiConfig.toolsBox.height / 2,
      ry: this.uiConfig.toolsBox.height / 2,
      ...buttonProps,
    };
  }

  private createBelowBoxText(buttonProps: any, sequenceNode: any) {
    return {
      belowBoxTextExpand: {
        event: 'element:expandBox',
        fill: this.uiConfig.labelText.fill,
        fontSize: this.uiConfig.fontSize,
        fontFamily: this.uiConfig.fontFamily,
        textAnchor: 'middle',
        textVerticalAnchor: 'middle',
        refY:
          this.uiConfig.molecule.height +
          (this.uiConfig.toolsBox.height * 3) / 2 +
          this.uiConfig.expand.paddingTop,
        refX:
          this.uiConfig.card.width / 2 - (this.uiConfig.card.width * this.uiConfig.expand.relativeWidth) / 4,
        text:
          '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0AI\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0',
        cursor: 'pointer',
        pointerEvents: 'visiblePainted',
      },
      belowBoxTextSeparator: {
        x1: this.uiConfig.card.width / 2,
        y1: this.uiConfig.molecule.height + this.uiConfig.toolsBox.height + this.uiConfig.expand.paddingTop,
        x2: this.uiConfig.card.width / 2,
        y2:
          this.uiConfig.molecule.height +
          this.uiConfig.toolsBox.height +
          this.uiConfig.expand.paddingTop +
          this.uiConfig.toolsBox.height,
        stroke: 'white',
        cursor: 'pointer',
        pointerEvents: 'visiblePainted',
      },
      belowBoxTextCustomExpand: {
        event: 'element:expandBoxCustom',
        fill: this.uiConfig.labelText.fill,
        fontSize: this.uiConfig.fontSize,
        fontFamily: this.uiConfig.fontFamily,
        textAnchor: 'middle',
        textVerticalAnchor: 'middle',
        refY:
          this.uiConfig.molecule.height +
          (this.uiConfig.toolsBox.height * 3) / 2 +
          this.uiConfig.expand.paddingTop,
        refX:
          this.uiConfig.card.width / 2 + (this.uiConfig.card.width * this.uiConfig.expand.relativeWidth) / 4,
        text: '\u00A0\u00A0\u00A0\u00A0Custom\u00A0\u00A0\u00A0\u00A0',
        cursor: 'pointer',
        pointerEvents: 'visiblePainted',
      },
    };
  }

  private createLinkBetweenNodes(source, target, addEdge) {
    const sequenceNode: RetrosynthesisMolecule = source.get('sequenceNode');

    const link = new joint.shapes.standard.Link({
      source,
      target,
      attrs: {
        root: {
          pointerEvents: 'none',
          opacity: sequenceNode.status === 'PENDING' ? 0.3 : 1,
        },
        line: {
          stroke: '#0f62fe',
          sourceMarker: {
            type: 'path',
            d: 'M 20 -10 0 0 20 10 Z',
          },
          targetMarker: {
            type: 'path',
            d: '',
          },
        },
      },
      z: 2,
      isLinkBetweenNodes: true,
    });

    if (this.showEdges) {
      link.set('z', 3);
      const markup = [
        {
          tagName: 'rect',
          selector: 'labelBody',
        },
        {
          tagName: 'text',
          selector: 'labelText',
        },
      ];

      if (!this.interactive && !this.roborxn && !this.readonly) {
        markup.push({
          tagName: 'image',
          selector: 'viewAlternativeReactionsIcon',
        });
      }

      if (this.interactive && !this.roborxn) {
        if (sequenceNode.isChildrenEditable && this.isTreeEditable()) {
          markup.push({
            tagName: 'image',
            selector: 'interactiveEditIcon',
          });
        }
        if (sequenceNode.isChildrenDeletable && this.isTreeEditable()) {
          markup.push({
            tagName: 'image',
            selector: 'interactiveDeleteIcon',
          });
        }
        if (sequenceNode.isChildrenEditable && this.isTreeEditable()) {
          markup.push({
            tagName: 'image',
            selector: 'interactiveAddChildIcon',
          });
        }
      }

      if (sequenceNode.isThermal) {
        markup.push({
          tagName: 'rect',
          selector: 'heatBkg',
        });
        markup.push({
          tagName: 'image',
          selector: 'heatIcon',
        });
      }

      if (sequenceNode.isPhotochemical) {
        markup.push({
          tagName: 'rect',
          selector: 'lightBkg',
        });
        markup.push({
          tagName: 'image',
          selector: 'lightIcon',
        });
      }

      let buttonProps = {
        fill: '#0f62fe',
        event: null,
        title:
          sequenceNode.isConfidenceComputed === false
            ? 'Computing confidence'
            : `${this.truncDecimalPipe.transform(+sequenceNode.confidence, 3)} - ${sequenceNode.rclass}`,
        textStroke: '#ffffff',
      };

      if (this.roborxn) {
        if ((this.treeData as Synthesis).isProcedure) {
          if (!this.readonly) {
            buttonProps.title = `INSPECT`;
            markup.push({
              tagName: 'image',
              selector: 'reactionSettingsIcon',
            });
            buttonProps.event = sequenceNode.isInspectable ? `element:inspectBox` : null;
          }

          const isInvalid =
            !sequenceNode.isValid ||
            (sequenceNode.actions && sequenceNode.actions.length === 0) ||
            sequenceNode.status === 'SKIPPED';

          if (!this.readonly) {
            if (isInvalid) {
              markup.push({
                tagName: 'rect',
                selector: 'actionsWarnBkg',
              });
              markup.push({
                tagName: 'image',
                selector: 'actionsWarn',
              });
            } else {
              buttonProps.fill = '#0f62fe';
              markup.push({
                tagName: 'rect',
                selector: 'actionsValidBkg',
              });
              markup.push({
                tagName: 'image',
                selector: 'actionsValid',
              });
            }
          }

          if (!sequenceNode.isParentValid) {
            buttonProps.fill = '#C6C6C6';
            buttonProps.event = null;
          }
        } else {
          if (this.annotationsMode) {
            buttonProps.title = `${sequenceNode.numberOfAnnotations}`;
            buttonProps.event = `element:inspectBox`;

            markup.push({
              tagName: 'image',
              selector: 'notesIcon',
            });
          } else {
            buttonProps = getStatusBoxProperties(sequenceNode);
          }
        }
      }

      const attrs = {
        labelBody: {
          width: this.uiConfig.card.width,
          height: this.uiConfig.toolsBox.height,
          x: -this.uiConfig.card.width / 2,
          y: -this.uiConfig.toolsBox.height / 2,
          rx: this.uiConfig.toolsBox.height / 2,
          ry: this.uiConfig.toolsBox.height / 2,
          pointerEvents: buttonProps.event && !this.readonly ? 'visiblePainted' : 'none',
          ...buttonProps,
        },
        labelText: {
          ref: 'labelBody',
          pointerEvents: 'none',
          textAnchor: 'middle',
          textVerticalAnchor: 'middle',
          textWrap: {
            text: buttonProps.title,
            ellipsis: true,
            width: this.uiConfig.card.width - 80,
            height: this.uiConfig.toolsBox.height / 2,
          },
          fill: buttonProps.textStroke || this.uiConfig.labelText.fill,
          fontSize: this.uiConfig.fontSize,
          fontFamily: this.uiConfig.fontFamily,
          opacity:
            this.isTreeEditable() && (sequenceNode.isChildrenEditable || sequenceNode.isChildrenDeletable)
              ? 0
              : 1,
        },
        reactionSettingsIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><defs><style>.cls-1{fill:none;}</style></defs><title>settings</title><path fill="${this.uiConfig.toolsBox.icons.fill}" d="M27,16.76c0-.25,0-.5,0-.76s0-.51,0-.77l1.92-1.68A2,2,0,0,0,29.3,11L26.94,7a2,2,0,0,0-1.73-1,2,2,0,0,0-.64.1l-2.43.82a11.35,11.35,0,0,0-1.31-.75l-.51-2.52a2,2,0,0,0-2-1.61H13.64a2,2,0,0,0-2,1.61l-.51,2.52a11.48,11.48,0,0,0-1.32.75L7.43,6.06A2,2,0,0,0,6.79,6,2,2,0,0,0,5.06,7L2.7,11a2,2,0,0,0,.41,2.51L5,15.24c0,.25,0,.5,0,.76s0,.51,0,.77L3.11,18.45A2,2,0,0,0,2.7,21L5.06,25a2,2,0,0,0,1.73,1,2,2,0,0,0,.64-.1l2.43-.82a11.35,11.35,0,0,0,1.31.75l.51,2.52a2,2,0,0,0,2,1.61h4.72a2,2,0,0,0,2-1.61l.51-2.52a11.48,11.48,0,0,0,1.32-.75l2.42.82a2,2,0,0,0,.64.1,2,2,0,0,0,1.73-1L29.3,21a2,2,0,0,0-.41-2.51ZM25.21,24l-3.43-1.16a8.86,8.86,0,0,1-2.71,1.57L18.36,28H13.64l-.71-3.55a9.36,9.36,0,0,1-2.7-1.57L6.79,24,4.43,20l2.72-2.4a8.9,8.9,0,0,1,0-3.13L4.43,12,6.79,8l3.43,1.16a8.86,8.86,0,0,1,2.71-1.57L13.64,4h4.72l.71,3.55a9.36,9.36,0,0,1,2.7,1.57L25.21,8,27.57,12l-2.72,2.4a8.9,8.9,0,0,1,0,3.13L27.57,20Z" transform="translate(0 0)"/><path fill="${this.uiConfig.toolsBox.icons.fill}" d="M16,22a6,6,0,1,1,6-6A5.94,5.94,0,0,1,16,22Zm0-10a3.91,3.91,0,0,0-4,4,3.91,3.91,0,0,0,4,4,3.91,3.91,0,0,0,4-4A3.91,3.91,0,0,0,16,12Z" transform="translate(0 0)"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/></svg>`
          ),
          preserveAspectRatio: 'xMidYMid',
          ref: 'labelBody',
          width: this.uiConfig.toolsBox.icons.size,
          height: this.uiConfig.toolsBox.icons.size,
          refX: this.uiConfig.card.width - this.uiConfig.toolsBox.icons.size - 10,
          refY: this.uiConfig.toolsBox.height / 2 - this.uiConfig.toolsBox.icons.size / 2,
          cursor: 'pointer',
        },
        viewAlternativeReactionsIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" style="will-change: transform;"><path fill="${this.uiConfig.toolsBox.icons.fill}" d="M15 14.3L10.7 10c1.9-2.3 1.6-5.8-.7-7.7S4.2.7 2.3 3 .7 8.8 3 10.7c2 1.7 5 1.7 7 0l4.3 4.3.7-.7zM2 6.5C2 4 4 2 6.5 2S11 4 11 6.5 9 11 6.5 11 2 9 2 6.5z"></path></svg>`
          ),
          preserveAspectRatio: 'xMidYMid',
          pointerEvents: 'visiblePainted',
          cursor: 'pointer',
          ref: 'labelBody',
          width: 24,
          height: 24,
          refDx: -35,
          y: -12,
          refY: '50%',
          event: 'element:showAlternativeReactions',
        },
        actionsWarn: {
          xlinkHref: createInlineSvgImage(
            '<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" style="will-change: transform; fill: #f1c21b"><path d="M16,22a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,16,22Z"></path><rect width="2" height="9" x="15" y="11"></rect><path d="M29,29H3a1,1,0,0,1-.89-1.46l13-25a1,1,0,0,1,1.78,0l13,25A1,1,0,0,1,29,29ZM4.65,27h22.7L16,5.17Z"></path></svg>'
          ),
          preserveAspectRatio: 'xMidYMid',
          pointerEvents: 'visiblePainted',
          width: 24,
          height: 24,
          x: 24,
          y: -52,
          title: sequenceNode.status === 'SKIPPED' ? 'Skipped' : 'There are invalid or no actions',
        },
        actionsWarnBkg: {
          width: 32,
          height: 32,
          x: 20,
          y: -56,
          fill: '#FCF4D6',
          rx: 16,
          ry: 16,
          strokeWidth: 2,
          stroke: '#FCF4D6',
          pointerEvents: 'visiblePainted',
        },
        actionsValid: {
          xlinkHref: createInlineSvgImage(
            `<svg id="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" style="fill: #42BE65">
            <defs>
              <style>
                .cls-1 {
                  fill: none;
                }
              </style>
            </defs>
            <polygon points="14 21.414 9 16.413 10.413 15 14 18.586 21.585 11 23 12.415 14 21.414"/>
            <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"/>
            <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
          </svg>
          `
          ),
          preserveAspectRatio: 'xMidYMid',
          pointerEvents: 'visiblePainted',
          width: 24,
          height: 24,
          x: 24,
          y: -52,
        },
        actionsValidBkg: {
          width: 32,
          height: 32,
          x: 20,
          y: -56,
          fill: '#DEFBE6',
          rx: 16,
          ry: 16,
          strokeWidth: 2,
          stroke: '#DEFBE6',
          pointerEvents: 'visiblePainted',
        },
        interactiveDeleteIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" style="will-change: transform;">
              <path fill="${this.uiConfig.toolsBox.icons.fill}" d="M12 12h2v12h-2zm6 0h2v12h-2z" />
              <path fill="${this.uiConfig.toolsBox.icons.fill}" d="M4 6v2h2v20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8h2V6zm4 22V8h16v20zm4-26h8v2h-8z"/>
            </svg>`
          ),
          width: this.uiConfig.toolsBox.icons.size,
          height: this.uiConfig.toolsBox.icons.size,
          refX: this.uiConfig.card.width / 2 - this.uiConfig.toolsBox.icons.size * 3,
          refY: this.uiConfig.toolsBox.height / 2 - this.uiConfig.toolsBox.icons.size / 2,
          event: 'element:deleteChild',
          cursor: 'pointer',
          ref: 'labelBody',
          title: 'Delete',
          pointerEvents: 'visiblePainted',
        },
        interactiveEditIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32" aria-hidden="true" style="will-change: transform;">
              <path fill="${this.uiConfig.toolsBox.icons.fill}" d="M2 27h28v2H2zM25.41 9a2 2 0 0 0 0-2.83l-3.58-3.58a2 2 0 0 0-2.83 0l-15 15V24h6.41zm-5-5L24 7.59l-3 3L17.41 7zM6 22v-3.59l10-10L19.59 12l-10 10z"/>
            </svg>`
          ),
          width: this.uiConfig.toolsBox.icons.size,
          height: this.uiConfig.toolsBox.icons.size,
          refX: this.uiConfig.card.width / 2 - this.uiConfig.toolsBox.icons.size / 2,
          refY: this.uiConfig.toolsBox.height / 2 - this.uiConfig.toolsBox.icons.size / 2,
          event: 'element:editChild',
          cursor: 'pointer',
          ref: 'labelBody',
          title: 'Edit',
          pointerEvents: 'visiblePainted',
        },
        interactiveAddChildIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <defs><style>.cls-1{fill:none;}</style></defs>
              <path fill="${this.uiConfig.toolsBox.icons.fill}" d="M28,12a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4v6a2,2,0,0,0,2,2H15v4H9a2,2,0,0,0-2,2v4H4a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V24a2,2,0,0,0-2-2H9V18H23v4H20a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V24a2,2,0,0,0-2-2H25V18a2,2,0,0,0-2-2H17V12ZM12,28H4V24h8Zm16,0H20V24h8ZM4,4H28v6H4Z"/>
              <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
            </svg>`
          ),
          width: this.uiConfig.toolsBox.icons.size,
          height: this.uiConfig.toolsBox.icons.size,
          fill: 'white',
          refX: this.uiConfig.card.width / 2 + this.uiConfig.toolsBox.icons.size * 2,
          refY: this.uiConfig.toolsBox.height / 2 - this.uiConfig.toolsBox.icons.size / 2,
          event: 'element:addChild',
          cursor: 'pointer',
          ref: 'labelBody',
          title: 'Add molecules',
          pointerEvents: 'visiblePainted',
        },
        heatBkg: {
          width: 32,
          height: 32,
          x: -52,
          y: -56,
          fill: '#dcdcdc',
          rx: 16,
          ry: 16,
          strokeWidth: 2,
          stroke: '#dcdcdc',
          pointerEvents: 'visiblePainted',
        },
        heatIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg id="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <rect x="26" y="13" width="4" height="2"/>
              <rect x="23.1214" y="5.879" width="3.9999" height="1.9998" transform="translate(2.4937 19.7783) rotate(-45)"/>
              <rect x="24.1212" y="19.1214" width="1.9998" height="3.9999" transform="translate(-7.5773 23.9496) rotate(-45)"/>
              <rect x="17" y="2" width="2" height="4"/>
              <path d="M18,8a6.0365,6.0365,0,0,0-1,.09v2.0518A3.9567,3.9567,0,0,1,18,10a4,4,0,0,1,0,8v2A6,6,0,0,0,18,8Z"/>
              <path d="M10,20.1839V7H8V20.1839a3,3,0,1,0,2,0Z"/>
              <path d="M9,30A6.9931,6.9931,0,0,1,4,18.1108V7A5,5,0,0,1,14,7V18.1108A6.9931,6.9931,0,0,1,9,30ZM9,4A3.0033,3.0033,0,0,0,6,7V18.9834l-.332.2983a5,5,0,1,0,6.664,0L12,18.9834V7A3.0033,3.0033,0,0,0,9,4Z"/>
            </svg>
            `
          ),
          preserveAspectRatio: 'xMidYMid',
          pointerEvents: 'visiblePainted',
          ref: 'labelBody',
          width: 20,
          height: 20,
          x: -46,
          y: -50,
          title: 'Reaction requires heat',
        },
        lightBkg: {
          width: 32,
          height: 32,
          x: -52,
          y: -56,
          fill: '#dcdcdc',
          rx: 16,
          ry: 16,
          strokeWidth: 2,
          stroke: '#dcdcdc',
          pointerEvents: 'visiblePainted',
        },
        lightIcon: {
          xlinkHref: createInlineSvgImage(
            // eslint-disable-next-line
            `<svg id="icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <rect x="11" y="24" width="10" height="2" />
              <rect x="13" y="28" width="6" height="2" />
              <path
                d="M16,2A10,10,0,0,0,6,12a9.19,9.19,0,0,0,3.46,7.62c1,.93,1.54,1.46,1.54,2.38h2c0-1.84-1.11-2.87-2.19-3.86A7.2,7.2,0,0,1,8,12a8,8,0,0,1,16,0,7.2,7.2,0,0,1-2.82,6.14c-1.07,1-2.18,2-2.18,3.86h2c0-.92.53-1.45,1.54-2.39A9.18,9.18,0,0,0,26,12,10,10,0,0,0,16,2Z"
                transform="translate(0 0)" />
            </svg>
            `
          ),
          preserveAspectRatio: 'xMidYMid',
          pointerEvents: 'visiblePainted',
          ref: 'labelBody',
          width: 20,
          height: 20,
          x: -46,
          y: -50,
          title: 'Reaction requires light',
        },
        notesIcon: {
          xlinkHref: createInlineSvgImage(
            `<svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="32px" height="32px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32; fill: ${this.uiConfig.toolsBox.icons.fill};" xml:space="preserve">
              <style type="text/css">
                .st0{fill:none;}
              </style>
              <title>document</title>
              <path d="M25.7,9.3l-7-7C18.5,2.1,18.3,2,18,2H8C6.9,2,6,2.9,6,4v24c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V10C26,9.7,25.9,9.5,25.7,9.3
                z M18,4.4l5.6,5.6H18V4.4z M24,28H8V4h8v6c0,1.1,0.9,2,2,2h6V28z"/>
              <rect x="10" y="22" width="12" height="2"/>
              <rect x="10" y="16" width="12" height="2"/>
              <rect class="st0" width="32" height="32"/>
            </svg>
            `
          ),
          preserveAspectRatio: 'xMidYMid',
          pointerEvents: 'none',
          ref: 'labelBody',
          width: this.uiConfig.toolsBox.icons.size,
          height: this.uiConfig.toolsBox.icons.size,
          refX: this.uiConfig.card.width / 2 + 10,
          refY: this.uiConfig.toolsBox.height / 2 - this.uiConfig.toolsBox.icons.size / 2,
        },
      };

      const position = {
        distance: 60,
        offset: {
          x: 0,
          y: 0,
        },
      };

      link.label(0, {
        markup,
        attrs,
        position,
      });
    }
    return link;
  }

  setZoom(zoomValue: number) {
    this.currentZoomIndex = this.zoomSteps.findIndex(z => z === zoomValue);
    const newZoomValue = (this.initialZoom / 100) * zoomValue;
    this.paperScroller.zoom(newZoomValue, { absolute: true });
    this.paperScroller.positionContent('top');
  }
}

function createInlineSvgImage(svg: string) {
  return 'data:image/svg+xml;base64,' + btoa(svg);
}
