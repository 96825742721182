import { Component, Input, OnInit } from '@angular/core';
import { EPlanForecastType, IPlanForecast } from '@app/core/models';
import { ApiService } from '@app/core/services';

@Component({
  selector: 'ibm-plan-forecast-notification',
  templateUrl: './plan-forecast-notification.component.html',
  styleUrls: ['./plan-forecast-notification.component.scss'],
})
export class PlanForecastNotificationComponent implements OnInit {
  @Input() showUpgradeButton = false;
  @Input() filterByType: EPlanForecastType[];
  limitToShow = 25;
  planForecast: IPlanForecast[] = [];
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.apiService.plans.getForecast().subscribe(data => {
      this.planForecast = data.content
        .filter(x => (this.filterByType ? this.filterByType.includes(x.type) : true))
        .filter(x => {
          const condition = localStorage.getItem(this.getLocalStorageKey(x)) !== String(x.percentage);
          if (x.type === EPlanForecastType.PLAN_CANCELED) {
            return condition;
          } else {
            return condition && 100 - x.percentage >= this.limitToShow;
          }
        });
    });
  }

  onClose(planForecast: IPlanForecast) {
    localStorage.setItem(this.getLocalStorageKey(planForecast), String(planForecast.percentage));
    this.planForecast.splice(this.planForecast.indexOf(planForecast), 1);
  }

  private getLocalStorageKey(planForecast: IPlanForecast) {
    return `notification-plan-forecast-${planForecast.type}`;
  }
}
