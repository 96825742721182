<ibm-notification [notificationObj]="{
  type: 'error',
  showClose: false,
  lowContrast: true,
  template: paymentErrorNotification
}"
  *ngIf="authService?.user?.planDemoted">
</ibm-notification>

<ng-template #paymentErrorNotification>
  <h4 class="pt-0 mt-0">Payment unsuccessful</h4>
  <p>We were unable to charge your credit card. Please update your billing information to continue
    using the full version of RXN for Chemistry.</p>
  <button ibmButton="tertiary"
    size='sm'
    class="mt-6"
    (click)="onRetryPayment()">Update payment information</button>
</ng-template>