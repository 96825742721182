<div class="d-flex align-items-center">
  <ng-container *ngFor="let precursorMolecule of precursorMolecules; let lastImg = last;">
    <img [src]="precursorMolecule.moleculeImageSvg?.base64Url"
      [ngStyle]="{
    width: precursorMolecule.moleculeImageSvg.width * size + precursorMolecule.moleculeImageSvg?.unit,
    height: precursorMolecule.moleculeImageSvg.height * size + precursorMolecule.moleculeImageSvg?.unit
  }" />
    <div class="mx-3">
      <svg icon
        ibmIconAdd
        size="20"
        *ngIf="!lastImg"></svg>
      <svg icon
        ibmIconArrowRight
        size="20"
        *ngIf="lastImg"></svg>
    </div>
  </ng-container>
  <img [src]="productMolecule?.moleculeImageSvg?.base64Url"
    [ngStyle]="{
    width: productMolecule?.moleculeImageSvg.width * size + productMolecule?.moleculeImageSvg?.unit,
    height: productMolecule?.moleculeImageSvg.height * size + productMolecule?.moleculeImageSvg?.unit
  }" />
  <ng-container *ngFor="let outcomeMolecule of outcomeMolecules; let lastImg = last; let firstImg = first;">
    <div class="mx-3">
      <svg icon
        ibmIconArrowRight
        size="20"
        *ngIf="firstImg"></svg>
    </div>
    <img [src]="outcomeMolecule.moleculeImageSvg?.base64Url"
      [ngStyle]="{
    width: outcomeMolecule.moleculeImageSvg.width * size + outcomeMolecule.moleculeImageSvg?.unit,
    height: outcomeMolecule.moleculeImageSvg.height * size + outcomeMolecule.moleculeImageSvg?.unit
  }" />
    <div class="mx-3">
      <svg icon
        ibmIconAdd
        size="20"
        *ngIf="!lastImg"></svg>
    </div>
  </ng-container>
</div>