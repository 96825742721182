import { SuperModel } from './super-model.model';

export class Action extends SuperModel {
  name: string;
  description: string;
  messages: IActionMessage[];
  content: { [key: string]: ActionContent[] };
  status: TActionStatus;
  completionPercentage: number;
  remainingTime: number;
  hasSpectrometerPdf: boolean;

  get label(): string {
    let label = this.name.toUpperCase().substring(0, 1) + this.name.substring(1);

    if (this.content) {
      if (this.content.material) {
        label += ' ' + (this.content.material[0] || { value: '' }).value;
      } else if (this.content.duration) {
        label += ' ' + (this.content.duration[0] || { value: 0 }).value + 's';
      }
    }

    return label;
  }

  constructor(data: any) {
    super(data);

    if (data.content) {
      Object.keys(data.content).forEach(key => {
        // Maps to single value to array, because parameters can be multiple
        const actionContent: ActionContent = data.content[key] || Action.emptyActionContent;
        if (Array.isArray(actionContent.value)) {
          this.content[key] = actionContent.value;
        } else if (!Array.isArray(actionContent)) {
          this.content[key] = [actionContent];
        }

        this.content[key].forEach(content => {
          content.quantity = content.quantity || { value: null, unit: null };
        });
      });
    }
  }

  static get emptyActionContent(): ActionContent {
    return {
      value: null,
      quantity: { value: null, unit: null },
      unit: null,
      stoichiometryFactor: null,
      isChanged: {
        value: false,
        quantity: { value: false, unit: false },
        unit: false,
        stoichiometryFactor: false,
      },
    };
  }

  get serialised() {
    return {};
  }
}

export interface IActionLegendStep {
  id: any;
  text: string;
  error?: {
    title: string;
    message: string;
  };
  warning?: {
    title: string;
    message: string;
  };
  info?: {
    title: string;
    message: string;
  };
}

export interface IActionMessage {
  type: TActionMessageType;
  shortMessage: string;
  longMessage: string;
}

export interface ActionContent {
  value: string | number | boolean;
  quantity: { value: number; unit: string };
  unit: string;
  stoichiometryFactor: number;
  isMissingMaterial?: boolean;
  feasibleUnits?: string[];
  smiles?: string;
  isChanged?: {
    value: boolean;
    quantity?: {
      value?: boolean;
      unit?: boolean;
    };
    unit: boolean;
    stoichiometryFactor: boolean;
  };
}

export type TActionMessageType = 'error' | 'warning' | 'info';
export type TActionStatus = 'WAITING' | 'RUNNING' | 'DONE' | 'ERROR' | 'SKIPPED' | 'PAUSED' | 'STOPPED';
