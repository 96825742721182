import { SuperModel } from '@models/super-model.model';

export class FileEntry extends SuperModel {
  baseFileName: string;
  extension: string;
  fileName: string;
  mimeType: string;

  constructor(data) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}
