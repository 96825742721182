<div class="feedback-share-bar"
  (ibmClickOutside)="onClickOutside()">
  <div class="feedback-share-bar__btn"
    (click)="toggleFeedbackBox()"
    [ngClass]="{'active': showFeedbackBox, 'mr-3': hasShare }"
    *ngIf="hasFeedback">
    <svg icon
      ibmIconFaceWink
      size="24"></svg>
  </div>
  <div class="feedback-share-bar__btn"
    (click)="toggleShareBox()"
    [ngClass]="{'active': showShareBox }"
    *ngIf="hasShare">
    <svg icon
      ibmIconShare
      size="20"></svg>
  </div>

  <!-- Share -->
  <div class="feedback-share-bar__box-container"
    *ngIf="showShareBox">
    <div class="feedback-share-bar__box-container__header p-5 pb-0">
      Share your retrosynthesis
      <svg icon
        ibmIconClose
        size="16"
        (click)="closeAllBoxes()"
        class="cursor-pointer"></svg>
    </div>
    <div class="feedback-share-bar__box-container__content share p-5">
      <div class="mb-6">If you proceed, this sequence will become public.</div>
      <div class="mb-4">Are you sure you want to continue?</div>
      <div class="mb-4 small--xs">Click on the option that you prefer</div>
      <div class="d-flex flex-column">
        <div class="share-button cursor-pointer mb-3"
          (click)="onShareOn(eShareType.TWITTER)">
          I want to share this on Twitter
          <div class="share-button__icon">
            <svg icon
              ibmIconLogoTwitter
              size="32"></svg>
          </div>
        </div>
        <div class="share-button cursor-pointer"
          (click)="onCopyShareUrl()">
          <div class="d-flex flex-column">
            Copy URL
            <span class="share-button__share-url">
              {{ shareUrl }}
            </span>
          </div>
          <div class="share-button__icon copy-icon">
            <svg icon
              ibmIconCopy
              size="16"></svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Feedback -->
  <div class="feedback-share-bar__box-container"
    *ngIf="showFeedbackBox">
    <div class="feedback-share-bar__box-container__header p-5 pb-0">
      Help us to improve
      <svg icon
        ibmIconClose
        size="16"
        (click)="closeAllBoxes()"
        class="cursor-pointer"></svg>
    </div>
    <div class="feedback-share-bar__box-container__content feedback p-5">
      <form [formGroup]="feedbackForm">
        <div class="mb-4">What do you think about this sequence?</div>
        <div class="feedback-options-container mb-8">
          <ibm-radio-group formControlName="feedback"
            (change)="onChangeFeedback($event)">
            <ibm-radio [value]="eFeedbackType.LIKE"
              [hostClass]="true">
              <div class="feedback-radio-box">
                <span class="feedback-label">It's good</span>
                <svg icon
                  ibmIconFaceSatisfied
                  size="32"></svg>
              </div>
            </ibm-radio>
            <ibm-radio [value]="eFeedbackType.DISLIKE"
              [hostClass]="true">
              <div class="feedback-radio-box">
                <span class="feedback-label">It's not so good</span>
                <svg icon
                  ibmIconFaceDissatisfied
                  size="32"></svg>
              </div>
            </ibm-radio>
          </ibm-radio-group>
        </div>
        <ng-container *ngIf="feedbackForm.value.feedback === eFeedbackType.DISLIKE">
          <div class="mb-3">Would you like to suggest a better solution?</div>
          <ibm-label class="mb-4">
            Write a SMILES string
            <textarea ibmTextArea
              rows="4"
              formControlName="smiles"></textarea>
          </ibm-label>
          <div class="d-flex flex-column mb-4">
            <span class="mb-3 small--xs">Suggest a correction using the visual editor</span>
            <button class="visual-editor-btn"
              (click)="onOpenKetcher()">
              Open visual editor
            </button>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="feedback-share-bar__box-container__footer">
      <button ibmButton="secondary"
        class="cancel-button pr-0"
        (click)="onCancelFeedback()">Cancel</button>
      <button ibmButton="primary"
        class="pr-0"
        (click)="submitFeedback()"
        [disabled]="!feedbackForm.valid">Send Feedback</button>
    </div>
  </div>
</div>