import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { KetcherComponent } from '@app/shared/components/ketcher/ketcher.component';
import { ESmilesEditorType, EVisualEditorType } from './smiles-editor.enum';

@Component({
  selector: 'ibm-smiles-editor',
  templateUrl: './smiles-editor.component.html',
  styleUrls: ['./smiles-editor.component.scss'],
})
export class SmilesEditorComponent implements OnInit, OnChanges {
  @ViewChild('ketcher', { static: true })
  ketcher: KetcherComponent;

  @Input()
  visualEditorType = EVisualEditorType.KETCHER;
  @Input()
  smiles: string;
  @Input()
  actionLabel: string;
  @Input()
  maxNumberOfMolecules = Infinity;
  @Input()
  inModal = false;
  @Output()
  action = new EventEmitter<{ smiles: string; mol: string }>();
  @Output()
  close = new EventEmitter<void>();

  currentEditor: ESmilesEditorType = ESmilesEditorType.VISUAL;
  eSmilesEditorType = ESmilesEditorType;

  constructor(private toastMessagesService: ToastMessagesService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.smiles?.currentValue) {
      this.ketcher.setSmiles([this.smiles]);
    }
  }

  onLoadSmilesInVisualEditor(smiles) {
    this.smiles = smiles;
    this.onChangeEditorType(ESmilesEditorType.VISUAL);
    this.ketcher.setSmiles([this.smiles]);
  }

  onChangeEditorType(editorType: ESmilesEditorType) {
    this.currentEditor = editorType;
    switch (editorType) {
      case ESmilesEditorType.TEXTUAL:
        this.smiles = ''; // force change detection
        setTimeout(() => (this.smiles = this.ketcher?.smiles));
        break;
    }
  }

  onAction() {
    const smiles = this.ketcher?.smiles;
    if (this.ketcher && this.ketcher.ketcherInitialised && this.checkMaxNumberOfMolecules(smiles)) {
      this.action.emit({ smiles, mol: this.ketcher?.mol });
    }
  }

  private checkMaxNumberOfMolecules(smiles: string) {
    if (smiles.split('.').length > this.maxNumberOfMolecules) {
      this.toastMessagesService.show({
        title: `Error`,
        message: `You've entered more than ${this.maxNumberOfMolecules} molecule.`,
        type: EToastMessageType.ERROR,
      });
      return false;
    } else {
      return true;
    }
  }
}
