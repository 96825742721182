import { SuperModel } from '@models/super-model.model';
import { IFeedback } from './retrosynthesis-sequence.model';
import { Action } from './action.model';
import { Molecule } from '.';

export class RetrosynthesisMolecule extends Molecule {
  moleculeId?: string;
  siblingId?: string;
  confidence?: string;
  isConfidenceComputed?: boolean;
  hasFeedback?: boolean;
  feedback?: IFeedback;
  label?: string;
  reactionSmiles?: string;
  moleculeImage?: string;
  metaData?: {
    borderColor?: string;
  };
  children?: RetrosynthesisMolecule[];
  parent?: RetrosynthesisMolecule;
  rclass: string;

  isExpandable?: boolean;
  isInspectable?: boolean;
  isEditable?: boolean;
  isDeletable?: boolean;
  isChildrenEditable?: boolean;
  isChildrenDeletable?: boolean;
  isValid?: boolean;
  isThermal?: boolean;
  isPhotochemical?: boolean;
  isTouched?: boolean;
  isFromFile?: boolean;
  numberOfAnnotations: number;

  initialActions: Action[];
  actions: Action[];
  status: SequenceStatus;
  completionPercentage?: number;
  remainingTime?: number;
  reactors: number[];
  statusDetails: string;
  statusLogs?: StatusLog[];
  custom?: boolean;
  productMassAndReactionInformation: {
    stoichiometryFactor: number;
  };
  count: number; // number of duplicates

  constructor(data: any = {}) {
    super(data);
    this.children = (data.children || []).map(m => new RetrosynthesisMolecule({ ...m, parent: this }));
    if (this.actions) {
      this.actions = this.actions.map(a => new Action(a));
    }
    if (this.initialActions) {
      this.initialActions = this.initialActions.map(a => new Action(a));
    }
    if (this.statusLogs) {
      this.statusLogs = this.statusLogs.map(e => new StatusLog(e));
    }

    this.reactors = this.reactors || [];
    this.isValid = data.isValid !== false;
  }

  get isParentValid() {
    return !this.parent || (this.parent?.isValid && this.parent?.isParentValid);
  }

  cloneMeWithOnlyFirstChild(): RetrosynthesisMolecule {
    return Object.assign({}, this, { children: this.children.map(c => ({ ...c, children: [] })) });
  }

  toJSON() {
    const data = Object.assign({}, this);
    // Prevents circular JSON
    delete data.parent;
    return data;
  }
}

export class StatusLog extends SuperModel {
  timestamp: number;
  text: string;
  time: Date;

  constructor(data: any) {
    super(data);
    this.time = new Date(this.timestamp);
  }

  get serialised() {
    return {};
  }
}

export type SequenceStatus =
  | 'PENDING'
  | 'WAITING'
  | 'RUNNING'
  | 'DONE'
  | 'ERROR'
  | 'NEW'
  | 'SKIPPED'
  | 'STOPPED'
  | 'PAUSED';

export enum ERetrosynthesisMoleculeAction {
  EXPAND = 'EXPAND',
  CUSTOM_EXPAND = 'CUSTOM_EXPAND',
  INSPECT = 'INSPECT',
  DELETE_CHILDREN = 'DELETE_CHILDREN',
  EDIT_CHILDREN = 'EDIT_CHILDREN',
  ADD_CHILDREN = 'ADD_CHILDREN',
  EDIT_MOLECULE = 'EDIT_MOLECULE',
  DELETE_MOLECULE = 'DELETE_MOLECULE',
}
