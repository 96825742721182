import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { SuperAuthService } from './super-auth.service';
import { AppSessionService } from '@services/app-session.service';
import { TryCatch } from '../../decorators';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends SuperAuthService {
  constructor(public http: HttpClient, public router: Router, public appSessionService: AppSessionService) {
    super(http, router, appSessionService);
  }

  get authTokenMetaData(): ITokenMetaData {
    if (this._authTokenMetaData) {
      return this._authTokenMetaData;
    }

    const metaData = this.decodeToken(this.authToken);
    if (metaData) {
      this._authTokenMetaData = metaData;
      return metaData;
    }
  }

  set authTokenMetaData(authTokenMetaData: ITokenMetaData) {
    super['authTokenMetaData'] = authTokenMetaData;
  }

  @TryCatch()
  decodeToken(token: string): ITokenMetaData {
    if (!token) {
      return;
    }
    return jwtDecode(token) as ITokenMetaData;
  }

  @TryCatch()
  isTokenValid(token: string) {
    const decodedToken = this.decodeToken(token);
    const tokenExpiration = this.getTokenExpirationInMilliseconds(decodedToken);
    if (!tokenExpiration) {
      return false;
    }
    return tokenExpiration > Date.now();
  }

  getTokenExpirationInMilliseconds(token: ITokenMetaData): number {
    if (token?.exp === undefined) {
      return null;
    }
    return token.exp * 1000;
  }
}

export interface ITokenMetaData {
  iss: string;
  exp: number;
  sub: string; // user id
  iat: number;
  tenant: string;
  scope: string;
}
