import {
  Component,
  OnInit,
  ComponentRef,
  ViewContainerRef,
  ViewChild,
  ComponentFactory,
  ComponentFactoryResolver,
} from '@angular/core';

import { LoaderService } from './loader.service';
import { ILoaderAction, LoaderAction, ICustomLoader } from './loader.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bitf-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @ViewChild('content', { read: ViewContainerRef })
  content;
  componentRef: ComponentRef<Component> = null;
  isReady = false;
  loaderType;

  constructor(private loaderService: LoaderService, private resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.loaderService.onLoaderChange.subscribe((loaderAction: ILoaderAction) => {
      switch (loaderAction.action) {
        case LoaderAction.SHOW:
          this.show(loaderAction.customLoader);
          break;
        case LoaderAction.HIDE:
          this.hide();
      }
    });
  }

  show(customLoader: ICustomLoader = null) {
    this.isReady = true;
    this.loaderType = undefined;
    setTimeout(() => {
      this.clearContent();

      let componentFactory: ComponentFactory<Component>;
      if (customLoader) {
        componentFactory = this.resolver.resolveComponentFactory(customLoader.component);
      }

      if (componentFactory && this.content) {
        this.loaderType = 'custom';
        this.componentRef = this.content.createComponent(componentFactory);
        Object.assign(this.componentRef.instance, customLoader.params || {});
      } else {
        this.loaderType = 'default';
      }
    });
  }

  hide() {
    this.isReady = false;
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
    this.clearContent();
  }

  clearContent() {
    if (!this.content) {
      return;
    }
    this.content.clear();
  }
}
