import { Injectable, Injector } from '@angular/core';

import { ApiSuperService } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class FileEntriesService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'file-entries';
  }
}
