import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuperService } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'emails';
  }

  shareApp(email: string): Observable<any> {
    return super.fetch({
      method: 'POST',
      body: { email },
      path: `${this.apiUrl}emails/share-app`,
    });
  }
}
