<ng-container [ngSwitch]="status">
  <div class="synthesis-status">
    <div *ngSwitchCase="'RUNNING'"
      class="synthesis-status__icon running">
      <svg icon
        ibmIconPlayFilled
        [size]="size"></svg>
    </div>

    <div *ngSwitchCase="'WAITING'"
      class="synthesis-status__icon waiting">
      <svg icon
        ibmIconInProgress
        [size]="size"></svg>
    </div>

    <div *ngSwitchCase="'SKIPPED'"
      class="synthesis-status__icon skipped">
      <svg icon
        ibmIconSkipForwardOutlineFilled
        [size]="size"></svg>
    </div>

    <div *ngSwitchCase="'ERROR'"
      class="synthesis-status__icon error">
      <svg icon
        ibmIconErrorFilled
        [size]="size"></svg>
    </div>

    <div *ngSwitchCase="'DONE'"
      class="synthesis-status__icon done">
      <svg icon
        ibmIconCheckmarkFilled
        [size]="size"></svg>
    </div>

    <div *ngSwitchCase="'PAUSED'"
      class="synthesis-status__icon paused">
      <svg icon
        ibmIconPauseOutlineFilled
        [size]="size"></svg>
    </div>

    <div *ngSwitchCase="'STOPPED'"
      class="synthesis-status__icon stopped">
      <svg icon
        ibmIconStopFilled
        [size]="size"></svg>
    </div>

    <div *ngSwitchCase="'NEW'"
      class="synthesis-status__icon new">
      <svg icon
        ibmIconEdit
        [size]="size"></svg>
    </div>

    <div *ngSwitchDefault>{{ status }}</div>
  </div>
</ng-container>