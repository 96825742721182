import { SuperModel } from './super-model.model';

export class SocketNotification extends SuperModel {
  subTopic: ESocketNotificationSubTopic;
  title: string;
  message: string;
  level: ESocketNotificationLevel;
  timeout: number;
  target: ESocketNotificationTarget;
  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {};
  }
}

export enum ESocketNotificationSubTopic {
  SUBSCRIPTION_COMPLETED = 'SUBSCRIPTION_COMPLETED',
  SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR',
}

export enum ESocketNotificationLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum ESocketNotificationTarget {
  TOAST = 'TOAST',
  DIALOG = 'DIALOG',
}
