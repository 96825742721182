import { Injectable, Injector } from '@angular/core';
import { IApiRequest, IApiResponse } from '@app/libs/bitforce/api';
import { ApiSuperService } from '@bitf/api/api-super.service';
import { ERetrosynthesisStatus, QueueState, Retrosynthesis, RetrosynthesisWizardConfig } from '@models';
import { interval, Observable, of } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { retrosynthesisPolling } from '../retrosynthesis-polling';

@Injectable({
  providedIn: 'root',
})
export class RetrosynthesisService extends ApiSuperService {
  // mockApiUrl = 'http://localhost:3001/';
  checkOutcomesInterval = 15000;
  expandInterval = 2000;
  pollQueueStateInterval = 50000;

  constructor(injector: Injector) {
    super(injector);
    this.name = 'retrosynthesis';
  }

  getWizardConfig({ categoryName }: { categoryName?: string }) {
    return this.request<RetrosynthesisWizardConfig>({
      method: 'GET',
      path: `config`,
      relation: 'retrosynthesis/config',
      queryParams: {
        ...(categoryName && { categoryName }),
      },
    });
  }

  getRetrosynthesis(request: IApiRequest): Observable<IApiResponse<Retrosynthesis>> {
    return retrosynthesisPolling({
      intervalMs: this.checkOutcomesInterval,
      service: {
        getRetrosynthesisResult: () => super.getById<Retrosynthesis>(request),
      },
    });
  }

  getExpandSequences({
    retroId,
    nodeId,
  }: {
    retroId: string;
    nodeId: string;
  }): Observable<IApiResponse<Retrosynthesis>> {
    return this.request<Retrosynthesis>({
      method: 'GET',
      relation: 'expand-molecule',
      path: `${retroId}/expand-molecule/${nodeId}`,
    }).pipe(
      switchMap(response => {
        if (response.content.status === ERetrosynthesisStatus.DONE) {
          return of(response);
        }
        const taskId = response.content.task.task_id;
        if (taskId !== undefined) {
          return retrosynthesisPolling({
            intervalMs: this.expandInterval,
            service: {
              getRetrosynthesisResult: () =>
                this.request<Retrosynthesis>({
                  method: 'GET',
                  relation: 'expand-molecule',
                  path: `${retroId}/expand-molecule/${nodeId}/result/${taskId}`,
                }),
            },
          });
        }

        return of(response);
      })
    );
  }

  pollQueueState(): Observable<IApiResponse<QueueState>> {
    return interval(this.pollQueueStateInterval).pipe(
      startWith(1),
      switchMap(() =>
        super
          .getRel<QueueState>({ relation: 'queue-state' } as IApiRequest)
          .pipe(catchError(e => of({ content: undefined })))
      )
    );
  }

  shareRetrosynthesis(selectedSequenceId: string): Observable<IApiResponse<IShareRetrosynthesis>> {
    return this.postRel({
      relation: 'share',
      id: selectedSequenceId,
      body: {
        sequenceId: selectedSequenceId,
      },
      isBodyRaw: true,
    });
  }
}

interface IShareRetrosynthesis {
  id: string;
  hashtags: string[];
  content: string;
}
