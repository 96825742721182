import { Injectable, Injector } from '@angular/core';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { IApiResponse } from '@bitf/api';
import { Project } from '@models';
import { ProjectsService } from '@services';
import { BaseModal, ListItem } from 'carbon-components-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { EProjectSelectionMode } from '@shared/components/select-or-create-project/select-or-create-project.component';
import { EventTrackingService } from '@app/core/services/event-tracking/event-tracking.service';

@Injectable()
export class SuperWizardComponent extends BaseModal {
  projects: ListItem[];
  protected projectsService: ProjectsService;
  protected eventTrackingService: EventTrackingService;

  constructor(public injector: Injector) {
    super();
    this.projectsService = this.injector.get<ProjectsService>(ProjectsService);
    this.eventTrackingService = this.injector.get<EventTrackingService>(EventTrackingService);
  }

  protected getProjectsListItems(selectProjectId?: string): Observable<ListItem[]> {
    return this.projectsService
      .get<Project>({ size: 1000 })
      .pipe(
        filter((response: IApiResponse<Project[]>) => response !== null),
        map((response: IApiResponse<Project[]>) =>
          response.content.map((project: Project) => ({
            content: project.name,
            project,
            selected: project.id === selectProjectId,
          }))
        ),
        tap((projects: ListItem[]) => (this.projects = projects)),
        catchError(err => {
          this.projects = [];
          return throwError(err);
        })
      );
  }

  protected getOrCreateProject(projectGroupValue: any) {
    let source$: Observable<Project> = of(projectGroupValue.selectedProject?.project);
    if (projectGroupValue.projectRadio === EProjectSelectionMode.NEW_PROJECT) {
      source$ = this.projectsService
        .post<Project>({ body: { name: projectGroupValue.projectName }, bodyParser: 'raw', isBodyRaw: true })
        .pipe(
          map(response => response.content),
          tap(() => {
            this.eventTrackingService.trackEvent('Project Creation');
          })
        );
    }
    return source$;
  }

  closeModal(status = EDialogCloseEventStatus.CANCEL) {
    this.close.emit({
      status,
    } as IDialogCloseEvent<void>);
  }
}
