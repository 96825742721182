import { Component, Injector } from '@angular/core';
import { IPlanOverageTrainingCostRate } from '@app/core/models';
import { BaseModal } from 'carbon-components-angular';

@Component({
  selector: 'ibm-training-cost-detail-dialog',
  templateUrl: './training-cost-detail-dialog.component.html',
  styleUrls: ['./training-cost-detail-dialog.component.scss'],
})
export class TrainingCostDetailDialogComponent extends BaseModal {
  dialogData: { data: IPlanOverageTrainingCostRate[] };
  constructor(private injector: Injector) {
    super();
    this.dialogData = this.injector.get('dialogData');
  }
}
