import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuperService } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class HubspotService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'hubspot';
  }

  subscribeHubspot(): Observable<any> {
    return this.fetch({
      method: 'POST',
      path: `${this.apiUrl}hubspot/subscribe`,
      body: {},
    });
  }
}
