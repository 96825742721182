<ibm-notification (close)="onClose(notification)"
  *ngFor="let notification of planForecast"
  [notificationObj]="{
      type: notification.level,
      template: templateNotification,
      lowContrast: true,
      title: notification.title,
      message: notification.message
    }"></ibm-notification>

<ng-template #templateNotification
  let-data>
  <div>
    <div class="d-flex flex-column">
      <h3 ibmToastTitle
        class="mt-0">{{data.title}}</h3>
      <p ibmToastCaption
        class="mb-0 pt-2 text-01">{{data.message}}</p>
    </div>
    <button *ngIf="showUpgradeButton"
      [routerLink]="['/', 'subscription', 'plans']"
      ibmButton="primary"
      size="md"
      class="mt-4">Upgrade</button>
  </div>
</ng-template>