import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError, TimeoutError, timer } from 'rxjs';
import { timeout, retryWhen, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { isCallingApi } from '@bitf/utils';
import { ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(private toastMessagesService: ToastMessagesService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isCallingApi(environment, req) || !environment.httpRetryConfig.enabled) {
      return next.handle(req);
    }

    const config = environment.httpRetryConfig.verbs[req.method.toLowerCase()] || null;

    if (!config || !config.enabled) {
      return next.handle(req);
    }

    let retryAttempt = 0;
    return next.handle(req).pipe(
      timeout(config.timeout * (1 + 0.2 * retryAttempt)),
      retryWhen(
        (obs: Observable<Error>): Observable<number> =>
          obs.pipe(
            mergeMap((error, i) => {
              retryAttempt = i + 1;
              const shouldRetryForStatus =
                error instanceof HttpErrorResponse && config.retryForStatusesCodes.includes(error.status);
              const shouldRetryAgain = retryAttempt <= config.maxRetries;
              const isTimeoutError = error instanceof TimeoutError;
              const shouldRetryOnTimeout = isTimeoutError && config.timeout;
              if (shouldRetryAgain && (shouldRetryForStatus || shouldRetryOnTimeout)) {
                // NOTE: wait 1sec + incremental shift before to do another call
                return timer(1000 * (1 + 0.2 * (retryAttempt - 1)));
              }
              this.toastMessagesService.show({
                title: 'HTTP Error',
                message: `Request timeout`,
                type: EToastMessageType.ERROR,
              });
              return throwError(error);
            })
          )
      )
    );
  }
}
