import { Component, Inject, OnInit } from '@angular/core';

import { BaseModal } from 'carbon-components-angular';

import { AiModel } from '@app/core/models';
import { FormControl, Validators } from '@angular/forms';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';

@Component({
  selector: 'ibm-ai-model-selection-dialog',
  templateUrl: './ai-model-selection-dialog.component.html',
  styleUrls: ['./ai-model-selection-dialog.component.scss'],
})
export class AIModelSelectionDialogComponent extends BaseModal implements OnInit {
  aiModelFromControl = new FormControl(null, [Validators.required]);
  aiModels: {
    content: string;
    model: AiModel;
    selected: boolean;
  }[] = [];

  constructor(
    @Inject('dialogData')
    public dialogData: { title: string; description: string; actionLabel: string; aiModels: AiModel[] }
  ) {
    super();
  }

  ngOnInit(): void {
    this.mapAiModels();
  }

  onSelectAIModel() {
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
      data: this.aiModelFromControl.value,
    } as IDialogCloseEvent<AiModel>);
  }

  private mapAiModels() {
    this.aiModels = [];
    if (this.dialogData.aiModels) {
      this.aiModels = this.dialogData.aiModels.map((model: AiModel) => {
        const item = {
          content: model.name,
          model,
          selected: model.isDefault,
        };

        if (model.isDefault) {
          this.aiModelFromControl.setValue(model);
        }

        return item;
      });
    }
  }
}
