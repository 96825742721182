import { SuperModel } from '@models/super-model.model';
import { Project } from '@models/project.model';
import { Group } from './group.model';
import { Country } from './country.model';
import { EPlanType } from './plan.model';
export class User extends SuperModel {
  role: UserRole;
  email: string;
  password?: string;
  firstName: string;
  lastName: string;
  fullName: string;
  company?: string;
  provider?: string;
  projects?: Project[];
  picture?: string;
  blocked?: boolean;
  apiKey?: string;
  isApiKeyLimitReached?: boolean;
  hasApiKeyCallsLimitation?: boolean;
  apiKeyCallsCount?: number;
  maxFreeApiKeyCalls?: number;
  roboRxnApiKey?: string;
  roboRxnEnabled: boolean;
  roboRxnApiKeyType: ERoboRxnApiKeyType;
  canUpgradePlan?: boolean;
  subscriptionInProgress?: boolean;
  planType: EPlanType;
  planDemoted?: boolean;
  migratedUser?: boolean;
  termsAccepted: string | Date;
  country: Country;
  canUseModelTuner?: boolean;

  lastLog?: string | Date;
  totalLogs?: number;
  totalProjects?: number;
  totalReactions?: number;
  totalFeedbecks?: number;
  subscriptionGroupId?: string;
  // avatar?: string;
  group: Group;
  walletId: string;

  constructor(user: Partial<User> = {}) {
    super(user);

    if (this.projects) {
      this.projects.map(project => new Project(project));
    }

    this.group = new Group(this.group || {});
  }

  get serialised() {
    return {
      email: this.email,
      password: this.password,
      name: this.firstName,
      surname: this.lastName,
      projects: this.projects && this.projects.map(project => project.serialised),
      picture: this.picture,
      apiKey: this.apiKey,
      country: this.country?.serialised,
    };
  }

  get groupName() {
    return this.group.name;
  }

  get isAdmin(): boolean {
    return this.role === UserRole.ADMIN;
  }

  get hasFreePlan(): boolean {
    return this.planType === EPlanType.FREE;
  }

  get hasNoLimitFileUpload(): boolean {
    return [EPlanType.PAYGO].includes(this.planType);
  }

  get isTeamOwner(): boolean {
    return this.planType === EPlanType.TEAM;
  }

  get isTeamMember(): boolean {
    return this.subscriptionGroupId !== null;
  }

  get isTerms(): boolean {
    return this.termsAccepted !== null;
  }

  get isBlocked(): boolean {
    return this.blocked;
  }

  get hasPayGoPlan(): boolean {
    return this.planType === EPlanType.PAYGO;
  }

  get hasSelectedCountry(): boolean {
    return (
      !!this.country &&
      !!this.country.countryCode2 &&
      !!this.country.countryCode3 &&
      !!this.country.countryName
    );
  }
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum ERoboRxnApiKeyType {
  ROBOT = 'robot',
  SIMULATOR = 'simulator',
}
