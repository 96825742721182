<ibm-modal [open]="open"
  [hasScrollingContent]="false">
  <ibm-modal-header [showCloseButton]="false">
    <h3 ibmModalHeaderHeading>Select country</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <ng-container [formGroup]="countryForm">
      <ibm-label> Choose between available countries</ibm-label>
      <ibm-combo-box *ngIf="!isLoading"
        [theme]="'light'"
        [appendInline]="false"
        [invalid]="countryForm.get('country').touched && !countryForm.get('country').valid"
        invalidText="Country is required"
        formControlName="country"
        [items]="countries"
        [autocomplete]="false"
        itemValueKey="code"
        [dropUp]="false">
        <ibm-dropdown-list></ibm-dropdown-list>
      </ibm-combo-box>
      <ibm-dropdown *ngIf="isLoading"
        [skeleton]="true"></ibm-dropdown>
    </ng-container>
  </div>
  <ibm-modal-footer>
    <button ibmButton="primary"
      [disabled]="!countryForm.valid"
      (click)="onSaveCountry()">
      Save
    </button>
  </ibm-modal-footer>
</ibm-modal>