import { Component, Input, OnInit } from '@angular/core';
import { ERetrosynthesisTypology, Synthesis } from '@app/core/models';
@Component({
  selector: 'ibm-synthesis-retrosynthesis-information',
  templateUrl: './synthesis-retrosynthesis-information.component.html',
  styleUrls: ['./synthesis-retrosynthesis-information.component.scss'],
})
export class SynthesisRetrosynthesisInformationComponent implements OnInit {
  @Input() synthesis: Synthesis;
  eRetrosynthesisTypology = ERetrosynthesisTypology;

  constructor() {}

  ngOnInit(): void {}
}
