import { SuperModel } from '@models/super-model.model';
import { RetrosynthesisMolecule } from './retrosynthesis-molecule.model';
import { EFeedback } from '@shared/enums/feedback.enum';
import { svgToUrl, ISvgToUrl } from '@app/shared/common-utilities/svg-to-url';
import { User } from './user.model';

export class RetrosynthesisSequence extends SuperModel {
  label?: string;
  confidence?: number;
  confidenceTag: string;
  isConfidenceComputed?: boolean;
  metaData?: any;
  isSaved: boolean;
  tree: RetrosynthesisMolecule;
  feedback: IFeedback;
  optimizationScore?: number | string;
  complexityGraph: string;
  canCreateSynthesis?: boolean;
  currentSynthesisId?: string;
  name?: string;
  isDeletable: boolean;
  isRenameable: boolean;
  isFavorite: boolean;
  isAiModelDeleted: boolean;
  canAddToCollection: boolean;
  aiModel: string;
  user?: User;
  isShareable: boolean;
  canShare: boolean;
  productMolecule: RetrosynthesisMolecule;
  outcomeMolecules: Array<RetrosynthesisMolecule>;
  isInteractive?: boolean;
  isFromFile?: boolean;
  retrosynthesisId: string;
  steps: number;
  reactionSmiles: string;
  hasSynthesisProcedures: boolean;
  datasetName: string;

  constructor(data: any = {}) {
    super(data);

    if (data.tree) {
      this.tree = new RetrosynthesisMolecule(data.tree);
    }

    if (data.productMolecule) {
      this.productMolecule = new RetrosynthesisMolecule(data.productMolecule);
    }

    if (data.outcomeMolecules && data.outcomeMolecules.length) {
      this.outcomeMolecules = data.outcomeMolecules.map(molecule => new RetrosynthesisMolecule(molecule));
    }
    if (data.user) {
      this.user = new User(data.user);
    }
  }

  get complexityGraphSvg(): ISvgToUrl {
    return this.complexityGraph && svgToUrl(this.complexityGraph);
  }

  get serialised() {
    return {};
  }

  get creatorName() {
    if (this.user && typeof this.user === 'object') {
      return this.user.fullName;
    }
    return this.createdBy;
  }
}

export interface IFeedback {
  opinion: EFeedback;
  message?: string;
}

export enum EConfidenceTagName {
  L = 'Low',
  M = 'Medium',
  H = 'High',
}
export enum EConfidenceTagColor {
  L = '#fa4d56',
  M = '#f1c21b',
  H = '#a7f0ba',
}

export enum EConfidenceTagTextColor {
  L = '#ffffff',
  M = '#161616',
  H = '#044317',
}
