<div class="smiles-editor-textual">
  <span class="smiles-editor-textual__title my-6">
    Write your smiles string, validate and load into the
    visual editor to proceed with the retrosynthesis
  </span>

  <div class="smiles-editor-textual__form d-flex">
    <form class="flex-grow-1"
      [formGroup]="smilesForm">
      <textarea ibmTextArea
        class="smiles-editor-textual__form__textarea"
        formControlName="smiles"></textarea>
    </form>
  </div>
  <div class="smiles-editor-textual__buttons">
    <button ibmButton="ghost"
      class="bg-white"
      size="lg"
      [disabled]="smilesForm.invalid"
      [skeleton]="isValidating"
      (click)="validateSmiles()">
      Validate string smiles
    </button>

    <button ibmButton="primary"
      size="lg"
      [disabled]="smilesForm.invalid"
      [skeleton]="isValidating"
      (click)="loadInKetcher()">
      Load in visual editor
    </button>
  </div>
</div>