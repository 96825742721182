import { SuperModel } from '@models/super-model.model';
import { User } from '@models/user.model';

export class Collaborator extends SuperModel {
  user: User;
  totalProjects: number;

  constructor(collaborator = {}) {
    super(collaborator);

    if (this.user) {
      this.user = new User(this.user);
    }
  }

  get serialised() {
    return {
      id: this.id,
      user: this.user && this.user.serialised,
    };
  }
}
