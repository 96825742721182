<div class="d-inline">
  <button #button
    ibmButton="ghost"
    (click)="onTogglePanel()"
    [ngClass]="{'btn-active': showPanel}"
    [iconOnly]="true"
    [attr.aria-expanded]="showPanel"
    [attr.data-e2e]="e2eButtonData"
    [title]="buttonTitle">
    <ng-content select="[icon]"></ng-content>
  </button>
  <div class="panel-container p-5"
    [ngStyle]="{
    'left': panelPosition.left, 
    'top': panelPosition.top, 
    'width': panelWidth + 'px', 
    'height': 'calc(100vh - ' + panelPosition.top +'',
    'max-height': 'calc(100vh - ' + panelPosition.top +''
  }"
    [ngClass]="{ 'panel-container__invisible': !showPanel }">
    <ng-content select="[content]"></ng-content>
  </div>
</div>