import { Injectable, Injector } from '@angular/core';
import { Subscription, SubscriptionCost } from '@app/core/models';
import { ApiSuperService, IApiAction, IApiRequest, IApiResponse } from '@app/libs/bitforce/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService extends ApiSuperService {
  // mockApiUrl = 'https://b8d18e18-66eb-481b-a04d-259bcd048385.mock.pstmn.io/api/v1/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'subscriptions';
  }

  cost(planId: string): Observable<IApiResponse<SubscriptionCost>> {
    return this.request({
      method: 'POST',
      path: `cost`,
      body: {
        planId,
      },
      modelMapper: 'subscription-cost',
      isBodyRaw: true,
    });
  }

  currentSubscription(): Observable<IApiResponse<Subscription>> {
    return this.request({
      method: 'GET',
    });
  }

  deleteCurrentSubscription(): Observable<IApiResponse<IApiAction>> {
    return this.request({
      method: 'DELETE',
    });
  }
}
