import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AiModel, AiModelCategory } from '@app/core/models';
import { ApiSuperService, IApiResponse } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class AiModelsService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'ai-models';
  }

  getAiModelsForRetrosynthesis({
    projectId,
    categoryName,
  }: {
    projectId?: string;
    categoryName?: string;
  }): Observable<IApiResponse<{ models: AiModel[] }>> {
    const queryParams = {
      ...(categoryName && { categoryName }),
      ...(projectId && { projectId }),
    };

    return this.request<{ models: AiModel[] }>({
      method: 'GET',
      path: 'retrosynthesis',
      queryParams,
    });
  }

  getAiModelsForPredictions(projectId?: string): Observable<IApiResponse<{ models: AiModel[] }>> {
    const queryParams = {};
    if (projectId) {
      queryParams['projectId'] = projectId;
    }
    return this.request<{ models: AiModel[] }>({
      method: 'GET',
      path: 'reaction',
      queryParams,
    });
  }

  getAiModelsForSynthesisFromSequence(projectId?: string) {
    const queryParams = {};
    if (projectId) {
      queryParams['projectId'] = projectId;
    }
    return this.request<{ models: AiModel[] }>({
      method: 'GET',
      path: 'smiles2actions',
      queryParams,
    });
  }

  getAiModelsForSynthesisFromText(projectId?: string) {
    const queryParams = {};
    if (projectId) {
      queryParams['projectId'] = projectId;
    }
    return this.request<{ models: AiModel[] }>({
      method: 'GET',
      path: 'paragraph2actions',
      queryParams,
    });
  }

  getAiModelsForPropertyPrediction(projectId?: string) {
    const queryParams = {};
    if (projectId) {
      queryParams['projectId'] = projectId;
    }
    return this.request<{ models: AiModel[] }>({
      method: 'GET',
      path: 'reaction-property-predictor',
      queryParams,
    });
  }

  getAiModelsForReactionCompletion(projectId?: string): Observable<IApiResponse<{ models: AiModel[] }>> {
    const queryParams = {};
    if (projectId) {
      queryParams['projectId'] = projectId;
    }
    return this.request<{ models: AiModel[] }>({
      method: 'GET',
      path: 'reaction-completion',
      queryParams,
    });
  }

  getAiModelCategoriesForRetrosynthesis(): Observable<IApiResponse<AiModelCategory[]>> {
    return this.request<AiModelCategory[]>({
      method: 'GET',
      path: 'retrosynthesis/categories',
      modelMapper: 'ai-model-categories',
    });
  }
}
