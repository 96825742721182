import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { ApiService, EventTrackingService } from '@services';
@Component({
  selector: 'ibm-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private apiService: ApiService, private eventTrackingService: EventTrackingService) {}

  ngOnInit() {
    this.eventTrackingService.trackLoginLogout('Service Logout');
    this.apiService.auth.logout();
  }
}
