import { SuperModel } from './super-model.model';
import { User } from './user.model';

export class SynthesisAnnotation extends SuperModel {
  title?: string;
  description: string;
  step?: string;
  createdBy: User;
  canUpdateAnnotation: boolean;
  canDeleteAnnotation: boolean;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {
      title: this.title,
      description: this.description,
      step: this.step,
    };
  }
}
