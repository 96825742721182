<ibm-modal class="message-after-login"
  [open]="open">
  <ibm-modal-header [showCloseButton]=false>
    <h3 ibmModalHeaderHeading>User Account Approval In Progress</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <div class="mt-5">
      Your account is currently under review. You will be notified when you are able to access RXN.
    </div>
  </div>
  <ibm-modal-footer>
    <button [routerLink]="['/', 'logout']"
      (click)="closeModal()"
      ibmButton="primary"
      size="md">Logout</button>
  </ibm-modal-footer>
</ibm-modal>