import { Injectable, Injector } from '@angular/core';
import { IAddressLookupResponse, IAddressFormat } from '@app/core/models';
import { ApiSuperService, IApiRequest, IApiResponse } from '@app/libs/bitforce/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressesService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'addresses';
  }

  validate(searchString: string): Observable<IApiResponse<IAddressLookupResponse>> {
    return this.request({
      method: 'POST',
      path: `validate`,
      body: { country: this.authService.user.country.countryCode3, searchString },
      isBodyRaw: true,
      modelMapper: 'address-lookup',
    });
  }

  format(formatUrl: string): Observable<IApiResponse<IAddressFormat>> {
    return this.request({
      method: 'POST',
      path: `format`,
      body: { formatUrl },
      isBodyRaw: true,
      modelMapper: 'address-format',
    });
  }
}
