import { Observable } from 'rxjs/Observable';

export function imageSizeValidator(
  image: Blob | File,
  imageSizeRules: IImageSizeValidation
): Observable<IFileValidationResult> {
  const obs = Observable.create(observer => {
    const img = new Image();
    img.onload = () => {
      let result;
      if (imageSizeRules.height < img.height || imageSizeRules.width < img.width) {
        result = {
          isValid: false,
          errors: [
            `Image height and width must not exceed ${imageSizeRules.height}px x ${imageSizeRules.width}px.`,
          ],
        } as IFileValidationResult;
      } else {
        result = { isValid: true, errors: [] } as IFileValidationResult;
      }
      observer.next(result);
    };
    img.src = URL.createObjectURL(image);
  });
  return obs;
}

export function inputFileValidator(data: IFileValidationRules): IFileValidationResult {
  const errors: string[] = [];

  if (!data.extensions) {
    const error = 'You have to provide accepted file extensions';
    errors.push(error);
  }

  if (!data.files || !data.files.length) {
    const error = 'No file has been selected';
    errors.push(error);
  }

  data.files.forEach(f => {
    const fileExt = f.name.split('.').pop();
    if (!data.extensions.includes(fileExt)) {
      const error = `File: ${f.name} - extension ${fileExt} is not accepted`;
      errors.push(error);
    }
  });

  if (data.maxFileSize) {
    data.files.forEach(f => {
      if (data.maxFileSize < f.size) {
        const error = `File: ${f.name} - size is too big`;
        errors.push(error);
      }
    });
  }

  if (data.maxNumberOfFiles && data.maxNumberOfFiles < data.files.length) {
    const error = `Number of allowed files equals ${data.maxNumberOfFiles}`;
    errors.push(error);
  }

  return { isValid: errors.length === 0, errors } as IFileValidationResult;
}

export const bitfPromptToDownloadFile = (
  fileContent: ArrayBuffer | string,
  fileType: string,
  fileExtension: string,
  fileName?: string
) => {
  const downloadFile = new Blob([fileContent], { type: fileType });
  const objectUrl = URL.createObjectURL(downloadFile);

  const a = document.createElement('a');
  a.href = objectUrl;
  if (fileName) {
    a.download = `${fileName}.${fileExtension}`;
  }
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export interface IFileValidationRules {
  files: File[];
  extensions: string[];
  maxFileSize?: number;
  maxNumberOfFiles?: number;
  required?: boolean;
}

export interface IImageSizeValidation {
  width: number;
  height: number;
}

export interface IFileValidationResult {
  isValid: boolean;
  errors: string[];
}
