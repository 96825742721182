<div class="align align-right w-100 h-100 imb-search-bar">
  <div class="imb-search-bar__dropdown-container"
    *ngIf="showSmilesOption">
    <ibm-dropdown (selected)="onSearchTypeSelected()"
      [appendInline]="false"
      itemValueKey="id"
      [(ngModel)]="searchType">
      <ibm-dropdown-list [items]="searchTypes"></ibm-dropdown-list>
    </ibm-dropdown>
  </div>
  <ibm-search autocomplete="off"
    [placeholder]="searchType === eSearchType.SMILES ? 'Search by smiles' : placeholder"
    [value]="searchString"
    (valueChange)="onSearch($event)"
    (clear)="onSearch($event)">
  </ibm-search>

  <div class="imb-search-bar__draw-search px-5"
    *ngIf="showKetcherButton"
    (click)="onOpenKetcherFilter()">
    Draw and search <svg class="bx--btn__icon"
      ibmIconEdit
      size="16"></svg>
  </div>
</div>