import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionCost, User, UserBillingInformation } from '@app/core/models';
import { nanoid } from 'nanoid';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { BaseModal } from 'carbon-components-angular';
import PaymentService from '@mpcheckout/payment-iframe';

import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { environment } from '@env/environment';

import { ToastMessagesService } from '@app/core/services/toast-messages/toast-messages.service';

import { PaymentsService } from '@app/core/services/api/payments.service';
import { UsersService } from '@app/core/services/api/users.service';
import { UserBillingInformationService } from '@app/core/services/api/user-billing-informations.service';
import { AuthService } from '@bitf/services/auth/auth.service';
import { of } from 'rxjs';

@Component({
  selector: 'ibm-payment-method-dialog',
  templateUrl: './payment-method-dialog.component.html',
  styleUrls: ['./payment-method-dialog.component.scss'],
})
export class PaymentMethodDialogComponent extends BaseModal implements OnInit, OnDestroy {
  dialogData: {
    subscriptionCost: SubscriptionCost;
    currencyCode: string;
    isRetry?: boolean;
    isWalletManagementSession: boolean;
  };
  paymentService: PaymentService;
  isLoading = false;
  hasError = false;
  userBillingInformationService: UserBillingInformationService;
  usersServices: UsersService;
  paymentsService: PaymentsService;
  authService: AuthService;
  toastMessagesService: ToastMessagesService;

  constructor(private injector: Injector) {
    super();
    this.dialogData = this.injector.get('dialogData');
    this.toastMessagesService = this.injector.get(ToastMessagesService);

    this.authService = this.injector.get(AuthService);
    this.usersServices = this.injector.get(UsersService);
    this.paymentsService = this.injector.get(PaymentsService);
    this.userBillingInformationService = this.injector.get(UserBillingInformationService);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.userBillingInformationService
      .get<UserBillingInformation>()
      .pipe(map(data => data.content?.[0]))
      .subscribe(data => {
        this.initPaymentService(data);
      });
  }

  private initPaymentService(userBillingInformation: UserBillingInformation) {
    const user = new User(this.authService.user);
    this.paymentService = new PaymentService('payment-section', 'payment-section', {
      startSession: {
        url: `${environment.apiUrl}payments/${this.dialogData.isRetry ? 'retry-payment' : 'start-session'}`,
        requestHeader: {
          Authorization: `Bearer ${this.authService.authToken}`,
        },
        payload: {
          cssUrl: `${environment.hostUrl}/assets/payment-service/style.css`,
          language: 'en',
          countryCode: userBillingInformation.countryCode2,
          currencyCode: this.dialogData.currencyCode,
          orderId: nanoid(20),
          customer: {
            type: 'Private',
            firstName: userBillingInformation.firstName,
            lastName: userBillingInformation.lastName,
            addressLine1: userBillingInformation.addressLine1,
            addressLine2: userBillingInformation.addressLine2,
            city: userBillingInformation.locality,
            countryCode: userBillingInformation.countryCode2,
            postalCode: userBillingInformation.postalCode,
            stateCode: userBillingInformation.province,
            phone: userBillingInformation.phone,
            email: userBillingInformation.email,
          },
          orderAmounts: {
            amount: this.dialogData.subscriptionCost?.plan.price,
            tax: this.dialogData.subscriptionCost?.tax.price,
            total: this.dialogData.subscriptionCost?.total.price,
          },
          isRecurringPayment: true,
          walletid: {
            id: `rxn-${this.authService.user.id}`,
            type: 'id',
          },
          autoSubmit: this.dialogData.isRetry,
          isWalletManagementSession: this.dialogData.isWalletManagementSession ?? false,
        },
      },
      create: {
        autoSubmit: true,
      },
      fail: {
        callback: error => {
          this.isLoading = false;
          this.hasError = true;
          this.toastMessagesService.show({
            title: 'Payment Method',
            message: 'Error on payment method',
            type: EToastMessageType.ERROR,
          });
        },
      },
      cancel: {
        callback: () => {
          this.isLoading = true;
          this.onClose();
        },
      },
      complete: {
        callback: result => {
          console.log('result', result);
          of(null)
            .pipe(
              tap(() => (this.isLoading = true)),
              switchMap(() => {
                if (user.walletId) {
                  return this.paymentsService.put({
                    path: 'update',
                    isBodyRaw: true,
                    body: {
                      cardBrand: result.paymentDetails.instrument.cardBrand,
                      authorizationReferenceNumber: result.paymentDetails.authorizationReferenceNumber,
                      authorizationReferenceNumberExtended:
                        result.paymentDetails.authorizationReferenceNumberExtended,
                    },
                  });
                } else {
                  return of(null);
                }
              }),
              switchMap(() => {
                if (this.dialogData.isRetry) {
                  return this.paymentsService.post({
                    path: 'completed',
                    isBodyRaw: true,
                    body: {
                      authorizationReferenceNumber: result?.paymentDetails.authorizationReferenceNumber,
                    },
                  });
                } else {
                  return of(null);
                }
              }),
              switchMap(() =>
                this.usersServices.patch<User>({
                  body: {
                    id: user.id,
                    walletId: result.walletid,
                  },
                  isBodyRaw: true,
                })
              ),
              finalize(() => (this.isLoading = false))
            )
            .subscribe(() =>
              this.close.emit({
                status: EDialogCloseEventStatus.OK,
                data: {
                  cardBrand: result.paymentDetails.instrument.cardBrand,
                  authorizationReferenceNumber: result.paymentDetails.authorizationReferenceNumber,
                  authorizationReferenceNumberExtended:
                    result.paymentDetails.authorizationReferenceNumberExtended,
                },
              } as IDialogCloseEvent<any>)
            );
        },
      },
      change: {
        callback: () => {
          this.isLoading = false;
        },
      },
      timeout: {
        callback: () => {
          this.isLoading = false;
          this.toastMessagesService.show({
            title: 'Payment Method',
            message: 'Timeout on payment method',
            type: EToastMessageType.ERROR,
          });
        },
      },
      autoResize: false,
    });
    this.isLoading = true;
    this.paymentService.init();
  }

  onClose() {
    this.close.emit({
      status: EDialogCloseEventStatus.CLOSE,
    } as IDialogCloseEvent<void>);
  }

  ngOnDestroy(): void {
    if (this.paymentService) {
      this.paymentService.destroy();
    }
  }
}
