import { SuperModel } from '@models/super-model.model';
import { Reaction } from './reaction.model';

export class Challenge extends SuperModel {
  name: string;
  source: string;
  challengeReaction: Reaction;
  groundTruthReaction?: Reaction;

  constructor(challenge = {}) {
    super(challenge);
    if (this.challengeReaction) {
      this.challengeReaction = new Reaction(this.challengeReaction);
    }
    if (this.groundTruthReaction) {
      this.groundTruthReaction = new Reaction(this.groundTruthReaction);
    }
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}
