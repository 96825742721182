import { Injectable, Injector } from '@angular/core';
import { ApiSuperService } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class OpticalChemicalRecognitionService extends ApiSuperService {
  // mockApiUrl = 'http://localhost:3001/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'optical-chemical-recognition';
  }
}
