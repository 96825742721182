import { SuperModel } from './super-model.model';

export class UserBillingInformation extends SuperModel {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  locality: string;
  province: string;
  postalCode: string;
  countryName: string;
  countryCode2: string;
  countryCode3: string;
  phone: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  constructor(data: any = {}) {
    super(data);
  }

  get fullAddress() {
    return `${this.addressLine1} ${this.addressLine2} ${this.addressLine3}\n ${this.locality} ${
      this.locality ? ',' : ''
    } ${this.province} ${this.postalCode}\n ${this.countryName}`
      .replace(/  +/g, ' ')
      .replace(/ ,/g, ',');
  }

  get serialised() {
    return {
      id: this.id,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      addressLine3: this.addressLine3,
      locality: this.locality,
      province: this.province,
      postalCode: this.postalCode,
      countryName: this.countryName,
      countryCode2: this.countryCode2,
      countryCode3: this.countryCode3,
      phone: this.phone,
      email: this.email,
      fullName: this.fullName,
    };
  }
}
