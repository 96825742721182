<ibm-modal [open]="open"
  [hasScrollingContent]="false">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>Plan a synthesis</h3>
    <span></span>
  </ibm-modal-header>

  <div ibmModalContent
    class="ibm-create-synthesis">
    <form (ngSubmit)="save()"
      [formGroup]="form">
      <ibm-select-or-create-project #projectSelect
        toAddLabel="synthesis"
        [form]="form"
        [projects]="projects"
        [selectedProject]="dialogData?.project"></ibm-select-or-create-project>
      <fieldset class="bx--fieldset mt-6"
        *ngIf="form.value.projectGroup?.selectedProject || form.value.projectGroup?.projectName">
        <legend class="bx--label">Start From</legend>
        <ibm-radio-group formControlName="startFrom"
          class="mt-2">
          <ibm-radio [value]="EXPERIMENTAL_PROCEDURE">
            Experimental procedure
          </ibm-radio>
          <ibm-radio [value]="RETROSYNTHESIS">
            Retrosynthesis
          </ibm-radio>
        </ibm-radio-group>
      </fieldset>

      <ng-container *ngIf="form.value.startFrom === RETROSYNTHESIS">
        <legend class="bx--label">Source</legend>
        <ibm-radio-group formControlName="retroStartFrom"
          class="mt-2">
          <ibm-radio [value]="FROM_EXISTING_RETROSYNTHESIS">
            Existing
          </ibm-radio>
          <ibm-radio [value]="FROM_RETROSYNTHESIS_FILE">
            From file
          </ibm-radio>
        </ibm-radio-group>

        <ng-container *ngIf="form.value.retroStartFrom === FROM_EXISTING_RETROSYNTHESIS">
          <fieldset *ngIf="fromProjects?.length > 0 || isFromProjectLoading"
            class="mt-6">
            <ibm-dropdown [skeleton]="true"
              [label]="'From which project'"
              *ngIf="isFromProjectLoading">
            </ibm-dropdown>
            <ibm-combo-box *ngIf="fromProjects"
              [theme]="'light'"
              [label]="'From which project'"
              [invalid]="form.get('fromProject').touched && !form.get('fromProject').valid"
              invalidText="Project is required"
              [appendInline]="false"
              formControlName="fromProject"
              [items]="fromProjects"
              [autocomplete]="false"
              [dropUp]="false">
              <ibm-dropdown-list></ibm-dropdown-list>
            </ibm-combo-box>
          </fieldset>

          <fieldset class="mt-6"
            *ngIf="(form.value.fromProject !== null && retrosynthesis?.length > 0) || isRetrosynthesisListLoading">
            <ibm-dropdown [skeleton]="true"
              [label]="'Select Retrosynthetic Route'"
              *ngIf="isRetrosynthesisListLoading">
            </ibm-dropdown>

            <ibm-combo-box *ngIf="retrosynthesis"
              [theme]="'light'"
              [label]="'Select Retrosynthetic Route'"
              [invalid]="form.get('selectedRetrosynthesis').touched && !form.get('selectedRetrosynthesis').valid"
              invalidText="Retrosynthetic Route is required"
              [appendInline]="false"
              formControlName="selectedRetrosynthesis"
              [items]="retrosynthesis"
              [autocomplete]="false"
              [dropUp]="false">>
              <ibm-dropdown-list></ibm-dropdown-list>
            </ibm-combo-box>
          </fieldset>


          <div *ngIf="retrosynthesis && retrosynthesis?.length === 0"
            class="mt-4">
            <ibm-icon-warning-alt-filled size="16"></ibm-icon-warning-alt-filled>
            It seems you did not create any retrosynthesis in the selected project.
          </div>

          <fieldset class="mt-6"
            *ngIf="(form.value.selectedRetrosynthesis !== null && sequences?.length > 0) ||isSequencesListLoading ">
            <ibm-dropdown [skeleton]="true"
              [label]="'Select a sequence of the retrosynthetic route'"
              *ngIf="isSequencesListLoading">
            </ibm-dropdown>

            <ibm-combo-box *ngIf="sequences"
              [theme]="'light'"
              [label]="'Select a sequence of the retrosynthetic route'"
              [invalid]="form.get('selectedSequence').touched && !form.get('selectedSequence').valid"
              invalidText="Sequence is required"
              [appendInline]="false"
              formControlName="selectedSequence"
              [items]="sequences"
              [autocomplete]="false"
              [dropUp]="false">>
              <ibm-dropdown-list></ibm-dropdown-list>
            </ibm-combo-box>
          </fieldset>

          <ng-container *ngIf="form.value.selectedSequence !== null">
            <ng-container [ngTemplateOutlet]="aiModelsFormField"
              [ngTemplateOutletContext]="{form:form, isAiModelsLoading: isAiModelsLoading, aiModels: aiModels}">
            </ng-container>
          </ng-container>

          <div *ngIf="sequences && sequences?.length === 0"
            class="mt-4">
            <ibm-icon-warning-alt-filled size="16"></ibm-icon-warning-alt-filled>
            There aren't available sequences in this retrosynthesis
          </div>
        </ng-container>

        <ng-container *ngIf="form.value.retroStartFrom === FROM_RETROSYNTHESIS_FILE">
          <ng-container [ngTemplateOutlet]="aiModelsFormField"
            [ngTemplateOutletContext]="{form:form, isAiModelsLoading: isAiModelsLoading, aiModels: aiModels}">
          </ng-container>

          <div class="mt-6"
            *ngIf="form.value.selectedAiModel !== null">
            <h6 class="mb-2">Upload retrosynthetic data</h6>
            <p class="font-caption-02 mb-4">
              You can upload text files containing one reaction SMILES per line ({{ maxFileSizeString }} max
              size).<br />
              <a (click)="onDownloadExampleFile()"
                title="Download file">Download an example file</a>
            </p>
            <ibm-mlrp-file-uploader [dropText]="'Drag and Drop files here or upload'"
              [maxFileSize]="uploadFileMaxSize"
              [multiple]="false"
              [acceptedExtensions]="['.txt']"
              (selectedFiles)="onSelectedFiles($event)"></ibm-mlrp-file-uploader>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #aiModelsFormField
        let-form='form'
        let-isAiModelsLoading='isAiModelsLoading'
        let-aiModels='aiModels'>
        <fieldset class="mt-6"
          *ngIf="aiModels?.length > 0 || isAiModelsLoading ">
          <ibm-dropdown [skeleton]="true"
            [label]="'Choose AI model'"
            *ngIf="isAiModelsLoading">
          </ibm-dropdown>

          <ibm-combo-box *ngIf="aiModels"
            [theme]="'light'"
            [label]="'Choose AI model'"
            [invalid]="form.get('selectedAiModel').touched && !form.get('selectedAiModel').valid"
            invalidText="Ai model is required"
            [appendInline]="false"
            formControlName="selectedAiModel"
            [items]="aiModels"
            [autocomplete]="false"
            [dropUp]="false">
            <ibm-dropdown-list></ibm-dropdown-list>
          </ibm-combo-box>
        </fieldset>
      </ng-template>
    </form>
  </div>

  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="closeModal()">Close</button>
    <button ibmButton="primary"
      (click)="save()"
      [disabled]="!form.valid">
      Plan a synthesis
    </button>
  </ibm-modal-footer>
</ibm-modal>