<ibm-modal [open]="open"
  [hasScrollingContent]="false"
  class="full-screen">
  <section class="bx--modal-content">
    <ibm-smiles-editor [smiles]="smiles"
      [actionLabel]="actionLabel"
      [maxNumberOfMolecules]="maxNumberOfMolecules"
      (action)="onConfirmSmiles($event)"
      (close)="closeModal()"
      [inModal]="true"></ibm-smiles-editor>
  </section>
</ibm-modal>