import { Component, Inject, OnInit } from '@angular/core';
import { ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { copyToClipboard } from '@app/shared/common-utilities/clipboard';
import { BaseModal } from 'carbon-components-angular';

@Component({
  selector: 'ibm-copy-smiles-dialog',
  templateUrl: './copy-smiles-dialog.component.html',
  styleUrls: ['./copy-smiles-dialog.component.scss'],
})
export class CopySmilesDialogComponent extends BaseModal implements OnInit {
  constructor(
    @Inject('dialogData') public dialogData: { smiles: string },
    private toastMessagesService: ToastMessagesService
  ) {
    super();
  }

  ngOnInit(): void {}

  async onCopySmiles() {
    const copied = await copyToClipboard(this.dialogData.smiles);
    this.closeModal();

    if (copied) {
      this.toastMessagesService.show({
        title: 'Info',
        message: 'Smiles copied to clipboard',
        type: EToastMessageType.SUCCESS,
      });
    } else {
      this.toastMessagesService.show({
        title: 'Error',
        message: `Couldn't copy smiles to clipboard`,
        type: EToastMessageType.ERROR,
      });
    }
  }
}
