import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IApiRequest, IApiResponse, IApiAction } from '@bitf/api/api.interfaces';
import { ApiSuperService } from '@bitf/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class GenericService extends ApiSuperService {
  // mockApiUrl = 'https://localhost:3002/';
  constructor(injector: Injector) {
    super(injector);
    this.name = '';
  }

  callAction(request: IApiRequest): Observable<IApiResponse<IApiAction>> {
    request.path = `${this.apiUrl}${this.name}${request.path}`;
    return this.fetch(request).pipe(
      map(envelope => this.parseEnvelope<IApiAction>(envelope, { ...request, modelMapper: 'action' }))
    );
  }
}
