<ibm-sidenav [expanded]="sidenavActive"
  [hidden]="!sidenavActive"
  #mainSidenav>
  <ibm-sidenav-item [route]="['admin', 'dashboard']"
    title="Dashboard"
    [active]="router.isActive(router.createUrlTree(['admin', 'dashboard']).toString(), true)">
    <svg ibmIconDashboard
      size="16"></svg>
    Admin dashboard
  </ibm-sidenav-item>
  <ibm-sidenav-item [route]="['admin', 'roborxn']"
    title=" Synthesis executions history"
    [active]="router.isActive(router.createUrlTree(['admin', 'roborxn']).toString(), true)">
    <svg ibmIconSchematics
      size="16"></svg>
    Synthesis executions history
  </ibm-sidenav-item>
  <ibm-sidenav-item [route]="['admin', 'configurations']"
    title="Configurations"
    [active]="router.isActive(router.createUrlTree(['admin', 'configurations']).toString(), true)">
    <svg ibmIconSettings
      size="16"></svg>
    Configuration
  </ibm-sidenav-item>
  <ibm-sidenav-item [route]="['admin', 'users']"
    title="Users"
    [active]="router.isActive(router.createUrlTree(['admin', 'users']).toString(), true)">
    <svg ibmIconUserMultiple
      size="16"></svg>
    Users
  </ibm-sidenav-item>
  <ibm-sidenav-item [route]="['admin', 'groups']"
    title="Groups"
    [active]="router.isActive(router.createUrlTree(['admin', 'groups']).toString(), false)">
    <svg ibmIconEventsAlt
      size="16"></svg>
    Groups
  </ibm-sidenav-item>
  <ibm-sidenav-item [route]="['admin', 'model-tuner', 'list']"
    title="Model tuner"
    [active]="router.isActive(router.createUrlTree(['admin', 'model-tuner', 'list']).toString(), true)">
    <svg ibmIconMachineLearningModel
      size="16"></svg>
    Model tuner
  </ibm-sidenav-item>
  <ibm-sidenav-item [route]="['admin', 'plans']"
    *ngIf="environment.ecommerceEnabled"
    title="Plans"
    [active]="router.isActive(router.createUrlTree(['admin', 'plans']).toString(), true)">
    <svg ibmIconDataBase
      size="16"></svg>
    Plans
  </ibm-sidenav-item>
</ibm-sidenav>