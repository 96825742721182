import { Reaction } from '.';
import { SuperModel } from './super-model.model';

export class Tmap extends SuperModel {
  backgroundColor: TmapColor;
  vertexCoordinates: TmapCoordinates;
  edgeCoordinates: TmapCoordinates;
  colors: {
    r: number[];
    g: number[];
    b: number[];
  };
  highlightedVertices: {
    index: number;
    color: TmapColor;
    rxnSmiles: string;
  }[];
  legend: TmapLegend;
  reactions: Reaction[];

  constructor(data: any = {}) {
    super(data);

    if (data.reactions) {
      this.reactions = data.reactions.map(reaction => new Reaction(reaction));
    }
  }

  get serialised() {
    return {};
  }
}

export interface TmapLegend {
  entries: {
    [key: string]: TmapColor;
  };
}

export interface TmapColor {
  r: number;
  g: number;
  b: number;
}
interface TmapCoordinates {
  x: number[];
  y: number[];
  z: number[];
}
