<!-- the following header is here only to avoid empty space on top of the page when navigating between routes, each component overrides this one -->
<ibm-mlrp-header [hasSidenav]="appSessionService.isLeftSidenavActive"></ibm-mlrp-header>
<main ibmGrid
  class="mlrp-content bx--grid--full-width"
  [ngClass]="{ 
    'sidenav-expanded': appSessionService.isLeftSidenavActive && appSessionService.isLeftSidenavVisible,  
    'fullscreen': isFullscreen()}">
  <router-outlet></router-outlet>
</main>
<!-- user sidenav -->
<ibm-mlrp-sidenav *ngIf="!appSessionService.isAdminArea && appSessionService.isLeftSidenavActive"
  [sidenavActive]="appSessionService.isLeftSidenavVisible">
</ibm-mlrp-sidenav>

<!-- admin sidnav -->
<ibm-mlrp-admin-sidenav *ngIf="appSessionService.isAdminArea && appSessionService.isLeftSidenavActive"
  [sidenavActive]="appSessionService.isLeftSidenavVisible">
</ibm-mlrp-admin-sidenav>

<div class="tablet-overlay"
  *ngIf="appSessionService.activeBreakpoints.isTabletPortrait">
  <div class="d-flex flex-column justify-content-center align-items-center h-100">
    <img src="assets/img/home/tablet-landscape-suggestione.png" />
  </div>
</div>