import { SuperModel } from './super-model.model';
import { svgToUrl, ISvgToUrl } from '@shared/common-utilities/svg-to-url';
import { IFeedback } from '@app/core/models';
import { Molecule } from './molecule.model';

export class Reaction extends SuperModel {
  attentionWeights: string;
  confidence: number;
  isConfidenceComputed?: boolean;
  message: string;
  smiles: string;
  firstAttempt: boolean;
  name: string;
  totaltime?: string;
  mol: string;
  attemptId?: string;
  predictionId?: string;
  feedback: IFeedback;
  isFavorite: boolean;

  isDeletable: boolean;
  isRenameable: boolean;
  isThermal: boolean;
  isPhotochemical: boolean;
  isAiModelDeleted: boolean;
  canAddToCollection: boolean;
  productMolecule: Molecule;
  precursorSmiles: string;
  datasetName: string;
  precursorMolecules: Molecule[];

  fieldsToCopy: Array<string> = [
    'attentionWeights',
    'confidence',
    'message',
    'reactionImage',
    'smiles',
    'firstAttempt',
    'name',
    'predictionId',
  ];

  constructor(data: any = {}) {
    super(data);
    if (data['attemptDTO']) {
      this.fieldsToCopy.forEach((field: string) => (this[field] = data['attemptDTO'][field]));
      this.attemptId = data['attemptDTO'].id;
    }

    if (data.productMolecule) {
      this.productMolecule = new Molecule(data.productMolecule);
    }

    if (data.precursorMolecules) {
      this.precursorMolecules = data.precursorMolecules.map(
        precursorMolecules => new Molecule(precursorMolecules)
      );
    }
  }

  get attentionWeightsSvg(): ISvgToUrl {
    return svgToUrl(this.attentionWeights);
  }

  get creatorName() {
    if (typeof this.createdBy === 'object') {
      return this.createdBy.fullName;
    }
    return this.createdBy;
  }

  get serialised() {
    return {
      id: this.id,
      attentionWeights: this.attentionWeights,
      confidence: this.confidence,
      message: this.message,
      smiles: this.smiles,
    };
  }
}
