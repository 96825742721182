<ng-container [formGroup]="projectForm">
  <div class="mb-4">
    <legend class="bx--label mb-6">
      Add {{toAddLabel}} to a pre-existing project or create a brand new one
    </legend>
    <ibm-radio-group aria-label="radiogroup"
      formControlName="projectRadio">
      <ibm-radio value="radio"
        [checked]="true"
        [value]="EProjectSelectionMode.EXISTING_PROJECT">
        Add to pre-existing project
      </ibm-radio>
      <ibm-radio [value]="EProjectSelectionMode.NEW_PROJECT">
        Add to a brand new project
      </ibm-radio>
    </ibm-radio-group>
  </div>
  <div *ngIf="projectForm.value.projectRadio === EProjectSelectionMode.NEW_PROJECT">
    <ibm-label [invalid]="!projectForm.get('projectName').pristine && !projectForm.get('projectName').valid"
      [invalidText]="'Project name is required'">
      Give a name to your new project
      <input ibmText
        formControlName="projectName"
        id="projectName"
        placeholder="Project name"
        autocomplete="off" />
    </ibm-label>
  </div>
  <div *ngIf="projectForm.value.projectRadio === EProjectSelectionMode.EXISTING_PROJECT">
    <ibm-label> Choose between your projects</ibm-label>
    <ibm-combo-box *ngIf="projects"
      [theme]="'light'"
      [appendInline]="false"
      [invalid]="projectForm.get('selectedProject').touched && !projectForm.get('selectedProject').valid"
      invalidText="Project is required"
      formControlName="selectedProject"
      [items]="projects"
      [autocomplete]="false"
      [dropUp]="false">
      <ibm-dropdown-list></ibm-dropdown-list>
    </ibm-combo-box>
    <ibm-dropdown *ngIf="!projects"
      [skeleton]="true"></ibm-dropdown>
  </div>
</ng-container>