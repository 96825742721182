import { Injectable } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { ClientJS } from 'clientjs';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private locationStrategy: LocationStrategy) {}

  log(params: ILoggerParams) {
    this.addToLogMessagesPipe(this.createLogObj(params));
  }

  private createLogObj(params) {
    const route =
      this.locationStrategy instanceof PathLocationStrategy
        ? this.locationStrategy.path()
        : window.location.href;
    const fullUrl = window.location.href;

    const logObj = { ...params, environment: environment.appName, route, fullUrl };
    const clientJs = new ClientJS();
    const browserData = clientJs.getBrowserData();
    Object.assign(logObj, {
      browser: browserData.browser,
      os: browserData.os,
      device: clientJs.getDeviceType(),
      timeZone: clientJs.getTimeZone(),
    });
    return logObj;
  }

  private addToLogMessagesPipe(message) {
    const keyName = 'logMessages';
    const logMessages = localStorage.getItem(keyName);
    let logMessagesArray = [];
    if (logMessages) {
      logMessagesArray = JSON.parse(logMessages);
    }
    logMessagesArray.unshift(message);
    // Trim the array to avoid overflow
    if (logMessagesArray.length > environment.loggerConfig.maxLogRentention) {
      logMessagesArray.length = environment.loggerConfig.maxLogRentention;
    }
    localStorage.setItem(keyName, JSON.stringify(logMessagesArray));

    if (!environment.production) {
      console.error(message);
    }
  }
}
export interface ILoggerParams {
  error?: Error;
  httpErrorResponse?: HttpErrorResponse;
  fromDecorator?: boolean;
  level?: string;
  title?: string;
  description?: string;
  className?: string;
  functionName?: string;
  location?: string;
  pageUrl?: string;
  browser?;
  os?;
  device?;
  timezone?;
}
