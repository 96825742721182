import { DOCUMENT } from '@angular/common';
import { Directive, Input, OnInit, HostListener, ElementRef, Inject } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
  selector: '[ibmHeapTrack]',
})
export class HeapDirective implements OnInit {
  @Input('ibmHeapTrack') value: string = null;

  @HostListener('click')
  sendTrackToHeap() {
    if (environment.heap.enabled) {
      window.heap.track(this.value);
    }
  }

  constructor(@Inject(DOCUMENT) private dom: Document, private trigger: ElementRef) {}

  ngOnInit() {}
}
