<ibm-modal class="message-after-login"
  [open]="open">
  <ibm-modal-header [showCloseButton]=false>
    <h3 ibmModalHeaderHeading>Terms and Conditions</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <div class="mt-5">
      <ibm-checkbox id="agreeTerms"
        [(ngModel)]="agreeTerms">
        I accept the <a [routerLink]="['/terms-of-use']"
          target="_blank">terms and conditions</a>.
      </ibm-checkbox>
    </div>
    <div class="mt-5">
      <ibm-checkbox id="enrollUpdates"
        [(ngModel)]="enrollUpdates">
        Sign me up for occasional Accelerated Discovery updates.
      </ibm-checkbox>
    </div>
  </div>
  <ibm-modal-footer>
    <button id="submitTerms"
      [disabled]="!agreeTerms"
      ibmButton="primary"
      (click)="onOK()">
      Submit
    </button>
  </ibm-modal-footer>
</ibm-modal>