import { SuperModel } from './super-model.model';
import { Molecule, RetrosynthesisMolecule } from '.';

export class SimilarReaction extends SuperModel {
  similaritiesScore: number;
  rclass: string;
  smiles: string;
  productMolecule: Molecule;
  outcomeMolecules: Molecule[];
  references: [
    {
      source: string;
      url: string;
      text: string;
      dataset: string;
    }
  ];

  constructor(data: any = {}) {
    super(data);

    if (data.productMolecule) {
      this.productMolecule = new RetrosynthesisMolecule(data.productMolecule);
    }
    if (data.outcomeMolecules && data.outcomeMolecules.length) {
      this.outcomeMolecules = data.outcomeMolecules.map(molecule => new RetrosynthesisMolecule(molecule));
    }
  }

  get score() {
    if (Math.abs(this.similaritiesScore) < 0.001) {
      return this.similaritiesScore.toExponential(3);
    } else if (Math.abs(this.similaritiesScore) < 1.0) {
      return this.similaritiesScore.toFixed(3);
    } else {
      return this.similaritiesScore;
    }
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}
