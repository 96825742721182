import { Injectable, Injector } from '@angular/core';
import { ApiSuperService, IApiAction, IApiRequest, IApiResponse } from '@bitf/api';
import { Observable, throwError, timer } from 'rxjs';
import { delayWhen, map, retryWhen } from 'rxjs/operators';

import { AuthService } from '@bitf/services';
import { ChallengeResult } from '@models';
@Injectable({
  providedIn: 'root',
})
export class ChallengesResultsService extends ApiSuperService {
  // mockApiUrl = 'https://localhost:3002/';
  checkResultsInterval = 5000;
  constructor(injector: Injector) {
    super(injector);
    this.name = 'challenges-results';
  }

  bulkDelete(requestParams: IApiRequest = {}): Observable<IApiResponse<IApiAction>> {
    return this.fetch({
      ...requestParams,
      method: 'DELETE',
      path: `${this.apiUrl}users/${this.authService.user.id}/${this.name}/delete-all`,
    }).pipe(
      map(envelope => this.parseEnvelope<IApiAction>(envelope, { ...requestParams, modelMapper: 'action' }))
    );
  }

  getChallengeResults(id: string): Observable<IApiResponse<ChallengeResult>> {
    return super
      .getById<ChallengeResult>({ id })
      .pipe(
        map(data => {
          if (data.metadata.uiMessages.errors.length) {
            throw new Error('PREDICTION_ERROR');
          }
          if (!this.isResultReady(data.content)) {
            throw new Error('PENDING');
          }
          return data;
        }),
        retryWhen(errorObs =>
          errorObs.pipe(
            delayWhen((error: Error) => {
              if (error.message === 'PENDING') {
                return timer(this.checkResultsInterval);
              }
              return throwError(error);
            })
          )
        )
      );
  }

  private isResultReady(challengeResult: ChallengeResult) {
    return !!challengeResult.machinePredictions;
  }
}
