import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuperService, IApiRequest, IApiResponse } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class AttemptsService extends ApiSuperService {
  // mockApiUrl = 'https://localhost:3002/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'attempts';
  }

  public submitFeedback(requestParams: IApiRequest): Observable<IApiResponse<any>> {
    return this.fetch({
      ...requestParams,
      method: 'POST',
      path: `${this.apiUrl}${this.name}/${requestParams.id}/feedback`,
    });
  }

  public likeDislikeAttempt(requestParams: IApiRequest): Observable<IApiResponse<any>> {
    return this.fetch({
      ...requestParams,
      method: 'POST',
      path: `${this.apiUrl}${this.name}/${requestParams.id}/${requestParams.action}`,
    });
  }
}
