import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { UserPlanUsage, User, IPaymentMethod } from '@app/core/models';
import { ApiSuperService, IApiResponse } from '@bitf/api';
import { AuthService } from '@app/libs/bitforce/services';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ApiSuperService {
  // mockApiUrl = 'https://localhost:3002/';
  user: User;

  constructor(injector: Injector) {
    super(injector);
    this.name = 'users';
  }

  logout() {
    localStorage.setItem('upgradeLater', 'false');
    return this.http.post(`${this.apiUrl}${this.name}/logout`, {});
  }

  // on api side we can use one method for each case
  changePasswordUsingToken(params: { token: string; password: string }): Observable<any> {
    return this.fetch({
      method: 'POST',
      path: `${this.apiUrl}users/change-password`,
      body: params,
    });
  }

  changePassword(params: { password: string; currentPassword: string }): Observable<any> {
    return this.fetch({
      method: 'POST',
      path: `${this.apiUrl}users/change-password`,
      body: params,
    });
  }

  generateKey(): Observable<any> {
    return this.fetch({
      method: 'POST',
      path: `${this.apiUrl}users/apikey`,
      body: {},
    });
  }

  removeKey(): Observable<any> {
    return this.fetch({
      method: 'DELETE',
      path: `${this.apiUrl}users/apikey`,
      body: {},
    });
  }

  addTerms(): Observable<any> {
    return this.fetch({
      method: 'POST',
      path: `${this.apiUrl}users/terms`,
      body: {},
    });
  }

  getPlanUsage(): Observable<IApiResponse<UserPlanUsage>> {
    return this.request({
      method: 'GET',
      path: `${this.authService.userId}/usage`,
      modelMapper: 'user-plan-usage',
    });
  }

  getPaymentMethods(): Observable<IApiResponse<IPaymentMethod[]>> {
    return this.request({
      method: 'GET',
      path: `payment-methods`,
      modelMapper: 'payment-method',
    });
  }
}
