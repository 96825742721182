import { IApiResponse, IApiPagination, IApiSorting, IApiCount, IApiAction, IApiActionData } from '@bitf/api';

import {
  IApiDefaultEnvelope,
  IApiDefaultPaginationResponse,
  IApiDefaultSortingResponse,
} from './default-api.interfaces';

import { modelMapper, modelsMapper } from '@bitf/api/parsers';

export const responseParser = <T>(apiOutput: any, relation: string): IApiResponse<T> => {
  if (apiOutput instanceof ArrayBuffer) {
    return {
      content: apiOutput as any,
    };
  }

  const envelope = apiOutput as IApiDefaultEnvelope;

  if (!envelope) {
    return undefined;
  }

  const parsedEnvelope: IApiResponse<T> = { content: null };

  if (isResponsePaged(envelope.payload)) {
    // Un wrap embed and computedFields
    envelope.payload.content = envelope.payload.content.map(item => unwrapExtraModelFields(item));
    parsedEnvelope.content = modelsMapper(envelope.payload.content, relation);
    const { content, sort, ...pagination } = envelope.payload;
    parsedEnvelope.pagination = parsePagination(pagination);
    parsedEnvelope.sorting = parseSorting(sort);
  } else if (isResponseList(envelope.payload)) {
    if (relation === 'actionData') {
      const apiActionData: IApiActionData = { data: envelope.payload } as IApiActionData;
      parsedEnvelope.content = apiActionData as any;
    } else {
      envelope.payload = envelope.payload.map(item => unwrapExtraModelFields(item));
      parsedEnvelope.content = modelsMapper(envelope.payload, relation);
    }
  } else {
    switch (relation) {
      case 'count':
        const apiCount = { count: envelope.payload } as IApiCount;
        parsedEnvelope.content = apiCount as any;
        break;
      case 'action':
        const apiAction = { success: envelope.payload } as IApiAction;
        parsedEnvelope.content = apiAction as any;
        break;
      case 'actionData':
        const apiActionData: IApiActionData = { data: envelope.payload } as IApiActionData;
        parsedEnvelope.content = apiActionData as any;
        break;
      default:
        envelope.payload = unwrapExtraModelFields(envelope.payload);
        parsedEnvelope.content = modelMapper(envelope.payload, relation);
    }
  }

  if (envelope.metadata) {
    parsedEnvelope.metadata = envelope.metadata;
  }

  return parsedEnvelope;
};

function parsePagination(pagination: IApiDefaultPaginationResponse): IApiPagination {
  if (!pagination) {
    return;
  }
  const parsedPagination: IApiPagination = {
    first: pagination.first,
    last: pagination.last,
    page: pagination.number,
    size: pagination.size,
    itemsInPage: pagination.numberOfElements,
    totalItems: pagination.totalElements,
    totalPages: pagination.totalPages,
  };
  return parsedPagination;
}

function parseSorting(sortingResponse: IApiDefaultSortingResponse[]): IApiSorting[] {
  if (!sortingResponse || !sortingResponse.length) {
    return;
  }
  return sortingResponse.map(
    (sorting: IApiDefaultSortingResponse) =>
      ({
        property: sorting.property,
        direction: sorting.direction,
      } as IApiSorting)
  );
}

function unwrapExtraModelFields(item: any) {
  if (!item) {
    return;
  }
  const extraFields = ['embed', 'computedFields'];
  extraFields.forEach(extraField => {
    if (item[extraField]) {
      Object.entries(item[extraField]).forEach(([key, val]) => {
        item[key] = val;
      });
    }
  });

  // NOTE: don't use delete for performance reason
  const returnObj = {};
  Object.entries(item).forEach(([key, val]) => {
    if (!extraFields.includes(key)) {
      returnObj[key] = val;
    }
  });
  return returnObj;
}

function isResponsePaged(response: any): boolean {
  try {
    return 'content' in response && Array.isArray(response.content);
  } catch (e) {
    return false;
  }
}

function isResponseList(response: any): boolean {
  return Array.isArray(response);
}
