import { SuperModel } from './super-model.model';

import { EActivityStatus, EActivityType } from '@app/shared/enums/activity.enum';
import { User } from '.';

export class Activity extends SuperModel {
  projectId: string;
  projectName: string;
  activityType: EActivityType;
  activityName: string;
  status: EActivityStatus;
  creator: User;

  constructor(data: any) {
    super(data);

    if (data.creator) {
      this.creator = new User(data.creator);
    }
  }

  get activityStatusLabel() {
    switch (this.status) {
      case EActivityStatus.PREDICTION_READY:
        return 'Prediction ready';
      case EActivityStatus.RETROSYNTHESIS_READY:
        return 'Retrosynthesis ready';
      case EActivityStatus.PENDING:
        return 'Pending';
      case EActivityStatus.PROCESSING:
        return 'Processing';
      case EActivityStatus.RUNNING:
        return 'Running';
      case EActivityStatus.WAITING:
        return 'Waiting';
      default:
        return this.status;
    }
  }

  get activityTypeLabel() {
    switch (this.activityType) {
      case EActivityType.PREDICTION:
        return 'Product prediction';
      case EActivityType.RETROSYNTHESIS:
        return 'Automatic retrosynthetic route';
      case EActivityType.SYNTHESIS_EXECUTION:
        return 'Synthesis execution';
      case EActivityType.REACTION_COMPLETION_PREDICTION:
        return 'Reagent';
      default:
        return this.activityType;
    }
  }

  get serialised() {
    return {
      id: this.id,
      projectId: this.projectId,
      projectName: this.projectName,
      activityName: this.activityName,
      status: this.status,
    };
  }
}
