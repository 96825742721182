<ibm-modal [open]="open">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>{{dialogData.title}}</h3>
  </ibm-modal-header>
  <div ibmModalContent
    class="ibm-rename-dialog">

    <div class="mb-6"
      *ngIf="dialogData.message">
      {{dialogData.message}}
    </div>

    <form (ngSubmit)="onRename()"
      [formGroup]="form">
      <section>
        <div class="mb-5">
          <label for="name"
            class="bx--label">{{dialogData.label || "Name"}}</label>
          <input *ibmValidationMessage="form.get('name')"
            formControlName="name"
            type="text"
            id="name"
            class="bx--text-input"
            placeholder="{{dialogData.placeholder || 'Insert the name' }}"
            autocomplete="off" />
        </div>
      </section>
    </form>
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary"
      (click)="closeModal()">Close</button>
    <button class="bx--btn bx--btn--primary"
      modal-primary-focus
      [disabled]="!form.valid"
      type="button"
      (click)="onRename()">
      Rename
    </button>
  </ibm-modal-footer>
</ibm-modal>