import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuperService, IApiRequest, IApiResponse } from '@app/libs/bitforce/api';

import { Activity } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'activities';
  }

  getQueuedActivities(requestParams: IApiRequest): Observable<IApiResponse<Activity[]>> {
    return this.get({
      ...requestParams,
      method: 'GET',
      path: `${this.apiUrl}${this.name}/${requestParams.relation}`,
      modelMapper: 'activities',
    });
  }
}
