import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ibm-full-height-panel',
  templateUrl: './full-height-panel.component.html',
  styleUrls: ['./full-height-panel.component.scss'],
})
export class FullHeightPanelComponent implements OnInit, AfterContentChecked {
  @ViewChild('button') buttonRef: ElementRef;
  @Input() buttonTitle: string;
  @Input() panelWidth;
  @Input() showPanel = false;
  @Input() e2eButtonData;
  @Output() togglePanel = new EventEmitter<boolean>();

  panelPosition = { top: '0', left: '0' };

  constructor() {}

  ngOnInit(): void {
    this.panelWidth = Math.min(this.panelWidth, 800);
  }

  ngAfterContentChecked(): void {
    const data = this.buttonRef?.nativeElement?.getBoundingClientRect();
    this.panelPosition.top = `${data?.top + data?.height}px`;
    this.panelPosition.left = `${data?.right - this.panelWidth}px`;
  }

  onTogglePanel() {
    this.showPanel = !this.showPanel;
    this.togglePanel.emit(this.showPanel);
  }
}
