import { SuperModel } from '@models/super-model.model';

export class Group extends SuperModel {
  name: string;
  description: string;
  isDefault: boolean;
  canAddUser: boolean;
  canDeleteUser: boolean;
  isDeletable: boolean;
  totalUsers: number;
  settings: GroupSettings;
  createdOnDate: Date;

  constructor(payload: Partial<Group> = {}) {
    super(payload);

    this.settings = new GroupSettings(this.settings || {});
    this.createdOnDate = new Date(this.createdOn as any);
    if (this.canAddUser === undefined) {
      this.canAddUser = true;
    }
  }

  get nameWithDefault() {
    return this.name + (this.isDefault ? ' (default)' : '');
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      settings: this.settings.serialised,
    };
  }
}

export class GroupSettings extends SuperModel {
  aiModels: { [key: string]: string[] };
  apiLimitEnabled: boolean;
  apiMaxCallPerMinute: number;
  apiMinTimeBetweenCall: number;

  constructor(payload: Partial<GroupSettings> = {}) {
    super(payload);

    this.aiModels = this.aiModels || { reaction: [], retrosynthesis: [] };
  }

  get serialised() {
    return {
      id: this.id,
      aiModels: this.aiModels,
      apiLimitEnabled: this.apiLimitEnabled,
      apiMaxCallPerMinute: this.apiMaxCallPerMinute,
      apiMinTimeBetweenCall: this.apiMinTimeBetweenCall,
    };
  }
}
