import { UntypedFormControl } from '@angular/forms';

/* check if min control in group is not greater than max */
export class MinMaxValidator {
  static valueChanges$ = null;

  static MinMaxValidator(maxControlName: string) {
    let minControl: UntypedFormControl;
    let maxControl: UntypedFormControl;

    return function minMaxValidate(control: UntypedFormControl) {
      if (!control.parent) {
        return null;
      }

      if (!minControl) {
        minControl = control;
        maxControl = control.parent.get(maxControlName) as UntypedFormControl;

        if (!maxControl) {
          throw new Error('Other control is not found in parent group');
        }

        MinMaxValidator.valueChanges$ = maxControl.valueChanges.subscribe(() => {
          minControl.updateValueAndValidity();
        });
      }

      if (maxControl && maxControl.value !== null && maxControl.value < minControl.value) {
        return { invalidMinMax: true };
      }

      return null;
    };
  }

  static clearValueChanges() {
    if (MinMaxValidator.valueChanges$) {
      MinMaxValidator.valueChanges$.unsubscribe();
    }
  }
}
