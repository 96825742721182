const hostUrl = window['hostUrl'] || 'https://rxn.res.ibm.com/rxn';
const apiUrl = hostUrl + '/api/api/v1/';
const wsUrl = window['wsUrl'] || 'wss://rxn.res.ibm.com/rxn/api/ws';
const userProfileUrl =
  window['userProfileUrl'] ||
  `https://ad.apps.rxn-stage.accelerator.cafe/auth/realms/RXN-prod/account?referrer=rxn-stage`;
const redirectUrl = window['redirectUrl'] || 'https://ad.apps.rxn-stage-cp.43ed.p1.openshiftapps.com/plans';
const logoutUrl =
  window['logoutUrl'] ||
  `https://ad.apps.rxn-stage.accelerator.cafe/auth/realms/RXN-prod/protocol/openid-connect/logout?post_logout_redirect_uri=${hostUrl}/sign-in&id_token_hint=`;
const upgradeUrl = window['upgradeUrl'] || '/subscription';
const plansEnabled = window['plansEnabled'] || false;
const ecommerceEnabled = window['ecommerceEnabled'] || false;

export const environment = {
  production: true,
  appName: 'ibm-mlrp-production',
  storageVersion: '1.0.0',
  apiUrl,
  wsUrl,
  hostUrl,
  userProfileUrl,
  redirectUrl,
  logoutUrl,
  upgradeUrl,
  plansEnabled,
  ecommerceEnabled,
  authInterceptorUrl: '/rxn/api/api/v1',
  logUrl: '',
  fbAppId: '368641450316101',
  httpRetryConfig: {
    enabled: false,
    verbs: {
      get: { enabled: false, timeout: 45000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: [500, 401, 403, 404],
    logDecoratedExceptions: true,
    logExceptions: true,
  },
  heap: {
    enabled: window['heapAnalyticsEnabled'] || false,
    appId: window['ibmHeapId'] || '',
  },
};
