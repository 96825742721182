import { SuperModel } from '@models/super-model.model';

export class Country extends SuperModel {
  countryName: string;
  countryCode2: string;
  countryCode3: string;

  constructor(country = {}) {
    super(country);
  }

  get serialised() {
    return {
      id: this.id,
      countryName: this.countryName,
      countryCode2: this.countryCode2,
      countryCode3: this.countryCode3,
    };
  }
}
