import { Injectable, Injector } from '@angular/core';

import { ApiSuperService } from '@bitf/api/api-super.service';

@Injectable({
  providedIn: 'root',
})
export class SynthesisProceduresService extends ApiSuperService {
  constructor(injector: Injector) {
    super(injector);
    this.name = 'synthesis';
  }
}
