import { SuperModel } from '@models/super-model.model';
import { User } from '@models/user.model';
export class Login extends SuperModel {
  token: string;
  refreshToken: string;
  user: User;
  idToken: string;

  constructor(data = {}) {
    super(data);

    if (this.user) {
      this.user = new User(this.user);
    }
  }

  get serialised() {
    console.error('this method should not being called');
    return undefined;
  }
}
