<div class="tree-legend">
  <div class="tree-legend__content p-5"
    *ngIf="isOpened && legend">
    <ng-container *ngFor="let legendEntry of legend;">
      <div class="d-flex align-items-center tree-legend__container">
        <div class="tree-legend__container__color mr-3"
          [ngStyle]="{ 'background-color': legendEntry.color }">
        </div>
        <div class="tree-legend__container__label small--xs"
          [title]="legendEntry.label">{{ legendEntry.label }}</div>
      </div>
    </ng-container>
  </div>

  <div (click)="isOpened = !isOpened"
    class="cursor-pointer tree-legend__button d-flex justify-content-between w-100 align-items-center px-5 py-4">
    <strong>Legend</strong>
    <svg icon
      *ngIf="!isOpened"
      ibmIconChevronUp
      size="20"></svg>
    <svg icon
      *ngIf="isOpened"
      ibmIconChevronDown
      size="20"></svg>
  </div>
</div>