import { Component, OnInit } from '@angular/core';
import { ListItem } from 'carbon-components-angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ICountryMappingDetails, countryMappings } from '@mpcheckout/checkout-cbr-library';

import { Country, User } from '@app/core/models';
import { ApiService, ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { LoaderService } from '@app/libs/bitforce/components';
import { SuperModalComponent } from '@app/core/components/super-modal/super-modal.component';

@Component({
  selector: 'ibm-select-country-dialog',
  templateUrl: './select-country-dialog.component.html',
  styleUrls: ['./select-country-dialog.component.scss'],
})
export class SelectCountryDialogComponent extends SuperModalComponent implements OnInit {
  public countries: ListItem[] = [];
  public countryForm: FormGroup;
  public isLoading = false;
  disabledHandleKeyboardEvent = true;
  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private toastMessagesService: ToastMessagesService,
    private loaderService: LoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.countries = countryMappings
      .map(country => ({
        content: country.countryName,
        code: country.countryCode2,
        country,
        selected: false,
      }))
      .sort((country1, country2) => country1.content.localeCompare(country2.content));

    this.countryForm = this.formBuilder.group({
      country: [null, Validators.required],
    });
  }

  onSaveCountry() {
    const selectedCountry = this.countries.find(
      country => country.code === this.countryForm.get('country').value
    ).country as ICountryMappingDetails;

    this.loaderService.show();
    this.apiService.users
      .patch<User>({
        body: {
          id: this.apiService.auth.user.id,
          country: new Country({
            countryCode2: selectedCountry.countryCode2,
            countryCode3: selectedCountry.countryCode3,
            countryName: selectedCountry.countryName,
          }),
        },
        isBodyRaw: true,
      })
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(response => {
        this.apiService.auth.user = response.content;
        this.closeModal();
        this.toastMessagesService.show({
          type: EToastMessageType.SUCCESS,
          title: 'Country saved',
          message: 'Your country has been saved.',
        });
      });
  }
}
