import { Injectable } from '@angular/core';

import { ModalService } from 'carbon-components-angular';
import { Observable, Subscriber } from 'rxjs';
import { IDialog, IDialogCloseEvent, IDialogService } from './dialog.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogsService implements IDialogService {
  public dialog: IDialog;
  closeEvent$: Observable<unknown>;

  constructor(public modalService: ModalService) {
    this.dialog = { open: this.wrapCarbonDialog.bind(this), closeAll: this.closeAll };
  }

  private wrapCarbonDialog(component: any, options: any) {
    let observer: Subscriber<IDialogCloseEvent<unknown>>;
    this.closeEvent$ = new Observable<IDialogCloseEvent<unknown>>(obs => {
      observer = obs;
    });

    let modal: any;
    this.openModal(component, options).then(mdl => {
      modal = mdl;
      mdl.instance.close.subscribe((result: IDialogCloseEvent<unknown>) => {
        if (observer) {
          observer.next(result);
          observer.complete();
        }
      });
    });

    return {
      afterClosed: () => this.closeEvent$,
      close: (dialogResult: IDialogCloseEvent<unknown>) => {
        if (modal) {
          modal.dismiss(dialogResult);
        }
      },
    };
  }

  private async openModal(component: any, options: any) {
    const modal = this.modalService.create({
      component,
      inputs: { dialogData: options },
    });
    return modal;
  }

  private closeAll() {}
}
