import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { DialogsService, ToastMessagesService } from '@services';

import { isCallingApi } from '@bitf/utils';
import { IApiDefaultEnvelope } from '@bitf/api/parsers/default-parser';
import { IApiMessage } from '@bitf/api';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { OkCancelDialogComponent } from '@shared/components/ok-cancel-dialog/ok-cancel-dialog.component';

@Injectable()
export class ApiUiMessagesInterceptor {
  messageTypes = ['infos', 'warnings', 'errors'];

  constructor(private toastMessagesService: ToastMessagesService, private dialogsService: DialogsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        response => {
          if (isCallingApi(environment, req) && response instanceof HttpResponse) {
            this.parseUiMessages(response.body);
          }
          return response;
        },
        error => {
          if (isCallingApi(environment, req) && error instanceof HttpErrorResponse) {
            this.parseUiMessages(error.error, error);
          }
        }
      )
    );
  }

  parseUiMessages(payload: IApiDefaultEnvelope, error?: HttpErrorResponse) {
    const uiMessages = payload && payload.metadata && payload.metadata.uiMessages;
    if (uiMessages) {
      this.messageTypes.forEach(messageType => {
        this.parseUiMessageByType(uiMessages, messageType);
      });
    } else if (error) {
      this.toastMessagesService.show({
        title: `Server error ${error.status}`,
        message: error.message,
        type: EToastMessageType.ERROR,
      });
    }
  }

  parseUiMessageByType(uiMessages, messageType) {
    uiMessages[messageType].forEach((message: IApiMessage) => {
      switch (message.target) {
        case 'TOAST':
          this.showToastMessages(message);
          break;
        case 'DIALOG':
          this.showDialog(message);
          break;
        case 'CONSOLE':
          console.log(message);
          break;
        default:
          console.log(message);
          break;
      }
    });
  }

  showToastMessages(uiMessage: IApiMessage) {
    let toastMessageType: EToastMessageType;
    switch (uiMessage.type) {
      case 'SUCCESS':
        toastMessageType = EToastMessageType.SUCCESS;
        break;
      case 'INFO':
        toastMessageType = EToastMessageType.SUCCESS;
        break;
      case 'WARNING':
        toastMessageType = EToastMessageType.WARNING;
        break;
      case 'ERROR':
        toastMessageType = EToastMessageType.ERROR;
        break;
      default:
        toastMessageType = EToastMessageType.WARNING;
        break;
    }

    this.toastMessagesService.show({
      title: uiMessage.title || '',
      message: uiMessage.message || '',
      duration: uiMessage.duration || 10000,
      type: toastMessageType,
    });
  }

  showDialog(uiMessage: IApiMessage) {
    this.dialogsService.dialog.closeAll();
    this.dialogsService.dialog.open(OkCancelDialogComponent, {
      title: uiMessage.title,
      message: uiMessage.message,
      okText: 'Ok',
    });
  }
}
