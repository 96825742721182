import { Component, OnInit, Input } from '@angular/core';
import { ITreeLegendItem } from '@models';

@Component({
  selector: 'ibm-tree-legend',
  templateUrl: './tree-legend.component.html',
  styleUrls: ['./tree-legend.component.scss'],
})
export class TreeLegendComponent implements OnInit {
  @Input()
  legend: Array<ITreeLegendItem>;
  isOpened = false;

  constructor() {}

  ngOnInit() {}
}
