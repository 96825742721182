import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuperService, IApiResponse } from '@bitf/api';
import { Project } from '@models/index';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends ApiSuperService {
  // mockApiUrl = 'http://localhost:3001/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'projects';
  }

  export(project: Project): Observable<any> {
    return this.fetch({
      method: 'GET',
      path: `${this.apiUrl}${this.name}/${project.id}/export`,
    });
  }

  getProjectsWithRetrosynthesis(): Observable<IApiResponse<Project[]>> {
    return this.request({
      method: 'GET',
      path: 'with-retrosynthesis',
      relation: 'projects',
      queryParams: {
        size: 1000,
      },
    });
  }
}
