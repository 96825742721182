export enum EToastVerticalPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum EToastHorizontalPosition {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum EToastMessageType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
