<ibm-modal [open]="open"
  [size]="dialogData.size || 'sm'"
  [hasScrollingContent]="false">
  <ibm-modal-header (closeSelect)="onCancel()">
    <h4>{{ dialogData.title }}</h4>
  </ibm-modal-header>
  <section class="bx--modal-content">
    <span *ngIf="dialogData.message"
      class="message"
      [innerHTML]="dialogData.message ">
    </span>

    <div *ngIf="dialogData?.useConfirmCheckbox"
      class="mt-5">
      <ibm-checkbox [(ngModel)]="confirmCheckbox">
        {{dialogData.confirmCheckboxText}}
      </ibm-checkbox>
    </div>
    <div *ngIf="dialogData?.useConfirmDropdown"
      class="mt-5">
      <ibm-dropdown [(ngModel)]="confirmDropdown"
        [label]="dialogData.confirmDropdownLabel">
        <ibm-dropdown-list [items]="dialogData?.confirmDropdownOptions"></ibm-dropdown-list>
      </ibm-dropdown>
    </div>

  </section>
  <ibm-modal-footer>
    <button *ngIf="dialogData.cancelText"
      [ibmButton]="dialogData.cancelButtonType || 'secondary'"
      (click)="onCancel()">
      {{ dialogData.cancelText }}
    </button>
    <button *ngIf="dialogData.okText"
      [ibmButton]="dialogData.okButtonType || 'primary'"
      [disabled]="(dialogData?.useConfirmCheckbox && !confirmCheckbox) || (dialogData?.useConfirmDropdown && !confirmDropdown)"
      (click)="onOk()">
      {{ dialogData.okText }}
    </button>
  </ibm-modal-footer>
</ibm-modal>