import { SuperModel } from '@models/super-model.model';

export class ChallengesResultsStats extends SuperModel {
  totalHumanTime: string;
  totalMachineTime: string;
  nOfCorrectHumanChallenges: number;
  nOfCorrectMachineChallenges: number;

  constructor(object: any) {
    super(object);
  }

  get serialised() {
    return {
      totalHumanTime: this.totalHumanTime,
      totalMachineTime: this.totalMachineTime,
      nOfCorrectHumanChallenges: this.nOfCorrectHumanChallenges,
      nOfCorrectMachineChallenges: this.nOfCorrectMachineChallenges,
    };
  }
}
