export interface IPlanForecast {
  title: string;
  message: string;
  level: string;
  type: EPlanForecastType;
  percentage: number;
}

export enum EPlanForecastType {
  REACTION_PREDICTIONS = 'reactionPredictions',
  TRAINING_HOURS = 'trainingHours',
  PLAN_CANCELED = 'planCanceled',
}
