import { Type } from '@angular/core';

export interface ILoaderAction {
  customLoader?: ICustomLoader;
  params?: any;
  action: LoaderAction;
}
export enum LoaderAction {
  SHOW = 'show',
  HIDE = 'hide',
}

export interface ICustomLoader {
  component: Type<any>;
  params: any;
}
