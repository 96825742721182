import { Injectable, Injector } from '@angular/core';
import { Observable, Observer, throwError, timer } from 'rxjs';
import { delayWhen, map, retryWhen } from 'rxjs/operators';

import { PredictionTask } from '@app/core/models/prediction-task.model';
import { ApiSuperService, IApiResponse } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class ReactionsService extends ApiSuperService {
  // mockApiUrl = 'https://localhost:3002/';
  checkOutcomesInterval = 5000;

  constructor(injector: Injector) {
    super(injector);
    this.name = 'predictions';
  }

  predict(
    projectId: string,
    predictionData: any,
    predictionTaskId: string = '',
    beam: boolean = false
  ): Observable<IApiResponse<PredictionTask>> {
    const queryParams: any = { projectId };
    if (predictionTaskId) {
      queryParams.predictionId = predictionTaskId;
    }
    return this.request({
      path: `${beam ? 'prb' : 'pr'}`,
      method: 'POST',
      body: predictionData,
      isBodyRaw: true,
      queryParams,
    });
  }

  getOutcomes(id: string) {
    return new Observable((observer: Observer<IApiResponse<PredictionTask>>) => {
      super
        .getById<PredictionTask>({ id, embed: ['createdBy'] })
        .pipe(
          map(data => {
            if (data.metadata.uiMessages.errors.length) {
              throw new Error('PREDICTION_ERROR');
            }
            if (!this.areOutcomesReady(data.content)) {
              throw new Error('PENDING');
            }
            if (data.content.status === 'PREDICTION_READY') {
              observer.next(data);
              throw new Error('PENDING');
            }
            return data;
          }),
          retryWhen(errorObs =>
            errorObs.pipe(
              delayWhen((error: Error) => {
                if (error.message === 'PENDING') {
                  return timer(this.checkOutcomesInterval);
                }
                return throwError(error);
              })
            )
          )
        )
        .subscribe(response => {
          observer.next(response);
          observer.complete();
        });
    });
  }

  private areOutcomesReady(predictionTask: PredictionTask) {
    if (predictionTask.status === 'PENDING') {
      return false;
    }
    if (!predictionTask.attempts || (!predictionTask.attempts.length && predictionTask.status !== 'ERROR')) {
      return false;
    }
    if (predictionTask.status === 'NEW' && predictionTask.type === 'prb') {
      return false;
    }
    return true;
  }
}
