import { EPredictionType } from '@app/shared/enums/reaction-property-prediction';
import { SuperModel } from '@models/super-model.model';
import { Molecule } from './molecule.model';

export class ReactionPropertyPrediction extends SuperModel {
  projectId: string;
  aiModel: string;
  reactionSmiles: string;
  type: EPredictionType;
  value: any;
  atomMapping?: {
    productMolecule: Molecule;
    precursorMolecules: Molecule[];
  };

  constructor(data: any = {}) {
    super(data);

    if (data.atomMapping) {
      if (data.atomMapping.productMolecule) {
        data.atomMapping.productMolecule = new Molecule(data.atomMapping.productMolecule);
      }
      if (data.atomMapping.precursorMolecules) {
        data.atomMapping.precursorMolecules = data.atomMapping.precursorMolecules.map(
          (molecule: any) => new Molecule(molecule)
        );
      }
    }
  }

  get typeLabel() {
    return this.type.replace(/_/g, ' ');
  }

  get serialised() {
    return;
  }
}
