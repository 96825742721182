import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { ApiSuperService, IApiResponse, IApiAction, IApiActionData } from '@bitf/api';
import { SimilarReaction, Tmap, TmapReaction } from '@app/core/models';
import { EAiModelType } from '@app/shared/enums/ai-model.enum';
@Injectable({
  providedIn: 'root',
})
export class ActionsService extends ApiSuperService {
  public tmapNumberOfNeighbors = 5; // suggested by Matteo Manica

  constructor(public http: HttpClient, injector: Injector) {
    super(injector);
    this.name = 'actions';
  }

  convertSimlesToSvgs(smilesList: string[]): Observable<IApiResponse<IApiActionData>> {
    return this.request({
      method: 'GET',
      path: `convert-smiles-to-svgs`,
      relation: 'actionData',
      queryParams: { smilesList },
    });
  }

  convertSimlesToMols(smilesList: string[]): Observable<IApiResponse<IApiActionData>> {
    return this.request({
      method: 'POST',
      path: `convert-smiles-to-mols`,
      relation: 'actionData',
      body: smilesList,
      isBodyRaw: true,
    });
  }

  validateSmiles(smiles: string): Observable<IApiResponse<IApiAction>> {
    return this.request<IApiAction>({
      method: 'GET',
      path: 'validate-smiles',
      relation: 'action',
      queryParams: { smiles },
    });
  }

  convertMaterialToSmiles(material: string): Observable<IApiResponse<IApiActionData>> {
    return this.request<IApiActionData>({
      method: 'POST',
      path: 'convert-material-to-smiles',
      relation: 'actionData',
      body: [material],
      isBodyRaw: true,
    });
  }

  convertSmilesToMaterial(smiles: string): Observable<IApiResponse<IApiActionData>> {
    return this.request<IApiActionData>({
      method: 'POST',
      path: 'convert-smiles-to-material',
      relation: 'actionData',
      body: [smiles],
      isBodyRaw: true,
    });
  }

  getSimilarReactionDocuments({
    smiles,
    page = 0,
    size = 10,
    referenceSize = 10,
    sort = 'score,DESC',
    aiModel,
    aiModelType,
  }: {
    smiles: string;
    page?: number;
    size?: number;
    referenceSize?: number;
    sort?: string;
    aiModelType: EAiModelType;
    aiModel: string;
  }): Observable<IApiResponse<SimilarReaction[]>> {
    return this.request<SimilarReaction[]>({
      method: 'GET',
      path: 'similar-reactions',
      relation: 'similar-reactions',
      queryParams: {
        smiles,
        size,
        referenceSize,
        sort,
        page,
        aiModelType,
        aiModel,
      },
    });
  }

  generateFingerprint(smiles: string): Observable<IApiResponse<IApiActionData>> {
    return this.request<IApiActionData>({
      method: 'GET',
      path: 'fingerprint',
      relation: 'actionData',
      queryParams: {
        smiles,
      },
    });
  }

  mapForReaction({
    aiModel,
    aiModelType,
    smiles,
    retrosynthesisSequenceId,
  }: {
    aiModel: string;
    aiModelType: EAiModelType;
    smiles?: string[];
    retrosynthesisSequenceId?: string;
  }) {
    return this.request<Tmap>({
      method: 'GET',
      path: `tmap/map-for-reactions`,
      relation: 'tmap',
      queryParams: {
        modelTag: aiModel,
        modelType: aiModelType,
        numberOfNeighbors: this.tmapNumberOfNeighbors,
        ...(smiles && smiles.filter(s => s).length > 0 && { smiles: smiles.join(',') }),
        ...(retrosynthesisSequenceId && { retrosynthesisSequenceId }),
      },
    });
  }

  renderReaction({
    vertexIndex,
    aiModel,
    aiModelType,
    showImage,
    imageBackgroundColor,
  }: {
    vertexIndex: number;
    aiModel: string;
    aiModelType: EAiModelType;
    showImage?: boolean;
    imageBackgroundColor?: string;
  }) {
    return this.request<TmapReaction>({
      method: 'GET',
      path: `tmap/render-reaction`,
      relation: 'tmap-reaction',
      queryParams: {
        ...(showImage && { showImage }),
        ...(imageBackgroundColor && { imageBackgroundColor }),
        modelTag: aiModel,
        modelType: aiModelType,
        vertexIndex,
      },
    });
  }
}
