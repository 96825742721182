<ibm-modal [open]="open"
  size="md"
  [hasScrollingContent]="false">
  <ibm-modal-header (closeSelect)="onClose()">
    <h4>Add payment method</h4>
  </ibm-modal-header>
  <section class="bx--modal-content d-grid"
    [ngClass]="{'mb-0 p-0': !isLoading && !hasError}">
    <div class="d-flex justify-content-center overflow-hidden align-center"
      *ngIf="isLoading">
      <ibm-loading [isActive]="true"
        size="md"></ibm-loading>
    </div>
    <div *ngIf="hasError">An error has occurred, please retry again</div>
    <div id="payment-section"
      [hidden]="isLoading"></div>
  </section>
</ibm-modal>