import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import interact from 'interactjs';

@Directive({
  selector: '[ibmDraggableResizable]',
})
export class DraggableResizableDirective implements OnInit, OnChanges {
  @Input()
  mutableElement: IMutableElement;
  @Input()
  interactive = true;
  @Output()
  updateElement = new EventEmitter<IMutableElement>();
  @Output()
  clickElement = new EventEmitter();

  constructor(private element: ElementRef) {}

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    this.clickElement.emit();
  }

  async ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.interactive) {
      if (changes.interactive.currentValue) {
        this.makeInteractive();
      } else {
        interact(this.element.nativeElement).unset();
      }
    }
  }

  private makeInteractive() {
    interact(this.element.nativeElement)
      .draggable({
        listeners: {
          move: event => {
            this.updateElement.emit({
              id: this.mutableElement?.id,
              xTopLeft: this.mutableElement?.xTopLeft + event.dx,
              xBottomRight: this.mutableElement?.xBottomRight + event.dx,
              yTopLeft: this.mutableElement?.yTopLeft + event.dy,
              yBottomRight: this.mutableElement?.yBottomRight + event.dy,
            });
          },
        },
        autoScroll: true,
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true,
          }),
        ],
      })
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: true },
        margin: 5,
        listeners: {
          move: event => {
            this.updateElement.emit({
              id: this.mutableElement.id,
              xTopLeft: this.mutableElement?.xTopLeft + event.deltaRect.left,
              xBottomRight: this.mutableElement?.xBottomRight + event.deltaRect.right,
              yTopLeft: this.mutableElement?.yTopLeft + event.deltaRect.top,
              yBottomRight: this.mutableElement?.yBottomRight + event.deltaRect.bottom,
            });
          },
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent',
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 20, height: 20 },
          }),
        ],
        inertia: true,
      });
  }
}

export interface IMutableElement {
  id: string;
  xTopLeft: number;
  yTopLeft: number;
  xBottomRight: number;
  yBottomRight: number;
}
