import { Component, Inject } from '@angular/core';

import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { BaseModal } from 'carbon-components-angular';

@Component({
  selector: 'ibm-dpl-fail',
  templateUrl: './dpl-fail.component.html',
  styleUrls: ['./dpl-fail.component.scss'],
})
export class DplFailComponent extends BaseModal {
  // NOTE: this is read from the MessagesAfterLoginService

  constructor(@Inject('dialogData') public dialogData: any) {
    super();
  }

  closeModal(): void {
    this.close.emit({
      status: EDialogCloseEventStatus.CLOSE,
    } as IDialogCloseEvent<void>);
  }
}
