import { Injectable, Injector } from '@angular/core';
import { IPlanLevel, Plan } from '@app/core/models';
import { ApiSuperService, IApiResponse } from '@bitf/api';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends ApiSuperService {
  // mockApiUrl = 'https://b8d18e18-66eb-481b-a04d-259bcd048385.mock.pstmn.io/api/v1/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'admin';
  }

  getPlans(): Observable<IApiResponse<Plan[]>> {
    return this.request({
      method: 'GET',
      path: 'plans',
      modelMapper: 'plans',
    });
  }

  patchPlanLevel(planLevel: IPlanLevel): Observable<IApiResponse<IPlanLevel>> {
    return this.request({
      method: 'PATCH',
      path: 'plans-levels',
      body: { ...planLevel },
      isBodyRaw: true,
      modelMapper: 'plan-level',
    });
  }
}
