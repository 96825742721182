import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModal, Modal } from 'carbon-components-angular';
@Component({ template: '' })
export abstract class SuperModalComponent extends BaseModal implements AfterViewInit {
  @ViewChild(Modal) modal!: Modal;
  disabledHandleKeyboardEvent: boolean;

  ngAfterViewInit() {
    if (!this.modal) {
      throw new Error('Modal not found');
    }
    if (this.disabledHandleKeyboardEvent) {
      // disable keyboard events, ESC and TAB
      this.modal.handleKeyboardEvent = () => {};
    }
  }
}
