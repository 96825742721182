<ibm-mlrp-header [hasSidenav]="false">
</ibm-mlrp-header>

<div class="vh-100 d-flex justify-content-center align-center">
  <div class="text-center">
    <h1>404 - Page not found</h1>
    <a class="bx--btn bx--btn--tertiary mt-5"
      [routerLink]="['/home']"
      type="button">Back home</a>
  </div>
</div>