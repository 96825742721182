import { Injectable } from '@angular/core';
import { MessageAfterLoginComponent } from '../components/message-after-login/message-after-login.component';
import { EDialogCloseEventStatus } from './dialog/dialog.enum';
import { DialogsService } from './dialog/dialog.service';
import { of } from 'rxjs';
import { SelectCountryDialogComponent } from '@app/shared/components/select-country-dialog/select-country-dialog.component';
import { switchMap, tap } from 'rxjs/operators';
import { ApiService } from './api';
import { EToastMessageType } from './toast-messages/toast-message.enum';
import { ToastMessagesService } from './toast-messages/toast-messages.service';
import { ReleaseNotesDialogComponent } from '@app/shared/components/release-notes-dialog/release-notes-dialog.component';
import { IDialogCloseEvent } from './dialog/dialog.interface';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class MessagesAfterLoginService {
  constructor(
    private apiService: ApiService,
    private dialogsService: DialogsService,
    private toastMessagesService: ToastMessagesService
  ) {}

  showMessages() {
    const user = this.apiService.auth.user;
    if (user.isBlocked === true) {
      this.toastMessagesService.show({
        title: 'User Account Approval In Progress',
        message:
          'Your account is currently under review. You will be notified when you are able to access RXN.',
        type: EToastMessageType.ERROR,
      });
      this.apiService.auth.logout();
    } else {
      of(true)
        .pipe(
          switchMap(() => {
            if (!user.country && environment.ecommerceEnabled) {
              return this.dialogsService.dialog.open(SelectCountryDialogComponent, {}).afterClosed();
            }
            return of({ status: EDialogCloseEventStatus.CLOSE });
          }),
          switchMap(() => {
            if (user.isTerms !== true) {
              return this.dialogsService.dialog.open(MessageAfterLoginComponent, {}).afterClosed();
            }
            return of({ status: EDialogCloseEventStatus.CLOSE });
          }),
          switchMap(() => {
            let releaseNotes;
            try {
              releaseNotes = JSON.parse(localStorage.getItem('releaseNotes'));
            } catch (e) {}
            releaseNotes = releaseNotes || { version: 0 };

            if (releaseNotes.version < ReleaseNotesDialogComponent.version) {
              return this.dialogsService.dialog
                .open(ReleaseNotesDialogComponent, {})
                .afterClosed()
                .pipe(
                  tap((response: IDialogCloseEvent<any>) => {
                    if (response?.status === EDialogCloseEventStatus.OK) {
                      localStorage.setItem(
                        'releaseNotes',
                        JSON.stringify({
                          version: ReleaseNotesDialogComponent.version,
                        })
                      );
                    }
                  })
                );
            }
            return of({ status: EDialogCloseEventStatus.CLOSE });
          })
        )
        .subscribe();
    }
  }
}
