import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  EPlanType,
  Plan,
  Subscription,
  ESubscriptionStatus,
  ESocketNotificationSubTopic,
} from '@app/core/models';
import { ApiService, DialogsService } from '@app/core/services';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IOkCancelDialogData } from '@app/core/services/dialog/dialog.interface';
import { OkCancelDialogComponent } from '@app/shared/components/ok-cancel-dialog/ok-cancel-dialog.component';
import { ListItem } from 'carbon-components-angular';
import { map, switchMap, filter, tap, take } from 'rxjs/operators';
import { SocketNotificationService } from '@app/core/services/socket-notification.service';
import { AuthService } from '@app/libs/bitforce/services';

@Component({
  selector: 'ibm-unsubscribe-button',
  templateUrl: './unsubscribe-button.component.html',
  styleUrls: ['./unsubscribe-button.component.scss'],
})
export class UnsubscribeButtonComponent implements OnInit {
  subscription: Subscription;

  constructor(
    private apiService: ApiService,
    private dialogsService: DialogsService,
    private datePipe: DatePipe,
    private socketNotificationService: SocketNotificationService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.fetchSubscription().subscribe();
  }

  private fetchSubscription() {
    return this.apiService.subscriptions
      .currentSubscription()
      .pipe(map(data => data.content))
      .pipe(
        tap(subscription => {
          this.subscription = subscription;

          if (subscription.subscriptionInProgress) {
            this.subscribeToNotifications();
          }
        })
      );
  }

  onDeleteSubscription() {
    this.dialogsService.dialog
      .open(OkCancelDialogComponent, {
        title: `Downgrade your plan`,
        message: `Are you sure you want to downgrade your <strong>${
          this.subscription?.plan?.name
        }</strong> plan to the <strong>Free</strong> plan? <br/><br/> You will continue to have access to the <strong>${
          this.subscription?.plan?.name
        }</strong> plan's features and rates until the end of your current cycle on ${this.datePipe.transform(
          this.subscription.endTermDate,
          'yyyy-MM-dd'
        )}.`,
        okText: 'Continue',
        cancelText: 'Cancel',
        okButtonType: 'danger',
        useConfirmDropdown: true,
        confirmDropdownLabel: 'Please Confirm',
        confirmDropdownOptions: [{ content: 'I want to downgrade to the Free plan' } as ListItem],
      } as IOkCancelDialogData)
      .afterClosed()
      .pipe(
        filter(closeEvent => closeEvent.status === EDialogCloseEventStatus.OK),
        switchMap(() => this.apiService.subscriptions.deleteCurrentSubscription()),
        switchMap(() => this.fetchSubscription())
      )
      .subscribe();
  }

  private subscribeToNotifications() {
    this.socketNotificationService.notifications$
      .pipe(
        filter(notification =>
          [
            ESocketNotificationSubTopic.SUBSCRIPTION_COMPLETED,
            ESocketNotificationSubTopic.SUBSCRIPTION_ERROR,
          ].includes(notification.subTopic)
        ),
        take(1),
        switchMap(() => this.fetchSubscription())
      )
      .subscribe();
  }

  get canUnsubscribe(): boolean {
    return (
      this.subscription?.status === ESubscriptionStatus.ACTIVE &&
      !this.subscription.subscriptionInProgress &&
      (this.authService.user?.isTeamOwner ||
        (this.subscription?.plan?.planType !== EPlanType.FREE && !this.authService.user?.isTeamMember))
    );
  }
}
