import { SuperModel } from '@models/super-model.model';
import { SynthesisExecution } from '.';
import { Retrosynthesis } from './retrosynthesis.model';

export class LatestSynthesis extends SuperModel {
  queued: SynthesisExecution[];
  completed: SynthesisExecution[];

  constructor(data: Partial<LatestSynthesis>) {
    super(data);

    if (data.queued) {
      this.queued = data.queued.map(item => new SynthesisExecution(item));
    }

    if (data.completed) {
      this.completed = data.completed.map(item => new SynthesisExecution(item));
    }
  }

  get serialised() {
    return {
      queued: this.queued,
      completed: this.completed,
    };
  }
}
