import { Injectable, Injector } from '@angular/core';
import { TEventTrackingName, TLoginLogoutTrackingName } from './event-tracking.type';
import {
  IBluemixAnalyticsLoginEventOptions,
  IBluemixAnalyticsCreatedObjectOptions,
} from './event-tracking.interface';
import { AuthService } from '@bitf/services';
@Injectable({
  providedIn: 'root',
})
// NOTE: All config variables are managed from ibm-env.js, injected by docker env
export class EventTrackingService {
  isBluemixAnalitycsEnabled = window['blueMixAnalyticsEnabled'];
  bluemixAnalytics: any = undefined;

  private authService: AuthService;
  constructor(injector: Injector) {
    // NOTE this is to avoid circular DI
    setTimeout(() => {
      this.authService = injector.get(AuthService);
    });
  }

  init() {
    if (!this.isBluemixAnalitycsEnabled) {
      return;
    }
    // This should be loaded because the bluemix script is loaded before the angular app and
    // is sync (no async await)
    this.bluemixAnalytics = window['bluemixAnalytics'];
  }

  trackLoginLogout(eventName: TLoginLogoutTrackingName, options?: IBluemixAnalyticsLoginEventOptions) {
    const _options: IBluemixAnalyticsLoginEventOptions = {
      ...options,
      email: this.authService?.user?.email,
      loginMethod: 'IBM appId',
      userAgent: navigator.userAgent,
    };
    this.sendEvent(eventName, _options);
  }

  trackEvent(eventName: TEventTrackingName, options: Partial<IBluemixAnalyticsCreatedObjectOptions> = {}) {
    const _options: IBluemixAnalyticsCreatedObjectOptions = {
      objectType: eventName,
      ...options,
    };
    this.sendEvent('Created Object', _options);
  }

  private sendEvent(label: string, options: any) {
    if (!this.isBluemixAnalitycsEnabled) {
      return;
    }
    if (!this.bluemixAnalytics) {
      console.warn('bluemixAnalytics not loaded');
      return;
    }
    try {
      const now = new Date();
      this.bluemixAnalytics.trackEvent(label, {
        ...options,
        userId: `rxnId-${this.authService?.user?.id}`,
        timestamp: now.toISOString(),
      });
    } catch (error) {
      console.error('Error on bluemixAnalytics.trackEvent', error);
    }
  }
}
