<ng-container *ngIf="synthesis.retrosynthesis">
  <div class="information-overflow-menu__border my-6"></div>

  <h5>Retrosynthesis info</h5>
  <ng-container *ngIf="synthesis?.retrosynthesis?.id">
    <h6 class="mt-6">Retro ID</h6>
    <div class="mt-4">{{synthesis?.retrosynthesis?.id}}</div>
  </ng-container>

  <ng-container *ngIf="synthesis.retrosynthesis?.typology">
    <h6 class="mt-6">Type</h6>
    <div class="mt-4">
      <ng-container [ngSwitch]="synthesis.retrosynthesis.typology">
        <ibm-tag type="blue"
          *ngSwitchCase="eRetrosynthesisTypology.INTERACTIVE">
          <span>{{ synthesis.retrosynthesis.typologyLabel }}</span>
        </ibm-tag>
        <ibm-tag type="blue"
          *ngSwitchCase="eRetrosynthesisTypology.AUTOMATIC">
          <span>{{ synthesis.retrosynthesis.typologyLabel }}</span>
        </ibm-tag>
        <ibm-tag type="yellow"
          *ngSwitchCase="eRetrosynthesisTypology.FROM_FILE">
          <span>{{ synthesis.retrosynthesis.typologyLabel }}</span>
        </ibm-tag>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="synthesis?.sequences[0]">
    <h6 class="mt-6">Confidence</h6>
    <div class="mt-4">{{synthesis?.sequences[0]?.confidence | truncDecimal: 3}}</div>
  </ng-container>

  <ng-container *ngIf="synthesis.retrosynthesis?.uploadedFile">
    <h6 class="mt-6">File uploaded</h6>
    <ibm-download-file-entry-button [fileEntry]="synthesis.retrosynthesis.uploadedFile">
    </ibm-download-file-entry-button>
  </ng-container>
</ng-container>