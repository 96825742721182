import { Component, Input, OnInit } from '@angular/core';
import { EAiModelType } from '@app/shared/enums/ai-model.enum';

@Component({
  selector: 'ibm-tmap-button',
  templateUrl: './tmap-button.component.html',
  styleUrls: ['./tmap-button.component.scss'],
})
export class TmapButtonComponent implements OnInit {
  @Input() aiModel: string;
  @Input() aiModelType: EAiModelType;
  @Input() reactionName: string;
  @Input() reactionSmiles: string;
  @Input() retrosynthesisSequenceId: string;
  constructor() {}

  ngOnInit(): void {}
}
