import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { of, Subscription, timer } from 'rxjs';

import { ESynthesisExecutionStatus, Project } from '@app/core/models';
import { ApiService } from '@app/core/services';
import { IApiResponse } from '@app/libs/bitforce/api';

@Component({
  selector: 'ibm-synthesis-execution-state',
  templateUrl: './synthesis-execution-state.component.html',
  styleUrls: ['./synthesis-execution-state.component.scss'],
})
export class SynthesisExecutionStateComponent implements OnDestroy, OnInit {
  @Input() projectId: string;
  @Input() showListLink = false;

  private pollingInterval = 15000;
  private subscription = new Subscription();
  synthesisExecutions: number;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.subscription.add(
      timer(0, this.pollingInterval)
        .pipe(
          switchMap(() =>
            this.apiService.projects
              .getById<Project>({
                id: this.projectId,
                computedFields: ['summary'],
              })
              .pipe(catchError(_ => of(null)))
          ),
          filter(response => {
            if (response?.content?.id === this.projectId) {
              return true;
            }
            return false;
          })
        )
        .subscribe((response: IApiResponse<Project>) => {
          this.synthesisExecutions = response.content?.summary?.totalSynthesisByStatus
            .filter(status =>
              [ESynthesisExecutionStatus.WAITING, ESynthesisExecutionStatus.RUNNING].includes(status.key)
            )
            .map(status => status.value)
            .reduce((c, p) => c + p, 0);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
