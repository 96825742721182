import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModal } from 'carbon-components-angular';

import { ToastMessagesService, ApiService } from '@services';
import { Project, User } from '@models';

import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { EventTrackingService } from '@app/core/services/event-tracking/event-tracking.service';

@Component({
  selector: 'ibm-create-project-dialog',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.scss'],
})
export class CreateProjectDialogComponent extends BaseModal implements OnInit {
  createProjectForm: UntypedFormGroup;
  selectedCollaborators: User[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private toastMessagesService: ToastMessagesService,
    private eventTrackingService: EventTrackingService
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.createProjectForm = this.fb.group({
      projectName: ['', [Validators.required, Validators.maxLength(500)]],
      isAddingCollaborator: false,
    });
  }

  collaboratorsSelectionChanged(collaboratorsEmails) {
    this.selectedCollaborators = collaboratorsEmails.map(
      (collaboratorEmail: string) => new User({ email: collaboratorEmail })
    );
  }

  createProject() {
    const isAddingCollaborator = this.createProjectForm.get('isAddingCollaborator').value;
    const newProject = {
      name: this.createProjectForm.get('projectName').value,
      invitations: isAddingCollaborator ? this.selectedCollaborators : [],
    };

    this.apiService.projects
      .post<Project>({ body: newProject, bodyParser: 'raw', isBodyRaw: true })
      .subscribe(
        data => {
          this.eventTrackingService.trackEvent('Project Creation');
          this.toastMessagesService.show({
            title: 'Create project',
            message: 'Project has been created',
            type: EToastMessageType.SUCCESS,
          });
          this.close.emit({
            status: EDialogCloseEventStatus.OK,
            data: data.content,
          } as IDialogCloseEvent<Project>);
        },
        () => {
          this.toastMessagesService.show({
            title: 'Create project - failed',
            message: 'Project has not been created',
            type: EToastMessageType.ERROR,
          });
        }
      );
  }

  closeModal() {
    this.close.emit({
      status: EDialogCloseEventStatus.CANCEL,
    } as IDialogCloseEvent<void>);
  }
}
