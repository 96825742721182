import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { FileItem } from 'carbon-components-angular';

@Component({
  selector: 'ibm-mlrp-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  @Input() files: Set<FileItem> = new Set();
  @Input() title: string;
  @Input() description: string;
  @Input() multiple = false;
  @Input() skeleton = false;
  @Input() buttonText;
  @Input() buttonType;
  @Input() dropText: string;
  @Input() acceptedExtensions: string[] = [];
  @Input() maxFileSize = 20000;
  @Input() size = 'normal';
  @Input() drop = true;

  @Output() selectedFiles: EventEmitter<Set<FileItem>> = new EventEmitter();

  constructor(private toastMessagesService: ToastMessagesService) {}

  onSelectFiles(event: Set<FileItem>) {
    const transferredFiles = Array.from(event);

    if (!transferredFiles.length) {
      this.files.clear();
      this.selectedFiles.emit();
      return;
    }

    const promises = transferredFiles.map(file => this.readFileAndCheckTypePromise(file));

    Promise.all(promises)
      .then(filePromiseArray => filePromiseArray.map((acceptedFile: any) => acceptedFile.file))
      .then((acceptedFiles: FileItem[]) => {
        const validFileItems = Array.from(this.files).filter((file: any) => !file.invalid);
        this.files = new Set(acceptedFiles);
        this.selectedFiles.emit(new Set(validFileItems));
      })
      .catch(error => {
        this.toastMessagesService.show({
          title: 'Error',
          message: error.currentTarget.error.message,
          type: EToastMessageType.ERROR,
        });
      });
  }

  private readFileAndCheckTypePromise(fileObj: FileItem) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(fileObj.file);
      reader.onload = () => {
        const invalidText = [];
        let isExtAllowed = true;

        if (this.acceptedExtensions.length) {
          isExtAllowed = this.acceptedExtensions.some(ext =>
            fileObj.file.name.toUpperCase().endsWith(ext.toUpperCase())
          );
          if (!isExtAllowed) {
            invalidText.push('File extension not allowed');
          }
        }

        const isFileSizeAllowed = this.maxFileSize >= fileObj.file.size;
        if (!isFileSizeAllowed) {
          invalidText.push('File exceeded size limit');
        }

        fileObj.uploaded = false;
        fileObj.state = invalidText.length ? 'edit' : 'upload';
        fileObj.invalid = !isExtAllowed || !isFileSizeAllowed;
        fileObj.invalidText = invalidText.join(', ');

        resolve({
          file: fileObj,
        });
      };

      reader.onerror = error => {
        this.files.delete(fileObj);
        reject(error);
      };
    });
  }
}
