import { SuperModel } from '@models/super-model.model';
import { Retrosynthesis } from './retrosynthesis.model';

export class Reactor extends SuperModel {
  index: number;
  status: string;
  currentSynthesis: Retrosynthesis;

  constructor(data: Partial<Reactor>) {
    super(data);

    if (data.currentSynthesis) {
      this.currentSynthesis = new Retrosynthesis(data.currentSynthesis);
    }
  }

  get serialised() {
    return {
      index: this.index,
      status: this.status,
      currentSynthesis: this.currentSynthesis,
    };
  }
}

export enum EReactorStatus {
  UNAVAILABLE = 'Unavailable',
  RUNNING = 'Running',
  STANDBY = 'Standby',
  MAINTENANCE = 'Maintenance Required',
}
