import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { ApiService, KetcherDataService } from '@services';
import { IApiResponse, IApiActionData } from '@bitf/api';

@Component({
  selector: 'ibm-ketcher',
  templateUrl: './ketcher.component.html',
  styleUrls: ['./ketcher.component.scss'],
})
export class KetcherComponent implements AfterViewInit, OnDestroy {
  @Input()
  set smiles(smiles: string) {
    if (!smiles) {
      return;
    }
    this.setSmiles([smiles]);
  }

  get smiles() {
    if (!this.ketcher) {
      return '';
    }
    return this.ketcher.getSmiles();
  }

  @Input()
  width = '100%';
  @Input()
  height = '400px';
  @Input()
  enableAutoSave: boolean;
  @Output()
  autoSave: EventEmitter<IKetcherEvent> = new EventEmitter<IKetcherEvent>();
  @Output()
  change: EventEmitter<IKetcherEvent> = new EventEmitter<IKetcherEvent>();

  @ViewChild('ketcherIframe', { read: ElementRef, static: true })
  ketcherIframe: ElementRef;

  ketcher: any;
  autosaveIntervaHandler: any;
  autoSaveInterval = 5000;
  molToAdd: string;
  molToSet: string;

  ketcherInitialised = false;

  constructor(private apiService: ApiService, private ketcherDataService: KetcherDataService) {}

  ngAfterViewInit() {
    this.initKetcher();
  }

  private initKetcher() {
    setTimeout(() => {
      this.ketcher = this.ketcherIframe.nativeElement.contentWindow.ketcher;
      this.initAutosave();
      this.initOnChange();
      this.afterInitKetcher();
      this.ketcherInitialised = true;
    }, 1500);
  }

  private afterInitKetcher() {
    if (this.ketcherDataService.smiles && this.ketcherDataService.smiles.length) {
      this.setSmiles(this.ketcherDataService.smiles);
      this.ketcherDataService.clear();
    }

    if (this.molToSet) {
      this.ketcher.setMolecule(this.molToSet);
    }

    if (this.molToAdd) {
      this.ketcher.addFragment(this.molToAdd);
    }
  }

  private initAutosave() {
    if (this.enableAutoSave) {
      this.autosaveIntervaHandler = setInterval(() => {
        const event = {
          smiles: this.ketcher.getSmiles(),
          mol: this.ketcher.getMolfile(),
        } as IKetcherEvent;
        this.autoSave.emit(event);
      }, this.autoSaveInterval);
    }
  }

  private initOnChange() {
    this.ketcher?.editor?.on('change', () => {
      const event = {
        smiles: this.ketcher?.getSmiles(),
        mol: this.ketcher?.getMolfile(),
      } as IKetcherEvent;
      this.change.emit(event);
    });
  }

  setSmiles(smiles: string[]) {
    if (!smiles || !smiles.length) {
      return;
    }
    this.apiService.actions
      .convertSimlesToMols([smiles.join('.')])
      .subscribe((response: IApiResponse<IApiActionData>) => {
        if (this.ketcher) {
          if (response.content.data && response.content.data[0]) {
            this.ketcher.setMolecule(response.content.data[0]);
          }
        } else {
          if (response.content.data && response.content.data.length) {
            this.molToSet = response.content.data[0];
          }
        }
        // NOTE: error handled by API
      });
  }

  addSmiles(smiles: string[]) {
    if (!smiles || !smiles.length) {
      return;
    }
    this.apiService.actions
      .convertSimlesToMols([smiles.join('.')])
      .subscribe((response: IApiResponse<IApiActionData>) => {
        if (this.ketcher) {
          if (response.content.data && response.content.data[0]) {
            this.ketcher.addFragment(response.content.data[0]);
          }
        } else {
          this.molToAdd = response.content.data[0];
        }
        // NOTE: error handled by API
      });
  }

  get mol() {
    return this.ketcher.getMolfile();
  }

  ngOnDestroy(): void {
    if (this.autosaveIntervaHandler) {
      clearInterval(this.autosaveIntervaHandler);
    }
    this.ketcher = undefined;
  }
}

export interface IKetcherEvent {
  smiles: string;
  mol: string;
}
