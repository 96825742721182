import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiSuperService } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class ReagentsService extends ApiSuperService {
  // mockApiUrl = 'https://localhost:3002/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'reagents';
  }

  export(): Observable<any> {
    return this.request({
      method: 'GET',
      path: `${this.name}/export`,
    });
  }
}
