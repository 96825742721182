import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SocialGoogleService {
  constructor() {}
  googleShareUrl = 'https://plus.google.com/share?url=';

  share(url) {
    window.open(
      this.googleShareUrl + url,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    );
  }
}
