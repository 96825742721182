import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { FileEntry } from '@app/core/models';
import { ApiService, ToastMessagesService } from '@app/core/services';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';
import { LoaderService } from '@app/libs/bitforce/components';
import { bitfPromptToDownloadFile } from '@app/libs/bitforce/utils';

@Component({
  selector: 'ibm-download-file-entry-button',
  templateUrl: './download-file-entry-button.component.html',
  styleUrls: ['./download-file-entry-button.component.scss'],
})
export class DownloadFileEntryButtonComponent implements OnInit {
  @Input() fileEntry: FileEntry;

  constructor(
    private loaderService: LoaderService,
    private apiService: ApiService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit(): void {}

  onDownloadFile() {
    if (this.fileEntry) {
      this.loaderService.show();
      this.apiService.fileEntries
        .request<any>({
          method: 'GET',
          path: `${this.fileEntry.id}/download`,
          responseType: 'arraybuffer',
        })
        .pipe(finalize(() => this.loaderService.hide()))
        .subscribe(response => {
          this.toastMessagesService.show({
            title: 'Download file',
            message: 'Download of file has been started',
            type: EToastMessageType.SUCCESS,
          });
          bitfPromptToDownloadFile(
            response.content,
            this.fileEntry.mimeType,
            this.fileEntry.extension,
            this.fileEntry.baseFileName
          );
        });
    } else {
      this.toastMessagesService.show({
        title: 'Error',
        message: 'Error while downloading file',
        type: EToastMessageType.ERROR,
      });
    }
  }
}
