<div class="sub-header"
  *ngIf="sequence">
  <div class="bg-ui-04 text-04 left-bar">
    <ibm-dropdown *ngIf="sequences && sequences.length > 1"
      [size]="'xl'"
      [theme]="'light'"
      [dropUp]="false"
      (selected)="selected.emit($event.item)"
      class="w-100 custom-dropdown">
      <ibm-dropdown-list [items]="sequences"></ibm-dropdown-list>
      <div class="d-none"
        *ngFor="let sequence of sequences"
        data-e2e="outcomeId">{{sequence.id}}</div>
    </ibm-dropdown>
    <span *ngIf="sequences  && sequences.length === 1"
      class="px-3">
      {{ sequences[0].content }}
    </span>
    <span *ngIf="!sequences"
      class="px-3">
      {{ sequence.label }}
    </span>
  </div>

  <div class="right-bar pl-4">
    <div class="d-flex align-items-center">
      <ng-container *ngIf="sequence.isConfidenceComputed || sequence.isConfidenceComputed === undefined">
        <span *ngIf="sequence.confidenceTag && eConfidenceTagName[sequence.confidenceTag]"
          class="bx--tag bx--tag--blue"
          [ngStyle]="{
          'background-color': eConfidenceTagColor[sequence.confidenceTag],
          color: sequence.confidenceTag && eConfidenceTagTextColor[sequence.confidenceTag]
        }">{{ eConfidenceTagName[sequence.confidenceTag] }}
          confidence
        </span>
        <span class="ml-4"
          *ngIf="sequence.confidence !== undefined"
          [title]="sequence.confidence">Confidence: {{ sequence.confidence | truncDecimal: 3 }}</span>
      </ng-container>
      <ng-container *ngIf="
          sequence.isConfidenceComputed===false">
        <ibm-inline-loading loadingText="Computing confidence"></ibm-inline-loading>
      </ng-container>
      <ng-content select="[left]"></ng-content>
    </div>
    <div class="d-flex align-items-center">
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
</div>