import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { APP_VERSION } from '@env/version';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';

import { Challenge, Project } from '@models';
import { configs } from '@configs';
import { EventTrackingService } from '@services/event-tracking/event-tracking.service';

import { IActiveFilters } from '@app/shared/components/filters/filters.component';
import { IIbmSearchData } from '@app/shared/components/search-bar/search-bar.interface';
import { TableHeaderItem } from 'carbon-components-angular';
import { ETableList } from '@app/shared/enums/table-list.enum';
import { Platform } from '@angular/cdk/platform';

export type SidebarType = 'left' | 'right';
export type LeftSideBarPanel = 'change-password' | 'user-profile';
export type RightSideBarPanel = 'project-collaborators' | 'project-archive' | 'project-delete';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService {
  activeBreakpoints = {
    isXSmall: false,
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isXLarge: false,
    isHandset: false,
    isTablet: false,
    isWeb: false,
    isHandsetPortrait: false,
    isTabletPortrait: false,
    isWebPortrait: false,
    isHandsetLandscape: false,
    isTabletLandscape: false,
    isWebLandscape: false,
  };

  platformInformation = {
    isAndroid: false,
    isIOS: false,
    isFirefox: false,
    isWebkit: false,
    isTrident: false,
    isEdge: false,
    isSafari: false,
  };

  challenge: Challenge;
  isAdminArea = false;
  isAccountArea = false;
  isLeftSidenavVisible = false;
  isLeftSidenavActive = false;
  listTableState: {
    [key in ETableList]?: {
      currentPage: number;
      pageLength: number;
      filters: IActiveFilters;
      searchData: IIbmSearchData;
      tableHeaders: TableHeaderItem[];
    };
  } = {};

  readonly activeBreakpointsChanges$ = new Subject<void>();
  private _uiHelpers: IUiHelper[] = [];
  private readonly currentProject$ = new BehaviorSubject<Project>(null);
  private readonly uiHelpers$ = new Subject<IUiHelper[]>();
  private readonly projectsFavorites$ = new Subject<void>();
  private sidebarActivePanel = {
    left: undefined,
    right: undefined,
  };

  private sidebarVisible = {
    left: false,
    right: false,
  };

  constructor(
    private eventTrackingService: EventTrackingService,
    private breakpointObserver: BreakpointObserver,
    private platform: Platform
  ) {
    this.eventTrackingService.init();
    this.initBreakpointListener();
    this.setPlatformInformation();

    if (!environment.production) {
      console.log('ENV', environment);
    }
    window['printEnv'] = () => {
      console.log('Environment: ', environment);
      console.log('App Version: ', APP_VERSION);
    };
  }

  isSidebarVisible(sidebarType: SidebarType): boolean {
    return this.sidebarVisible[sidebarType];
  }

  getActivePanel(sidebarType: SidebarType): LeftSideBarPanel | RightSideBarPanel {
    return this.sidebarActivePanel[sidebarType];
  }

  toggleSidebarVisibility(sidebarType: SidebarType): void {
    this.sidebarVisible[sidebarType] = !this.sidebarVisible[sidebarType];
  }

  setSidebarVisibility(sidebarType: SidebarType, isVisible: boolean): void {
    this.sidebarVisible[sidebarType] = isVisible;
  }

  setSidebarPanel(sidebarType: SidebarType, panel: LeftSideBarPanel | RightSideBarPanel): void {
    this.sidebarActivePanel[sidebarType] = panel;
  }

  cleanSession() {
    this.setSidebarVisibility('left', false);
    this.setSidebarVisibility('right', false);
  }

  private get redirectUrlKey() {
    return `${environment.appName}-redirectUrl`;
  }

  set redirectUrl(url: string) {
    localStorage.setItem(this.redirectUrlKey, url);
  }

  get redirectUrl(): string {
    const redirectUrl = localStorage.getItem(this.redirectUrlKey);
    localStorage.removeItem(this.redirectUrlKey);
    return redirectUrl || configs.general.homePageUrl;
  }

  get projectsFavoritesChanges() {
    return this.projectsFavorites$;
  }

  get currentProject() {
    return this.currentProject$;
  }

  setCurrentProject(project: Project | null) {
    this.currentProject$.next(project);
  }

  getUiHelper(element: EUiHelperElement): Observable<IUiHelper> {
    return this.uiHelpers$.pipe(
      map(el => el[element]),
      filter(el => el)
    );
  }

  showUIHelper(element: EUiHelperElement) {
    this._uiHelpers[element] = { element, active: true };
    this.uiHelpers$.next(this._uiHelpers);
  }

  hideUIHelper(element: EUiHelperElement) {
    this._uiHelpers[element] = { element, active: false };
    this.uiHelpers$.next(this._uiHelpers);
  }

  resetListTableStateForProject() {
    delete this.listTableState[ETableList.REACTIONS_LIST];
    delete this.listTableState[ETableList.REACTIONS_COMPLETION_LIST];
    delete this.listTableState[ETableList.RETROSYNTHESIS_LIST];
    delete this.listTableState[ETableList.SYNTHESIS_PROCEDURE_LIST];
    delete this.listTableState[ETableList.SYNTHESIS_EXECUTION_LIST];
  }

  private setPlatformInformation() {
    this.platformInformation.isIOS =
      this.platform.IOS || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); // iPadOS is not detected as IOS
    this.platformInformation.isAndroid = this.platform.ANDROID;
    this.platformInformation.isFirefox = this.platform.FIREFOX;
    this.platformInformation.isWebkit = this.platform.WEBKIT;
    this.platformInformation.isEdge = this.platform.EDGE;
    this.platformInformation.isTrident = this.platform.TRIDENT;
  }

  private initBreakpointListener() {
    // Ref: https://material.io/design/layout/responsive-layout-grid.html#breakpoints
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Web,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
        Breakpoints.WebPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletLandscape,
        Breakpoints.WebLandscape,
      ])
      .subscribe(() => {
        this.activeBreakpoints.isXSmall = this.breakpointObserver.isMatched(Breakpoints.XSmall);
        this.activeBreakpoints.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
        this.activeBreakpoints.isMedium = this.breakpointObserver.isMatched(Breakpoints.Medium);
        this.activeBreakpoints.isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
        this.activeBreakpoints.isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);
        this.activeBreakpoints.isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
        this.activeBreakpoints.isTablet = this.breakpointObserver.isMatched(Breakpoints.Tablet);
        this.activeBreakpoints.isWeb = this.breakpointObserver.isMatched(Breakpoints.Web);
        this.activeBreakpoints.isHandsetPortrait = this.breakpointObserver.isMatched(
          Breakpoints.HandsetPortrait
        );
        this.activeBreakpoints.isTabletPortrait = this.breakpointObserver.isMatched(
          Breakpoints.TabletPortrait
        );
        this.activeBreakpoints.isWebPortrait = this.breakpointObserver.isMatched(Breakpoints.WebPortrait);
        this.activeBreakpoints.isHandsetLandscape = this.breakpointObserver.isMatched(
          Breakpoints.HandsetLandscape
        );
        this.activeBreakpoints.isTabletLandscape = this.breakpointObserver.isMatched(
          Breakpoints.TabletLandscape
        );
        this.activeBreakpoints.isWebLandscape = this.breakpointObserver.isMatched(Breakpoints.WebLandscape);
        this.activeBreakpointsChanges$.next();
      });
  }
}

interface IUiHelper {
  element: EUiHelperElement;
  active: boolean;
}

export enum EUiHelperElement {
  COPY_AND_CREATE_BUTTON = 'copy_and_create_button',
}
