<div class="zoom-container d-flex align-items-center"
  [ngClass]="theme">
  <button ibmButton="ghost"
    [iconOnly]="true"
    class="zoom-container__zoom-in"
    (click)="onZoomIn()">
    <svg icon
      ibmIconZoomIn
      size="16"></svg>
  </button>
  <ibm-dropdown *ngIf="showZoomSelector && !appSessionService.activeBreakpoints.isTablet"
    itemValueKey="value"
    [ngModel]="currentZoom.value"
    (selected)="onZoomSelected($event)"
    [ngClass]="theme">
    <ibm-dropdown-list [items]="zoomOptions"></ibm-dropdown-list>
  </ibm-dropdown>
  <button *ngIf="showFitToScreen"
    ibmButton="ghost"
    [iconOnly]="true"
    (click)="onFitToScreen()"><svg icon
      ibmIconFitToScreen
      size="16"></svg>
  </button>
  <button ibmButton="ghost"
    class="zoom-container__zoom-out"
    [iconOnly]="true"
    (click)="onZoomOut()"><svg icon
      ibmIconZoomOut
      size="16"></svg>
  </button>
</div>