import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IAddressFormatWithCountry, IAddressLookupResponse, User } from '@app/core/models';
import { ApiService } from '@app/core/services';
import { IApiResponse } from '@app/libs/bitforce/api';
import { Dropdown, ListItem } from 'carbon-components-angular';
import { Subject } from 'rxjs';
import { map, finalize, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

type AddressListItem = ListItem & { format: string };

@Component({
  selector: 'ibm-search-billing-address',
  templateUrl: './search-billing-address.component.html',
  styleUrls: ['./search-billing-address.component.scss'],
})
export class SearchBillingAddressComponent implements OnInit {
  @ViewChild('addressesDropDown', { static: true }) addressesDropDown: Dropdown;

  @Input() theme = 'light';
  options: Array<AddressListItem> = [];
  searchString = '';
  isLoading = false;
  placeholder: string;
  private search$ = new Subject<string>();
  @Output() valueChange = new EventEmitter<IAddressFormatWithCountry>();

  constructor(private apiService: ApiService) {}
  ngOnInit(): void {
    const user = new User(this.apiService.auth.user);
    this.placeholder = `Address - ${user.country.countryName}`;
    this.search$
      .pipe(
        filter(x => !!x),
        debounceTime(500),
        tap(() => (this.isLoading = true)),
        switchMap(x => this.apiService.addresses.validate(x).pipe(finalize(() => (this.isLoading = false)))),
        map((response: IApiResponse<IAddressLookupResponse>) =>
          response.content.results.map(
            x =>
              ({
                content: x.suggestion,
                format: x.format,
              } as AddressListItem)
          )
        )
      )
      .subscribe(options => {
        this.options = options;
        setTimeout(() => this.addressesDropDown.openMenu());
      });
  }

  onSelect(event: { item: AddressListItem; isUpdate?: boolean }) {
    if (event.item?.format) {
      this.valueChange.emit(null);
      this.isLoading = true;
      this.apiService.addresses
        .format(event.item.format)
        .pipe(map(y => y.content))
        .subscribe(x => {
          const user = new User(this.apiService.auth.user);
          this.valueChange.emit({ ...x, ...user.country });
          this.searchString = event.item.content;
          this.options = [];
          this.isLoading = false;
        });
    }
  }

  onSearch(search: string) {
    this.search$.next(search);
  }

  onClear() {
    this.options = [];
    this.valueChange.emit(null);
  }
}
