import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { finalize, map, catchError, tap } from 'rxjs/operators';
import { TimeoutError } from 'rxjs';
import { Router } from '@angular/router';

import { User } from '@models';
import { AppSessionService, UsersService } from '@services';
import { AuthService } from '@bitf/services';
import { configs } from '@configs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private router: Router,
    private appSessionService: AppSessionService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.user) {
      const canAccess = configs.guard.checkUserRoutePermission(state, this.authService);
      if (canAccess) {
        return true;
      }
      this.router.navigate([configs.general.homePageUrl]);
      return false;
    } else if (this.authService.authToken && this.authService.userId) {
      return this.usersService
        .getById<User>({ id: this.authService.userId })
        .pipe(
          tap(data => {
            this.authService.user = data.content;
          }),
          map(() => {
            const canAccess = configs.guard.checkUserRoutePermission(state, this.authService);
            if (canAccess) {
              return true;
            }
            this.router.navigate([configs.general.homePageUrl]);
            return false;
          }),
          finalize(() => {
            // this.authService.appReady = true;
          }),
          catchError(error => {
            if (!(error instanceof TimeoutError)) {
              this.authService.logout();
            }
            return of(false);
          })
        );
    } else {
      // NOTE: here we could add some re-auth mechanism
      this.appSessionService.redirectUrl = state.url;
      this.router.navigate(['/sign-in']);
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
