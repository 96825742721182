import { Component, Injector, OnInit } from '@angular/core';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent, IOkCancelDialogData } from '@app/core/services/dialog/dialog.interface';

import { BaseModal } from 'carbon-components-angular';

@Component({
  selector: 'ibm-ok-cancel-dialog',
  templateUrl: './ok-cancel-dialog.component.html',
  styleUrls: ['./ok-cancel-dialog.component.scss'],
})
export class OkCancelDialogComponent extends BaseModal implements OnInit {
  dialogData: IOkCancelDialogData;
  okButtonType: string;
  confirmCheckbox = false;
  confirmDropdown: unknown;
  constructor(private injector: Injector) {
    super();
    this.dialogData = this.injector.get('dialogData');
  }

  ngOnInit() {}

  onOk() {
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
      data: this.confirmDropdown,
    } as IDialogCloseEvent<unknown>);
  }

  onCancel() {
    this.close.emit({
      status: EDialogCloseEventStatus.CANCEL,
    } as IDialogCloseEvent<unknown>);
  }
}
