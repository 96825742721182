import { IPlanLevel } from './plan.model';
import { SuperModel } from './super-model.model';

export class Subscription extends SuperModel {
  plan: IPlanLevel;
  endTerm: number;
  endTermDate: Date;
  status: ESubscriptionStatus;
  subscriptionInProgress: boolean;
  constructor(data: any) {
    super(data);
    if (data?.endTerm) {
      this.endTermDate = new Date(data.endTerm);
    }
  }

  get serialised() {
    return {
      plan: this.plan,
      endTerm: this.endTerm,
      status: this.status,
      subscriptionInProgress: this.subscriptionInProgress,
    };
  }
}

export enum ESubscriptionStatus {
  ACTIVE = 'Active',
  CANCELLED = 'Canceled',
}
