import { ISvgToUrl, svgToUrl } from '@app/shared/common-utilities/svg-to-url';
import { EIngredientPhase, EIngredientType } from '@app/shared/enums/reaction-settings-ingredient.enum';
import { SuperModel } from '@models/super-model.model';
import { IActionMessage } from './action.model';

export class ReactionSettingsIngredient extends SuperModel {
  name: string;
  smiles: string;
  image: string;
  quantity: number;
  unit: string;
  description: string;
  isVirtual: boolean;
  isPreviousProduct: boolean;
  isProduct: boolean;
  isPrecursor: boolean;
  isNonMolecularSubstance: boolean;
  isMissing: boolean; // computed client side
  ingredientCategory: TIngredientCategory;
  ingredientType: EIngredientType;
  phase: EIngredientPhase;
  pureCompound?: {
    weighings: {
      position: number;
      quantity: number;
      unit: string;
    }[];
  };
  handmadeSolution?: {
    position: number;
    components: {
      name: string;
      quantity: number;
      unit: string;
      main: boolean;
      saturatedSolution: boolean;
    }[];
  };
  standardSolution: {
    position: number;
    quantity: number;
    unit: string;
    concentration: number;
    concentrationUnit: string;
  };
  moleculeInfo: {
    molecularWeight: number;
    density: number;
  };
  messages: IActionMessage[];

  // Only for FE
  isExpanded = false;

  constructor(data: any = {}) {
    super(data);
    this.isMissing = data.messages?.some(m => m.shortMessage === 'Missing ingredient');
  }

  get moleculeImageSvg(): ISvgToUrl {
    return svgToUrl(this.image);
  }

  static getIngredientPhase(ingredientCategory: TIngredientCategory): EIngredientPhase {
    switch (ingredientCategory) {
      case 'solvent':
        return EIngredientPhase.LIQUID;
      case 'reagent':
        return EIngredientPhase.LIQUID;
      case 'pin':
        return EIngredientPhase.SOLID;
    }
  }
  get serialised() {
    return {
      id: this.id,
    };
  }
}

export interface IMoleculeInformation {
  name: string;
  molecularWeight: number;
  density: number;
  stoichiometryFactor: number;
}

export interface IHardwareSettings {
  product: {
    units: string[];
  };
  ingredientCategories: {
    [key in TIngredientCategory]: {
      availablePositions: number[];
      hardwareLimits: any[];
    };
  };
  units: {
    quantities: {
      [phaseKey in EIngredientPhase]: string[];
    };
    concentrations: {
      [phaseKey in EIngredientPhase]: string[];
    };
  };
  ingredientTypes: {
    [key in EIngredientType]: {
      quantities: {
        [phaseKey in EIngredientPhase]: string[];
      };
      concentrations: {
        [phaseKey in EIngredientPhase]: string[];
      };
    };
  };
}

export interface IIngredientCategory {
  name: TIngredientCategory;
}

export type TIngredientCategory = 'solvent' | 'reagent' | 'pin';
