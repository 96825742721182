import { SuperModel } from './super-model.model';

export class SubscriptionCost extends SuperModel {
  plan: SubscriptionCostDescriptionPrice;
  tax: SubscriptionCostDescriptionPrice;
  total: SubscriptionCostDescriptionPrice;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {
      plan: this.plan,
      tax: this.tax,
      total: this.total,
    };
  }
}

export interface SubscriptionCostDescriptionPrice {
  description: string;
  price: number;
  formattedPrice: string;
}
