<ibm-modal [open]="open"
  [hasScrollingContent]="false">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>{{ dialogData.title }}</h3>
    <span>{{ dialogData.description }}</span>
  </ibm-modal-header>
  <div ibmModalContent
    class="ibm-rename-retrosynthesis">
    <form (ngSubmit)="onSelectProject()"
      [formGroup]="form">
      <ibm-select-or-create-project #projectSelect
        [toAddLabel]="dialogData.entityName"
        [form]="form"
        [projects]="projects"></ibm-select-or-create-project>
    </form>
  </div>
  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="closeModal()">Close</button>
    <button ibmButton="primary"
      (click)="onSelectProject()"
      [disabled]="!form.valid">
      {{ dialogData.buttonCallToAction }}
    </button>
  </ibm-modal-footer>
</ibm-modal>