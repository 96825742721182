import { SuperModel } from '@models/super-model.model';
import { Collaborator } from '@models/collaborator.model';
import { User } from './user.model';

export class Project extends SuperModel {
  name: string;
  collaborators: Collaborator[];
  totalReactionsCollected: number;
  totalRetrosynthesisCollected: number;
  totalReactions: number;
  totalSynthesis: number;
  totalRetrosynthesisRoutes: number;
  totalReactionCompletions: number;
  isFavorite: boolean;
  summary: any;

  constructor(project: any = {}) {
    super(project);

    if (this.collaborators) {
      this.collaborators = this.collaborators.map(colaborator => new Collaborator(colaborator));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      colaborators: this.collaborators && this.collaborators.map(collaborator => collaborator.serialised),
    };
  }

  isOwner(user: User): boolean {
    return (this.createdBy instanceof User ? (this.createdBy as User).id : this.createdBy) === user.id;
  }
}
