import { RetrosynthesisMolecule } from '@app/core/models';

export function getStatusBoxProperties(sequenceNode: RetrosynthesisMolecule) {
  const buttonProps = {
    fill: '#0f62fe',
    event: sequenceNode.isInspectable ? `element:inspectBox` : null,
    title: sequenceNode.status as string,
    textStroke: '#ffffff',
  };

  switch (sequenceNode.status) {
    case 'WAITING':
      buttonProps.title = 'Waiting';
      buttonProps.fill = '#0062ff';
      break;
    case 'RUNNING':
      buttonProps.title = 'In progress';
      buttonProps.fill = '#f0c11c';
      break;
    case 'DONE':
      buttonProps.fill = '#42be65';
      buttonProps.title = 'Completed';
      break;
    case 'PENDING':
      buttonProps.title = 'Pending';
      buttonProps.event = undefined;
      buttonProps.fill = '#0062ff';
      break;
    case 'ERROR':
      buttonProps.title = 'Unsuccessfully completed';
      buttonProps.fill = '#fb4b53';
      break;
    case 'SKIPPED':
      buttonProps.title = 'Skipped';
      // buttonProps.event = undefined;
      buttonProps.fill = '#8d8d8d';
      break;
    case 'STOPPED':
      buttonProps.title = 'Stopped';
      buttonProps.fill = '#da1a2a';
      break;
    case 'PAUSED':
      buttonProps.title = 'Paused';
      buttonProps.fill = '#FFA500';
      break;
    default:
      buttonProps.title = sequenceNode.status;
  }

  return buttonProps;
}
