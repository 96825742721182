export enum EActivityType {
  PREDICTION = 'PREDICTION',
  RETROSYNTHESIS = 'RETROSYNTHESIS',
  SYNTHESIS_EXECUTION = 'SYNTHESIS_EXECUTION',
  REACTION_COMPLETION_PREDICTION = 'REACTION_COMPLETION_PREDICTION',
}

export enum EActivityStatus {
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  WAITING = 'WAITING',
  PROCESSING = 'PROCESSING',
  RETROSYNTHESIS_READY = 'RETROSYNTHESIS_READY',
  PREDICTION_READY = 'PREDICTION_READY',
  REACTION_COMPLETION_READY = 'REACTION_COMPLETION_READY',
}
