import { SuperModel } from '@models/super-model.model';
import { AiTrainingFile } from '.';

export class AiTrainingModel extends SuperModel {
  name: string;
  modelType: EAiTrainingModelType;
  status: EAiTrainingModelStatus;
  roundTripAccuracy: number;
  top1Accuracy: number;
  levenshteinDistance: number;
  aiTrainingFile: AiTrainingFile;
  projectIds: string[];
  isDeletable: boolean;
  isRenameable: boolean;
  isStoppable: boolean;
  isSaveable: boolean;
  isReadyToUse: boolean;
  isProjectAssignable: boolean;

  constructor(data: any = {}) {
    super(data);

    if (data.aiTrainingFile) {
      this.aiTrainingFile = new AiTrainingFile(data.aiTrainingFile);
    }

    this.projectIds = data.projectIds || [];
  }

  get typeLabel() {
    return EAiTrainingModelTypeLabel[this.modelType];
  }

  get statusLabel() {
    return EAiTrainingModelStatusLabel[this.status];
  }

  get serialised() {
    return {
      id: this.id,
    };
  }
}

export enum EAiTrainingModelType {
  REACTION = 'REACTION',
  RETROSYNTHESIS = 'RETROSYNTHESIS',
}

export enum EAiTrainingModelTypeLabel {
  REACTION = 'Product prediction',
  RETROSYNTHESIS = 'Retrosynthetic route prediction',
}

export enum EAiTrainingModelStatusLabel {
  TRAINING = 'Training',
  TRAINING_ERROR = 'Training error',
  COMPLETED = 'Completed',
  STOPPED = 'Stopped',
  SAVING = 'Saving',
  SAVE_ERROR = 'Save error',
  SAVED = 'Ready to run',
  DELETED_BY_ADMIN = 'Deleted by admin',
  EXPIRED = 'Expired',
}

export enum EAiTrainingModelStatus {
  TRAINING = 'TRAINING',
  TRAINING_ERROR = 'TRAINING_ERROR',
  COMPLETED = 'COMPLETED',
  STOPPED = 'STOPPED',
  SAVING = 'SAVING',
  SAVE_ERROR = 'SAVE_ERROR',
  SAVED = 'SAVED',
  DELETED_BY_ADMIN = 'DELETED_BY_ADMIN',
  EXPIRED = 'EXPIRED',
}
