import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';

@Directive({
  selector: 'img[ibmAvatar]',
})
export class AvatarDirective implements OnChanges {
  @Input()
  ibmAvatar: string;
  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ibmAvatar.currentValue) {
      this.elementRef.nativeElement.src = changes.ibmAvatar.currentValue;
    } else {
      this.elementRef.nativeElement.src = 'assets/img/avatars/default-avatar.svg';
    }
  }
}

@Directive({
  selector: '[ibmBackgroundAvatar]',
})
export class BackgroundAvatarDirective implements OnChanges {
  @Input()
  ibmBackgroundAvatar: string;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ibmBackgroundAvatar.currentValue) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'backgroundImage',
        `url(${changes.ibmBackgroundAvatar.currentValue})`
      );
    } else {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'backgroundImage',
        'url(assets/img/avatars/default-avatar.svg)'
      );
    }
  }
}
