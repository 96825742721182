import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { QueueState } from '@app/core/models';
import { RetrosynthesisService } from '@app/core/services';

@Component({
  selector: 'ibm-retrosynthesis-queue-state',
  templateUrl: './retrosynthesis-queue-state.component.html',
  styleUrls: ['./retrosynthesis-queue-state.component.scss'],
})
export class RetrosynthesisQueueStateComponent implements OnInit, OnDestroy {
  @Input() showListLink = false;
  queueState: QueueState;
  private subscription = new Subscription();

  constructor(private retrosynthesisService: RetrosynthesisService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.retrosynthesisService.pollQueueState().subscribe(response => {
        this.queueState = response.content;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
