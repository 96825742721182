import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ibm-information-details-menu',
  templateUrl: './information-details-menu.component.html',
  styleUrls: ['./information-details-menu.component.scss'],
})
export class InformationDetailsMenuComponent implements OnInit {
  @Input() title: string;
  @Input() owner: string;
  @Input() createdOn: Date;
  @Input() aiModel: string;

  constructor() {}

  ngOnInit(): void {}
}
