import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ibm-modal-close-button',
  templateUrl: './modal-close-button.component.html',
  styleUrls: ['./modal-close-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCloseButtonComponent {
  @Input()
  autoclose = true;
  @Output()
  click = new EventEmitter<void>();

  constructor() {}
}
