import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUserPlanLimits } from '@app/core/models';
import { environment } from '@env/environment';
import { BaseModal } from 'carbon-components-angular';

@Component({
  selector: 'ibm-upgrade-plan-dialog',
  templateUrl: './upgrade-plan-dialog.component.html',
  styleUrls: ['./upgrade-plan-dialog.component.scss'],
})
export class UpgradePlanDialogComponent extends BaseModal implements OnInit {
  constructor(
    private router: Router,
    @Inject('dialogData')
    public dialogData: { userPlanLimits: IUserPlanLimits }
  ) {
    super();
  }

  ngOnInit(): void {}

  onUpgradePlan() {
    this.closeModal();
    this.router.navigate(environment.upgradeUrl.split('/'));
  }
}
