import { IApiResponse } from '@app/libs/bitforce/api';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, repeatWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ERetrosynthesisStatus, Retrosynthesis } from '../models';

export function retrosynthesisPolling({
  intervalMs,
  service,
}: {
  intervalMs: number;
  service: RetroService;
}) {
  const nextPoll$ = new Subject<void>();
  const complete$ = new Subject<void>();
  let timeoutId: any;
  return of(null).pipe(
    repeatWhen(() => nextPoll$),
    takeUntil(complete$),
    switchMap(() =>
      service.getRetrosynthesisResult().pipe(
        tap(response => {
          if (
            response.metadata &&
            response.metadata.uiMessages &&
            response.metadata.uiMessages.errors.length
          ) {
            throw new Error(ERetrosynthesisStatus.ERROR);
          }

          if (response.content.status === ERetrosynthesisStatus.ERROR) {
            throw new Error(ERetrosynthesisStatus.ERROR);
          }
        }),
        catchError(err => {
          console.error(err);
          throw err;
        })
      )
    ),
    switchMap(response => {
      const success =
        response.content.status === ERetrosynthesisStatus.SUCCESS ||
        response.content.status === ERetrosynthesisStatus.DONE;

      if (success) {
        complete$.next();
        return of(response);
      }

      return of(response).pipe(
        tap(() => {
          timeoutId = setTimeout(() => nextPoll$.next(), intervalMs);
        })
      );
    }),
    finalize(() => clearTimeout(timeoutId)),
    filter(response =>
      [
        ERetrosynthesisStatus.PROCESSING,
        ERetrosynthesisStatus.RETROSYNTHESIS_READY,
        ERetrosynthesisStatus.SUCCESS,
        ERetrosynthesisStatus.NEW,
        ERetrosynthesisStatus.DONE,
      ].includes(response.content.status)
    )
  );
}

interface RetroService {
  getRetrosynthesisResult(): Observable<IApiResponse<Retrosynthesis>>;
}
