import { IApiParser } from '../api.interfaces';
import { configs } from '@configs';

export const getParser = (parserName: string): IApiParser => {
  const selectedParser = configs.parser.parserStrategy[parserName];
  if (!selectedParser) {
    throw new Error(`parser ${parserName} not present`);
  }
  return selectedParser;
};

export const modelMapper = (item, relation: string = null): any =>
  configs.parser.modelsMapperStrategy(item, relation);

export const modelsMapper = (items, relation: string = null): any =>
  items.map(item => configs.parser.modelsMapperStrategy(item, relation));
