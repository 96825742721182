import { UntypedFormControl } from '@angular/forms';

/* check if all controls in group has the same value */
export class MatchOtherValidator {
  static valueChanges$ = null;

  static matchOtherValidator(otherControlName: string) {
    let currentControl: UntypedFormControl;
    let otherControl: UntypedFormControl;

    return function matchOtherValidate(control: UntypedFormControl) {
      if (!control.parent) {
        return null;
      }

      if (!currentControl) {
        currentControl = control;
        otherControl = control.parent.get(otherControlName) as UntypedFormControl;

        if (!otherControl) {
          throw new Error('Other control is not found in parent group');
        }

        MatchOtherValidator.valueChanges$ = otherControl.valueChanges.subscribe(() => {
          currentControl.updateValueAndValidity();
        });
      }

      if (otherControl && otherControl.value !== currentControl.value) {
        return {
          invalidMatchOther: true,
        };
      }

      return null;
    };
  }

  static clearValueChanges() {
    if (MatchOtherValidator.valueChanges$) {
      MatchOtherValidator.valueChanges$.unsubscribe();
    }
  }
}
