<ibm-modal [open]="open"
  [hasScrollingContent]="false"
  size="sm">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>You reached your limit</h3>
  </ibm-modal-header>
  <div ibmModalContent>
    <div [ngClass]="{'mb-4': dialogData.userPlanLimits.reactionLimit}"
      *ngIf="dialogData.userPlanLimits.modelLimit">You reached your limit of
      {{ dialogData.userPlanLimits?.limits?.trainingModels }} model trainings
      included in your plan. To train more models, please upgrade your plan.</div>
    <div *ngIf="dialogData.userPlanLimits.reactionLimit">You reached your limit of
      {{ dialogData.userPlanLimits?.limits?.reactionPredictions }} reaction predictions
      included in your plan. To create more predictions, please upgrade your plan.</div>
  </div>
  <ibm-modal-footer>
    <button ibmButton="secondary"
      (click)="closeModal()">Close</button>
    <button ibmButton="primary"
      (click)="onUpgradePlan()">
      Upgrade Now
    </button>
  </ibm-modal-footer>
</ibm-modal>