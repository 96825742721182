import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseModal, FileItem } from 'carbon-components-angular';

import { ApiService } from '@app/core/services';
import { bitfPromptToDownloadFile } from '@app/libs/bitforce/utils';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';

@Component({
  selector: 'ibm-file-uploader-dialog',
  templateUrl: './file-uploader-dialog.component.html',
  styleUrls: ['./file-uploader-dialog.component.scss'],
})
export class FileUploaderDialogComponent extends BaseModal implements OnInit {
  uploadFileMaxSize = 20971520;
  form: FormGroup;

  constructor(
    @Inject('dialogData')
    public dialogData: {
      dialogTitle: string;
      dialogDescription: string;
      showExampleFile: boolean;
      exampleFileContent: string;
      exampleFileName: string;
      uploadFileMaxSize: number;
      acceptedFileExtensions: string[];
    },
    private formBuilder: FormBuilder,
    private apiService: ApiService
  ) {
    super();
    this.uploadFileMaxSize = dialogData.uploadFileMaxSize || this.uploadFileMaxSize;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      fileId: [null, Validators.required],
    });
  }

  get maxFileSizeString() {
    return (this.uploadFileMaxSize || 0) / 1024 / 1024 + 'MB';
  }

  onSelectedFiles(files: Set<FileItem>) {
    if (files) {
      const selectedFile = Array.from(files)[0];
      if (selectedFile) {
        selectedFile.state = 'upload';
        this.apiService.fileEntries
          .upload<any>({ files: [selectedFile.file] })
          .subscribe(
            (fileResponse: any) => {
              selectedFile.state = 'edit';
              selectedFile.uploaded = true;

              this.form.patchValue({
                fileId: fileResponse.content?.id,
              });
            },
            error => {
              selectedFile.state = 'edit';
              selectedFile.invalid = true;
              selectedFile.invalidText =
                error.error?.metadata?.uiMessages?.errors[0]?.message || error.message;
            }
          );
      }
    }
  }

  onDownloadExampleFile() {
    bitfPromptToDownloadFile(
      this.dialogData.exampleFileContent,
      'txt',
      'txt',
      this.dialogData.exampleFileName
    );
  }

  onConfirm() {
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
      data: {
        fileId: this.form.get('fileId').value,
      },
    } as IDialogCloseEvent<{ fileId: string }>);
  }

  closeModal() {
    this.close.emit({
      status: EDialogCloseEventStatus.CANCEL,
    } as IDialogCloseEvent<void>);
  }
}
