import { Injectable, Injector } from '@angular/core';

import { ApiSuperService } from '@bitf/api';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends ApiSuperService {
  // mockApiUrl = 'https://localhost:3002/';
  constructor(injector: Injector) {
    super(injector);
    this.name = 'config';
  }
}
