import {
  Component,
  OnInit,
  ComponentRef,
  ViewContainerRef,
  ViewChild,
  ComponentFactory,
  ComponentFactoryResolver,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { AppSessionService, EventTrackingService } from '@services';
import { configs } from '@configs';

// TODO we should provide a default toast similar to loader
import { ToastMessagesService } from '@services';
import { LoaderService } from '@bitf/components';
import { AuthService } from '@bitf/services';
import { ApiService } from '../../../../../core/services/api/api.service';
import { EToastMessageType } from '@app/core/services/toast-messages/toast-message.enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bitf-get-auth-token',
  templateUrl: './get-auth-token.component.html',
  styleUrls: ['./get-auth-token.component.scss'],
})
export class GetAuthTokenComponent implements OnInit {
  @ViewChild('content', { read: ViewContainerRef, static: true })
  content;
  componentRef: ComponentRef<Component> = null;
  userIsBlocked = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastMessagesService: ToastMessagesService,
    private router: Router,
    private loaderService: LoaderService,
    private appSessionService: AppSessionService,
    private apiService: ApiService,
    private resolver: ComponentFactoryResolver,
    private eventTrackingService: EventTrackingService
  ) {}

  ngOnInit() {
    this.loaderService.show();

    const token = this.activatedRoute.snapshot.queryParamMap.get(configs.authService.tokenQueryParmName);
    setTimeout(() => {
      // if (token) {
      let componentFactory: ComponentFactory<Component>;
      if (configs.getAuthTokenComponent.signInComponent) {
        componentFactory = this.resolver.resolveComponentFactory(
          configs.getAuthTokenComponent.signInComponent
        );
      }
      if (componentFactory) {
        this.componentRef = this.content.createComponent(componentFactory);
      }
      this.apiService.auth.user$.subscribe(user => {
        if (user.isBlocked === true) {
          this.userIsBlocked = true;
        }
      });
      setTimeout(() => {
        this.generateToken(token);
      }, 300);
      // } else {
      //   this.loaderService.hide();
      //   setTimeout(() => this.router.navigate([configs.general.loginUrl]));
      //   this.toastMessagesService.show({
      //     title: 'Error',
      //     message: 'Error on logging in',
      //     type: EToastMessageType.ERROR,
      //   });
      // }
    }, 300);
  }

  generateToken(token) {
    // TODO pass in constant the shape of this payload
    this.authService.generateToken(token).subscribe(
      data => {
        this.authService.login(data.content);
        if (!this.userIsBlocked) {
          this.toastMessagesService.show({
            title: 'Sign in',
            message: 'You are logged in',
            type: EToastMessageType.SUCCESS,
          });
        }

        this.eventTrackingService.trackLoginLogout('Service Login');
        this.router.navigateByUrl(this.appSessionService.redirectUrl);
        setTimeout(() => {
          this.loaderService.hide();
        }, 500);
      },
      () => {
        this.loaderService.hide();
        setTimeout(() => this.router.navigate([configs.general.loginUrl]));
        this.toastMessagesService.show({
          title: 'Error',
          message: 'Error on logging in',
          type: EToastMessageType.ERROR,
        });
      }
    );
  }
}
