import { Component, OnInit } from '@angular/core';
import { ActionsService } from '@app/core/services';
import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';
import { BaseModal } from 'carbon-components-angular';

@Component({
  selector: 'ibm-release-notes-dialog',
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss'],
})
export class ReleaseNotesDialogComponent extends BaseModal implements OnInit {
  static version = 1;

  constructor(public actionsService: ActionsService) {
    super();
  }

  ngOnInit(): void {}

  onClose() {
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
    } as IDialogCloseEvent<void>);
  }
}
