import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roboSynthesisStatus' })
export class RoboSynthesisStatus implements PipeTransform {
  transform(value: string): string {
    return statusLabel(value);
  }
}

export function statusLabel(value: string): string {
  value = translate(value || '');
  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
}

function translate(value: string) {
  const translations = {
    NEW: 'Draft',
    DONE: 'Completed',
  };

  return translations[value] || value;
}
