import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { UntypedFormControl, ControlContainer, FormGroupDirective } from '@angular/forms';
import * as errorMessages from './validation-messages.json';

@Component({
  selector: 'ibm-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent implements OnInit {
  @Input()
  attachedTo: UntypedFormControl;
  @Input()
  validateOnSubmitOnly = false;
  @Input()
  inputElement: HTMLElement = null;

  constructor(private elementRef: ElementRef, public controlContainer: ControlContainer) {
    elementRef.nativeElement.className += ' invalid-feedback';
  }

  get errors(): any[] {
    if (!this.attachedTo || !this.attachedTo.errors) {
      this.inputElement.removeAttribute('data-invalid');
      return [];
    }

    const errors = [];
    for (const error in this.attachedTo.errors) {
      if (errorMessages['default'][error]) {
        errors.push(errorMessages['default'][error]);
      } else if (error === 'fromServer') {
        errors.push(this.attachedTo.errors[error]);
      }
    }

    if (this.attachedTo.status === 'INVALID' && (this.attachedTo.touched || this.formSubmitted)) {
      this.inputElement.setAttribute('data-invalid', '');
    } else {
      this.inputElement.removeAttribute('data-invalid');
    }

    return errors;
  }

  get formSubmitted() {
    return (this.controlContainer.formDirective as FormGroupDirective).submitted;
  }

  ngOnInit() {}
}
