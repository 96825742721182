import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SideNavMenu } from 'carbon-components-angular';

import { Project } from '@app/core/models';
import { ApiService, AppSessionService, CommonService } from '@app/core/services';
import { IApiSortDirection } from '@app/libs/bitforce/api';
import { EventTrackingService } from '@services';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';

@Component({
  selector: 'ibm-mlrp-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  @ViewChildren(SideNavMenu) sidenavMenus: QueryList<SideNavMenu>;
  @Input() sidenavActive: boolean;

  isFetchingFavoritesProjects = false;
  subscriptions: Subscription = new Subscription();
  projects: Project[] = [];
  currentProject: Project;

  environment = environment;

  constructor(
    public apiService: ApiService,
    public router: Router,
    public appSessionService: AppSessionService,
    public eventTrackingService: EventTrackingService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.appSessionService.projectsFavoritesChanges.subscribe(() => {
        this.loadFavoritesProjects();
      })
    );

    this.subscriptions.add(
      this.appSessionService.currentProject.subscribe(currentProject => {
        this.currentProject = currentProject;
      })
    );

    this.loadFavoritesProjects();
  }

  get isFavoriteProjectMenuExpanded() {
    return (
      (this.currentProject && this.projects.some(project => project.id === this.currentProject.id)) ||
      this.sidenavMenus.first?.expanded
    );
  }

  showCookieConsent() {
    const cookieBtn: any = document.querySelector('#teconsent > a');
    if (cookieBtn) {
      cookieBtn.click();
    }
  }

  loadFavoritesProjects() {
    if (this.apiService.auth.user) {
      this.isFetchingFavoritesProjects = true;
      this.apiService.projects
        .get<Project>({
          query: { favorite: true },
          sorting: [{ property: 'name', direction: IApiSortDirection.ASC }],
        })
        .pipe(finalize(() => (this.isFetchingFavoritesProjects = false)))
        .subscribe(response => {
          this.projects = response.content;
        });
    }
  }

  onCreate(resourceType: 'forward-reaction' | 'retrosynthesis' | 'synthesis' | 'reaction-completion') {
    switch (resourceType) {
      case 'forward-reaction':
        this.commonService.onCreateForwardReaction();
        break;
      case 'retrosynthesis':
        this.commonService.onCreatePredictRetrosyntheticRoutes();
        break;
      case 'synthesis':
        this.commonService.onPlanASynthesis();
        break;
      case 'reaction-completion':
        this.commonService.onCreateReactionCompletion();
        break;
    }
  }

  onExternalLinkClick(url) {
    window.open(url, 'blank');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
