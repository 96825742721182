<div class="retrosynthesis-queue-state-container d-flex align-items-center p-5 mb-5"
  *ngIf="queueState && queueState.itemsInQueue">
  <ibm-loading class="mr-5"></ibm-loading>
  <div *ngIf="!queueState.willBeCompletedOn">
    All your retrosynthetic tasks are currently running.
  </div>
  <div *ngIf="queueState.willBeCompletedOn">
    You have {{ queueState.itemsInQueue }} retrosynthesis requests in the queue. The last
    one is expected to be completed on {{ queueState.willBeCompletedOn | date: 'short' }}.
  </div>
  <a class="ml-5"
    [routerLink]="['..','retrosynthesis']"
    *ngIf="showListLink">View</a>
</div>