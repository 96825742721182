import { Component, Inject, Input, OnInit } from '@angular/core';
import { BaseModal } from 'carbon-components-angular';

import { EDialogCloseEventStatus } from '@app/core/services/dialog/dialog.enum';
import { IDialogCloseEvent } from '@app/core/services/dialog/dialog.interface';

@Component({
  selector: 'ibm-smiles-editor-dialog',
  templateUrl: './smiles-editor-dialog.component.html',
  styleUrls: ['./smiles-editor-dialog.component.scss'],
})
export class SmilesEditorDialogComponent extends BaseModal implements OnInit {
  smiles: string;
  actionLabel: string;
  headerTitle: string;
  maxNumberOfMolecules: number;

  constructor(
    @Inject('dialogData')
    public dialogData: {
      smiles: string;
      actionLabel: string;
      maxNumberOfMolecules: number;
    }
  ) {
    super();

    this.smiles = dialogData.smiles;
    this.actionLabel = dialogData.actionLabel;
    this.maxNumberOfMolecules = dialogData.maxNumberOfMolecules || Infinity;
  }

  ngOnInit() {}

  closeModal() {
    this.close.emit({
      status: EDialogCloseEventStatus.CANCEL,
    } as IDialogCloseEvent<void>);
  }

  onConfirmSmiles({ smiles }: { smiles: string }) {
    this.close.emit({
      status: EDialogCloseEventStatus.OK,
      data: smiles,
    });
  }
}
