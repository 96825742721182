<ibm-modal [open]="open">
  <ibm-modal-header (closeSelect)="closeModal()">
    <h3 ibmModalHeaderHeading>{{ dialogData.dialogTitle }}</h3>
  </ibm-modal-header>
  <div ibmModalContent
    class="ibm-rename-synthesis">
    <p class="font-caption-02 mb-4">
      {{ dialogData.dialogDescription }} ({{maxFileSizeString }} max
      size).<br />
      <a (click)="onDownloadExampleFile()"
        *ngIf="dialogData.showExampleFile"
        title="Download file">Download an example file</a>
    </p>
    <ibm-mlrp-file-uploader [dropText]="'Drag and Drop files here or upload'"
      [maxFileSize]="uploadFileMaxSize"
      [multiple]="false"
      [acceptedExtensions]="dialogData.acceptedFileExtensions || []"
      (selectedFiles)="onSelectedFiles($event)"></ibm-mlrp-file-uploader>
  </div>
  <ibm-modal-footer>
    <button class="bx--btn bx--btn--secondary"
      (click)="closeModal()">Close</button>
    <button class="bx--btn bx--btn--primary"
      (click)="onConfirm()"
      [disabled]="!form.valid"
      modal-primary-focus>
      Confirm
    </button>
  </ibm-modal-footer>
</ibm-modal>