import {
  Directive,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  Inject,
  ElementRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[ibmClipboard]',
})
export class ClipboardDirective implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ibmClipboard')
  value: string = null;

  @Output()
  copyEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  errorEvent: EventEmitter<Error> = new EventEmitter<Error>();

  @HostListener('click')
  copyToClipboard() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari && navigator.clipboard) {
      navigator.clipboard
        .write([
          // @ts-ignore
          new ClipboardItem({
            'text/plain': new Promise((resolve, reject) =>
              resolve(new Blob([this.value], { type: 'text/plain' }))
            ),
          }),
        ])
        .catch(() => {
          this.errorEvent.emit(new Error('The content could not be copied'));
        })
        .then(() => {
          this.copyEvent.emit();
        });
    } else {
      let input: HTMLInputElement = null;

      try {
        input = this.dom.createElement('input');
        input.setAttribute('value', this.value);
        input.style.position = 'absolute';
        input.style.top = '-10000px';
        this.trigger.nativeElement.parentElement.appendChild(input);
        input.select();
        this.dom.execCommand('copy');
        setTimeout(() => this.copyEvent.emit());
      } catch (error) {
        this.errorEvent.emit(new Error('The content could not be copied'));
      } finally {
        this.trigger.nativeElement.parentElement.removeChild(input);
      }
    }
  }

  constructor(@Inject(DOCUMENT) private dom: Document, private trigger: ElementRef) {}

  ngOnInit() {}
}
